import {
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../../../routes/app-nav';
import AdminIcon from '../../../../components/CustomIcons/AdminIcon';
import { AppFeature } from '../../../../utils/constants';
import Box from '@mui/material/Box/Box';
import ChevronLeftIcon from '../../../../components/CustomIcons/ChevronLeftIcon';
import ChildDrawer from './ChildDrawer';
import DashboardIcon from '../../../../components/CustomIcons/DashboardIcon';
import DirectoryIcon from '../../../../components/CustomIcons/DirectoryIcon';
import DrawerItemData from '../../../../types/ui/drawer-item-data';
import LoginUtil from '../../../../utils/login-util';
import LogsIcon from '../../../../components/CustomIcons/LogsIcon';
import MenuIcon from '../../../../components/CustomIcons/MenuIcon';
import MessageIcon from '../../../../components/CustomIcons/MessageIcon';
import PagerIcon from '../../../../components/CustomIcons/PagerIcon';
import ParentDrawer from './ParentDrawer';
import SettingsIcon from '../../../../components/CustomIcons/SettingsIcon';
import SiteMapIcon from '../../../../components/CustomIcons/SiteMapIcon';
import SubDrawerItemData from '../../../../types/ui/sub-drawer-item-data';
import TeamIcon from '../../../../components/CustomIcons/TeamIcon';
import UserSquareIcon from '../../../../components/CustomIcons/UserSquareIcon';
import { useInboxApi } from '../../../../data/inbox/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * DrawerNavigator Component
 * 
 * This component manages the top-level navigation drawer and a potential child drawer
 * based on menu items. It utilizes state, theme, and navigation hooks for functionality,
 * and custom components for the drawers and icons.
 */
const DrawerNavigator: React.FC = () => {
  
  const [isSubDrawerOpen, setIsSubDrawerOpen] = useState(false);
  const location = useLocation();
  const styles = useStyles(isSubDrawerOpen, location.pathname);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [childDrawerItem, setChildDrawerItem] = useState<SubDrawerItemData>();
  const [subMenuToggleMap, setSubMenuToggleMap] = useState<Map<string, boolean>>(new Map());
  const inboxApi = useInboxApi();
  const hasUnreadMsg = inboxApi.state.inbox.messageList.some(item => item.count > 0);
  // Navigation menu items for the top section
  const drawerTopMenuList = [
    {
      label: LoginUtil.getUserFullName(),
      isAvatar: true,
      avatarUrl: LoginUtil.getProfileImage(),
      route: APP_NAV.PROFILE,
      hide: isMobile
    },
    {
      icon: <MessageIcon />,
      hasBadge: hasUnreadMsg,
      route: APP_NAV.INBOX
    },
    {
      icon: <DirectoryIcon />,
      route: APP_NAV.DIRECTORY
    },
    {
      icon: <DashboardIcon />,
      route: APP_NAV.DASHBOARD,
      hide: isMobile
    },
    {
      icon: <AdminIcon />,
      route: APP_NAV.ADMIN,
      hide: isMobile,
      subDrawerItem: {
        label: t('admin'),
        menuList: [
          {
            label: t('users'),
            icon: <UserSquareIcon />,
            route: APP_NAV.ADMIN_USERS,
            hide: !LoginUtil.hasPermission(AppFeature.ManageUser)
          },
          {
            label: t('departments'),
            icon: <SiteMapIcon />,
            route: APP_NAV.ADMIN_DEPARTMENTS,
            hide: !LoginUtil.hasPermission(AppFeature.ManageDepartment)
          },
          {
            label: t('devices'),
            icon: <PagerIcon />,
            route: APP_NAV.ADMIN_DEVICES,
            hide: !LoginUtil.hasPermission(AppFeature.ManagePager)
          },
          {
            label: t('logs'),
            icon: <LogsIcon />,
            route: APP_NAV.ADMIN_LOGS,
            menuList: [
              {
                label: t('call'),
                route: APP_NAV.ADMIN_LOGS_CALL
              },
              // {
              //   label: t('system'),
              //   route: APP_NAV.ADMIN_LOGS_SYSTEM
              // }
            ]
          },
          {
            label: t('settings'),
            icon: <SettingsIcon />,
            route: APP_NAV.ADMIN_SETTINGS,
            hide: !LoginUtil.hasPermission(AppFeature.ManageSystemSetting),
            menuList: [
              {
                label: t('domains'),
                route: APP_NAV.ADMIN_SETTINGS_DOMAINS
              },
              {
                label: t('permissions'),
                route: APP_NAV.ADMIN_SETTINGS_PERMISSIONS
              },
              {
                label: t('sites'),
                route: APP_NAV.ADMIN_SETTINGS_SITES
              }
            ]
          },
          {
            label: t('teams'),
            icon: <TeamIcon />,
            route: APP_NAV.ADMIN_TEAMS,
            hide: !LoginUtil.hasPermission(AppFeature.ManageTeam)
          },
        ]
      }
    },
    {
      icon: <UserSquareIcon />,
      route: APP_NAV.PROFILE,
      hide: !isMobile
    }
  ];

  /**
   * This effect runs on component mount and iterates through drawerTopMenuList.
   * It checks if the current route starts with the item's route and if it has a subDrawerItem.
   * If both conditions are met, it sets childDrawerItem state with the corresponding sub-drawer item object.
   * This ensures proper child drawer visibility on page reloads.
   */
  useEffect(() => {
    drawerTopMenuList.map(item => {
      if (location.pathname.startsWith(item.route) && item.subDrawerItem) {
        const subItem = item.subDrawerItem;
        subItem.menuList = item.subDrawerItem.menuList.filter(item => !item.hide);
        setChildDrawerItem(item.subDrawerItem);
      }
    });
  }, []);
  
  /**
   * Function to check if the provided route matches the current pathname.
   * @param {string} route - The route path to check.
   * @returns {boolean} - True if the route matches the current pathname, false otherwise.
   */
  const isActiveRoute = (route: string): boolean => {
    
    return location.pathname === route;
  };

  /**
   * Function to handle clicks on top-level drawer items.
   * @param {DrawerItemData} item - The clicked drawer item object.
   */
  const onDrawerItemClick = (item: DrawerItemData) => {
    if (!location.pathname.startsWith(item.route)) {
      onChildDrawerItemClick(item);
      if (item.subDrawerItem?.menuList) {
        item.subDrawerItem.menuList = item.subDrawerItem?.menuList?.filter(item => !item.hide);
      }
      setChildDrawerItem(item.subDrawerItem);
    }
  };

  /**
   * Function to handle clicks on child drawer items.
   * @param {DrawerItemData} item - The clicked child drawer item object.
   */
  const onChildDrawerItemClick = (item: DrawerItemData) => {
    setIsSubDrawerOpen(false);
    !isActiveRoute(item.route) && navigate(item.route);
  }

  /**
   * Handles clicks on submenu headers.
   * Toggles the open/closed state of the submenu associated with the clicked label.
   * 
   * @param {string} label The label of the submenu header that was clicked.
   */
  const onSubmenuHeaderClick = (label: string) => {
    setSubMenuToggleMap(prevMap => {
      const newMap = new Map<string, boolean>(prevMap);
      const currentValue = prevMap.get(label) || false;
      newMap.set(label, !currentValue);
      return newMap;
    });
  }

  return (
    <Box sx={styles.container}>
      <ParentDrawer topMenuList={drawerTopMenuList} onDrawerItemClick={onDrawerItemClick} />
      <ChildDrawer
        isChildDrawerOpen={isSubDrawerOpen}
        childDrawerItem={childDrawerItem}
        subMenuToggleMap={subMenuToggleMap}
        onSubmenuHeaderClick={onSubmenuHeaderClick}
        onDrawerItemClick={onChildDrawerItemClick}
        onClose={() => setIsSubDrawerOpen(false)}
      />
      {childDrawerItem &&
        <IconButton sx={styles.floatingBtn} onClick={() => setIsSubDrawerOpen(!isSubDrawerOpen)}>
          {isSubDrawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      }
    </Box>
  );
};

export default DrawerNavigator;
