import Dimens from '../../../../theme/dimens';

/**
 * useStyles hook
 * 
 * This hook creates a styles object containing styling information for the 
 * `ChatItem` component.
 * 
 * @returns {object} An object containing styles for the `ChatItem` component.
 */
const useStyles = () => {

  const styles = {
    dateHeaderWrapper: {
      mb: Dimens.spacing.lg,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    dateHeaderLabel: {
      pr: Dimens.spacing.xxs
    }
  };

  return styles;
};

export default useStyles;