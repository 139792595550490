import { ActualSessionStatus, AttachmentStatus, ChatRoomKey, InteractionMarkingType, InteractionType, MessageSendStatus } from '../../utils/constants';
import React, { useContext } from 'react';
import { UndeliveredInteractionGroupMessage, UndeliveredInteractionHistoryData, UndeliveredInteractionHistoryResponse, UndeliveredInteractionPrivateMessage } from '../../types/undelivered-interaction-history-response';
import { apiFailure, chatHistorySuccess, chatSendAPI, chatSendSuccess, fetchChatHistoryAPI, fetchInteractionHistoryAPI, fetchUndeliveredInteractionHistoryAPI, initMarkChatAsDeliveredApi, initMarkChatAsReadApi, initMessageInfoFetch, interactionHistorySuccess, markReceivedChatAsDeliveredSuccess, markReceivedChatAsReadSuccess, messageInfoSuccess, resetInboxState, undeliveredInteractionHistorySuccess, updateMessageAttachment, updateSessionStatus, updateSessionStatusSuccess } from './actions';
import { doGet, doMultipartPost, doPost } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import ChatHistoryDispatchPayload from '../../types/chat-history-dispatch-payload';
import ChatHistoryResponse from '../../types/chat-history-response';
import ChatUtil from '../../utils/chat-util';
import FileUploadResponse from '../../types/file-upload-response';
import GroupChatHistoryResponse from '../../types/group-chat-history-response';
import { HttpStatusCode } from 'axios';
import InteractionHistoryResponse from '../../types/interaction-history-response';
import LoginUtil from '../../utils/login-util';
import MarkChatAsDeliveredReadRequest from '../../types/mark-chat-as-delivered-read-request';
import MessageData from '../../types/message-data';
import MessageInfoRequest from '../../types/message-info-request';
import MessageInfoResponse from '../../types/message-info-response';
import SendMessageResponse from '../../types/send-message-response';
import { SessionExpirationContext } from '../../store/session-expiration-provider';
import SessionStatusRequest from '../../types/session-status-request';
import { Store } from '../../store/store';
import Util from '../../utils/util';
import { t } from 'i18next';
import { useCallback } from 'react';

/**
 * useInboxApi Hook
 * 
 * This custom React hook provides a set of API-related functionalities for managing and interacting
 * with chat and messaging features. It leverages Redux for state management and contains methods 
 * for fetching chat history, sending messages, marking messages as read or delivered, and managing
 * session statuses.
 *
 * Dependencies:
 * - React and Redux for state management.
 * - API configuration, service utilities, and data types from the project's utility files.
 *
 * @returns {Object} - An object containing functions and the current state for inbox management.
 */
export function useInboxApi() {

  const { state, dispatch } = React.useContext(Store);
  const { setSessionExpired } = useContext(SessionExpirationContext);

  /**
   * Updates the chat session status.
   *
   * @param {SessionType} sessionType - The type of session (e.g., private, group).
   * @param {ActualSessionStatus} sessionStatus - The new session status.
   */
  const updateChatSessionStatus = useCallback(async (sessionStatus: ActualSessionStatus) => {
    dispatch(updateSessionStatus());
    try {
      const sessionStatusRequest: SessionStatusRequest = {
        loginId: LoginUtil.getLoginId(),
        actualSessionStatus: sessionStatus,
        deviceId: LoginUtil.getClientId()
      };
      await doPost(APIConfig.chatSession, sessionStatusRequest);
      dispatch(updateSessionStatusSuccess(sessionStatusRequest));
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Fetches and updates inbox list data. This function is memoized using useCallback for efficiency.
   * 
   * @returns {Promise<void>} - A promise that resolves after fetching and updating inbox data.
   */
  const fetchInteractionHistory = useCallback(async () => {
    dispatch(fetchInteractionHistoryAPI());
    try {
      const url = APIConfig.interactionHistory
        .replace('{login_id}', LoginUtil.getLoginId());
      const response: InteractionHistoryResponse = await doGet(url);
      dispatch(interactionHistorySuccess(response.data || []));
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
  * Fetches undelivered interaction history for the current user.
  *
  * @returns {Promise<Array>} - Resolves with the list of undelivered interactions or handles errors.
  */
  const fetchUndeliveredInteractionHistory = useCallback(async () => {
    dispatch(fetchUndeliveredInteractionHistoryAPI());
    try {
      const url = APIConfig.undeliveredInteractionHistory.replace('{login_id}', LoginUtil.getLoginId());
      const response: UndeliveredInteractionHistoryResponse = await doGet(url);
      const data = response.data as UndeliveredInteractionHistoryData;

      if (data?.privateMessages) {
        await processUndeliveredInteractionHistoryMessages(data.privateMessages, InteractionType.Private);
      }
      if (data?.groupMessages) {
        await processUndeliveredInteractionHistoryMessages(data.groupMessages, InteractionType.Group);
      }
      dispatch(undeliveredInteractionHistorySuccess());

    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * fetchPrivateChatHistory function
   * 
   * This function fetches the chat history for a private conversation with 
   * the specified `recipientId`. It dispatches an initial API call action 
   * (`initApi`) followed by a success or failure action depending on the 
   * outcome.
   * 
   * @param {string} recipientId - ID of the recipient user.
   * 
   */
  const fetchPrivateChatHistory = useCallback(async (recipientId: string, page: number) => {
    dispatch(fetchChatHistoryAPI());
    try {
      const url = APIConfig.privateChatHistory
        .replace('{login_id}', LoginUtil.getLoginId())
        .replace('{receipient_id}', recipientId)
        .replace('{page}', page.toString());
      const response: ChatHistoryResponse = await doGet(url);
      const payload: ChatHistoryDispatchPayload = {
        roomKey: recipientId,
        chatHistory: response.data
      };
      dispatch(chatHistorySuccess(payload));
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * fetchGroupChatHistory function
   * 
   * This function fetches the chat history for a group chat with the specified 
   * `roomKey`. It dispatches an initial API call action (`initApi`) followed 
   * by a success or failure action depending on the outcome.
   *
   * @param {string} roomKey - Key of the group chat room.
   *  
   */
  const fetchGroupChatHistory = useCallback(async (roomKey: string, page: number) => {
    dispatch(fetchChatHistoryAPI());
    try {
      const url = APIConfig.groupChatHistory
        .replace('{login_id}', LoginUtil.getLoginId())
        .replace('{room_key}', roomKey)
        .replace('{page}', page.toString());
      const response: GroupChatHistoryResponse = await doGet(url);
      const payload: ChatHistoryDispatchPayload = {
        roomKey: roomKey,
        chatHistory: response.data.groupMessageResponseList,
        memberList: response.data.memberData
      };
      dispatch(chatHistorySuccess(payload));
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * sendChat function
   * 
   * This function sends a chat message using a POST request. 
   * It utilizes the `useCallback` hook to memoize the function, preventing 
   * unnecessary re-renders when its dependencies don't change.
   * 
   * @param messageData {MessageData} Object containing the message data to be sent.
   * 
   * @returns {Promise<SendMessageResponse | undefined>} Promise resolving to the API response 
   * containing the sent message data on success, or undefined on error.
   */
  const sendChat = useCallback(async (messageData: MessageData) => {
    messageData.localMessageId = Util.generateClientId();
    messageData.status = (messageData.file && !messageData.attachmentKey) ? MessageSendStatus.FileUpload
      : MessageSendStatus.InProgress;
    messageData.senderDeviceId = LoginUtil.getClientId();
    messageData.createdAt = new Date().toUTCString();
    dispatch(chatSendAPI(messageData));
    try {
      if (messageData.file && !messageData.attachmentKey) {
        const formData = new FormData();
        formData.append('file', messageData.file);
        const response: FileUploadResponse = await doMultipartPost(APIConfig.chatFileUpload, formData);
        messageData.status = MessageSendStatus.InProgress;
        messageData.attachmentKey = response.data;
        dispatch(chatSendSuccess(messageData));
      }
      const url = messageData.groupRoomKey ? APIConfig.sendGroupChat : APIConfig.sendPrivateChat;
      const response: SendMessageResponse = await doPost(url, messageData);
      const responseData = response.data;
      responseData.status = MessageSendStatus.Sent;
      responseData.localMessageId = messageData.localMessageId;
      responseData.file = messageData.file;
      responseData.recipientAssignedSessionStatus = messageData.recipientAssignedSessionStatus;
      dispatch(chatSendSuccess(responseData));

      return responseData;
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
      messageData.error = (error && error.message) ? error.message : t('defaultErrorMsg');
      messageData.status = MessageSendStatus.Failed;
      dispatch(chatSendSuccess(messageData));
    }
  }, []);

  /**
   * markAsRead function
   * 
   * This function marks unread messages as read by sending a POST request 
   * to the API endpoint defined in `APIConfig.markChatAsRead`.
   * 
   * @param request {ChatMarkAsReadRequest} Object containing details about the messages to mark as read.
   * 
   * @returns {Promise<void>} - This function returns a Promise that resolves 
   * upon successful marking or rejects on error.
   */
  const markAsRead = useCallback(async (request: MarkChatAsDeliveredReadRequest) => {
    dispatch(initMarkChatAsReadApi());
    try {
      await doPost(APIConfig.markChatInteraction, request);
      dispatch(markReceivedChatAsReadSuccess(request));
    } catch (error: any) { /* eslint-disable-line */
      if (error.code === HttpStatusCode.BadRequest) {
        dispatch(markReceivedChatAsReadSuccess(request));
      } else {
        dispatchFailureAction(error);
      }
    }
  }, []);

  /**
  * Marks specified chat messages as delivered.
  *
  * @param {MarkChatAsDeliveredReadRequest} request - Details of the messages to be marked as delivered.
  * @returns {Promise<void>} - Resolves upon successful marking or handles errors internally.
  */
  const markAsDelivered = useCallback(async (request: MarkChatAsDeliveredReadRequest) => {
    dispatch(initMarkChatAsDeliveredApi());
    try {
      await doPost(APIConfig.markChatInteraction, request);
      dispatch(markReceivedChatAsDeliveredSuccess(request));
    } catch (error: any) { /* eslint-disable-line */
      if (error.code === HttpStatusCode.BadRequest) {
        dispatch(markReceivedChatAsDeliveredSuccess(request));
      } else {
        dispatchFailureAction(error);
      }
    }
  }, []);

  /**
   * Fetches message information for a specific message.
   *
   * @param {MessageData} messageData - The message data object containing details like message ID
   * and group room key (if applicable).
   * @param {MessageInfoRequest} request - An object specifying the message details needed (e.g., group room key, message ID).
   * 
   * @returns {Promise<MessageInfo | undefined>} A promise that resolves to the fetched message info
   * data on success, or undefined on error.
   */
  const fetchMessageInfo = useCallback(async (messageData: MessageData, request: MessageInfoRequest) => {
    dispatch(initMessageInfoFetch());
    try {
      const response: MessageInfoResponse = await doPost(APIConfig.messageDetail, request);
      messageData.messageInfo = response.data;
      dispatch(messageInfoSuccess(messageData));

      return response.data;
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Downloads an attachment associated with a message.
   *
   * @param {MessageData} messageData - The message data containing attachment information.
   * @param {Dispatch<Action>} dispatch - Function to dispatch actions for state updates.
   * 
   * @returns {Promise<Blob | undefined>} A promise that resolves to the downloaded blob on success, or undefined on error.
   */
  const downloadAttachment = useCallback(async (messageData: MessageData) => {
    try {
      messageData.attachmentStatus = AttachmentStatus.DOWNLOADING;
      dispatch(updateMessageAttachment(messageData));
      const response = await doGet(APIConfig.chatFileDownload + encodeURIComponent(messageData.attachmentKey ?? ''), 'blob');
      const mimeType = Util.getMimeTypeFromPath(messageData.attachmentKey ?? '');
      const blob = new Blob([response], { type: mimeType });
      messageData.file = new File([blob], Util.getFilenameFromPath(messageData.attachmentKey ?? ''),
        {
          type: mimeType
        });
      messageData.attachmentStatus = AttachmentStatus.DOWNLOADED;
      dispatch(updateMessageAttachment(messageData));

      return response;
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
      messageData.error = (error && error.message) ? error.message : t('defaultErrorMsg');
      messageData.attachmentStatus = AttachmentStatus.NOT_DOWNLOADED;
      dispatch(updateMessageAttachment(messageData));
    }
  }, []);

  /**
   * Dispatches an `apiFailure` action with an error message for failure scenarios during messaging apis.
   * 
   * @param {Error} error - Optional error object encountered during sign-in/out processes.
   */
  const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
    const message: string = error?.message || t('defaultErrorMsg');
    dispatch(apiFailure(new AppError(error?.code, message)));
    if (error?.code === HttpStatusCode.Unauthorized) {
      setSessionExpired(true);
    }
  }

  /**
   * Resets the inbox state to its initial state.
   *
   * @param {Dispatch<Action>} dispatch - Function to dispatch actions for state updates.
   */
  const resetInbox = () => {
    dispatch(resetInboxState());
  }

  /**
   * Processes undelivered interaction history messages and marks them as delivered.
   * 
   * Groups messages by their respective room keys and sends a "mark as delivered" request for each group.
   * 
   * @param {Array<UndeliveredInteractionPrivateMessage | UndeliveredInteractionGroupMessage>} messages - 
   *        List of undelivered messages to process.
   * @param {InteractionType} interactionType - The type of interaction (Private or Group).
   * @returns {Promise<void>} - Resolves after processing all undelivered messages.
   */
  const processUndeliveredInteractionHistoryMessages = async (
    messages: Array<UndeliveredInteractionPrivateMessage | UndeliveredInteractionGroupMessage>,
    interactionType: InteractionType
  ) => {

    // Ignore messages where senderLoginId same as that of loged in user
    const filteredMessages = messages.filter(message => message.senderLoginId !== LoginUtil.getLoginId());

    // Group messages by their room key.
    const groupedMessages = filteredMessages.reduce<Record<string, string[]>>((acc, message) => {
      const roomKey = interactionType === InteractionType.Private && ChatUtil.hasKey(ChatRoomKey.Private, message)
        ? String(message[ChatRoomKey.Private])
        : interactionType === InteractionType.Group && ChatUtil.hasKey(ChatRoomKey.Group, message)
          ? String(message[ChatRoomKey.Group])
          : undefined;

      if (roomKey) {
        acc[roomKey] = acc[roomKey] || [];
        acc[roomKey].push(message.messageId);
      }

      return acc;
    }, {});

    // Mark messages as delivered in batches by room key.
    Object.entries(groupedMessages).map(([roomKey, messageIds]) => {
      const request: MarkChatAsDeliveredReadRequest = {
        loginId: LoginUtil.getLoginId(),
        roomKey,
        interactionType,
        messageIds,
        deviceId: LoginUtil.getClientId(),
        markType: InteractionMarkingType.Delivered
      };
      markAsDelivered(request);
    });
  }

  return {
    updateChatSessionStatus,
    fetchInteractionHistory,
    fetchUndeliveredInteractionHistory,
    fetchPrivateChatHistory,
    fetchGroupChatHistory,
    sendChat,
    markAsRead,
    markAsDelivered,
    fetchMessageInfo,
    downloadAttachment,
    resetInbox,
    state
  };
}