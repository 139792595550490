import Colors from '../../../theme/colors'
import Dimens from '../../../theme/dimens'
import useCommonStyles from '../../../theme/use-common-styles'
import { useTheme } from '@mui/material'

/**
 * Generates MUI theme-dependent styles for the UserAutocompleteChipSelect component.
 *
 * @returns An object containing styled classes for various component elements.
 */
const useStyles = (isReadOnly = false, isMultiple = false) => {
  const theme = useTheme()
  const commonStyles = useCommonStyles()
  const { xxs, xs, sm, base, md, lg } = Dimens.spacing

  const styles = {
    wrapper: {
      flexDirection: 'column',
      display: 'flex',
      pb: base
    },
    autocomplete: {
      display: isReadOnly && isMultiple ? 'none' : 'flex'
    },
    textField: {
      '& .MuiOutlinedInput-root': {
        p: `${md} ${lg} !important`,
        '.MuiAutocomplete-endAdornment': {
          mr: xxs
        },
        '.MuiAutocomplete-input': {
          height: 'inherit',
          ...theme.typography.p1,
          padding: `0 !important`
        },
        '& fieldset': {
          borderColor: theme.palette.background.default
        },
        '&:hover fieldset': {
          borderColor: theme.palette.background.default
        },
        '&.Mui-focused fieldset': {
          borderWidth: Dimens.inputBorderWidth,
          borderColor: theme.palette.primary.main
        },
        '&.Mui-error fieldset': {
          borderWidth: Dimens.inputBorderWidth,
          borderColor: theme.palette.error.main
        }
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
        ...theme.typography.p1,
        color: theme.palette.text.secondary,
        opacity: 1
      },
      '.MuiInputBase-multiline': {
        p: 0
      }
    },
    outerInput: {
      borderRadius: Dimens.radius.md,
      backgroundColor: theme.palette.background.default
    },
    endIcon: {
      ...commonStyles.smIcon,
      color: theme.palette.text.secondary
    },
    menuItem: {
      ...commonStyles.borderBottom,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        backgroundColor: theme.palette.background.default
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          backgroundColor: theme.palette.background.default
        }
      }
    },
    divider: {
      my: '0 !important',
      borderBottomWidth: Dimens.menuBorderWidth,
      borderColor: theme.palette.background.default
    },
    fieldError: {
      ...theme.typography.footer,
      m: 0,
      pt: xs,
      color: theme.palette.error.main
    },   
    chip: {
      m: `${xxs} ${xs} ${xxs} 0`,
      borderRadius: Dimens.radius.md,
      p: `${sm} ${lg}`,
      height: 'auto',
      '.MuiChip-label': {
        ...theme.typography.p1,
        ...commonStyles.textEllipsis(1),
        whiteSpace: 'normal',
        p: 0
      },
      '.MuiChip-deleteIcon': {
        display: isReadOnly ? 'none' : 'flex',
        m: 0,
        ml: base,
        fontSize: Dimens.icon.sm,
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.text.primary
        }
      },
      '& .MuiChip-avatar': {
        fontSize: Dimens.icon.sm,
        m: 0,
        mr: base,
        width: Dimens.icon.sm,
        height: Dimens.icon.sm
      }
    },
    popper: {
      '.MuiAutocomplete-paper': {
        border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        borderRadius: Dimens.radius.md,
        maxHeight: Dimens.dropDownMaxHeight,
        mt: Dimens.spacing.sm,
        boxShadow: `0 ${Dimens.spacing.xxs} ${Dimens.spacing.xs} 0 ${Colors.shadow}`,
        ul: {
          ...commonStyles.noScrollBar,
          p: 0,
          li: {
            p: `${md} ${lg}`
          }
        }
      }
    },
    content: {
      ...commonStyles.borderBottom,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      p: `${lg} ${xs} ${lg} 0`,
      '&:hover': {
        '& .MuiIconButton-root': {
          display: 'flex'
        }
      }
    },
    avatar: {
      width: Dimens.avatarSize.lg,
      height: Dimens.avatarSize.lg,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary,
      mr: xs
    },
    contentText: {
      flexDirection: 'column',
      display: 'flex',
      px: lg
    },
    primaryText: {
      ...commonStyles.textEllipsis(1),
      color: theme.palette.text.primary
    }
  }

  return styles
}

export default useStyles
