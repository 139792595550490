import { AppFeature, SortByAlphabet } from '../../utils/constants';
import { Box, Table, TableBody, TableContainer } from '@mui/material';
import { DELETE_USER_TYPE, FETCH_USER_TYPE_LIST, UPDATE_USER_TYPE } from '../../data/user-type/action-types';
import React, { useEffect, useState } from 'react';

import APP_NAV from '../../routes/app-nav';
import AddIcon from '../../components/CustomIcons/AddIcon';
import AddNewPermissionDialog from './AddNewPermissionDialog';
import AlertDialogSmall from '../../components/AlertDialogSmall';
import BinIcon from '../../components/CustomIcons/BinIcon';
import CircularProgressBar from '../../components/CircularProgressBar';
import HPermissionTableHeader from './HPermissionTableHeader';
import InlineAlert from '../../components/InlineAlert';
import LoginUtil from '../../utils/login-util';
import NoDataFound from '../../components/NoDataFound';
import Permission from '../../types/permission';
import PermissionTableFooter from './PermissionTableFooter';
import PermissionTableRow from './PermissionTableRow';
import SearchAndSortTopBar from '../../components/SearchAndSortTopBar';
import UserType from '../../types/user-type';
import Util from '../../utils/util';
import VPermissionTableHeader from './VPermissionTableHeader';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserTypeApi } from '../../data/user-type/api';

/**
 * Permission Screen Component
 *
 * This component displays a table for managing permissions for different user types.
 * It includes features like searching, sorting, adding new permissions, and deleting existing permissions.
 */
const PermissionScreen: React.FC = () => {

  const styles = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState<SortByAlphabet>(SortByAlphabet.A_Z);
  const [showAddPermissionDialog, setShowAddPermissionDialog] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedUserType, setSelectedUserType] = useState<UserType>();
  const [collapsedHeaders, setCollapsedHeaders] = useState<Record<string, boolean>>({});
  const userTypeApi = useUserTypeApi();
  const apiStatus = userTypeApi.state.userType.apiStatus;

  /**
   * Initializes user type and app feature fetches if the user has the necessary permission.
   *
   * This effect checks if the logged-in user has the `ManageSystemSetting` permission. If yes,
   * it initiates fetching the user type list and app feature list. If not, it navigates to the
   * appropriate route based on the user's permissions (determined by `Util.getNavigationByPermission()`).
   */
  useEffect(() => {
    if (LoginUtil.hasPermission(AppFeature.ManageSystemSetting)) {
      userTypeApi.fetchUserTypeList();
      userTypeApi.fetchAppFeatureList();
    } else {
      navigate(Util.getNavigationByPermission());
    }
  }, []);

  /**
   * Filters the user type list based on search key and sort order.
   *
   * @returns {Array<UserType>} The filtered user type list.
   */
  const getFilteredUserTypeList = (): Array<UserType> => {

    return userTypeApi.state.userType.userTypeList.filter((userType) =>
      userType.userType.toLowerCase().includes(searchKey.toLowerCase())
    ).sort((a, b) => {
      const comparison = a.userType.localeCompare(b.userType);

      return sortBy === SortByAlphabet.A_Z ? comparison : -comparison;
    });
  }

  const userTypeList = getFilteredUserTypeList();

  /**
   * Handles changes in permission checkboxes.
   *
   * Updates the user type's permissions based on the checked state.
   *
   * @param {UserType} userType The user type.
   * @param {Permission} feature The permission.
   * @param {boolean} checked The checked state of the checkbox.
   */
  const onPermissionChanged = (userType: UserType, feature: Permission, checked: boolean): void => {
    if (checked) {
      userType.permissions.push({ id: feature.id });
    } else {
      userType.permissions = userType.permissions.filter(p => p.id !== feature.id);
    }
    userTypeApi.updateUserType(userType);
  }

  /**
   * Handles deleting a user type (to be implemented).
   *
   * @param {UserType} userType The user type to delete.
   */
  const onPermissionDeleteClick = (userType: UserType) => {
    setSelectedUserType(userType);
    setOpenDeleteDialog(true);
  }

  /**
   * Handles deleting a selected user type.
   *
   * Closes the delete dialog, deletes the selected user type using the userTypeApi, and updates the selectedUserType state if the deletion is successful.
   */
  const deletePermission = () => {
    setOpenDeleteDialog(false);
    if (selectedUserType) {
      userTypeApi.deleteUserType(selectedUserType).then(userType => {
        if (userType) {
          setSelectedUserType(undefined);
        }
      });
    }
  }

  /**
   * Handles toggling the collapse/expand state of a feature header.
   * 
   * This function updates the `collapsedHeaders` state by flipping the collapse state 
   * of the specified header. If the header is currently collapsed, it will be expanded, 
   * and vice versa. If the `id` is undefined, the function returns early without making 
   * any changes.
   *
   * @param {string | undefined} id - The unique identifier of the feature header to toggle.
   */
  const handleCollapseToggle = (id: string | undefined) => {
    if (!id) return; // Handle undefined
    setCollapsedHeaders((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Box sx={styles.container}>
      <SearchAndSortTopBar
        title={t('permissions')}
        subTitle={t('managePermissions')}
        actionText={t('new')}
        actionIcon={<AddIcon />}
        onActionClick={() => setShowAddPermissionDialog(true)}
        sortBy={sortBy}
        onSortChange={setSortBy}
        onSearchChange={setSearchKey}
      />
      <Box sx={styles.content}>
        <InlineAlert message={Util.getApiError([FETCH_USER_TYPE_LIST, DELETE_USER_TYPE], apiStatus)} />
        {Util.isArrayEmpty(userTypeList) ? (
          <NoDataFound message={t('noPermissionsFound')} />
        ) : (
          <TableContainer sx={styles.tableContainer}>
            <Table stickyHeader>
              <HPermissionTableHeader userTypeList={userTypeList} />
              <TableBody component={'tbody'}>
                {userTypeApi.state.userType.appFeatureList.map((featureHeader, index) => {
                  const headerKey = featureHeader.label ?? `header-${index}`; // fallback to `header-{index}`
                  const isCollapsed = collapsedHeaders[headerKey];
                  return (
                  [<VPermissionTableHeader
                    key={`feature-row-header-${index}`}
                    featureHeader={featureHeader}
                    colSpan={userTypeList.length}
                    isCollapsed={isCollapsed}
                    onToggleCollapse={() => handleCollapseToggle(featureHeader.label)
                    }
                  />,
                  !isCollapsed &&
                  (featureHeader.data ?? []).map((feature, index) =>
                    <PermissionTableRow
                      key={`feature-row-${index}`}
                      feature={feature}
                      userTypeList={userTypeList}
                      onPermissionChanged={onPermissionChanged}
                    />
                  )]
                )}
                )}
                <PermissionTableFooter userTypeList={userTypeList} onPermissionDelete={onPermissionDeleteClick} />
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <AddNewPermissionDialog
        open={showAddPermissionDialog}
        userTypeList={userTypeApi.state.userType.userTypeList}
        onClose={() => setShowAddPermissionDialog(false)}
      />
      <AlertDialogSmall
        open={openDeleteDialog}
        title={t('deletePermission')}
        titleIcon={<BinIcon />}
        message={t('deletePermissionMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeleteDialog(false)}
        primaryLabel={t('delete')}
        onPrimaryAction={deletePermission}
        isDestructive
        onClose={() => setOpenDeleteDialog(false)}
      />
      <CircularProgressBar show={Util.isApiLoading([FETCH_USER_TYPE_LIST, DELETE_USER_TYPE], apiStatus)} />
    </Box>
  );
};

export default PermissionScreen;
