import { useMediaQuery, useTheme } from '@mui/material';

import Colors from '../../../../theme/colors';
import Dimens from '../../../../theme/dimens';
import ThemeUtil from '../../../../theme/theme-util';

/**
 * Hook to generate styles for a Material UI dialog component.
 *
 * This hook utilizes Material UI theming and breakpoints to create responsive styles.
 *
 * @returns {Object} Object containing styled classes for the dialog component.
 */
const useStyles = () => {

  const { xs, base, lg, xl } = Dimens.spacing;
  const theme = useTheme();
  const isLightTheme = ThemeUtil.isLightTheme(theme);

  const styles = {
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        maxWidth: Dimens.alertDialogSmallWidth.lg,
        p: `${xl} ${xl} ${lg} ${xl}`,
        [theme.breakpoints.down('sm')]: {
          maxWidth: Dimens.alertDialogSmallWidth.sm
        }
      },
      '& .MuiDialogTitle-root': {
        p: 0
      },
      '& .MuiDialogContent-root': {
        p: 0
      },
      '& .MuiDialogActions-root': {
        p: 0,
        flexDirection: 'column',
        alignItems: 'normal'
      }
    },
    header: {
      flexDirection: 'row',
      flex: 1,
      alignItems: 'start',
      justifyContent: 'space-between',
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    iconWrapper: {
      bgcolor: isLightTheme ? Colors.palette.error[100] : Colors.palette.error[600],
      borderRadius: Dimens.radius.xxl,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      padding: Dimens.highlightIconPadding,
      border: `${xs} solid ${isLightTheme ? Colors.palette.error[50] : Colors.palette.error[700]}`,
      'svg': {
        color: isLightTheme ? theme.palette.error.main : theme.palette.background.paper
      }
    },
    message: {
      p: `${xs} 0 ${lg} 0`,
    },
    btnLayout: {
      flexDirection: useMediaQuery(theme.breakpoints.down('sm')) ? 'column-reverse' : 'row',
      flex: 1,
      ml: '0 !important',
      'button': {
        flex: 1
      },
      '& .MuiButton-colorPrimary': {
        display: 'flex'
      },
      '& .MuiButton-colorSecondary': {
        marginRight: xs,
        display: 'flex'
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        '& .MuiButton-colorSecondary': {
          mt: xs,
          mr: 0
        }
      }
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column'
    }
  };

  return styles;
};

export default useStyles;
