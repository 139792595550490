import React, { useCallback, useContext } from 'react';
import {
	apiFailure,
	appFeatureListSuccess,
	initAppFeatureListFetch,
	initUserTypeCreate,
	initUserTypeDelete,
	initUserTypeListFetch,
	initUserTypeUpdate,
	resetUserTypeState,
	userTypeListSuccess
} from './actions';
import { doDelete, doGet, doPost, doPut } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import AppFeatureListResponse from '../../types/app-feature-list-response';
import { HttpStatusCode } from 'axios';
import { SessionExpirationContext } from '../../store/session-expiration-provider';
import { Store } from '../../store/store';
import UserType from '../../types/user-type';
import UserTypeListResponse from '../../types/user-type-list-response';
import UserTypeResponse from '../../types/user-type-response';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to manage user type list data and actions.
 *
 * @returns An object containing functions for fetching user type list and the user type list state.
 */
export function useUserTypeApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();
	const { setSessionExpired } = useContext(SessionExpirationContext);

	/**
	 * Fetches the userType list from the API and dispatches actions based on the result.
	 */
	const fetchUserTypeList = useCallback(async () => {
		dispatch(initUserTypeListFetch());
		try {
			const response: UserTypeListResponse = await doGet(APIConfig.userType);
			dispatch(userTypeListSuccess(response.data || []));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Fetches the app feature list and dispatches actions accordingly.
   */
	const fetchAppFeatureList = useCallback(async () => {
		dispatch(initAppFeatureListFetch());
		try {
			const response: AppFeatureListResponse = await doGet(APIConfig.appFeatures);
			response.data.sort((a, b) => (a.label ?? '').localeCompare(b.label ?? ''));
			dispatch(appFeatureListSuccess(response.data || []));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Creates a new user type and dispatches actions accordingly.
   *
   * @param {UserType} userType The user type data to be created.
   */
	const createUserType = useCallback(async (userType: UserType) => {
		dispatch(initUserTypeCreate());
		try {
			const response: UserTypeResponse = await doPost(APIConfig.userType, userType);
			const userTypeList = [...state.userType.userTypeList];
			userTypeList.push(response.data);
			dispatch(userTypeListSuccess(userTypeList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.userType.userTypeList]);

	/**
   * Updates an existing user type and dispatches actions accordingly.
   *
   * @param {UserType} userType The user type data with updated information.
   */
	const updateUserType = useCallback(async (userType: UserType) => {
		dispatch(initUserTypeUpdate());
		try {
			const response: UserTypeResponse = await doPut(`${APIConfig.userType}/${userType.userType}`, userType);
			const userTypeList = [...state.userType.userTypeList
				.filter(item => item.userType !== userType.userType), response.data];
			dispatch(userTypeListSuccess(userTypeList));
			
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.userType.userTypeList]);

	/**
	 * Initializes the delete user type action.
	 *
	 * @returns {DeleteUserTypeAction} The delete user type action object.
	 */
	const deleteUserType = useCallback(async (userType: UserType) => {
		dispatch(initUserTypeDelete());
		try {
			await doDelete(`${APIConfig.userType}/${userType.userType}`);
			const userTypeList = [...state.userType.userTypeList.filter(item => item.userType !== userType.userType)];
			dispatch(userTypeListSuccess(userTypeList));

			return userType;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Resets the user-type state in the Redux store.
	 */
	const resetUserType = () => {
		dispatch(resetUserTypeState());
	}

	/**
	 * Dispatches an API failure action with an error message.
	 *
	 * @param {any} error - The error object.
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(apiFailure(new AppError(error?.code, message)));
		if (error?.code === HttpStatusCode.Unauthorized) {
			setSessionExpired(true);
		}
	}

	return {
		fetchUserTypeList,
		fetchAppFeatureList,
		createUserType,
		updateUserType,
		deleteUserType,
		resetUserType,
		state
	};
}
