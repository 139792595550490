import { Card, CardContent, IconButton, Typography } from '@mui/material';
import AddIcon from '../../../components/CustomIcons/AddIcon';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { DIRECTORY_CREATE_ITEM_MENU } from '../../../utils/ui-constants';
import MenuSelectDialog from '../../../components/MenuSelectDialog';
import MenuData from '../../../types/ui/menu-data';
import LoginUtil from '../../../utils/login-util';
import { AppFeature, DirectoryCreateItemMenu } from '../../../utils/constants';

interface Props {
	handleSubMenuItemClick: (menu: MenuData) => void;
}

const CreateDirectoryCard: React.FC<Props> = (props: Props) => {
	const styles = useStyles();
	const { t } = useTranslation();
	const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);
	const hasTeamPermission = LoginUtil.hasPermission(AppFeature.ManageTeam);
	const hasGroupPermission = LoginUtil.hasPermission(AppFeature.ManageGroup);

	/**
	 * Handles the click event on a sub-menu item.
	 *
	 * @param {MenuData} menu - The clicked menu item data.
	 * 
	 * Closes the sub-menu and delegates handling the sub-menu item click to the parent component.
	 */
	const handleSubMenuItemClick = (menu: MenuData) => {
		setSubMenuAnchorEl(null);
		props.handleSubMenuItemClick(menu);
	}

	/**
	 * Handles the sub-menu click event.
	 * - If both team and group permissions are granted, sets the sub-menu anchor.
	 * - If only one permission is available, finds the corresponding menu item and triggers `handleSubMenuItemClick`.
	 * 
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The click event from the button.
	 */
	const onSubMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();

		if (hasTeamPermission && hasGroupPermission) {
				setSubMenuAnchorEl(event.currentTarget);
				return;
		}

		const menu: MenuData | undefined = DIRECTORY_CREATE_ITEM_MENU.find(item =>
				hasTeamPermission ? item.id === DirectoryCreateItemMenu.Team
				: hasGroupPermission ? item.id === DirectoryCreateItemMenu.Group
				: false
		);

		if (menu) {
				props.handleSubMenuItemClick(menu);
		}
	};

	/**
	 * Determines the appropriate menu label based on team and group permissions.
	 *
	 * @returns The localized menu label:
	 * - 'create' if both permissions are granted,
	 * - 'createTeam' if only team permission is granted,
	 * - 'createGroup' if only group permission is granted,
	 * - An empty string if no permissions are granted.
	 */
	const getMenuLabel = () => 
		hasTeamPermission && hasGroupPermission ? t('create') 
			: hasTeamPermission ? t('createTeamLabel') 
			: hasGroupPermission ? t('createGroup') 
			: '';

	return (
		<>		
			<Card sx={styles.card}>
				<CardContent sx={styles.cardContent}>
					<IconButton sx={styles.actionBtn} onClick={onSubMenuClick}>
						<AddIcon />
					</IconButton>
					<Typography variant={'p2'} sx={styles.title}>{getMenuLabel()}</Typography>
				</CardContent>
			</Card>
			<MenuSelectDialog
				anchorEl={subMenuAnchorEl}
				open={Boolean(subMenuAnchorEl)}
				onClose={() => setSubMenuAnchorEl(null)}
				menuList={DIRECTORY_CREATE_ITEM_MENU}
				onMenuItemSelected={handleSubMenuItemClick}
			/>
		</>
	);
};

export default CreateDirectoryCard;
