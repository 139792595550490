import React, { useState } from 'react';

import ArrowLeftIcon from '../../../components/CustomIcons/ArrowLeftIcon';
import { Box } from '@mui/material';
import Department from '../../../types/department';
import DepartmentMemberCard from './DepartmentMemberCard';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SearchBar from '../../../components/SearchBar';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	department: Department;
	onClose: () => void;
}

/**
 * DepartmentMemberPanel component for displaying a list of department members.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const DepartmentMemberPanel: React.FC<Props> = (props: Props) => {

	const { open, department, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [searchKey, setSearchKey] = useState<string>('');

	/**
	 * Retrieves and processes the list of department members.
	 *
	 * @returns {Array<GroupMemberBaseInfo>} An array of group member information objects.
	 */
	const getDeptMemberList = (): Array<User> => {

		let filteredMemberList = department.members || [];
		if (!Util.isArrayEmpty(filteredMemberList)) {
			filteredMemberList = filteredMemberList.filter(member =>
				member.name.toLowerCase().includes(searchKey.toLowerCase()));
			filteredMemberList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
		}

		return filteredMemberList;
	}

	const memberList = getDeptMemberList();

	return (
		<RightDrawerLayout
			open={open}
			hasBorder
			title={t('members')}
			leftIcon={<ArrowLeftIcon />}
			onActionClick={onClose}
			onClose={onClose}
		>
			<Box sx={styles.contentWrapper}>
				<SearchBar placeholder={t('search')} handleChange={setSearchKey} />
				<Box sx={styles.content}>
					{memberList.map((user, index) => (
						<DepartmentMemberCard
							key={`dept-member-${index}`}
							name={user.name}
							position={user.position}
							imageUrl={user.image?.thumbnail}
						/>
					))}
				</Box>
			</Box>
		</RightDrawerLayout>
	);
};

export default DepartmentMemberPanel;