import { Box, ListItemButton, Typography } from '@mui/material';
import React, { useState } from 'react';

import AssignMemberPanel from '../../AssignMemberPanel';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import DepartmentMemberCard from '../../../DepartmentScreen/DepartmentMemberPanel/DepartmentMemberCard';
import InlineAlert from '../../../../components/InlineAlert';
import { Pager } from '../../../../types/pager';
import SwapIcon from '../../../../components/CustomIcons/SwapIcon';
import { UPDATE_PAGER } from '../../../../data/device/action-types';
import Util from '../../../../utils/util';
import { useDeviceApi } from '../../../../data/device/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  pager: Pager;
}

/**
 * Assign Tab Component
 *
 * This component displays a button to reassign a pager and, if a pager is already assigned, shows the assigned user's information.
 * It also handles opening and closing the DeviceAssignPanel component for reassigning.
 *
 * @param {Props} props Component props.
 */
const AssignTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [showDeviceAssignPanel, setShowDeviceAssignPanel] = useState<boolean>(false);
  const deviceApi = useDeviceApi();
  const apiStatus = deviceApi.state.device.apiStatus;

  return (
    <Box sx={styles.wrapper}>
      <InlineAlert message={Util.getApiError([UPDATE_PAGER], apiStatus)} />
      <Box sx={styles.contentWrapper}>
        <ListItemButton sx={styles.addMemberLayout} onClick={() => setShowDeviceAssignPanel(true)}>
          <Box sx={styles.addMemberBtn}><SwapIcon /></Box>
          <Typography variant='p1' sx={styles.addMemberText}>{props.pager.assignedTo ? t('reassign') : t('assign')}</Typography>
        </ListItemButton>
        {props.pager.assignedTo &&
          <DepartmentMemberCard
            name={props.pager.assignedTo.name}
            position={props.pager.assignedTo.position}
            imageUrl={props.pager.assignedTo?.image?.thumbnail}
          />
        }
      </Box>
      <AssignMemberPanel
        open={showDeviceAssignPanel}
        pager={props.pager}
        onClose={() => setShowDeviceAssignPanel(false)}
      />
      <CircularProgressBar show={Util.isApiLoading([UPDATE_PAGER], apiStatus)} />
    </Box>
  );
};

export default AssignTab;