import { Avatar, Box, Stack, Typography } from '@mui/material';

import React, { } from 'react';
import Util from '../../../../utils/util';
import useStyles from './styles';

interface Props {
  name: string;
  position: string;
  profilePic?: string;
}

/**
 * TeamMemberCard component for displaying information about a team member.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const TeamMemberCard: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
 
  return (
    <Box sx={styles.content}>
      <Avatar sx={styles.avatar} src={props.profilePic}>
        <Typography variant='p3' >
          {Util.getInitialsFromName(props.name)}
        </Typography>
      </Avatar>
      <Box sx={styles.container}>
        <Stack sx={styles.contentText}>
          <Typography variant='p1'>
            {props.name}
          </Typography>
          <Typography variant='p1'>
            {props.position}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export default TeamMemberCard;
