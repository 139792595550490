import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SignInFooter component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const theme = useTheme();
  
  const styles = {
    footer: {
      textAlign: 'center'
    },
    footerTxt: {
      cursor: 'pointer',
      color: theme.palette.primary.main
    }
  };

  return styles;
};

export default useStyles;