import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import { Props } from '.';
import ThemeUtil from '../../theme/theme-util';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Generates MUI styles for the SegmentedButton component.
 *
 * This function uses the current theme and common styles to create styles for 
 * various elements of the segmented button.
 *
 * @param {Props} props - Component props used for conditional styling.
 * @returns {Object} - The generated MUI styles object.
 */
const useStyles = (props: Props) => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row'
    },
    base: {
      lineHeight: 0,
      textTransform: 'none',
      borderRadius: Dimens.radius.md,
      padding: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      minWidth: 0,
      '&.MuiButton-colorPrimary': {
        backgroundColor: props.destructive ? theme.palette.error.main : 'primary',
        '&.Mui-disabled': {
          backgroundColor: props.destructive ? theme.palette.error.light : theme.palette.primary.light,
          color: ThemeUtil.isLightTheme(theme) ? Colors.white : Colors.palette.secondary[600],
          pointerEvents: 'all',
          cursor: 'not-allowed'
        }
      },
      '&.MuiButton-colorSecondary': {
        color: props.destructive ? theme.palette.error.dark : 'secondary',
        '&.Mui-disabled': {
          backgroundColor: theme.palette.secondary.main,
          color: ThemeUtil.isLightTheme(theme) ? Colors.palette.secondary[200] : Colors.palette.secondary[700],
          pointerEvents: 'all',
          cursor: 'not-allowed'
        }
      },
      'svg': {
        ...commonStyles.smIcon
      }
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    leftButton: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      'span': {
        m: props.leftTitle ? 'none' : 0,
        'svg': {
          ...commonStyles.smIcon
        }
      }
    },
    rightButton: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      'span': {
        m: props.rightTitle ? 'none' : 0,
        'svg': {
          ...commonStyles.smIcon
        }
      }
    },
    divider: {
      width: Dimens.menuBorderWidth
    }
  };

  return styles;
};

export default useStyles;
