import { AUDIO_EXT_WAV, AUDIO_INPUT, VOICE_DURATION } from '../../../../../utils/constants';
import { Box, IconButton, Slider, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import AlertDialogSmall from '../../../../../components/AlertDialogSmall';
import BinIcon from '../../../../../components/CustomIcons/BinIcon';
import MicIcon from '../../../../../components/CustomIcons/MicIcon';
import PauseIcon from '../../../../../components/CustomIcons/PauseIcon';
import PlayIcon from '../../../../../components/CustomIcons/PlayIcon';
import StopIcon from '../../../../../components/CustomIcons/StopIcon';
import Toast from '../../../../../components/Toast';
import Util from '../../../../../utils/util';
import useMediaDeviceAvailability from '../../../../../hooks/use-media-device-availability';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  onAudioActivityChange: (isActive: boolean) => void;
  onFileReady: (file: File) => void;
  onFileDeleted: () => void;
}

/**
 * VoiceRecorder Component
 * 
 * A React component that allows users to record, play, and manage audio recordings.
 * It provides UI controls for recording, playing, pausing, and deleting audio files.
 * 
 * Props:
 * - onAudioActivityChange: Function to notify when audio activity starts/stops.
 * - onFileReady: Function to handle the recorded audio file.
 * - onFileDeleted: Function to handle file deletion.
 */
const VoiceRecorder: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { onAudioActivityChange, onFileReady, onFileDeleted } = props;
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(VOICE_DURATION);
  const { checkDeviceAvailability } = useMediaDeviceAvailability();
  const [file, setFile] = useState<File>();
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [showAccessDeniedAlert, setShowAccessDeniedAlert] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string>('');
  const styles = useStyles(file);

  /**
   * Initializes the recording process.
   *
   * This effect starts the recording process and sets up event listeners for audio data and playback.
   * It also cleans up resources on unmount.
   */
  useEffect(() => {

    return () => {
      clearInterval(intervalId);
      stopRecording();
    }
  }, []);

  /**
   * Handles audio playback events and updates state accordingly.
   */
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', () => {
        if (audioRef.current?.currentTime) {
          setCurrentTime(audioRef.current.currentTime);
        }
      });
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
      });
      if (audioRef.current.duration && Number.isFinite(audioRef.current.duration)) {
        setDuration(audioRef.current.duration);
      }
    }
  }, [audioRef, audioRef.current?.duration]);

  /**
   * Starts the audio recording process.
   */
  const startRecording = async () => {

    const isMicAvailable = await checkDeviceAvailability(AUDIO_INPUT);
    if (isMicAvailable) {
      const chunks: Blob[] = [];
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;
          const startTime = new Date();
          mediaRecorder.ondataavailable = (event) => {
            chunks.push(event.data)
          };
          mediaRecorder.onstop = () => {
            setIsRecording(false);
            setIsPlaying(false);
            setIsPaused(false);
            clearInterval(intervalId);
            setCurrentTime(0);
            if (startTime) {
              const dif = new Date().getTime() - startTime.getTime();
              setDuration(dif / 1000);
            }
            const tracks = stream.getTracks();
            tracks.forEach(track => track.stop());
            const fileName = `audio_${new Date().getTime().toString()}${AUDIO_EXT_WAV}`;
            Util.convertWebmToWav(chunks[0], fileName).then(blob => {
              const file = Util.convertBlobToFile(blob, fileName);
              setFile(file);
              const url = URL.createObjectURL(blob);
              setAudioUrl(url);
              onFileReady(file);
              onAudioActivityChange(false);
            });
          };
          mediaRecorder.start();
          setIsRecording(true);
          const intervalId = setInterval(() => {
            setCurrentTime((prevSeconds) => {
              if (prevSeconds >= VOICE_DURATION) {
                stopRecording();

                return 0;
              }

              return (prevSeconds + 0.01);
            });
          }, 10);
          setIntervalId(intervalId);
          onAudioActivityChange(true);
        }).catch((_) => {
          setShowAccessDeniedAlert(true);
        });
    } else {
      setToastMsg(t('micNotAvailable'));
    }
  };

  /**
   * Stops the audio recording process.
   */
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  /**
   * Starts audio playback.
   */
  const playRecording = () => {
    if (audioRef.current) {
      setIsPlaying(true);
      setIsPaused(false);
      setTimeout(() => audioRef.current?.play(), 500);
    }
  };

  /**
   * Pauses audio playback.
   */
  const pausePlayback = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
      setIsPaused(true);
    }
  };

  /**
   * Formats the given seconds into a formatted time string.
   *
   * @param {number} seconds The number of seconds to format.
   * 
   * @returns {string} The formatted time string.
   */
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(Math.round(seconds) / 60);
    const secondsRemaining = Math.round(seconds) % 60;

    return `${minutes.toString().padStart(2, '0')}:${secondsRemaining.toFixed(0).padStart(2, '0')}`;
  };

  /**
   * Handles changes to the audio playback position.
   * Updates the current playback time of the audio element based on the provided value.
   * 
   * @param {number} value - The new playback time in seconds.
   */
  const handleChange = (value: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setCurrentTime(value);
    }
  }

  /**
   * Resets the voice recorder to its initial state.
   */
  const resetVoiceRecorder = () => {
    setFile(undefined);
    setAudioUrl('');
    setCurrentTime(0);
    setDuration(VOICE_DURATION);
    onFileDeleted();
  };

  /**
   * Handles the click event on the record icon.
   * - Starts recording if no file is attached and recording is not in progress.
   * - Stops recording if recording is in progress.
   * - Resets the voice recorder if a file is attached.
   */
  const onRecordIconClick = () => {
    if (!file && !isRecording) {
      startRecording();
    } else if (isRecording) {
      stopRecording();
    } else if (file) {
      resetVoiceRecorder();
    }
  };

  /**
   * Renders the appropriate icon for the record button based on the current state.
   * - Displays a microphone icon (`MicIcon`) by default.
   * - Displays a trash bin icon (`BinIcon`) if a file is attached.
   * - Displays a stop icon (`StopIcon`) if recording is in progress.
   * @returns {JSX.Element} The icon component to render.
   */
  const renderRecordIcon = () => {
    let icon = <MicIcon />;
    if (file) {
      icon = <BinIcon />
    } else if (isRecording) {
      icon = <StopIcon />
    }

    return icon;
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.msgLayout}>
        <Box sx={styles.recorderContainer}>
          <Box sx={styles.audioPlayer}>
            {file && (
              <IconButton sx={styles.iconBtn} onClick={(_) => (isPlaying ? pausePlayback() : playRecording())}>
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </IconButton>
            )}
            <audio ref={audioRef} src={audioUrl} />
            {(isRecording || file) && (
              <Slider
                sx={styles.slider}
                defaultValue={0}
                size='small'
                onChange={(_, value) => handleChange(value as number)}
                value={currentTime}
                max={isRecording ? VOICE_DURATION : duration}
              />
            )}
            {(isRecording || file) && (
              <Typography variant='footer' sx={styles.timeInfo}>
                {(isRecording || (file && (isPlaying || isPaused))) ? formatTime(currentTime) : formatTime(duration)}
              </Typography>
            )}
          </Box>
        </Box>
        <Box color={'primary'} sx={styles.iconBox} onClick={onRecordIconClick}>
          <Box sx={styles.iconContainer}>
            {renderRecordIcon()}
          </Box>
        </Box>
      </Box>
      <AlertDialogSmall
        open={showAccessDeniedAlert}
        title={t('micAccessDeniedTitle')}
        message={t('micAccessDeniedMsg')}
        primaryLabel={t('close')}
        onPrimaryAction={() => setShowAccessDeniedAlert(false)}
        onClose={() => setShowAccessDeniedAlert(false)}
      />
      <Toast open={Boolean(toastMsg)} severity='error' title={toastMsg} onClose={() => setToastMsg('')} />
    </Box>
  );
};

export default VoiceRecorder;