import AppError from '../../exception/app-error';
import { Dashboard } from '../../types/dashboard';

export const FETCH_DASHBOARD_LIST = 'FETCH_DASHBOARD_LIST';
export const FETCH_DASHBOARD_ITEM = 'FETCH_DASHBOARD_ITEM';
export const FETCH_DASHBOARD_PERMISSION = 'FETCH_DASHBOARD_PERMISSION';
export const CREATE_DASHBOARD = 'CREATE_DASHBOARD';
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const CREATE_DASHBOARD_ITEM = 'CREATE_DASHBOARD_ITEM';
export const UPDATE_DASHBOARD_ITEM = 'UPDATE_DASHBOARD_ITEM';
export const DELETE_DASHBOARD_ITEM = 'DELETE_DASHBOARD_ITEM';
export const DASHBOARD_LIST_SUCCESS = 'DASHBOARD_LIST_SUCCESS';
export const CREATE_EMERGENCY_CALL = 'CREATE_EMERGENCY_CALL';
export const EMERGENCY_CALL_SUCCESS = 'EMERGENCY_CALL_SUCCESS';
export const API_FAILURE = 'DASHBOARD_API_FAILURE';
export const RESET_DASHBOARD_STATE = 'RESET_DASHBOARD_STATE';
export const RESET_ERROR_STATE = 'RESET_ERROR_STATE';

export interface FetchDashboardListAction {
	type: typeof FETCH_DASHBOARD_LIST;
}

export interface CreateDashboardAction {
	type: typeof CREATE_DASHBOARD;
}

export interface UpdateDashboardAction {
	type: typeof UPDATE_DASHBOARD;
}

export interface FetchDashboardItemAction {
	type: typeof FETCH_DASHBOARD_ITEM;
}

export interface CreateDashboardItemAction {
	type: typeof CREATE_DASHBOARD_ITEM;
}

export interface UpdateDashboardItemAction {
	type: typeof UPDATE_DASHBOARD_ITEM;
}

export interface DeleteDashboardItemAction {
	type: typeof DELETE_DASHBOARD_ITEM;
}

export interface FetchDashboardPermissionAction {
	type: typeof FETCH_DASHBOARD_PERMISSION;
}

export interface DeleteDashboardAction {
	type: typeof DELETE_DASHBOARD;
}

export interface DashboardListSuccessAction {
	type: typeof DASHBOARD_LIST_SUCCESS;
	payload: Array<Dashboard>;
}

export interface CreateEmergencyCallAction {
	type: typeof CREATE_EMERGENCY_CALL;
}

export interface EmergencyCallSuccessAction {
	type: typeof EMERGENCY_CALL_SUCCESS;
}

export interface ApiFailureAction {
	type: typeof API_FAILURE;
	payload: AppError;
}

export interface DashboardStateResetAction {
	type: typeof RESET_DASHBOARD_STATE;
}

export interface ResetErrorStateAction {
	type: typeof RESET_ERROR_STATE;
}

export type DashboardActionTypes = FetchDashboardListAction | CreateDashboardAction | CreateDashboardItemAction
	| UpdateDashboardAction | FetchDashboardItemAction | UpdateDashboardItemAction | DeleteDashboardItemAction | FetchDashboardPermissionAction
	| DeleteDashboardAction | DashboardListSuccessAction | CreateEmergencyCallAction | EmergencyCallSuccessAction 
	| ApiFailureAction | DashboardStateResetAction | ResetErrorStateAction;
