import { Box, Dialog, DialogActions, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import { CREATE_USER_TYPE } from '../../../data/user-type/action-types';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import InlineAlert from '../../../components/InlineAlert';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import UserType from '../../../types/user-type';
import Util from '../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserTypeApi } from '../../../data/user-type/api';
import { userTypeSchema } from '../../../utils/validation-schema';

interface Props extends DialogProps {
	userTypeList: Array<UserType>;
	onClose: () => void;
}

/**
 * Add New Permission Dialog Component
 *
 * This component displays a dialog for creating a new user type. It includes a form for entering user type details and a confirmation dialog for saving changes.
 *
 * @param {Props} props Component props containing the user type list and close handler.
 */
const AddNewPermissionDialog: React.FC<Props> = (props: Props) => {

	const { userTypeList, onClose, ...rest } = props;
	const { t } = useTranslation();
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const styles = useStyles();
	const formikRef = React.createRef<FormikProps<UserType>>();
	const userTypeApi = useUserTypeApi();
	const apiStatus = userTypeApi.state.userType.apiStatus;
	const initialValue: UserType = {
		userType: '',
		permissions: []
	};

	/**
   * Handles closing the dialog and optionally shows a confirmation dialog.
   */
	const onDialogClose = () => {
		setShowSaveDialog(false);
		onClose();
	}

	/**
   * Retrieves a list of existing user type names for validation.
   *
   * @returns {Array<string>} The list of existing user type names.
   */
	const getExistingUserTypeList = () => {

		return userTypeList.map(item => item.userType.toLowerCase());
	}

	/**
	 * Handles closing the dialog based on form state.
	 * 
	 * If the form is dirty (changes have been made), shows a confirmation dialog to save changes.
	 * Otherwise, closes the dialog directly.
	 */
	const handleClose = () => {
		if (formikRef.current?.dirty) {
			setShowSaveDialog(true);
		} else {
			onClose();
		}
	};

	/**
   * Handles form submission and creates a new user type.
   *
   * @param {UserType} values The form values representing the new user type data.
   */
	const onSubmit = (values: UserType) => {
		userTypeApi.createUserType(values).then((userType: UserType | undefined) => {
			if (userType) {
				onClose();
			}
		});
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>{t('newPreset')}</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<InlineAlert message={Util.getApiError([CREATE_USER_TYPE], apiStatus)} />
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validationSchema={userTypeSchema(getExistingUserTypeList())}
				initialValues={initialValue}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							<Field
								name='userType'
								label={t('name')}
								placeholder={t('name')}
								component={CustomInput}
							/>
						</DialogContent>
						<DialogActions>
							<Box sx={styles.actionContainer}>
								<CustomButton
									type='submit'
									title={t('save')}
									color='primary'
									disabled={!dirty || !isValid}
									startIcon={<SaveIcon sx={styles.smIcon} />}
								/>
							</Box>
						</DialogActions>
					</Form>
				)}
			</Formik>
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={onDialogClose}
				onClose={() => setShowSaveDialog(false)}
			/>
			<CircularProgressBar show={Util.isApiLoading([CREATE_USER_TYPE], apiStatus)} />
		</Dialog>
	);
};

export default AddNewPermissionDialog;