import {
	API_FAILURE,
	CREATE_DASHBOARD,
	CREATE_DASHBOARD_ITEM,
	CREATE_EMERGENCY_CALL,
	DASHBOARD_LIST_SUCCESS,
	DELETE_DASHBOARD,
	DELETE_DASHBOARD_ITEM,
	DashboardActionTypes,
	EMERGENCY_CALL_SUCCESS,
	FETCH_DASHBOARD_ITEM,
	FETCH_DASHBOARD_LIST,
	FETCH_DASHBOARD_PERMISSION,
	RESET_DASHBOARD_STATE,
	RESET_ERROR_STATE,
	UPDATE_DASHBOARD,
	UPDATE_DASHBOARD_ITEM
} from './action-types';

import DashboardState from '../../types/states/dashboard-state';

export const dashboardInitialState: DashboardState = {
	apiStatus: undefined,
	dashboardList: []
};

/**
 * dashboard reducer
 *
 * Handles state updates for dashboard-related data, including fetching, success, and failure states.
 */
export const dashboardReducer = (state: DashboardState, action: DashboardActionTypes): DashboardState => {
	switch (action.type) {
		case FETCH_DASHBOARD_LIST:
		case CREATE_DASHBOARD:
		case UPDATE_DASHBOARD:
		case FETCH_DASHBOARD_PERMISSION:
		case DELETE_DASHBOARD:
		case FETCH_DASHBOARD_ITEM:
		case CREATE_DASHBOARD_ITEM:
		case UPDATE_DASHBOARD_ITEM:
		case DELETE_DASHBOARD_ITEM:
		case CREATE_EMERGENCY_CALL:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
		case DASHBOARD_LIST_SUCCESS:
			return {
				...state,
				dashboardList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true,
				}
			};
		case EMERGENCY_CALL_SUCCESS:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					error: undefined,
					isCompleted: true,
				}
			};			
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: undefined,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_DASHBOARD_STATE:
			return {
				...dashboardInitialState
			};
		case RESET_ERROR_STATE:
			return {
				...dashboardInitialState,
				apiStatus: undefined
			};
		default:
			return state;
	}
};

export default dashboardReducer;
