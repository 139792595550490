import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const SendIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3615_1624)'>
          <path d='M3.04454 8.00233H14.932' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M14.9327 8.00106L1.69437 14.215C1.61893 14.243 1.53703 14.2488 1.45841 14.2316C1.37978 14.2145 1.30773 14.1751 1.25082 14.1182C1.19392 14.0613 1.15455 13.9892 1.13739 13.9106C1.12023 13.832 1.12601 13.7501 1.15403 13.6746L3.04522 8.00106L1.15403 2.32749C1.12601 2.25205 1.12023 2.17015 1.13739 2.09152C1.15455 2.01289 1.19392 1.94085 1.25082 1.88394C1.30773 1.82703 1.37978 1.78766 1.45841 1.7705C1.53703 1.75335 1.61893 1.75912 1.69437 1.78715L14.9327 8.00106Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_3615_1624'>
            <rect width='16' height='16' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default SendIcon;