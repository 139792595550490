import { Box, Dialog, DialogActions, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { DirectoryRecordType, emergencyCallInitialValue } from '../../../utils/constants';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import { Dashboard } from '../../../types/dashboard';
import { DashboardItem } from '../../../types/dashboard-item';
import { DirectoryRecord } from '../../../types/directory-record';
import InlineAlert from '../../../components/InlineAlert';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import SearchableUserAndTeamList from '../DashboardTab/SendEmergencyCallDialog/SearchableUserAndTeamList';
import TablerIconPicker from '../../../components/TablerIconPicker';
import { TablerIconType } from '../../../types/tabler-icon-type';
import Util from '../../../utils/util';
import { emergemcyCallItemSchema } from '../../../utils/validation-schema';
import { useDashboardApi } from '../../../data/dashboard/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import BinIcon from '../../../components/CustomIcons/BinIcon';

/**
 * Interface defining the props for the CreateEmergencyCallDialog component.
 */
interface Props extends DialogProps {
	/** Function to close the dialog */
	onClose: () => void;

	/** The dashboard where the emergency call item will be added */
	dashboard: Dashboard;
	dashboardItem: DashboardItem;
}

/**
 * A dialog component for creating and editing an emergency call dashboard item.
 *
 * This component provides a form to input details like name, icon, and associated teams.
 * It also includes validation and API integration to save the emergency call item.
 *
 * @param {Props} props - Component props.
 * @returns {JSX.Element} The rendered CreateEmergencyCallDialog component.
 */
const CreateEditEmergencyCallItemDialog: React.FC<Props> = (props: Props) => {

	const { onClose, dashboard, dashboardItem, ...rest } = props;
	const styles = useStyles(Boolean(dashboardItem.id));
	const { t } = useTranslation();
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const formikRef = React.createRef<FormikProps<DashboardItem>>();
	const dashboardApi = useDashboardApi();
	const apiStatus = dashboardApi.state.dashboard.apiStatus;
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);

	/**
	 * Handles closing the dialog.
	 * If there are unsaved changes, it shows a confirmation dialog.
	 */
	const handleClose = () => {
		if (formikRef.current?.dirty) {
			setShowSaveDialog(true);
		} else {
			dashboardApi.resetError();
			onClose();
		}
	};
	/**
	 * Handles closing the confirmation dialog.
	 * Close main dialog.
	 */
	const closeSaveDialog = () => {
		setShowSaveDialog(false);
		onClose();
	}

	/**
	 * Handles the confirmation of deleting a dashboard item.
	 * Calls the API to delete the dashboard item and then calls close dialog.
	 */
		const onDeleteClick = () => {
			setOpenDeleteDialog(false);
			dashboardApi.deleteDashboardItem(dashboard.id, dashboardItem.id).then(data => {
        if (data) {
          onClose();
        }
      });
		}

	/**
	 * Handles form submission to create or update an emergency call dashboard item.
	 *
	 * @param {DashboardItem} item - The dashboard item data submitted from the form.
	 */
	const onSubmit = (item: DashboardItem) => {
	//	item.icon = selectedIcon;
		item.teamUuids = item.teams?.map(team => team.teamKey).filter((key): key is string => key !== undefined);
		if(item.id){
			dashboardApi.updateDashboardItem(dashboard.id, item).then(() => {
				onClose();
			});
		} else {
			dashboardApi.createDashboardItem(dashboard.id, item).then(() => {
				onClose();
			});
		}
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>
					{dashboardItem.id ? t('editEmergencyCall') : t('emergencyCall')}
				</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<InlineAlert message={Util.getApiError(['CREATE_DASHBOARD_ITEM','UPDATE_DASHBOARD_ITEM'], apiStatus)} />
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validationSchema={emergemcyCallItemSchema}
				initialValues={dashboardItem.id ? dashboardItem : emergencyCallInitialValue}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							<Box sx={styles.contentLeft}>
								<Field
									name='icon'
									label={t('icon')}		
									placeholder={t('search')}									
									component={TablerIconPicker}
								/>
								<Field
									name='title'
									label={t('name')}
									placeholder={t('name')}
									component={CustomInput}
								/>
								<Box sx={styles.memberContainer}>
									<Field
										name='teams'
										typeList={[DirectoryRecordType.Team]}
										existingTeamList={dashboardItem.teams}
										editExistingTeamList={true}
										value={dashboardItem.teams || []}
										subHeading={t('destinations')}
										component={SearchableUserAndTeamList}
									/>	
								</Box>
							</Box>
						</DialogContent>
						<DialogActions>
							<Box sx={styles.buttonBox}>
								<CustomButton
									sx={styles.nextBtn}
									title={t('delete')}
									color='secondary'
									destructive
									startIcon={<BinIcon sx={styles.smIcon} />}
									onClick={() => setOpenDeleteDialog(true)}
								/>
								<CustomButton
									sx={styles.saveBtn}
									type='submit'
									title={t('save')}
									color='primary'
									disabled={!dirty || !isValid}
									endIcon={<SaveIcon sx={styles.smIcon} />}
								/>
							</Box>
							
						</DialogActions>
					</Form>
				)}
			</Formik>
			<CircularProgressBar show={Util.isApiLoading(['CREATE_DASHBOARD_ITEM', 'UPDATE_DASHBOARD_ITEM', 'DELETE_DASHBOARD_ITEM'], apiStatus)} />
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={closeSaveDialog}
				onClose={() => setShowSaveDialog(false)}
			/>
			<AlertDialogSmall
				open={openDeleteDialog}
				title={t('deleteDashboardItem')}
				titleIcon={<BinIcon />}
				message={t('deleteDashboardItemMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setOpenDeleteDialog(false)}
				primaryLabel={t('delete')}
				onPrimaryAction={onDeleteClick}
				isDestructive
				onClose={() => setOpenDeleteDialog(false)}
			/>
		</Dialog>
	);
};

export default CreateEditEmergencyCallItemDialog;