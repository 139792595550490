import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material';

import DeactivatedIcon from '../CustomIcons/DeactivatedIcon';
import DotsVerticalIcon from '../CustomIcons/DotsVerticalIcon';
import MenuData from '../../types/ui/menu-data';
import MenuSelectDialog from '../MenuSelectDialog';
import PagerIcon from '../CustomIcons/PagerIcon';
import PlusCircleIcon from '../CustomIcons/PlusCircleIcon';
import React, { } from 'react';
import Util from '../../utils/util';
import useStyles from './styles';
import { ListItemType } from '../../utils/constants';
import TeamIcon from '../CustomIcons/TeamIcon';

interface Props {
  title: string;
  subTitle: string;
  imageUrl?: string;
  isExistingItem: boolean;
  showHoverMenu?: boolean;
  menu?: Array<MenuData>;
  onMenuItemClick?: (item: MenuData) => void;
  onActionClick: () => void;
  itemType?: ListItemType;
  hideActionButton?: boolean;
}

/**
 * ListItemAddRemoveCard component for displaying an item with an add or remove action.
 *
 * @param {Object} props Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ListItemAddRemoveCard: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuItemClick = (item: MenuData) => {
    if (props.onMenuItemClick) {
      setSubMenuAnchorEl(null);
      props.onMenuItemClick(item);
    }
  }

  /**
   * Determines the avatar content based on the item type.
   *
   * @returns {JSX.Element} The appropriate avatar content.
   */
    const renderAvatar = () => {
      switch (props.itemType) {
        case ListItemType.Device:
          return <PagerIcon />;
        case ListItemType.Team:
          return <TeamIcon />;
        case ListItemType.User:
        default:
          return (
            <Typography variant='p3'>
              {Util.getInitialsFromName(props.title)}
            </Typography>
          );
      }
    };

  return (
    <Box sx={styles.content}>
      <Avatar sx={styles.avatar} src={props.imageUrl}>
        {renderAvatar()}
      </Avatar>
      <Box sx={styles.contentRight}>
        <Stack sx={styles.contentText}>
          <Typography variant='p1' sx={styles.primaryText}>
            {props.title}
          </Typography>
          <Typography variant='p1' sx={styles.primaryText}>
            {props.subTitle}
          </Typography>
        </Stack>
        <Stack sx={styles.action}>
          {props.showHoverMenu &&
            <IconButton sx={styles.iconToggle} onClick={e => setSubMenuAnchorEl(e.currentTarget)}>
              <DotsVerticalIcon />
            </IconButton>
          }
          { !props.hideActionButton && 
            <IconButton onClick={props.onActionClick}>
              {props.isExistingItem ? (
                <DeactivatedIcon color='error' />
              ) : (
                <PlusCircleIcon color='primary' />
              )}
            </IconButton>
          }
        </Stack>
      </Box>
      <MenuSelectDialog
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={() => setSubMenuAnchorEl(null)}
        menuList={props.menu ?? []}
        onMenuItemSelected={handleMenuItemClick}
      />
    </Box>
  );
};

export default ListItemAddRemoveCard;
