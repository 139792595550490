import { Box } from '@mui/material';
import React from 'react';
import { useAppSignUpControl } from '../../../hooks/use-app-sign-up-control';
import useStyles from './styles';

interface MinimalProps {
  children: React.ReactNode;
}

/**
 * Minimal component renders a basic layout for application views accessed before login.
 * 
 * Props:
 *  - children (React.ReactNode): Content to be displayed within the main application area.
 */
const Minimal: React.FC<MinimalProps> = (props: MinimalProps) => {

  const { children } = props;
  const styles = useStyles();
  /**
   * If the user already clicked the email invite and have the sign up info, then
   * useAppSignUpControl will directly initiate sign up process. This hook is called to
   * handle the browser refresh, if the user have already validated the email link and has
   * the sign up info. Here the true boolean represents whether this hooks can init
   * sign up process directly.
   */
  useAppSignUpControl(true);

  return (
    <Box sx={styles.root}>
      <Box component='main' sx={styles.content}>
        {children}
      </Box>
    </Box>
  );
};

export default Minimal;
