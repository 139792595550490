import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the MenuSelectDialog component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();
	
	const styles = {
    menuPaperProps: {
      borderRadius: Dimens.radius.md,
      backgroundImage: 'none',
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`
    },
    menu: {
      padding: 0,
			minWidth: Dimens.menuItemMinWidth
    },
		formGroup: {
			flexDirection: 'column',
			'.MuiFormControlLabel-root': {
				m: 0,
				p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
				'&:hover': {
					backgroundColor: theme.palette.background.default
				},
				'span': {
					p: 0,
					'svg': {
						...commonStyles.smIcon,
						mr: Dimens.spacing.base
					}
				}
			}
		},
		divider: {
			my: '0 !important',
      borderBottomWidth: Dimens.menuBorderWidth,
      borderColor: theme.palette.background.default
		}
	};

	return styles;
};

export default useStyles;