import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Avatar,
  Chip,
  FormHelperText,
  MenuItem,
  Popper,
  SelectProps,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import ChevronDownIcon from '../../../components/CustomIcons/ChevronDownIcon';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomLabel from '../../../components/CustomLabel';
import { FieldProps } from 'formik';
import { SEARCH_DEBOUNCE_DELAY } from '../../../utils/constants';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

/**
 * Interface defining the props for the UserAutocompleteChipSelect component.
 * It combines props from several Material UI components and Formik.
 * @property {string} label - The label for the input field.
 * @property {string} subLabel - The sub-label for the input field.
 * @property {string} placeholder - The placeholder text for the input field.
 * @property {Array<User>} exisitingUserList - An array of existing users to be excluded from the options.
 * @property {boolean} showDropdownIcon - Whether to show the dropdown icon.
 */
type Props = {
  label?: string;
  subLabel?: string;
  placeholder?: string;
  exisitingUserList: Array<User>;
  showDropdownIcon?: boolean;
} & SelectProps & FieldProps & AutocompleteProps<any, false, false, false>; /* eslint-disable-line */

/**
 * UserAutocompleteChipSelect component provides an autocomplete input field for selecting users, with chip display for selected users.
 * It uses Formik for form management and Material UI for styling and functionality.
 * @param {Props} props - The props passed to the component.
 * @returns {JSX.Element} The rendered UserAutocompleteChipSelect component.
 */
const UserAutocompleteChipSelect: React.FC<Props> = (props: Props) => {
  const {
    field: { name, onBlur, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    label,
    subLabel,
    placeholder,
    exisitingUserList,
    showDropdownIcon,
    ...rest
  } = props;
  const styles = useStyles(rest.readOnly, rest.multiple);
  const { t } = useTranslation();
  const hasError = Boolean(errors[name] && touched[name]);
  const [searchKey, setSearchKey] = useState<string>('');
  const userApi = useUserApi();

  /**
   * useEffect hook to perform a debounced search for users when the `searchKey` changes.
   */
  useEffect(() => {
    const handler = setTimeout(() => {
      if (searchKey) {
        userApi.searchUserList(searchKey, 0);
      }
    }, SEARCH_DEBOUNCE_DELAY);

    return () => clearTimeout(handler);
  }, [searchKey]);

  /**
   * Handles the blur event of the Autocomplete component.
   */
  const handleBlur = () => {
    setFieldTouched(name);
    onBlur(name);
  };

  /**
   * Handles the change event of the Autocomplete component.
   * @param {React.SyntheticEvent<Element, Event>} e - The event object.
   * @param {any} newValue - The new selected value(s).
   */
  const handleChange = (e: React.SyntheticEvent<Element, Event>, newValue: any) => { /* eslint-disable-line */
    setFieldValue(name, newValue);
  };

  /**
   * Filters a list of users to exclude those already present in another list.
   * It compares users based on their email addresses.
   * @returns {User} An array of users from `searchedUserList` who are not in `exisitingUserList`.
   */
  const filteredUserList = userApi.state.user.searchedUserList.filter(
    user => !exisitingUserList.some(existingUser => existingUser.profileId === user.profileId)
  );

  /**
   * Renders the input field for the Autocomplete component.
   * @param {AutocompleteRenderInputParams} params - Parameters for rendering the input.
   * @returns {JSX.Element} - The rendered input field.
   */
  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        error={hasError}
        sx={styles.textField}
        placeholder={props.placeholder}
        InputProps={{
          ...params.InputProps,
          sx: styles.outerInput
        }}
        onChange={(e) => setSearchKey(e.target.value)}
      />
    );
  };

  /**
   * Renders each option in the Autocomplete dropdown.
   * @param {React.HTMLAttributes<HTMLLIElement>} optionProps - Props for the menu item.
   * @param {User} option - The user object to render.
   * @param {AutocompleteRenderOptionState} state - The state of the current option.
   * @returns {JSX.Element} - The rendered menu item.
   */
  const renderOption = ( optionProps: React.HTMLAttributes<HTMLLIElement>, option: User) => {
    return (
      <MenuItem {...optionProps} key={`option-${option.email}`} value={option.email} sx={styles.menuItem}>
        <Avatar sx={styles.avatar} src={option.image?.image}>
          <Typography variant='p3' >
            {Util.getInitialsFromName(option.name ?? '')}
          </Typography>
        </Avatar>
        <Stack sx={styles.contentText}>
          <Typography variant='p1' sx={styles.primaryText}>
            {option.name}
          </Typography>
          <Typography variant='p1' sx={styles.primaryText}>
            {option.email}
          </Typography>
        </Stack>
      </MenuItem>
    );
  };

  /**
   * Renders the selected tags in the Autocomplete component.
   * @param {User[]} tagValue - The array of selected users.
   * @param {AutocompleteRenderGetTagProps} getTagProps - Function to get props for each tag.
   * @returns {JSX.Element} - The rendered tag list.
   */
  const renderTagList = (
    tagValue: User[],
    getTagProps: AutocompleteRenderGetTagProps
  ) => {
    return tagValue.map((option: User, index: number) => {
      const tagProps = getTagProps({ index });

      return (
        <Chip
          {...tagProps} 
          variant='filled'
          key={tagProps.key}
          label={option.name}
          sx={styles.chip}
          deleteIcon={<CrossIcon />}
        />
      );
    });
  };

  return (
    <Stack sx={styles.wrapper}>
      <CustomLabel label={label} subLabel={subLabel} isReadOnly={rest.readOnly} />
      <Autocomplete
        {...rest}
        sx={styles.autocomplete}
        popupIcon={showDropdownIcon ? <ChevronDownIcon sx={styles.endIcon} /> : <></>}
        noOptionsText={props.noOptionsText || t('noOptions')}
        value={value}
        options={filteredUserList}
        filterSelectedOptions
        disableClearable={props.disableClearable}
        renderInput={renderInput}
        PopperComponent={(props) => <Popper {...props} sx={styles.popper} />}
        renderOption={renderOption}
        getOptionLabel={(option) => option.name || ''}
        isOptionEqualToValue={(option, value) => option.email === value.email}
        renderTags={renderTagList}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasError &&
        <FormHelperText sx={styles.fieldError}>
          {errors[name]?.toString()}
        </FormHelperText>
      }
    </Stack>
  );
};

export default UserAutocompleteChipSelect;