import { Box, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';

import CustomAutocomplete from '../../../../components/CustomAutocomplete';
import CustomInput from '../../../../components/CustomInput';
import React from 'react';
import Util from '../../../../utils/util';
import { useProfileApi } from '../../../../data/profile/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * ProfileDetails component for displaying details of logged in user.
 *
 * @param {Props} props - Component props.
 *
 * @returns {JSX.Element} The rendered component.
 */
const ProfileDetails: React.FC = () => {

	const profileApi = useProfileApi();
	const profile = profileApi.state.profile
	const styles = useStyles();
	const { t } = useTranslation();

	const initialValues = {
		...profile.user,
		pagerName: profile.user.pager ? profile.user.pager.name : '',
		pager: [{
			id: profile.user.pager?.id,
			name: profile.user.pager?.name
		}]
	};

	return (
		<Grid sx={styles.wrapper}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				onSubmit={() => {/* Do Nothing */ }}>
				<Form style={styles.form as React.CSSProperties}>
					<Box sx={styles.formInnerWrapper}>
					{profile.user.about &&
						<Field
							name='about'
							label={t('aboutMe')}
							placeholder={t('aboutYouPlaceholder')}
							component={CustomInput}
							maxRows={Number.MAX_SAFE_INTEGER}
							readOnly
							multiline
						/>
					}
						{!Util.isArrayEmpty(profile.user.sites) && (
							<Field
								name='sites'
								label={t('sites')}
								placeholder={t('findSites')}
								component={CustomAutocomplete}
								multiple
								menu={[]}
								readOnly
							/>
						)}
						<Field
							name='departments'
							label={t('departments')}
							placeholder={t('findDepartments')}
							component={CustomAutocomplete}
							multiple
							defaultOptionId={profile.user.defaultDepartmentId}
							menu={[]}
							readOnly
						/>
						{profile.user.pager && profile.user.pager.name && (		
						<Field
								name='pager'
								label={t('pager')}
								placeholder={t('pager')}
								component={CustomAutocomplete}
								multiple
								menu={[]}
								readOnly
							/>
						)}
					</Box>
				</Form>
			</Formik>
		</Grid>
	);
};

export default ProfileDetails;
