import {
	ADD_TEAM_MEMBER,
	API_FAILURE,
	AddTeamMemberAction,
	ApiFailureAction,
	CREATE_TEAM,
	CreateTeamAction,
	DELETE_TEAM,
	DeleteTeamAction,
	FETCH_TEAM_DETAIL,
	FETCH_TEAM_LIST,
	FetchTeamDetailAction,
	FetchTeamListAction,
	REMOVE_TEAM_MEMBER,
	RESET_ERROR,
	RESET_TEAM_STATE,
	RemoveTeamMemberAction,
	ResetErrorStateAction,
	TEAM_LIST_SUCCESS,
	TeamListSuccessAction,
	TeamStateResetAction,
	UPDATE_TEAM,
	UpdateTeamAction,
} from './action-types';

import AppError from '../../exception/app-error';
import Team from '../../types/team';

/**
 * Action creator to fetch the team list.
 *
 * @returns {FetchTeamListAction} - The action to fetch the team list.
 */
export const fetchTmList = (): FetchTeamListAction => ({
	type: FETCH_TEAM_LIST
});

/**
 * Action creator to initiate the team creation process.
 *
 * @returns {CreateTeamAction} An action object indicating the start of team creation.
 */
export const initTeamCreate = (): CreateTeamAction => ({
	type: CREATE_TEAM
});

/**
 * Action creator to initiate the team update process.
 *
 * @returns {UpdateTeamAction} An action object indicating the start of team update.
 */
export const initTeamUpdate = (): UpdateTeamAction => ({
	type: UPDATE_TEAM
});

/**
 * Action creator to initiate fetching team details.
 *
 * @returns {FetchTeamDetailAction} An action object indicating the start of fetching team details.
 */
export const initTeamDetailFetch = (): FetchTeamDetailAction => ({
	type: FETCH_TEAM_DETAIL
});

/**
 * Action creator to initiate the team deletion process.
 *
 * @returns {DeleteTeamAction} An action object indicating the start of team deletion.
 */
export const initTeamDelete = (): DeleteTeamAction => ({
	type: DELETE_TEAM
});

/**
 * Action creator to initiate the team user removal process.
 *
 * @returns {RemoveTeamUserAction} An action object indicating the start of user removal from the team.
 */
export const initTeamMemberRemove = (): RemoveTeamMemberAction => ({
	type: REMOVE_TEAM_MEMBER
});

/**
 * Action creator to initiate the team user removal process.
 *
 * @returns {AddTeamUserAction} An action object indicating the start of user add to the team.
 */
export const initTeamMemberAdd = (): AddTeamMemberAction => ({
	type: ADD_TEAM_MEMBER
});

/**
 * Action creator to indicate successful team list fetching.
 *
 * @param {Array<Team>} teamList - The fetched team list.
 * @returns {TeamListSuccessAction} - The action to indicate successful fetching with the team list as payload.
 */
export const teamListSuccess = (teamList: Array<Team>): TeamListSuccessAction => ({
	type: TEAM_LIST_SUCCESS,
	payload: teamList,
});

/**
 * Action creator to handle API failure.
 *
 * @param {AppError} error - The API error object.
 * @returns {ApiFailureAction} - The action to indicate API failure with the error as payload.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error,
});

/**
 * Resets the team state to its initial state.
 *
 * @returns {TeamStateResetAction} An action to reset the team state.
 */
export const resetTeamState = (): TeamStateResetAction => ({
	type: RESET_TEAM_STATE
});

/**
 * Creates an action to reset the error state.
 * 
 * @returns {ResetErrorStateAction} The action to be dispatched.
 */
export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR
});
