import Colors from '../../../theme/colors';
import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the CreateDepartmentDialog component.
 *
 * @param {boolean} showDetailForm (optional) Flag indicating whether to show the detail form section. Defaults to true.
 * 
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { xxs, xs,base, lg, xl } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    ...commonStyles,
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        minWidth: Dimens.alertDialogLargeWidth.lg,
        minHeight: Dimens.dialogContentMaxHeight,
        justifyContent: 'flex-start',
        p: `${xl} ${xl} ${lg} ${xl}`,
        [theme.breakpoints.down('md')]: {
          minWidth: Dimens.alertDialogLargeWidth.md
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: '100%',
          height: '100%',
          minHeight: '100%',
          borderRadius: 0,
          justifyContent: 'center',
          p: lg
        }
      },
      '& .MuiDialogContent-root': {
        ...commonStyles.noScrollBar,
        p: 0,
        minHeight: Dimens.dialogContentMaxHeight,
        maxHeight: Dimens.dialogContentMaxHeight,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
          minHeight: 'auto',
          maxHeight: 'none'
        }
      },
      '& .MuiDialogActions-root': {
        p: 0,
        pt: lg,
        borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        alignItems: 'center',
        justifyContent: 'end',
        '& .MuiButton-colorPrimary': {
          [theme.breakpoints.down('sm')]: {
            margin: 0,
            flex: 1
          }
        }
      }
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base,
        flex: 'none'
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    title: {
      fontWeight: Dimens.fontWeight.semiBold
    },
    cardContainer: {
      display:'flex', 
      flexDirection:'row', 
      justifyContent:'flex-start'
    },
    content: {
      display:'flex', 
      flexDirection:'column', 
      alignItems: 'center'
    },
    card: {
			borderRadius: Dimens.radius.md,
			boxShadow: `0px ${xxs} ${xs} 0px ${Colors.shadow}`,
			'&:hover': {
				cursor: 'pointer'
			}
		},
    iconWrapper: {
			width: Dimens.icon.md,
			height: Dimens.icon.md,		
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.text.primary,
      mb: base			
		}    
  };

  return styles;
};

export default useStyles;
