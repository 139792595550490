import Dimens from '../../../../theme/dimens'
import useCommonStyles from '../../../../theme/use-common-styles'

/**
 * Generates MUI theme-dependent styles for the UserPermissionList component.
 *
 * @returns An object containing styled classes for various component elements.
 */
const useStyles = () => {
  const commonStyles = useCommonStyles()
  const { base } = Dimens.spacing

  const styles = {
    wrapper: {
      flexDirection: 'column',
      display: 'flex',
      pb: base,
      overflow: 'hidden'
    },
    contentWrapper: {
      ...commonStyles.noScrollBar,
      overflowY: 'auto'
    }
  }

  return styles;
}

export default useStyles
