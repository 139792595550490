import { AppFeature, DeviceTableHeader } from '../../../utils/constants';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';

import { DEVICE_LIST_SUB_MENU } from '../../../utils/ui-constants';
import DotsVerticalIcon from '../../../components/CustomIcons/DotsVerticalIcon';
import LoginUtil from '../../../utils/login-util';
import MenuData from '../../../types/ui/menu-data';
import MenuSelectDialog from '../../../components/MenuSelectDialog';
import { Pager } from '../../../types/pager';
import React, { } from 'react';
import useStyles from './styles';

interface Props {
  pager: Pager;
  columns: Array<MenuData>;
  onClick: () => void;
  handleSubMenuItemClick: (pager: Pager, item: MenuData) => void;
}

/**
 * Renders a table row for the pager list, displaying pager information and options menu.
 *
 * @param {Props} props - Component props containing pager data and click handler.
 * 
 * @returns JSX element representing the pager list table row.
 */
const DeviceListTableRow: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  /**
   * Checks if a specific column is visible based on its ID.
   *
   * @param {string} columnId - The ID of the column to check.
   * 
   * @returns {boolean} True if the column is visible, false otherwise.
   */
  const isColumnVisible = (columnId: string): boolean => {
    const column = props.columns.find(item => item.id === columnId);

    return (column?.isChecked ?? false);
  }

  /**
   * Handles the click event on a sub-menu item.
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The click event object.
   * 
   * Prevents event propagation and sets the anchor element for the sub-menu.
   */
  const onSubMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setSubMenuAnchorEl(event.currentTarget);
  }

  /**
   * Handles the click event on a sub-menu option.
   *
   * @param {MenuData} menu - The data object representing the clicked sub-menu option.
   */
  const handleSubMenuItemClick = (menu: MenuData) => {
    setSubMenuAnchorEl(null);
    props.handleSubMenuItemClick(props.pager, menu);
  }

  return (
    <>
      <TableRow onClick={props.onClick} sx={styles.tableRow}>
        {isColumnVisible(DeviceTableHeader.Identifier) &&
          <TableCell><Typography variant='p1'>{props.pager.destination}</Typography></TableCell>
        }
        {isColumnVisible(DeviceTableHeader.Name) &&
          <TableCell><Typography variant='p1'>{props.pager.name}</Typography></TableCell>
        }
        {isColumnVisible(DeviceTableHeader.DeviceType) &&
          <TableCell><Typography variant='p1'>{props.pager.pagerType?.name}</Typography></TableCell>
        }
        {isColumnVisible(DeviceTableHeader.AssignedTo) &&
          <TableCell>
            <Typography variant='p1'>
              {props.pager.assignedTo?.name}
            </Typography>
          </TableCell>
        }
        {LoginUtil.hasPermission(AppFeature.ManagePager) && 
          <TableCell sx={styles.optionMenuBtn} align='right'>
            <IconButton onClick={onSubMenuClick}><DotsVerticalIcon /></IconButton>
          </TableCell>
        }
      </TableRow>
      <MenuSelectDialog
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={() => setSubMenuAnchorEl(null)}
        menuList={DEVICE_LIST_SUB_MENU}
        onMenuItemSelected={handleSubMenuItemClick}
      />
    </>
  );
};

export default DeviceListTableRow;
