import { Route, Routes } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import InboxDetailScreen from './InboxDetailScreen';
import InboxListScreen from './InboxListScreen';
import MasterDetailLayout from '../../layouts/MasterDetailLayout';
import React from 'react';

/**
 * InboxScreen Component
 * 
 * This component defines the routing for the Inbox screen.
 * It uses a master-detail layout with InboxListScreen as the master component 
 * and InboxDetailScreen as the detail component.
 */
const InboxScreen: React.FC = () => {

  return (
    <Routes>
      <Route
        path='/*'
        element={
          <MasterDetailLayout
            MasterComponent={InboxListScreen}
            DetailComponent={InboxDetailScreen}
            detailRelativePath={APP_NAV.INBOX_DETAIL_REL_PATH}
          />
        }
      />
    </Routes>
  );
};

export default InboxScreen;