import { Box, Dialog, DialogActions, DialogContent, DialogProps, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import { CREATE_GROUP } from '../../../data/directory/action-types';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import { Group } from '../../../types/group';
import InlineAlert from '../../../components/InlineAlert';
import LoginUtil from '../../../utils/login-util';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import SearchableUserList from '../SearchableUserList';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import { groupSchema } from '../../../utils/validation-schema';
import { useDirectoryApi } from '../../../data/directory/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface for props passed to the CreateGroupDialog component.
 */
interface Props extends DialogProps {
	onClose: () => void;
}

/**
 * React component representing a dialog for creating a new group.
 *
 * @param {Props} props Component props.
 * @returns {JSX.Element} The rendered component.
 */
const CreateGroupDialog: React.FC<Props> = (props: Props) => {

	const { onClose, ...rest } = props;
	const [showDetailForm, setShowDetailForm] = useState<boolean>(true);
	const styles = useStyles(showDetailForm);
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const { t } = useTranslation();
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const groupInitialValue: Group = {
		groupRoomKey: '',
		name: '',
		description: '',
		createdByLoginId: LoginUtil.getLoginId(),
		createdAt: '',
		groupMembers: []
	};
	const [groupMemberList, setGroupMemberList] = useState<Array<User>>([]);
	const formikRef = React.createRef<FormikProps<Group>>();
	const directoryApi = useDirectoryApi();
	const apiStatus = directoryApi.state.directory.apiStatus;

	/**
	 * Handles closing the dialog, with confirmation if the form is dirty.
	 */
	const handleClose = () => {
		if (formikRef.current?.dirty) {
			setShowSaveDialog(true);
		} else {
			directoryApi.resetError();
			onClose();
		}
	};

	/**
	 * Handles form submission.
	 *
	 * @param {Group} group Form values representing group data.
	 */
	const onSubmit = (group: Group) => {
		group.groupMembers = groupMemberList.map(member => ({
			loginId: member.email
		}));
		group.groupMembers.push({
			loginId: LoginUtil.getLoginId()
		});
		group.name = Util.getText(group.name);
		group.description = Util.getText(group.description);
		directoryApi.createGroup(group).then((group?: Group) => {
			if (group) {
				onClose();
			}
		});
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>
					{(isDesktop || showDetailForm) ? t('newGroup') : t('addMembers')}
				</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<InlineAlert message={Util.getApiError([CREATE_GROUP], apiStatus)} />
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validationSchema={groupSchema}
				initialValues={groupInitialValue}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							<Box sx={styles.contentLeft}>
								<Field
									name='name'
									label={t('name')}
									placeholder={t('name')}
									component={CustomInput}
								/>
								<Field
									name='description'
									label={t('description')}
									subLabel={t('optional')}
									placeholder={t('createGroupDesc')}
									component={CustomInput}
									multiline
								/>
							</Box>
							<Divider sx={styles.divider} />
							<Box sx={styles.contentRight}>
								<SearchableUserList userList={groupMemberList} onUserListChange={setGroupMemberList} />
							</Box>
						</DialogContent>
						<DialogActions>
							<CustomButton
								sx={styles.nextBtn}
								title={t('next')}
								color='primary'
								disabled={!dirty || !isValid}
								onClick={() => setShowDetailForm(false)}
							/>
							<CustomButton
								sx={styles.saveBtn}
								type='submit'
								title={t('save')}
								color='primary'
								disabled={!dirty || !isValid || Util.isArrayEmpty(groupMemberList)}
								startIcon={<SaveIcon sx={styles.smIcon} />}
							/>
						</DialogActions>
					</Form>
				)}
			</Formik>
			<CircularProgressBar show={Util.isApiLoading([CREATE_GROUP], apiStatus)} />
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={onClose}
				onClose={() => setShowSaveDialog(false)}
			/>
		</Dialog>
	);
};

export default CreateGroupDialog;