import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import CheckIcon from '../../../../components/CustomIcons/CheckIcon';
import MenuList from '../../SettingsMenuList';
import { SETTINGS_APPEARANCE_MENU_OPTIONS } from '../../../../utils/ui-constants';
import SettingsMenuData from '../../../../types/ui/settings_menu_data';
import { colorModeManager } from '../../../../theme/color-mode-manager';
import useStyles from './styles';

/**
 * AppearanceSubMenu Component
 * 
 * This component renders a list of appearance-related menu options, allowing users to select between different themes.
 * The currently selected theme is indicated by a check icon.
 * 
 * @component
 * @returns {JSX.Element} The AppearanceSubMenu component JSX.
 */
const AppearanceSubMenu: React.FC = () => {

	const styles = useStyles();
	const themeMode = colorModeManager.get();
	const [ menuOptions, setMenuOptions ] = useState<Array<SettingsMenuData>>(SETTINGS_APPEARANCE_MENU_OPTIONS);

	/**
	 * Effect hook to update the menu options with the correct icon based on the current theme mode.
	 * This ensures that the check icon is displayed next to the currently selected theme.
	 * 
	 * @param {string} themeMode - The current theme mode (e.g., 'light', 'dark', 'system')
	 */
	useEffect(() => {
		const mode = localStorage.getItem('@aurora-app-color-mode');
		const updatedMenuOptions = SETTINGS_APPEARANCE_MENU_OPTIONS.map(option => ({
			...option,
			icon: option.id === mode ? <CheckIcon /> : undefined,
		}));
		setMenuOptions(updatedMenuOptions);

	}, [ themeMode ]);

	/**
	 * Handles the selection of a new theme option.
	 * 
	 * Updates the theme mode via colorModeManager and reloads the page to apply the new theme.
	 * 
	 * @param {SettingsMenuData} item - The selected menu item representing the new theme
	 */
	const handleThemeChange = (item: SettingsMenuData) => {
		colorModeManager.set(item.id.toString()); // Update the theme mode
		location.reload(); // Reload the page to apply the new theme
	};

	return (
		<Box sx={styles.container}>
			<MenuList
				menuItems={menuOptions}
				onItemClick={handleThemeChange}
			/>
		</Box>
	);
};

export default AppearanceSubMenu;
