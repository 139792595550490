import { AssignedSessionStatus } from '../../../../../../utils/constants';
import Dimens from '../../../../../../theme/dimens';
import { DrawerItemProps } from '.';
import { useTheme } from '@mui/material';

/**
 * This hook creates styles for the ParentDrawerItem component based on its props.
 * It utilizes theme object, dimensions, and utility functions from the theme.
 * 
 * @param {DrawerItemProps} props - An object containing properties for the drawer item.
 * @returns {object} - An object containing styles for the drawer item and its elements.
 */
const useStyles = (props: DrawerItemProps, userStatus = AssignedSessionStatus.Online) => {

  const theme = useTheme();

  const styles = {
    avatar: {
      width: Dimens.avatarSize.xl,
      height: Dimens.avatarSize.xl,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary
    },
    avatarBadge: {
      '&.MuiBadge-root': {
        flexDirection: 'column',
        alignItems: 'center'
      },
      'span': {
        height: Dimens.avatarBadgeSize,
        width: Dimens.avatarBadgeSize,
        border: `${Dimens.badgeBorderWidth} solid ${theme.palette.background.default}`,
        borderRadius: Dimens.radius.md,
        bgcolor: () => {
					let color = theme.palette.text.secondary
					if (userStatus === AssignedSessionStatus.Online) {
						color = theme.palette.success.main;
					} else if (userStatus === AssignedSessionStatus.Away) {
						color = theme.palette.warning.main;
					} else if (userStatus === AssignedSessionStatus.DoNotDisturb) {
						color = theme.palette.error.main;
					}

					return color;
				}
      }
    },
    divider: {
      bgcolor: theme.palette.primary.main,
      width: '60%',
      borderRadius: Dimens.radius.md,
      mt: Dimens.spacing.xs,
      height: Dimens.drawer.activeDividerHeight,
      visibility: props.isActive ? 'visible' : 'hidden',
    },
    iconBadge: {
      'span': {
        height: props.hasBadge ? Dimens.iconBadgeSize : 0,
        width: props.hasBadge ? Dimens.iconBadgeSize : 0,
        border: `${props.hasBadge ? Dimens.badgeBorderWidth : 0} solid ${theme.palette.background.default}`,
        borderRadius: Dimens.radius.md,
        bgcolor: theme.palette.error.main
      }
    },
    avatarBadgePos: {
      vertical: 'top',
      horizontal: 'left',
    },
    iconBadgePos: {
      vertical: 'top',
      horizontal: 'right',
    },
    listBtn: {
      p: props.isAvatar ? 0 : Dimens.spacing.base,
      justifyContent: 'center',
      '&:hover': {
        borderRadius: Dimens.radius.md
      },
      display: props.hide ? 'none' : 'flex',
      mb: props.isDestructive ? 0 : Dimens.spacing.md,
      [theme.breakpoints.down('sm')]: {
        mb: 0
      }
    },
    listIcon: {
      minWidth: 0,
      color: props.isDestructive ? theme.palette.error.main : (
        props.isActive ? theme.palette.primary.main : theme.palette.text.primary)
    }
  };

  return styles;
};

export default useStyles;
