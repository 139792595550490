import Dimens from '../../../theme/dimens';

/**
 * useStyles hook defines the styling for the DashboardTab component.
 * It utilizes Material UI's `useTheme` hook to access the current theme and
 * custom dimensions from the `Dimens` object.
 * @returns {object} An object containing the style definitions.
 */
const useStyles = () => {

  const { lg, section } = Dimens.spacing;

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden'
    },
    innerWrapper: {
      display: 'flex',
      flex: 1
    },
    contentWrapper: {
      overflowY: 'auto',
      m: lg,
      p: 0,
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      m: 0,
      alignContent: 'center'
    },
    searchWrapper: {
      display: 'flex',
      justifyContent: 'end',
      flexDirection: 'row',
      p: section,
      pb: 0,
      '.MuiInputBase-root': {
        minWidth: Dimens.dashboardSearchWidth
      }
    }
  };

  return styles;
};

export default useStyles;