import Dimens from '../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SettingsHeader component.
 *
 * @returns An object containing the styled classes.
 */
const useStyles = () => {
	const { sm, lg, section } = Dimens.spacing;
	const theme = useTheme();

	const styles = {
		appBar: {
			zIndex: 0,
			bgcolor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			backgroundImage: 'none',
			boxShadow: 'none',
			p: section,
			position: 'relative',
			'& .MuiToolbar-root': {
				p: 0,
				minHeight: 'unset'
			},
			[ theme.breakpoints.down('sm') ]: {
				p: lg
			}
		},
		title: {
			fontWeight: Dimens.fontWeight.bold,
			overflow: 'hidden',
			textOverflow: 'auto',
			whiteSpace: 'nowrap',
			[ theme.breakpoints.down('sm') ]: {
				textAlign: 'center',
				flexGrow: 0,
				position: 'absolute',
				left: '50%',
				transform: 'translateX(-50%)',
				width: '90%'
			}
		},
		iconBtn: {
			p: sm,
			radius: Dimens.radius.md,
			zIndex: 2
		},
		icon: {
			color: theme.palette.text.primary,
			fontSize: Dimens.icon.md
		}
	};

	return styles;
};

export default useStyles;
