import AppError from '../../exception/app-error';
import { User } from '../../types/user';

export const RESET_ERROR_STATE = 'RESET_USER_ERROR_STATE';
export const SEARCH_USER_LIST = 'SEARCH_USER_LIST';
export const SEARCH_USER_LIST_SUCCESS = 'SEARCH_USER_LIST_SUCCESS';
export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const FETCH_POSITION_LIST = 'FETCH_POSITION_LIST';
export const POSITION_LIST_SUCCESS = 'POSITION_LIST_SUCCESS';
export const FETCH_USER_DETAIL = 'FETCH_USER_DETAIL';
export const UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL';
export const UPDATE_ACCOUNT_LOCK_STATUS = 'UPDATE_ACCOUNT_LOCK_STATUS';
export const DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT';
export const INVITE_USERS = 'INVITE_USERS';
export const INVITE_USERS_SUCCESS = 'INVITE_USERS_SUCCESS';
export const API_FAILURE = 'USER_API_FAILURE';
export const RESET_USER_STATE = 'RESET_USER_STATE';

export interface ResetErrorStateAction {
  type: typeof RESET_ERROR_STATE;
}

export interface SearchUserListAction {
  type: typeof SEARCH_USER_LIST;
}

export interface SearchUserListSuccessAction {
  type: typeof SEARCH_USER_LIST_SUCCESS;
  payload: Array<User>;
}

export interface FetchUserListAction {
  type: typeof FETCH_USER_LIST;
}

export interface UserListSuccessAction {
  type: typeof USER_LIST_SUCCESS;
  payload: Array<User>;
}

export interface FetchUserDetailAction {
  type: typeof FETCH_USER_DETAIL;
}

export interface UpdateUserDetailAction {
  type: typeof UPDATE_USER_DETAIL;
}

export interface FetchPositionListAction {
  type: typeof FETCH_POSITION_LIST;
}

export interface PositionListSuccessAction {
  type: typeof POSITION_LIST_SUCCESS;
  payload: Array<string>;
}

export interface InviteUsersAction {
  type: typeof INVITE_USERS;
}

export interface InviteUsersSuccessAction {
  type: typeof INVITE_USERS_SUCCESS;
}

export interface ApiFailureAction {
  type: typeof API_FAILURE;
  payload: AppError;
}

export interface UserStateResetAction {
  type: typeof RESET_USER_STATE;
}

export type UserActionTypes = ResetErrorStateAction | SearchUserListAction | SearchUserListSuccessAction
  | FetchUserListAction | UserListSuccessAction | FetchUserDetailAction | UpdateUserDetailAction
  | FetchPositionListAction | PositionListSuccessAction | InviteUsersAction | InviteUsersSuccessAction
  | ApiFailureAction | UserStateResetAction;