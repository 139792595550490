import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const VideoIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3615_194)'>
          <path d='M15.5 9.6651L20.8118 7.00976C20.9897 6.9209 21.1872 6.87896 21.3858 6.8879C21.5844 6.89684 21.7774 6.95638 21.9465 7.06087C22.1157 7.16535 22.2553 7.31132 22.3521 7.48491C22.449 7.65851 22.4999 7.85397 22.5 8.05276V15.9441C22.4999 16.1429 22.449 16.3384 22.3521 16.5119C22.2553 16.6855 22.1157 16.8315 21.9465 16.936C21.7774 17.0405 21.5844 17.1 21.3858 17.109C21.1872 17.1179 20.9897 17.076 20.8118 16.9871L15.5 14.3318V9.6651Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M1.5 7.33333C1.5 6.71449 1.74583 6.121 2.18342 5.68342C2.621 5.24583 3.21449 5 3.83333 5H13.1667C13.7855 5 14.379 5.24583 14.8166 5.68342C15.2542 6.121 15.5 6.71449 15.5 7.33333V16.6667C15.5 17.2855 15.2542 17.879 14.8166 18.3166C14.379 18.7542 13.7855 19 13.1667 19H3.83333C3.21449 19 2.621 18.7542 2.18342 18.3166C1.74583 17.879 1.5 17.2855 1.5 16.6667V7.33333Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_3615_194'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default VideoIcon;