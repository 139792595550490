import { Box } from '@mui/material';
import React, { useState } from 'react';
import { FieldProps } from 'formik';
import { useTranslation } from 'react-i18next';

import CustomLabel from '../../../../../components/CustomLabel';
import SearchBar from '../../../../../components/SearchBar';
import Util from '../../../../../utils/util';
import useStyles from './styles';
import { DEFAULT_PAGE_SIZE, DirectoryRecordType, ListItemType, SortOrder } from '../../../../../utils/constants';
import { DirectorySearchRequest as SearchRequest } from '../../../../../types/directory-search-request';
import { DirectoryRecord } from '../../../../../types/directory-record';
import ListItemAddRemoveCard from '../../../../../components/ListItemAddRemoveCard';
import Team from '../../../../../types/team';
import { useSearchApi } from '../../../../../data/search/api';
import InlineAlert from '../../../../../components/InlineAlert';
import { SEARCH_RECORDS } from '../../../../../data/search/action-types';

/**
 * Interface for props passed to the SearchableUserAndTeamList component.
 */
type Props = {
  typeList: Array<DirectoryRecordType>;
  existingTeamList?: Array<Team>;
  editExistingTeamList?: boolean;
  subHeading?: string;
} & FieldProps;

/**
 * React component for searching and adding/removing users and teams.
 *
 * @param {Props} props Component props.
 * @returns {JSX.Element} The rendered component.
 */
const SearchableUserAndTeamList: React.FC<Props> = (props: Props) => {
  const {
    field: { name, value },
    form: { setFieldValue },
    typeList,
    existingTeamList,
    editExistingTeamList,
    subHeading,
  } = props;
  const styles = useStyles();
  const { t } = useTranslation();
  const [searchKey, setSeachKey] = useState<string>('');
  const searchApi = useSearchApi();
  const apiStatus = searchApi.state.search.apiStatus;
  const recordList: DirectoryRecord[] = value || [];

  /**
   * Filters the list based on search criteria, removes existing teams, and sorts alphabetically.
   *
   * @returns {Array<DirectoryRecord>} Filtered list of users and teams.
   */
  const getFilteredList = (): Array<DirectoryRecord> => {
    let searchList: Array<DirectoryRecord> = [];
    if (searchKey && searchKey.length > 0 && !Util.isArrayEmpty(searchApi.state.search.recordList)) {
      searchList = searchApi.state.search.recordList;
      if (existingTeamList && existingTeamList.length > 0) {
        searchList = searchList.filter(record =>
          !record.teamKey || !existingTeamList?.some(team => team.teamKey === record.teamKey)
        );
      }
    } else if (!Util.isArrayEmpty(recordList)) {
      searchList = recordList.sort((a, b) => {
        const nameA = a.name || a.teamName;
        const nameB = b.name || b.teamName;

        return nameA.localeCompare(nameB);
      });
    }

    return searchList;
  };

  /**
   * Checks if a record already exists in the selected record list.
   *
   * @param {DirectoryRecord} record The record to check.
   * @returns {boolean} True if the record exists, false otherwise.
   */
  const isRecordExist = (record: DirectoryRecord): boolean => {
    
    return !!recordList && recordList.some(item =>
      (record.teamKey && item.teamKey === record.teamKey) ||
      (record.name && item.name === record.name)
    );
  };

  // Get the filtered record list based on search and sorting logic.
  const filteredList = getFilteredList();

  /**
   * Adds or removes a record from the selected list.
   *
   * @param {DirectoryRecord} record The record to add or remove.
   */
  const addOrRemoveRecord = (record: DirectoryRecord): void => {
    const newRecordList = isRecordExist(record)
      ? recordList.filter(item => item.email !== record.email || item.teamKey !== record.teamKey)
      : [...recordList, record];
    setFieldValue(name, newRecordList);
  };

  const isOrginalRecord = (record: DirectoryRecord): boolean => {
    return !!(existingTeamList && existingTeamList.some(item =>
      record.teamKey && item.teamKey === record.teamKey
    ));
  };

  /**
   * Handles changes in the search bar input and triggers a search request.
   *
   * @param {string} searchKey Updated search keyword.
   */
  const onSearchChange = (searchKey: string) => {
    setSeachKey(searchKey);
    const request: SearchRequest = {
      search: searchKey,
      types: typeList,
      filter: {
        siteIds: null,
        departmentIds: null,
      },
      page: {
        pageNumber: 0,
        size: DEFAULT_PAGE_SIZE,
        sort: {
          sortBy: DirectoryRecordType.User,
          order: SortOrder.Asc,
        },
      },
      isComplete: true,
    };
    if (searchKey) {
      searchApi.searchRecords(request);
    }
  };

  return (
    <Box sx={styles.container}>
      {
        subHeading && <CustomLabel label={subHeading} />
      }
      <SearchBar placeholder={t('search')} handleChange={onSearchChange} />
      <InlineAlert message={Util.getApiError([SEARCH_RECORDS], apiStatus)} />
      <Box sx={styles.contentWrapper}>
        {filteredList.map((record, index) => (
          <ListItemAddRemoveCard
            key={`record-${index}`}
            title={record.name || record.teamName}
            subTitle={record.position || `${record.teamMembers?.length ?? 0}${''} ${t('members')}`}
            imageUrl={record.image?.image}
            isExistingItem={isRecordExist(record)}
            itemType={record.teamKey ? ListItemType.Team : undefined}
            onActionClick={() => addOrRemoveRecord(record)}
            hideActionButton={editExistingTeamList ? !editExistingTeamList: isOrginalRecord(record)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SearchableUserAndTeamList;