import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

const useStyles = () => {

  const theme = useTheme();

  const styles = {
    container: {
      bgcolor: theme.palette.background.paper,
      height: 'inherit'
    }
  };

  return styles;
};

export default useStyles;