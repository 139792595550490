import { Box, Typography } from '@mui/material';

import MessageDateItem from '../MessageDateItem';
import MessageInfo from '../../../../../types/message-info';
import React, {  } from 'react';
import Util from '../../../../../utils/util';
import useStyles from './styles';

interface Props {
	title: string;
	icon: React.ReactNode;
	userList: Array<MessageInfo>;
	isGroup: boolean;
	date?: string;
}

/**
 * Renders a component displaying the message header information (title, icon, and date for single messages).
 *
 * @param {Props} props - Component props containing the required `title`, optional `icon`, `userList`, `isGroup`,
 * and `date` string.
 * 
 * @returns {JSX.Element} JSX element representing the MessageInfoHeader component.
 */
const MessageInfoHeader: React.FC<Props> = (props: Props) => {

	const styles = useStyles();

	return (
		<>
			{(!Util.isArrayEmpty(props.userList) || !props.isGroup) &&
				<Box sx={styles.titleLayout}>
					{props.icon}
					<Typography variant='h5' sx={styles.title}>{props.title}</Typography>
					{!props.isGroup && <MessageDateItem date={props.date} />}
				</Box>
			}
		</>
	);
};

export default MessageInfoHeader;