import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import CircularProgressBar from '../../../components/CircularProgressBar';
import { FETCH_TEAM_DETAIL } from '../../../data/team/action-types';
import PencilIcon from '../../../components/CustomIcons/PencilIcon';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import Team from '../../../types/team';
import TeamEditPanel from '../TeamEditPanel';
import TeamIcon from '../../../components/CustomIcons/TeamIcon';
import TeamMemberCard from './TeamMemberCard';
import Util from '../../../utils/util';
import useStyles from './styles';
import { useTeamApi } from '../../../data/team/api';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	team?: Team;
	onClose: () => void;
}

/**
 * Renders a drawer displaying team information, actions, and potentially an edit panel.
 *
 * @param {Props} props - Component props containing open state, navigation mode, team data, and close callback.
 * 
 * @returns JSX element representing the team view panel.
 */
const TeamViewPanel: React.FC<Props> = (props: Props) => {

	const { open, team, onClose } = props;
	const styles = useStyles(team?.memberCount);
	const [showTeamEdit, setShowTeamEdit] = useState<boolean>(false);
	const teamApi = useTeamApi();
	const apiStatus = teamApi.state.team.apiStatus;
	const { t } = useTranslation();

	/**
	 * Fetches team details if the provided team object is incomplete.
	 */
	useEffect(() => {
		const teamDetail = getTeamDetail();
		if (open && team?.teamKey && (!teamDetail || !teamDetail?.isFetched)) {
			teamApi.fetchTeamDetail(team?.teamKey);
		}
	}, [open]);

	/**
	 * Fetches team details if not already available or incomplete.
	 *
	 * @returns {Team | null} The fetched team details or null if not found or loading.
	 */
	const getTeamDetail = () => {
		const detail = teamApi.state.team.teamList.find(item => item.teamKey === team?.teamKey);
		
		return detail;
	}

	// Fetch team details when the component loads
	const teamDetail = getTeamDetail();

	/**
	 * Handles closing of the team edit modal.
	 * 
	 * @param {string} [action] - Optional action parameter to determine if additional actions should be taken upon 		closing.
	 */
	const onTeamEditClose = (action?: string) => {
		setShowTeamEdit(false);
		if (action) {
			props.onClose();
		}
	}

	return (
		<RightDrawerLayout
			hasDefaultBg
			open={open}
			rightIcon={(teamDetail && teamDetail.isFetched) ? <PencilIcon /> : undefined}
			onActionClick={() => setShowTeamEdit(true)}
			onClose={onClose}
		>
			{(open && teamDetail && teamDetail.isFetched && !showTeamEdit) &&
				<Box sx={styles.contentWrapper}>
					<Box sx={styles.content}>
						<Box sx={styles.teamHeader}>
							<Box sx={styles.avatarWrapper}>
								<Box sx={styles.iconWrapper}>
									<TeamIcon />
								</Box>
							</Box>
							<Box sx={styles.teamContent}>
								<Typography variant='h2' sx={styles.title}>{teamDetail.teamName}</Typography>
							</Box>
						</Box>
						<Box sx={styles.teamInfo}>
							<Box sx={styles.content}>
								<Typography variant='p1'>{t('members')}</Typography>
								{teamDetail.teamMembers.map((user, index) => (
									<TeamMemberCard
										key={`team-member-${index}`}
										name={user.name ?? ''}
										position={user.loginId}
										profilePic={user.thumbnailImageKey}
									/>
								))}
							</Box>
						</Box>
					</Box>
				</Box>
			}
			{teamDetail && teamDetail.isFetched &&
				<TeamEditPanel
					open={showTeamEdit}
					team={teamDetail}
					onClose={onTeamEditClose}
				/>
			} 
			<CircularProgressBar show={Util.isApiLoading([FETCH_TEAM_DETAIL], apiStatus)} />
		</RightDrawerLayout>
	);
};

export default TeamViewPanel;