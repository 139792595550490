import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';

import Dimens from '../../theme/dimens';
import { FieldProps } from 'formik';
import MenuData from '../../types/ui/menu-data';
import Util from '../../utils/util';
import useStyles from './styles';

type Props = {
  label?: string;
  subLabel?: string;
  menu: Array<MenuData>;
} & FieldProps;

/**
 * CheckboxSelector component for selecting multiple options using checkboxes.
 * 
 * This component renders a group of checkboxes with labels and handles form integration
 * using Formik. It populates the selected options from props and updates the form field
 * value on checkbox changes.
 * 
 * @props Props - The component properties including:
 *   - label: Optional label for the checkbox group.
 *   - subLabel: Optional sub-label to provide additional context.
 *   - menu: An array of objects containing checkbox options. Each object should have
 *     - id: A unique identifier for the option.
 *     - value: The display text for the checkbox.
 *     - isChecked: (boolean, optional) The initial checked state for the option. Defaults to false.
 *   - field: Formik field object containing name, onBlur, and value properties.
 *   - form: Formik form object containing errors, touched, setFieldTouched, and setFieldValue methods.
 * 
 * @returns {JSX.Element} - The rendered CheckboxSelector component.
 */
const CheckboxSelector: React.FC<Props> = (props: Props) => {

  const {
    field: { name, onBlur, value },
    form: { errors, touched, setFieldTouched, setFieldValue },
    label,
    subLabel
  } = props;
  const styles = useStyles();
  const hasError = Boolean(errors[name] && touched[name]);

  /**
   * Populates the initial selected options if the menu is not empty and the value is empty.
   */
  useEffect(() => {
    if (!Util.isArrayEmpty(props.menu) && Util.isArrayEmpty(value)) {
      setFieldValue(name, props.menu);
    }
  }, [props.menu]);

  /**
   * Handles checkbox change event.
   * 
   * Updates the selected options in the form field value.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event.
   * @param {MenuData} option - The selected option.
   * @param {boolean} checked - The checked state of the checkbox.
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, option: MenuData, checked: boolean) => {
    handleBlur();
    const updatedMenu = value.map((menu: MenuData) => {
      return menu.id === option.id ? { ...menu, isChecked: checked } : menu;
    });
    setFieldValue(name, updatedMenu);
  }

  /**
   * Handles blur event for the form field.
   * 
   * Sets the field as touched and triggers the onBlur callback.
   */
  const handleBlur = () => {
    setFieldTouched(name);
    onBlur(name);
  }

  return (
    <Stack sx={styles.wrapper}>
      {label &&
        <Stack sx={styles.header}>
          <Typography variant='p1' fontWeight={Dimens.fontWeight.semiBold}>{label}</Typography>
          {subLabel && (
            <Typography variant='p1' sx={styles.subLabel}>
              {subLabel}
            </Typography>
          )}
        </Stack>
      }
      <FormGroup sx={styles.formGroup}>
        {value.map((option: MenuData, index: number) => {
          return (
            <FormControlLabel
              key={`checkbox-${index}`}
              label={<Typography variant='p1'>{option.value}</Typography>}
              control={
                <Checkbox checked={option.isChecked} onChange={(e, checked) => handleChange(e, option, checked)} />
              }
            />)
        })}
      </FormGroup>
      {hasError && <FormHelperText sx={styles.fieldError}>{errors[name]?.toString()}</FormHelperText>}
    </Stack>
  );
};

export default CheckboxSelector;
