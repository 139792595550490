import Dimens from '../../theme/dimens';
import { Props } from '.';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the CustomInput component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (props: Props) => {

	const theme = useTheme();
	
	const styles = {
		wrapper: {
			pb: Dimens.spacing.base
		},
		noPad: {
			pb: 0
		},
		textField: {
			'& .MuiOutlinedInput-root': {
				'input': {
					height: 'inherit'
				},
				'& fieldset': {
					borderColor: theme.palette.background.default
				},
				'&:hover fieldset': {
					borderColor: theme.palette.background.default
				},
				'&.Mui-focused fieldset': {
					borderWidth: Dimens.inputBorderWidth,
					borderColor: props.readOnly ? theme.palette.background.default : theme.palette.primary.main
				},
				'&.Mui-error fieldset': {
					borderWidth: Dimens.inputBorderWidth,
					borderColor: theme.palette.error.main
				}
			},
			'& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
				...theme.typography.p1,
				color: theme.palette.text.secondary,
				opacity : 1
			},
			'.MuiInputBase-multiline': {
				p: 0
			}
		},
		outerInput: {
			borderRadius: Dimens.radius.md,
			backgroundColor: theme.palette.background.default
		},
		innerInput: {
			...theme.typography.p1,
			p: 0,
			m: `${Dimens.spacing.md} ${Dimens.spacing.lg}`
		},
		fieldError: {
			...theme.typography.footer,
			m: 0,
			pt: Dimens.spacing.xs
		}
	};

	return styles;
};

export default useStyles;