import Dimens from '../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DeviceEditPanel component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { base, lg, section } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    tabListWrapper: {
      flex: 1,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    },
    tabList: {
      borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderColor: theme.palette.background.default,
      px: section,
      minHeight: 0,
      [theme.breakpoints.down('sm')]: {
        px: lg
      }
    },
    tab: {
      ...theme.typography.p1,
      fontWeight: Dimens.fontWeight.medium,
      textTransform: 'none',
      mr: section,
      p: `${base} 0`,
      minHeight: 0,
      minWidth: 0
    },
    tabPanel: {
      p: 0,
      display: 'contents',
      overflowY: 'auto'
    }
  };

  return styles;
};

export default useStyles;
