import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const StopIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M11.957 2H4.45703C3.86029 2 3.288 2.23705 2.86604 2.65901C2.44408 3.08097 2.20703 3.65326 2.20703 4.25V11.75C2.20703 12.3467 2.44408 12.919 2.86604 13.341C3.288 13.7629 3.86029 14 4.45703 14H11.957C12.5538 14 13.1261 13.7629 13.548 13.341C13.97 12.919 14.207 12.3467 14.207 11.75V4.25C14.207 3.65326 13.97 3.08097 13.548 2.65901C13.1261 2.23705 12.5538 2 11.957 2Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default StopIcon;