import Dimens from '../../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the MessageDateItem component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    date: {
      color: theme.palette.text.secondary,
      pr: Dimens.spacing.base
    }
  };

  return styles;
};

export default useStyles;
