import { TableCell, TableRow } from '@mui/material';
import { USER_TYPE_ADMIN, USER_TYPE_USER } from '../../../utils/constants';

import CustomButton from '../../../components/CustomButton';
import React, { } from 'react';
import UserType from '../../../types/user-type';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  userTypeList: Array<UserType>;
  onPermissionDelete: (userType: UserType) => void;
}

/**
 * Footer component for the PermissionTable.
 * This component renders a row of buttons for deleting each user type permission.
 *
 * @param {Props} props Component props containing the user type list and delete handler.
 * 
 * @returns {JSX.Element} The JSX element for the table footer.
 */
const PermissionTableFooter: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  /**
   * Checks if the delete button should be disabled for a given user type.
   * Disables the delete button for "Admin" and "User" user types.
   *
   * @param {UserType} userType The user type to check.
   * 
   * @returns {boolean} True if the delete button should be disabled, false otherwise.
   */
  const isDeleteDisabled = (userType: UserType): boolean => {
    const userTypeName = userType.userType.toLowerCase();

    return (userTypeName === USER_TYPE_ADMIN.toLowerCase()
      || userTypeName === USER_TYPE_USER.toLowerCase());
  }

  return (
    <TableRow>
      <TableCell></TableCell>
      {props.userTypeList.map((userType, index) => (
        <TableCell key={`delete-footer-${index}`} onClick={() => props.onPermissionDelete(userType)}>
          <CustomButton
            sx={styles.btn}
            title={t('delete')}
            color='secondary'
            disabled={isDeleteDisabled(userType)}
            destructive
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default PermissionTableFooter;
