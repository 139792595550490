import { AppFeature, CARD_MAX_WIDTH, SortByAlphabet, TeamSubMenuItem, teamInitialValue } from '../../utils/constants';
import { Box, Grid } from '@mui/material';
import { DELETE_TEAM, FETCH_TEAM_LIST } from '../../data/team/action-types';
import React, { useEffect, useState } from 'react';

import AddIcon from '../../components/CustomIcons/AddIcon';
import AlertDialogSmall from '../../components/AlertDialogSmall';
import BinIcon from '../../components/CustomIcons/BinIcon';
import CreateTeamDialog from './CreateTeamDialog';
import InlineAlert from '../../components/InlineAlert';
import LoginUtil from '../../utils/login-util';
import { Masonry } from '@mui/lab';
import MenuData from '../../types/ui/menu-data';
import NoDataFound from '../../components/NoDataFound';
import SearchAndSortTopBar from '../../components/SearchAndSortTopBar';
import Team from '../../types/team';
import TeamCard from './TeamCard';
import TeamEditPanel from './TeamEditPanel';
import TeamViewPanel from './TeamViewPanel';
import Util from '../../utils/util';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import useStyles from './styles';
import { useTeamApi } from '../../data/team/api';
import { useWindowSize } from '../../hooks/use-window-size';

/**
 * TeamScreen component for managing team information.
 *
 * This component displays a list of teams with search, sort, and creation functionalities.
 * Users can view team details in a modal dialog and potentially edit or create new teams.
 *
 * @returns {JSX.Element} - The rendered TeamScreen component.
 */
const TeamScreen: React.FC = () => {

  const styles = useStyles();
  const [width] = useWindowSize();
  const numOfColumns = Math.floor(width / CARD_MAX_WIDTH);
  const navigate = useNavigate();
  const [sortBy, setSortBy] = useState<string>(SortByAlphabet.A_Z);
  const [searchKey, setSearchKey] = useState<string>('');
  const [team, setTeam] = useState<Team>();
  const [openTeamViewPanel, setOpenTeamViewPanel] = useState<boolean>(false);
  const [openCreateTeamDialog, setOpenCreateTeamDialog] = useState<boolean>(false);
  const [showTeamEdit, setShowTeamEdit] = useState<boolean>(false);
  const [openDeleteTeamDialog, setOpenDeleteTeamDialog] = useState<boolean>(false);
  const teamApi = useTeamApi();
  const apiStatus = teamApi.state.department.apiStatus;

  /**
   * Initializes the team fetch if the user has the necessary permission.
   */
  useEffect(() => {
    if (LoginUtil.hasPermission(AppFeature.ManageTeam)) {
      teamApi.fetchTeamList();
    } else {
      navigate(Util.getNavigationByPermission());
    }
  }, []);

  /**
   * Sorts and filters the team list based on search and sort criteria.
   *
   * @returns {Team[]} - The sorted and filtered team list.
   */
  const sortAndFilterTeams = () => {
    return (teamApi.state.team.teamList || []).filter((team) =>
      team.teamName.toLowerCase().includes(searchKey.toLowerCase())
    ).sort((a, b) => {
      const comparison = a.teamName.localeCompare(b.teamName);

      return sortBy === SortByAlphabet.A_Z ? comparison : -comparison;
    });
  };

  const teamList = sortAndFilterTeams();

  /**
   * Handles selection of a team.
   *
   * @param {Team} team - The selected team object.
   */
  const onItemSelected = (team: Team) => {
    setTeam(team);
    if (team.teamKey) {
      setOpenTeamViewPanel(true);
    } else {
      setOpenCreateTeamDialog(true);
    }
  };

  /**
   * Handles clicking of submenu items within a team card.
   *
   * @param {Team} team - The team associated with the submenu.
   * @param {MenuData} menu - The menu item clicked.
   */
  const handleSubMenuItemClick = (team: Team, menu: MenuData) => {
    setTeam(team);
    if (menu.id === TeamSubMenuItem.Edit) {
      setShowTeamEdit(true);
    } else if (TeamSubMenuItem.Delete) {
      setOpenDeleteTeamDialog(true);
    }
  };

  /**
   * Handles the deletion of a team.
   * Closes the delete confirmation dialog and calls the API to delete the team.
   */
  const onDeleteTeamClick = () => {
    setOpenDeleteTeamDialog(false);
    if (team) {
      teamApi.deleteTeam(team);
    }
  }

  return (
    <Box sx={styles.container}>
      <SearchAndSortTopBar
        title={t('teams')}
        subTitle={t('manageTeams')}
        actionText={t('new')}
        actionIcon={<AddIcon />}
        onActionClick={() => onItemSelected(teamInitialValue)}
        sortBy={sortBy}
        onSortChange={setSortBy}
        onSearchChange={setSearchKey}
      />
      <InlineAlert sx={styles.alertWrapper} message={Util.getApiError([FETCH_TEAM_LIST, DELETE_TEAM], apiStatus)} />
      {Util.isArrayEmpty(teamList) ? (
        <NoDataFound message={t('noTeamsFound')} />
      ) : (
        <Grid sx={styles.contentWrapper}>
          <Masonry columns={numOfColumns === 0 ? 1 : numOfColumns} spacing={2} sx={styles.content} sequential>
            {teamList.map((team) => (
              <TeamCard
                team={team}
                key={team.teamKey}
                onItemSelected={onItemSelected}
                handleSubMenuItemClick={menu => handleSubMenuItemClick(team, menu)}
              />
            ))}
          </Masonry>
        </Grid>
      )}
      {openCreateTeamDialog &&
        <CreateTeamDialog
          open={openCreateTeamDialog}
          onClose={() => setOpenCreateTeamDialog(false)}
        />
      }
      <TeamViewPanel
        open={Boolean(team && openTeamViewPanel)}
        team={team}
        onClose={() => setOpenTeamViewPanel(false)}
      />
      {(team && showTeamEdit) &&
        <TeamEditPanel
          open={showTeamEdit}
          team={team}
          onClose={() => setShowTeamEdit(false)}
        />
      }
      <AlertDialogSmall
        open={openDeleteTeamDialog}
        title={t('deleteTeam')}
        titleIcon={<BinIcon />}
        message={t('deleteTeamMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeleteTeamDialog(false)}
        primaryLabel={t('delete')}
        onPrimaryAction={onDeleteTeamClick}
        isDestructive
        onClose={() => setOpenDeleteTeamDialog(false)}
      />

    </Box>
  );
};

export default TeamScreen;