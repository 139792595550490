import React, { useCallback, useContext } from 'react';
import {
	apiFailure,
	fetchTmList,
	initTeamCreate,
	initTeamDelete,
	initTeamDetailFetch,
	initTeamMemberAdd,
	initTeamMemberRemove,
	initTeamUpdate,
	resetErrorState,
	resetTeamState,
	teamListSuccess
} from './actions';
import { doGet, doPost } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import { HttpStatusCode } from 'axios';
import { SessionExpirationContext } from '../../store/session-expiration-provider';
import { Store } from '../../store/store';
import Team from '../../types/team';
import TeamDetailResponse from '../../types/team-detail-response';
import TeamListResponse from '../../types/team-list-response';
import TeamMemberChangeRequest from '../../types/team-members-change-request';
import { useTranslation } from 'react-i18next';
import { DirectoryRecord } from '../../types/directory-record';
import { useDirectoryApi } from '../directory/api';
import { searchDirectorySuccess } from '../directory/actions';

/**
 * Custom hook to manage team list data and actions.
 *
 * @returns An object containing functions for fetching team list and the team list state.
 */
export function useTeamApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();
	const { setSessionExpired } = useContext(SessionExpirationContext);
	const directoryApi = useDirectoryApi();

	/**
   * Fetches the team list from the API and dispatches actions based on the result.
   */
	const fetchTeamList = useCallback(async () => {
		dispatch(fetchTmList());
		try {
			const response: TeamListResponse = await doGet(APIConfig.teamList);
			dispatch(teamListSuccess(response.data || []));
			
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Creates a new team by sending a POST request to the API.
   * 
   * @param {Team} team - The team object to be created.
	 * 
   * @returns {TeamDetailResponse} The response object containing the created team details.
   */
	const createTeam = useCallback(async (team: Team) => {
		dispatch(initTeamCreate());
		try {
			const response: TeamDetailResponse = await doPost(APIConfig.createTeam, team);
			response.data.isFetched = true;
			const teamList = [...state.team.teamList];
			teamList.push(response.data);
			dispatch(teamListSuccess(teamList));
			// Update directory record
			const recordList: Array<DirectoryRecord> = directoryApi.state.directory.recordList;
			recordList.unshift(response.data as DirectoryRecord);
			dispatch(searchDirectorySuccess(recordList));			

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Updates an existing team by sending a POST request to the API.
   * 
   * @param {Team} team - The team object with updated details.
	 * 
   * @returns {TeamDetailResponse} The response object containing the updated team details.
   */
	const updateTeam = useCallback(async (team: Team) => {
		dispatch(initTeamUpdate());
		try {
			const response: TeamDetailResponse = await doPost(APIConfig.updateTeam, team);
			response.data.isFetched = true;
			const teamList = [...state.team.teamList.filter(tm => tm.teamKey !== team.teamKey), response.data];
			dispatch(teamListSuccess(teamList));
			// Update team records in the directory
			const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
			const recordIndex = recordList.findIndex(record => record.teamKey === team.teamKey);			
			if (recordIndex !== -1) {
				recordList[recordIndex] = response.data as DirectoryRecord;
			}
			dispatch(searchDirectorySuccess(recordList));
			
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Fetches details for a specific team by sending a GET request to the API.
   * 
   * @param {string} id - The ID of the team to fetch details for.
	 * 
   * @returns {TeamDetailResponse} The response object containing the team details.
   */
	const fetchTeamDetail = useCallback(async (id: string) => {
		dispatch(initTeamDetailFetch());
		try {
			const response: TeamDetailResponse = await doGet(`${APIConfig.teamDetails}/${id}`);
			response.data.isFetched = true;
			const teamList = [...state.team.teamList.filter(item => item.teamKey !== id), response.data];
			dispatch(teamListSuccess(teamList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.team.teamList]);

	/**
	 * Adds or removes a team member based on the provided request and operation flag.
	 *
	 * @param {TeamMemberChangeRequest} request - Information about the team member to be added or removed.
	 * @param {boolean} isAddMember - Flag indicating whether to add or remove the member.
	 * 
	 * @returns {Promise<TeamDetailResponse>} Promise resolving to the API response data.
	 */
	const addOrRemoveTeamMember = useCallback(async (request: TeamMemberChangeRequest, isAddMember?: boolean) => {
		dispatch(isAddMember ? initTeamMemberAdd() : initTeamMemberRemove());
		try {
		
			const url = isAddMember ? APIConfig.addTeamMember : APIConfig.removeTeamMember;
			const response: TeamDetailResponse = await doPost(url, request);
			response.data.isFetched = true;
			const teamList = [...state.team.teamList.filter(item => item.teamKey !== request.teamKey), response.data];
			dispatch(teamListSuccess(teamList));

			//update the directory team record
			const recordList: Array<DirectoryRecord> = [...state.directory.recordList];
			const recordIndex = recordList.findIndex(record => record.teamKey === request.teamKey);
			if (recordIndex !== -1) {
				// Modify the record at the found index with the updated response data
				recordList[recordIndex] = response.data as DirectoryRecord;
				dispatch(searchDirectorySuccess(recordList));
			}
			
			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
   * Deletes a team by sending a POST request to the API.
   *
   * @param {Team} team - The team object to be deleted.
   * @returns {team} (optional) The deleted team object on success.
   */
		const deleteTeam = useCallback(async (team: Team) => {
			dispatch(initTeamDelete());
			try {
				await doPost(`${APIConfig.removeTeam}/${team.teamKey}`);
				const teamList = [...state.team.teamList.filter(item => item.teamKey !== team.teamKey)];
				dispatch(teamListSuccess(teamList));
				
				return team;
			} catch (error: any) { /* eslint-disable-line */
				dispatchFailureAction(error);
			}
		}, [state.team.teamList]);

	/**
	 * Resets the site state in the Redux store.
	 */
	const resetTeam = () => {
		dispatch(resetTeamState());
	}

	/**
   * Dispatches an API failure action with an error message.
   *
   * @param {any} error - The error object.
   */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
    const message: string = error?.message || t('defaultErrorMsg');
    dispatch(apiFailure(new AppError(error?.code, message)));
		if (error?.code === HttpStatusCode.Unauthorized) {
			setSessionExpired(true);
		}
  }

	/**
	 * Resets the team error state in the Redux store.
	 */
		const resetError = () => {
			if (state.team.apiStatus?.error) {
				dispatch(resetErrorState());
			}
		}

	return {
		fetchTeamList,
		createTeam,
		updateTeam,
		deleteTeam,
		addOrRemoveTeamMember,
		fetchTeamDetail,	
		resetTeam,
		resetError,
		state
	};
}
