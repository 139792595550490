import {
  Box,
  Divider,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';

import React from 'react';
import useStyles from './styles';

export interface Props {
  label?: string;
  isActive: boolean;
  onChildDrawerSubItemClick: () => void;
}

/**
 * ChildDrawerSubItem component
 * 
 * This component renders a single sub-item within the child drawer's sub-menu.
 * It displays a label and triggers a callback on click.
 * 
 * @param {Props} props - An object containing properties for the child drawer sub-item.
 * @returns {JSX.Element} - The JSX element representing the child drawer sub-item.
 */
const ChildDrawerSubItem: React.FC<Props> = (props: Props) => {

  const styles = useStyles(props.isActive);

  return (
    <Box sx={styles.wrapper}>
      <Divider sx={styles.vDivider} />
      <Box sx={styles.listItem}>
        <Divider sx={styles.hDivider} />
        <ListItemButton sx={styles.listBtn} onClick={props.onChildDrawerSubItemClick}>
          <ListItemText sx={styles.listText} primary={<Typography variant='p1'>{props.label}</Typography>} />
        </ListItemButton>
      </Box>
    </Box>
  );
};

export default ChildDrawerSubItem;
