import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';

import Permission from '../../../types/permission';
import React, { } from 'react';
import { USER_TYPE_ADMIN } from '../../../utils/constants';
import UserType from '../../../types/user-type';
import useStyles from './styles';

interface Props {
  feature: Permission;
  userTypeList: Array<UserType>;
  onPermissionChanged: (userType: UserType, feature: Permission, checked: boolean) => void;
}

/**
 * Row component for the PermissionTable.
 * This component renders a single row for a permission with checkboxes for each user type.
 *
 * @param {Props} props Component props containing the permission data, user type list, and permission change handler.
 * 
 * @returns {JSX.Element} The JSX element for the table row.
 */
const PermissionTableRow: React.FC<Props> = (props: Props) => {

  const styles = useStyles();

  return (
    <TableRow>
      <TableCell>
        <Typography variant='p1'>{props.feature.label}</Typography>
      </TableCell>
      {props.userTypeList.map((userType, index) => (
        <TableCell key={`feature-checkbox-${index}`}>
          <Checkbox
            sx={styles.checkbox}
            disabled={userType.userType === USER_TYPE_ADMIN}
            checked={userType.permissions.map(p => p.id).includes(props.feature.id)}
            onChange={(_, checked) => props.onPermissionChanged(userType, props.feature, checked)}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default PermissionTableRow;
