import ChatUtil from '../../../../../utils/chat-util';
import Dimens from '../../../../../theme/dimens';
import { Props } from '.';
import ThemeUtil from '../../../../../theme/theme-util';
import useCommonStyles from '../../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Hook to generate styles for the DocumentView component based on props and theme.
 *
 * @param {Props} props - Props of the DocumentView component, including message data.
 * 
 * @returns {Object} An object containing styles for various document view elements.
 */
const useStyles = (props: Props) => {

  const { mini, xs, sm, base } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    pdfContainer: {
      display: 'flex',
      alignItems: props.messageData.file ? 'start' : 'center',
      bgcolor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      p: xs,
      mt: props.messageData.contentText ? base : 0,
      borderRadius: Dimens.radius.sm,
      cursor: props.messageData.file ? 'pointer' : 'unset',
      '& svg': {
        color: theme.palette.error.main,
        mt: mini,
        mr: base
      }
    },
    contentText: {
      ...commonStyles.textEllipsis(1),
      whiteSpace: 'pre-wrap',
      mr: props.messageData.file ? 0 : base,
      lineHeight: ThemeUtil.pxToRem(16)
    },
    contentSize: {
      color: theme.palette.text.secondary,
      lineHeight: ThemeUtil.pxToRem(12)
    },
    downloadBtn: {
      alignItems: 'center',
      display: 'flex',
      bgcolor: theme.palette.secondary.main,
      p: sm,
      '& svg': {
        ...commonStyles.smIcon,
        color: `${theme.palette.text.primary} !important`,
        m: '0 !important'
      }
    },
    imageContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: props.messageData.file ? 'left' : 'center',
      bgcolor: props.messageData.file ? 'transparent' : theme.palette.action.disabled,
      minHeight: props.messageData.file ? 0 : Dimens.attachment.defaultHeight,
      minWidth: props.messageData.file ? 0 : Dimens.attachment.defaultWidth,
      maxHeight: Dimens.attachment.defaultHeight,
      maxWidth: Dimens.attachment.defaultWidth,
      color: theme.palette.text.primary,
      borderRadius: Dimens.radius.sm,
      mt: props.messageData.contentText ? base : 0,
      mb: ChatUtil.isLoggedInUser(props.messageData.recipientLoginId ?? '') ?  xs : 0,
      '& img': {
        borderRadius: Dimens.radius.sm,
        maxHeight: Dimens.attachment.defaultHeight,
        maxWidth: Dimens.attachment.defaultWidth,
        cursor: 'pointer'
      }
    },
    videoContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: theme.palette.action.disabled,
      minHeight: props.messageData.file ? 0 : Dimens.attachment.defaultHeight,
      minWidth: Dimens.attachment.defaultWidth,
      maxHeight: Dimens.attachment.defaultHeight,
      maxWidth: Dimens.attachment.defaultWidth,
      color: theme.palette.text.primary,
      borderRadius: Dimens.radius.sm,
      mt: props.messageData.contentText ? base : 0,
      mb: ChatUtil.isLoggedInUser(props.messageData.recipientLoginId ?? '') ?  xs : 0,
      '& video': {
        maxHeight: Dimens.attachment.defaultHeight,
        maxWidth: Dimens.attachment.defaultWidth,
        minHeight: 'auto',
        minWidth: '100%',
        borderRadius: Dimens.radius.sm,
        objectFit: 'cover',
        cursor: 'pointer'
      }
    },
    audioContainer: {
      mt: props.messageData.contentText ? base : 0,
      minWidth: Dimens.attachment.defaultWidth,
      mb: ChatUtil.isLoggedInUser(props.messageData.recipientLoginId ?? '') ?  xs : 0
    },
    downloadOverlay: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      flex: 1,
      width: '100%',
      height: '100%'
    },
    playBtn: {
      position: 'absolute',
      alignItems: 'center',
      display: 'flex',
      bgcolor: theme.palette.secondary.main,
      p: sm,
      '& svg': {
        ...commonStyles.smIcon,
        color: `${theme.palette.text.primary} !important`,
        m: '0 !important'
      }
    }
  };

  return styles;
};

export default useStyles;