import Dimens from '../../theme/dimens';
import { MessagePriority } from '../../utils/constants';
import { Opacity } from '@mui/icons-material';
import { useTheme } from '@mui/material';

/**
 * Creates MUI theme styles for the AudioPlayer component based on priority and received state.
 *
 * @param {MessagePriority} priority - The message priority (Normal, Urgent). Defaults to Normal.
 * @param {boolean} isReceived - Indicates if the audio is received. Defaults to false.
 * @returns {object} - The MUI theme styles for the AudioPlayer component.
 */
const useStyles = (priority = MessagePriority.Normal, isReceived = false) => {

  const theme = useTheme();
  const { lg } = Dimens.spacing;

  const getColor = () => {
    let color = theme.palette.primary.contrastText;
    if (isReceived) {
      color = priority === MessagePriority.Urgent ? theme.palette.error.main : theme.palette.text.primary;
    }

    return color;
  }

  const styles = {
    audioPlayer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      color: getColor()
    },
    iconBtn: {
      p: 0,
      mr: lg,
      'svg': {
        fontSize: Dimens.icon.sm,
        color: getColor()
      }
    },
    timeInfo: {
      textAlign: 'center',
      ml: lg,
      whiteSpace: 'nowrap'
    },
    slider: {
      color: getColor(),
			minWidth: Dimens.attachment.audioWidth,
      '& .MuiSlider-rail': {
        opacity: 0.5
      }
    }
  };

  return styles;
};

export default useStyles;
