import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PagerIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M1 5.00087C1 4.58831 1.16389 4.19265 1.45561 3.90092C1.74733 3.6092 2.143 3.44531 2.55556 3.44531H13.4444C13.857 3.44531 14.2527 3.6092 14.5444 3.90092C14.8361 4.19265 15 4.58831 15 5.00087V12.0009C15 12.4134 14.8361 12.8091 14.5444 13.1008C14.2527 13.3925 13.857 13.5564 13.4444 13.5564H2.55556C2.143 13.5564 1.74733 13.3925 1.45561 13.1008C1.16389 12.8091 1 12.4134 1 12.0009V5.00087Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2.85742 5.98241C2.85742 5.79127 2.93335 5.60796 3.06851 5.4728C3.20366 5.33765 3.38697 5.26172 3.57811 5.26172H12.423C12.6141 5.26172 12.7974 5.33765 12.9326 5.4728C13.0677 5.60796 13.1437 5.79127 13.1437 5.98241V8.69939C13.1437 8.89053 13.0677 9.07384 12.9326 9.209C12.7974 9.34415 12.6141 9.42008 12.423 9.42008H3.57811C3.38697 9.42008 3.20366 9.34415 3.06851 9.209C2.93335 9.07384 2.85742 8.89053 2.85742 8.69939V5.98241Z' fill='currentColor' />
        <path d='M3 10.4114C3 10.3375 3.02935 10.2667 3.08159 10.2144C3.13384 10.1622 3.2047 10.1328 3.27858 10.1328H5.61492C5.6888 10.1328 5.75966 10.1622 5.8119 10.2144C5.86415 10.2667 5.8935 10.3375 5.8935 10.4114V11.4616C5.8935 11.5355 5.86415 11.6064 5.8119 11.6586C5.75966 11.7109 5.6888 11.7402 5.61492 11.7402H3.27858C3.2047 11.7402 3.13384 11.7109 3.08159 11.6586C3.02935 11.6064 3 11.5355 3 11.4616V10.4114Z' fill='currentColor' />
        <path d='M6.72656 10.4114C6.72656 10.3375 6.75591 10.2667 6.80816 10.2144C6.8604 10.1622 6.93126 10.1328 7.00514 10.1328H9.34148C9.41536 10.1328 9.48622 10.1622 9.53847 10.2144C9.59071 10.2667 9.62006 10.3375 9.62006 10.4114V11.4616C9.62006 11.5355 9.59071 11.6064 9.53847 11.6586C9.48622 11.7109 9.41536 11.7402 9.34148 11.7402H7.00514C6.93126 11.7402 6.8604 11.7109 6.80816 11.6586C6.75591 11.6064 6.72656 11.5355 6.72656 11.4616V10.4114Z' fill='currentColor' />
      </svg>
    </SvgIcon>
  );
}

export default PagerIcon;