import { AppFeature, DEFAULT_PAGE_SIZE, DirectoryRecordType, SortOrder, TAB_USER_ACTIVE, TAB_USER_ALL, TAB_USER_DEACTIVATED, TAB_USER_PENDING, UserListSubMenu, UserProfileStatus } from '../../utils/constants';
import { Box, Table, TableBody, TableContainer, useMediaQuery, useTheme } from '@mui/material';
import { FETCH_USER_LIST, INVITE_USERS } from '../../data/user/action-types';
import React, { useEffect, useRef, useState } from 'react';

import APP_NAV from '../../routes/app-nav';
import AlertDialogLarge from '../../components/AlertDialogLarge';
import CircularProgressBar from '../../components/CircularProgressBar';
import InlineAlert from '../../components/InlineAlert';
import InviteSentDark from '../../assets/invitation_sent_dark.svg';
import InviteSentLight from '../../assets/invitation_sent_light.svg';
import InviteUserRequest from '../../types/invite-user-request';
import LoginUtil from '../../utils/login-util';
import MenuData from '../../types/ui/menu-data';
import NoDataFound from '../../components/NoDataFound';
import ThemeUtil from '../../theme/theme-util';
import { User } from '../../types/user';
import UserFilter from '../../types/ui/user-filter';
import UserInviteDialog from './UserInviteDialog';
import { UserListRequest } from '../../types/user-list-request';
import UserListTableHeader from './UserListTableHeader';
import UserListTableRow from './UserListTableRow';
import UserSearchAndFilterHeader from './UserSearchAndFilterHeader';
import UserViewPanel from './UserViewPanel';
import Util from '../../utils/util';
import { getUserTableHeaders } from '../../utils/ui-constants';
import { t } from 'i18next';
import { useDepartmentApi } from '../../data/department/api';
import { useDomainApi } from '../../data/domain/api';
import { useLoadMore } from '../../hooks/use-load-more';
import { useNavigate } from 'react-router-dom';
import { useSiteApi } from '../../data/site/api';
import useStyles from './styles';
import { useUserApi } from '../../data/user/api';
import { useUserTypeApi } from '../../data/user-type/api';

/**
 * Renders the main user screen, displaying a list of users with search, filter, and view options.
 */
const UserScreen: React.FC = () => {

  const styles = useStyles();
  const theme = useTheme();
  const isLightTheme = ThemeUtil.isLightTheme(theme);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isLoadMore = useLoadMore(containerRef);
  const initialFilter: UserFilter = {
    status: UserProfileStatus.All,
    searchKey: '',
    sites: [],
    departments: [],
    positions: [],
    userTypes: []
  };
  const [filter, setFilter] = useState<UserFilter>(initialFilter);
  const [userListRequest, setUserListRequest] = useState<UserListRequest>();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [openUserPanel, setOpenUserPanel] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState(TAB_USER_ALL);
  const [sortBy, setSortBy] = React.useState(SortOrder.Asc);
  const [searchKey, setSearchKey] = useState<string>('');
  const [columns, setColumns] = useState<Array<MenuData>>([]);
  const [showInviteDialog, setShowInviteDialog] = useState<boolean>(false);
  const [showInviteSentMsg, setShowInviteSentMsg] = useState<boolean>(false);
  const deptApi = useDepartmentApi();
  const siteApi = useSiteApi();
  const userTypeApi = useUserTypeApi();
  const domainApi = useDomainApi();
  const userApi = useUserApi();
  const apiStatus = userApi.state.user.apiStatus;

  /**
   * Updates the columns displayed in the user table based on the selected tab.
   */
  useEffect(() => {
    setColumns(getUserTableHeaders(isDesktop, selectedTab));
    if (userListRequest && !userListRequest.isFetchedAll) {
      userListRequest.status = getUserStatusByTab();
      userListRequest.page.pageNumber = 0;
      initUserListFetchApi(userListRequest);
    }
  }, [selectedTab]);

  /**
   * Fetches initial data on component mount: department list, site list, user type list, and position list.
   * Then, initializes the user list fetch request with the initial sort order and filter.
   */
  useEffect(() => {
    if (LoginUtil.hasPermission(AppFeature.ManageUser)) {
      deptApi.fetchDepartmentList();
      siteApi.fetchSiteList();
      userTypeApi.fetchUserTypeList();
      domainApi.fetchDomainList();
      userApi.fetchPositionList();
      initUserListFetchRequest(initialFilter);
    } else {
      navigate(APP_NAV.ADMIN_DEVICES);
    }
    
  }, []);

  /**
  * Handles infinite scrolling to fetch additional users when the user reaches the end of the list.
  */
  useEffect(() => {
    if (isLoadMore && !Util.isApiLoading([FETCH_USER_LIST], apiStatus)
      && userListRequest && !userListRequest.isPaginationComplete) {
      userListRequest.page.pageNumber += 1;
      initUserListFetchApi(userListRequest);
    }
  }, [isLoadMore, sortBy]);

  /**
   * Handles changes to column visibility based on the selected sub-menu option.
   *
   * @param {MenuData} menu - The data object representing the clicked sub-menu option.
   *  - menu.id: The ID of the column associated with the menu option.
   *  - menu.isChecked: A boolean indicating whether the column should be visible.
   */
  const onColumnVisibilityChange = (menu: MenuData) => {
    const updatedColumns = [...columns];
    updatedColumns.map(column => {
      if (column.id === menu.id) {
        column.isChecked = menu.isChecked;
      }
    });
    setColumns(updatedColumns);
  }

  /**
   * Determines the user profile status based on the selected tab.
   *
   * @returns {string} The user profile status based on the selected tab.
   */
  const getUserStatusByTab = () => {
    let status = UserProfileStatus.All;
    if (selectedTab === TAB_USER_ACTIVE) {
      status = UserProfileStatus.Active;
    } else if (selectedTab === TAB_USER_PENDING) {
      status = UserProfileStatus.Pending;
    } else if (selectedTab === TAB_USER_DEACTIVATED) {
      status = UserProfileStatus.Deactivated;
    }

    return status;
  }

  /**
   * Filters the user list based on selected tab, search key, and other filter criteria.
   *
   * @returns {Array<User>} The filtered user list.
   */
  const getFilteredUserList = (): Array<User> => {
    let filteredUserList: Array<User> = userApi.state.user.userList;
    if (selectedTab !== TAB_USER_ALL) {
      filteredUserList = filteredUserList.filter(user => user.status === getUserStatusByTab());
    }
    filteredUserList.sort((a, b) => {
      const comparison = a.name.localeCompare(b.name);

      return sortBy === SortOrder.Asc ? comparison : -comparison;
    });

    return filteredUserList;
  }

  const filteredUserList = getFilteredUserList();

  /**
    * Handles the click event on a user item in the list.
    *
    * @param {User} user - The selected user object.
    */
  const onItemSelected = (user: User) => {
    if (user.status !== UserProfileStatus.Pending) {
      setSelectedUser(user);
      setOpenUserPanel(true);
    }
  }

  /**
   * Handles the closing of the invite dialog and sets the `showInviteSentMsg` state accordingly.
   *
   * @param {boolean} success - Indicates whether the invitation process was successful.
   */
  const inviteDialogClose = (success: boolean) => {
    setShowInviteDialog(false);
    setShowInviteSentMsg(success);
  }

  /**
   * Handles the selection of a sub-menu option for a specific user.
   *
   * @param {User} user - The user object associated with the sub-menu option.
   * @param {MenuData} menu - The data object representing the clicked sub-menu option.
   *  - menu.id: The ID of the sub-menu option (e.g., UserListSubMenu.Edit, UserListSubMenu.ResendInvite).
   */
  const onSubMenuItemSelected = (user: User, menu: MenuData) => {
    setSelectedUser(user);
    if (menu.id === UserListSubMenu.Edit) {
      setOpenUserPanel(true);
    } else if (menu.id === UserListSubMenu.ResendInvite) {
      const request: InviteUserRequest = {
        userType: user.type,
        departmentId: user.defaultDepartmentId,
        invitations: [{
          loginId: user.email
        }]
      };
      userApi.inviteUsers(request).then(data => {
        if (data) {
          setShowInviteSentMsg(true);
        }
      });
    }
  }

  /**
   * Handles the close event for the user view panel.
   */
  const handleUserPanelClose = () => {
    setOpenUserPanel(false);
    setSelectedUser(undefined);
  }

  /**
   * Handles the sort order change event, updating the sort order and refreshing the user list.
   */
  const onSortChange = () => {

    const updatedSortBy = (sortBy === SortOrder.Asc) ? SortOrder.Desc : SortOrder.Asc;
    setSortBy(updatedSortBy);
    if (userListRequest && !userListRequest.isFetchedAll) {
      userListRequest.page.pageNumber = 0;
      userListRequest.page.sort.order = SortOrder.Asc;
      initUserListFetchApi(userListRequest);
    }
  }

  /**
   * Handles applying user filters.
   *
   * @param {UserFilter} value - The updated user filter values.
   */
  const onApplyFilter = (value: UserFilter) => {
    value.searchKey = searchKey;
    if (JSON.stringify(filter) !== JSON.stringify(value)) { //Avoiding unwanted API calls.
      initUserListFetchRequest(value);
    }
    setFilter(value);
  }

  /**
   * Constructs the initial user list fetch request with sort order and filter.
   *
   * @param {SortOrder} sortBy - The sort order for the user list.
   * @param {UserFilter} value - The user filter object.
   */
  const initUserListFetchRequest = (value: UserFilter) => {
    // TODO: When empty array issue is fixed at backend, remove passing null.
    const departmentIds: Array<string> = value.departments.map(department => department.id);
    const siteIds: Array<string> = value.sites.map(site => site.id);
    const userTypes: Array<string> = value.userTypes.map(menu => menu.id as string);
    const positions: Array<string> = value.positions.map(menu => menu.id as string);
    const request: UserListRequest = {
      search: value.searchKey,
      status: getUserStatusByTab(),
      filterBy: {
        departmentIds: Util.isArrayEmpty(departmentIds) ? null : departmentIds,
        siteIds: Util.isArrayEmpty(siteIds) ? null : siteIds,
        userTypes: Util.isArrayEmpty(userTypes) ? null : userTypes,
        positions: Util.isArrayEmpty(positions) ? null : positions
      },
      page: {
        pageNumber: 0,
        size: DEFAULT_PAGE_SIZE,
        sort: {
          sortBy: DirectoryRecordType.User,
          order: sortBy
        }
      },
      isPaginationComplete: false,
      isFetchedAll: false
    };
    initUserListFetchApi(request);
  }

  /**
   * Sends the user list fetch request to the API and updates the request state.
   *
   * @param {UserListRequest} request - The user list fetch request object.
   */
  const initUserListFetchApi = (request: UserListRequest) => {
    userApi.fetchUserList(request).then((data) => {
      request.isPaginationComplete = (data?.length ?? 0) < DEFAULT_PAGE_SIZE;
      if (selectedTab === TAB_USER_ALL) {
        request.isFetchedAll = request.isPaginationComplete;
      }
      setUserListRequest(request);
    });
  }

  return (
    <Box sx={styles.container}>
      <UserSearchAndFilterHeader
        filter={filter}
        selectedTab={selectedTab}
        handleTabChange={setSelectedTab}
        onSearchKeyChange={setSearchKey}
        onResetFilter={() => onApplyFilter(initialFilter)}
        onApplyFilter={onApplyFilter}
        onInviteClick={() => setShowInviteDialog(true)}
      />
      <Box sx={styles.content}>
        <InlineAlert message={Util.getApiError([FETCH_USER_LIST, INVITE_USERS], apiStatus)} />
        {Util.isArrayEmpty(filteredUserList) ? (
          <NoDataFound message={t('noUsersFound')} />
        ) : (
          <TableContainer sx={styles.tableContainer} ref={containerRef}>
            <Table stickyHeader>
              <UserListTableHeader
                selectedTab={selectedTab}
                columns={columns}
                sortBy={sortBy}
                onSortChange={onSortChange}
                onColumnVisibilityChange={onColumnVisibilityChange}
              />
              <TableBody component={'tbody'}>
                {filteredUserList.map((user, index) => (
                  <UserListTableRow
                    key={`user-${index}`}
                    user={user}
                    columns={columns}
                    onClick={() => onItemSelected(user)}
                    handleSubMenuItemClick={onSubMenuItemSelected}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <AlertDialogLarge
        open={showInviteSentMsg}
        title={t('invitesSent')}
        titleIcon={isLightTheme ? InviteSentLight : InviteSentDark}
        message={t('invitesSentMsg')}
        primaryLabel={t('ok')}
        onPrimaryAction={() => setShowInviteSentMsg(false)}
        onClose={() => setShowInviteSentMsg(false)}
      />
      <UserViewPanel
        open={Boolean(openUserPanel && selectedUser?.email)}
        user={selectedUser}
        onClose={handleUserPanelClose}
      />
      {showInviteDialog && <UserInviteDialog open={showInviteDialog} onClose={success => inviteDialogClose(success)} />}
      <CircularProgressBar show={Util.isApiLoading([FETCH_USER_LIST, INVITE_USERS], apiStatus)} />
    </Box>
  );
};

export default UserScreen;
