import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Hook to generate styles for the Call Log Detail table row.
 *
 * @param {boolean} open - Whether the row is expanded to show message details.
 * @returns {Object} An object containing the styles for the table row.
 */
const useStyles = (open = false) => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    tableRow: {
      cursor: 'pointer',
      '& td': {
        borderBottom: open ? 'none' : 'undefined'
      }
    },
    btnLayout: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      '& svg': {
        ...commonStyles.smIcon
      }
    },
    innerRow: (isLastRow: boolean) => ({
      '& td': {
        color: theme.palette.text.secondary,
        borderBottom: (isLastRow && open) ? `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}` : 'none'
      }
    })
  };

  return styles;
};

export default useStyles;
