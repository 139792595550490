import {
	API_FAILURE,
	ApiFailureAction,
	CREATE_DEPT,
	CreateDeptAction,
	DELETE_DEPT,
	DEPT_LIST_SUCCESS,
	DeleteDeptAction,
	DeptListSuccessAction,
	DeptStateResetAction,
	FETCH_DEPT_DETAIL,
	FETCH_DEPT_LIST,
	FetchDeptDetailAction,
	FetchDeptListAction,
	REMOVE_DEPT_USER,
	RESET_DEPT_STATE,
	RemoveDeptUserAction,
	UPDATE_DEPT,
	UpdateDeptAction,
} from './action-types';

import AppError from '../../exception/app-error';
import Department from '../../types/department';

/**
 * Action creator to fetch the department list.
 *
 * @returns {FetchDeptListAction} - The action to fetch the department list.
 */
export const fetchDeptList = (): FetchDeptListAction => ({
	type: FETCH_DEPT_LIST
});

/**
 * Action creator to initiate the department creation process.
 *
 * @returns {CreateDeptAction} An action object indicating the start of department creation.
 */
export const initDeptCreate = (): CreateDeptAction => ({
	type: CREATE_DEPT
});

/**
 * Action creator to initiate the department update process.
 *
 * @returns {UpdateDeptAction} An action object indicating the start of department update.
 */
export const initDeptUpdate = (): UpdateDeptAction => ({
	type: UPDATE_DEPT
});

/**
 * Action creator to initiate fetching department details.
 *
 * @returns {FetchDeptDetailAction} An action object indicating the start of fetching department details.
 */
export const initDeptDetailFetch = (): FetchDeptDetailAction => ({
	type: FETCH_DEPT_DETAIL
});

/**
 * Action creator to initiate the department deletion process.
 *
 * @returns {DeleteDeptAction} An action object indicating the start of department deletion.
 */
export const initDeptDelete = (): DeleteDeptAction => ({
	type: DELETE_DEPT
});

/**
 * Action creator to initiate the department user removal process.
 *
 * @returns {RemoveDeptUserAction} An action object indicating the start of user removal.
 */
export const initDeptUserRemove = (): RemoveDeptUserAction => ({
	type: REMOVE_DEPT_USER
});

/**
 * Action creator to indicate successful department list fetching.
 *
 * @param {Array<Department>} departmentList - The fetched department list.
 * @returns {DeptListSuccessAction} - The action to indicate successful fetching with the department list as payload.
 */
export const deptListSuccess = (departmentList: Array<Department>): DeptListSuccessAction => ({
	type: DEPT_LIST_SUCCESS,
	payload: departmentList,
});

/**
 * Action creator to handle API failure.
 *
 * @param {AppError} error - The API error object.
 * @returns {ApiFailureAction} - The action to indicate API failure with the error as payload.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error,
});

/**
 * Resets the department state to its initial state.
 *
 * @returns {DeptStateResetAction} An action to reset the department state.
 */
export const resetDeptState = (): DeptStateResetAction => ({
	type: RESET_DEPT_STATE
});

