import {
	API_FAILURE,
	CREATE_PAGER,
	DeviceActionTypes,
	FETCH_PAGER_DETAIL,
	FETCH_PAGER_LIST,
	FETCH_PAGER_TYPE_LIST,
	PAGER_LIST_SUCCESS,
	PAGER_TYPE_LIST_SUCCESS,
	RESET_DEVICE_STATE,
	RESET_ERROR_STATE,
	UPDATE_PAGER
} from './action-types';

import DeviceState from '../../types/states/device-state';

/**
 * Initial state for the device reducer.
 */
export const deviceInitialState: DeviceState = {
	apiStatus: undefined,
	pagerList: [],
	pagerTypeList: []
};

/**
 * Device reducer function to handle state updates based on actions.
 *
 * @param {DeviceState} state The current device state.
 * @param {DeviceActionTypes} action The action to be handled.
 * 
 * @returns {DeviceState} The updated device state.
 */
const deviceReducer = (state: DeviceState, action: DeviceActionTypes): DeviceState => {
	switch (action.type) {
		case RESET_ERROR_STATE:
			return {
				...state,
				apiStatus: undefined
			};
		case FETCH_PAGER_TYPE_LIST:
		case FETCH_PAGER_LIST:
		case FETCH_PAGER_DETAIL:
		case CREATE_PAGER:
		case UPDATE_PAGER:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					error: undefined,
					isCompleted: false
				}
			};
		case PAGER_LIST_SUCCESS:
			return {
				...state,
				pagerList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true
				}
			};
		case PAGER_TYPE_LIST_SUCCESS:
			return {
				...state,
				pagerTypeList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_DEVICE_STATE:
			return {
				...deviceInitialState
			};
		default:
			return state;
	}
}

export default deviceReducer;