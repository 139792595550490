import { Box, Card, CardContent, IconButton, ListItemButton, Typography } from '@mui/material';

import { AppFeature } from '../../../utils/constants';
import { DEPT_SUB_MENU } from '../../../utils/ui-constants';
import Department from '../../../types/department';
import DotsVerticalIcon from '../../../components/CustomIcons/DotsVerticalIcon';
import LoginUtil from '../../../utils/login-util';
import MenuData from '../../../types/ui/menu-data';
import MenuSelectDialog from '../../../components/MenuSelectDialog';
import React from 'react';
import SiteMapIcon from '../../../components/CustomIcons/SiteMapIcon';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface defining props for the DepartmentCard component.
 * 
 * @interface DepartmentCardProps
 */
interface DepartmentCardProps {
	department: Department;
	onItemSelected: (param: Department) => void;
	handleSubMenuItemClick: (menu: MenuData) => void;
}

/**
 * DepartmentCard component for displaying information about a department.
 *
 * This component renders a Material-UI Card with details about a department, including its name, 
 * address (if available), and phone number (if available). It utilizes custom icons and 
 * handles clicks to trigger a callback function for department selection.
 *  
 * @param {DepartmentCardProps} props - Component properties including department data and selection callback.
 * 
 * @returns {JSX.Element} - The rendered DepartmentCard component.
 */
const DepartmentCard: React.FC<DepartmentCardProps> = (props: DepartmentCardProps) => {

	const { department, onItemSelected, ...rest } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

	/**
	 * Handles the click event on a sub-menu item.
	 *
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The click event object.
	 * 
	 * Prevents event propagation and sets the anchor element for the sub-menu.
	 */
	const onSubMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		setSubMenuAnchorEl(event.currentTarget);
	}

	/**
	 * Handles the click event on a sub-menu item.
	 *
	 * @param {MenuData} menu - The clicked menu item data.
	 * 
	 * Closes the sub-menu and delegates handling the sub-menu item click to the parent component.
	 */
	const handleSubMenuItemClick = (menu: MenuData) => {
		setSubMenuAnchorEl(null);
		props.handleSubMenuItemClick(menu);
	}

	return (
		<>
			<Card sx={styles.card} onClick={_ => onItemSelected(department)}>
				<ListItemButton sx={styles.cardActionArea} disableRipple>
					<CardContent sx={styles.cardContent}>
						<Box sx={styles.iconWrapper}>
							<SiteMapIcon />
						</Box>
						<Box sx={styles.content}>
							<Box sx={styles.contentText}>
								<Typography variant='p1' sx={styles.title}>
									{department.name}
								</Typography>
								<Typography variant='footer' sx={styles.textEllipsis(1)}>
									{`${department.memberCount ?? 0} ${t('members').toLowerCase()}`}
								</Typography>
							</Box>
							{LoginUtil.hasPermission(AppFeature.ManageDepartment) && 
								<IconButton onClick={onSubMenuClick}>
									<DotsVerticalIcon />
								</IconButton>
							}
						</Box>
					</CardContent>
				</ListItemButton>
			</Card>
			<MenuSelectDialog
				anchorEl={subMenuAnchorEl}
				open={Boolean(subMenuAnchorEl)}
				onClose={() => setSubMenuAnchorEl(null)}
				menuList={DEPT_SUB_MENU}
				onMenuItemSelected={handleSubMenuItemClick}
			/>
		</>
	);
};

export default DepartmentCard;