import { DashboardPermission } from '../../../../utils/constants';
import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the UserPermissionCard component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = (permission = DashboardPermission.CanView) => {

  const { xxs, xs, lg, dialog } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    content: {
      ...commonStyles.borderBottom,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      p: `${lg} 0`
    },
    avatar: {
      width: Dimens.avatarSize.lg,
      height: Dimens.avatarSize.lg,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary
    },
    contentRight: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    contentText: {
      flexDirection: 'column',
      display: 'flex',
      px: lg,
      '& p': {
        ...commonStyles.textEllipsis(1)
      }
    },
    permissionInfo: {
      alignItems: 'center',
      color: theme.palette.text.primary,
      display: 'flex',
      flexDirection: 'row',
      '& .MuiStack-root': {
        p: 0,
        minWidth: 0,
        pl: xs,
        '& .Mui-disabled': {
          WebkitTextFillColor: `${theme.palette.text.primary} !important`
        },
        '& .MuiTypography-root': {
          ...theme.typography.caption,
          color: permission === DashboardPermission.Remove ? theme.palette.error.main : theme.palette.text.primary
        }
      },
      '& svg': {
        color: permission === DashboardPermission.Remove ? theme.palette.error.main
          : `${theme.palette.text.primary} !important`
      }
    },
    selectPosition: {
      mt: xxs,
      ml: `-${dialog}`
    }
  };

  return styles;
};

export default useStyles;
