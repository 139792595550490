import { AssignedSessionStatus, InteractionType } from '../../../../utils/constants';

import ConversationData from '../../../../types/conversation-data';
import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles Hook for InboxListItem Styles
 * 
 * This hook defines the styles used by the InboxListItem component.
 * It dynamically applies styles based on conversation data.
 */
const useStyles = (conversation: ConversationData, isSelected = false) => {

  const { mini, xxs, xs, sm, lg, section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const isEmergency = conversation.interactionType === InteractionType.EmergencyGroup;
  const assignedStatus = conversation.recipientAssignedSessionStatus;

  const styles = {
    listItem: {
      minHeight: Dimens.chatItemHeight,
      bgcolor: isSelected ? theme.palette.background.default : 'inherit',
      p: `${lg} ${section}`,
      '&:hover': {
        bgcolor: theme.palette.background.default,
        'button': {
          // display: 'flex'
        }
      },
      [theme.breakpoints.down('sm')]: {
        p: lg,
      }
    },
    avatar: {
      width: Dimens.avatarSize.xl,
      height: Dimens.avatarSize.xl,
      color: theme.palette.primary.contrastText,
      bgcolor: isEmergency ? theme.palette.error.main : theme.palette.text.secondary,
      'svg': {
        fontSize: Dimens.icon.lg
      }
    },
    avatarWrapper: {
      minWidth: 0
    },
    badge: {
      'span': {
        height: Dimens.avatarBadgeSize,
        width: Dimens.avatarBadgeSize,
        border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.paper}`,
        borderRadius: Dimens.radius.md,
        bgcolor: () => {
          let color = theme.palette.text.secondary;
          if (assignedStatus === AssignedSessionStatus.Online) {
            color = theme.palette.success.main;
          } else if (assignedStatus === AssignedSessionStatus.Away) {
            color = theme.palette.warning.main;
          } else if (assignedStatus === AssignedSessionStatus.DoNotDisturb) {
            color = theme.palette.error.main;
          }

          return color;
        },
        display: conversation.interactionType === InteractionType.Private ? 'unset' : 'none'
      }
    },
    contentWrapper: {
      p: `0 0 0 ${lg}`,
      m: 0,
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    headerWrapper: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    primaryText: {
      ...commonStyles.textEllipsis(1),
      fontWeight: Dimens.fontWeight.medium,
      color: (conversation.hasPriority || isEmergency) ? theme.palette.error.main : theme.palette.text.primary,
      mr: xs
    },
    date: {
      color: theme.palette.text.secondary
    },
    detailsWrapper: {
      pt: xxs,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    secondaryText: {
      ...commonStyles.textEllipsis(2),
      color: theme.palette.text.primary,
      '& svg': {
        ...commonStyles.smIcon,
        mr: xs,
        mb: `-${mini}`
      }
    },
    counter: {
      p: `${xxs} ${xs}`,
      borderRadius: Dimens.radius.md,
      bgcolor: conversation.hasPriority ? theme.palette.error.main : theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      display: conversation.count > 0 ? 'unset' : 'none',
      ml: sm
    },
    actionWrapper: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      'svg': {
        fontSize: Dimens.icon.sm
      }
    },
    iconToggle: {
      display: 'none',
      p: xxs,
      ml: sm
    },
    senderWrapper: {
      pr: xs
    },
    divider: {
      borderBottomWidth: Dimens.menuBorderWidth,
      borderColor: theme.palette.background.default
    }
  };

  return styles;
};

export default useStyles;