import { Box, Grid, Tab } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import { FETCH_DEPT_DETAIL } from '../../../data/department/action-types';
import InlineAlert from '../../../components/InlineAlert';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import { UPDATE_PROFILE } from '../../../data/profile/action-types';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import { useDepartmentApi } from '../../../data/department/api';
import { useProfileApi } from '../../../data/profile/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { userSchema } from '../../../utils/validation-schema';

interface Props {
	open: boolean;
	user: User;
	onClose: () => void;
}

/**
 * Renders a drawer with tabs for editing department, add/remove members and settings.
 *
 * @param {Props} props - Component props containing open state, department data, and close callback.
 * 
 * @returns JSX element representing the department edit panel.
 */
const ProfileEditPanel: React.FC<Props> = (props: Props) => {

	const { open, user, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();

	const [detailsEdited, setDetailsEdited] = useState<boolean>(false);
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const formikRef = React.createRef<FormikProps<User>>();
	const deptApi = useDepartmentApi();
	const profileApi = useProfileApi();
	const apiStatus = profileApi.state.profile.apiStatus;

  /**
   * Fetches department list on component mount
   * 
   */
  useEffect(() => {   
		deptApi.fetchDepartmentList();   
	}, []);

	/**
	 * Handles form submission by calling updateProfileDetail API and updating local state.
	 *
	 * @param {User} values - User data from the submitted form.
	 */
	const onSubmit = (values: User) => {
		profileApi.updateProfileDetail(values).then((data) => {
			if (data) {
				setDetailsEdited(false);
				onClose();
			}
		});
	};

	/**
	 * Handles the logic for closing the tab.
	 * If there are unsaved changes (`detailsEdited` is true), it prompts the user with a save dialog.
	 * Otherwise, it resets the state and closes the tab.
	 */
	const onTabClose = () => {
		if (detailsEdited) {
				setShowSaveDialog(true);
		} else {
				setDetailsEdited(false);
				setShowSaveDialog(false);
				onClose();
		}
	};

	/**
		* Handles closing the tab without saving.
		* Resets the state and closes the tab immediately.
		*/
	const onLeaveWithoutSaving = () => {
		setDetailsEdited(false);
		setShowSaveDialog(false);
		onClose();
	};

	return (
		<RightDrawerLayout open={open} title={t('editProfile')} onClose={onTabClose}>
			<Box sx={styles.wrapper}>
				<InlineAlert message={Util.getApiError([UPDATE_PROFILE], apiStatus)} />
				<Formik
					innerRef={formikRef}
					enableReinitialize
					validationSchema={userSchema}
					initialValues={user}
					onSubmit={values => onSubmit(values)}>
					{({ dirty, isValid }) => {
						setDetailsEdited(dirty);
						return( 
							<Form style={styles.form as React.CSSProperties}>					
								<Box sx={styles.formInnerWrapper}>								
									<Field
										name='name'
										label={t('name')}
										placeholder={t('name')}
										component={CustomInput}
									/>
									<Field
										name='type'
										label={t('userType')}
										placeholder={t('type')}
										component={CustomInput}
										readOnly
									/>
									<Field
										name='userCode'
										label={t('userId')}
										placeholder={t('userId')}
										component={CustomInput}
										readOnly
									/>
									<Field
										name='email'
										label={t('email')}
										placeholder={t('email')}
										component={CustomInput}
										readOnly
									/>	
									<Field
										name='position'
										label={t('position')}
										placeholder={t('position')}
										component={CustomInput}
									/>
									<Field
										name='about'
										label={t('aboutYou')}
										placeholder={t('aboutYouPlaceholder')}
										multiline
										component={CustomInput}
									/>								
									<Field
										name='departments'
										label={t('departments')}
										placeholder={t('findDepartments')}
										component={CustomAutocomplete}
										noOptionsText={t('noDepts')}
										multiple
										defaultOptionId={user.defaultDepartmentId}
										menu={deptApi.state.department.departmentList}
									/>								
								</Box>			
								<CustomButton
									sx={styles.saveBtn}
									type='submit'
									title={t('save')}
									color='primary'
									fullWidth={true}
									disabled={!dirty || !isValid}
									startIcon={<SaveIcon sx={styles.smIcon} />}
								/>
							</Form>
						);
					}}
				</Formik>
				<CircularProgressBar show={Util.isApiLoading([FETCH_DEPT_DETAIL], apiStatus)} />
				<AlertDialogSmall
					open={showSaveDialog}
					title={t('leaveWithouSave')}
					titleIcon={<AlertCircleIcon />}
					message={t('leaveWithouSaveMsg')}
					secondaryLabel={t('cancel')}
					onSecondaryAction={() => setShowSaveDialog(false)}
					primaryLabel={t('leave')}
					onPrimaryAction={onLeaveWithoutSaving}
					onClose={() => setShowSaveDialog(false)}
				/>
			</Box>
		</RightDrawerLayout>
	);
};

export default ProfileEditPanel;