import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const UserAddIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5369_48713)'>
          <path d='M3.67456 7.55385C3.67456 8.62032 4.09821 9.64311 4.85232 10.3972C5.60643 11.1513 6.62922 11.575 7.69569 11.575C8.76216 11.575 9.78495 11.1513 10.5391 10.3972C11.2932 9.64311 11.7168 8.62032 11.7168 7.55385C11.7168 6.48738 11.2932 5.46458 10.5391 4.71048C9.78495 3.95637 8.76216 3.53271 7.69569 3.53271C6.62922 3.53271 5.60643 3.95637 4.85232 4.71048C4.09821 5.46458 3.67456 6.48738 3.67456 7.55385Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M1.66406 21.6279V19.6173C1.66406 18.5509 2.08772 17.5281 2.84182 16.774C3.59593 16.0198 4.61872 15.5962 5.68519 15.5962H9.70632C10.7728 15.5962 11.7956 16.0198 12.5497 16.774C13.3038 17.5281 13.7275 18.5509 13.7275 19.6173V21.6279' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M19.7087 2.37207V9.28277' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' />
          <path d='M23.1641 5.82764L16.2534 5.82764' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' />
        </g>
        <defs>
          <clipPath id='clip0_5369_48713'>
            <rect width='24' height='24' fill='transparent' transform='translate(0.414062)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default UserAddIcon;