import { AppFeature, CARD_MAX_WIDTH, DepartmentSubMenuItem, SortByAlphabet, deptInitialValue } from '../../utils/constants';
import { Box, Grid } from '@mui/material';
import { DELETE_DEPT, FETCH_DEPT_LIST } from '../../data/department/action-types';
import React, { useEffect, useState } from 'react';

import AddIcon from '../../components/CustomIcons/AddIcon';
import AlertDialogSmall from '../../components/AlertDialogSmall';
import BinIcon from '../../components/CustomIcons/BinIcon';
import CircularProgressBar from '../../components/CircularProgressBar';
import CreateDepartmentDialog from './CreateDepartmentDialog';
import Department from '../../types/department';
import DepartmentCard from './DepartmentCard';
import DepartmentEditPanel from './DepartmentEditPanel';
import DepartmentViewPanel from './DepartmentViewPanel';
import InlineAlert from '../../components/InlineAlert';
import LoginUtil from '../../utils/login-util';
import { Masonry } from '@mui/lab';
import MenuData from '../../types/ui/menu-data';
import NoDataFound from '../../components/NoDataFound';
import SearchAndSortTopBar from '../../components/SearchAndSortTopBar';
import Util from '../../utils/util';
import { t } from 'i18next';
import { useDepartmentApi } from '../../data/department/api';
import { useNavigate } from 'react-router-dom';
import { useSiteApi } from '../../data/site/api';
import useStyles from './styles';
import { useWindowSize } from '../../hooks/use-window-size';

/**
 * DepartmentScreen component for managing department information.
 *
 * This component displays a list of departments with search, sort, and creation functionalities.
 * Users can view department details in a modal dialog and potentially edit or create new departments.
 *
 * @returns {JSX.Element} - The rendered DepartmentScreen component.
 */
const DepartmentScreen: React.FC = () => {

  const styles = useStyles();
  const navigate = useNavigate();
  const [width] = useWindowSize();
  const numOfColumns = Math.floor(width / CARD_MAX_WIDTH);
  const [sortBy, setSortBy] = useState<string>(SortByAlphabet.A_Z);
  const [searchKey, setSearchKey] = useState<string>('');
  const [openCreateDeptDialog, setOpenCreateDeptDialog] = useState<boolean>(false);
  const [openDeptViewPanel, setOpenDeptViewPanel] = useState<boolean>(false);
  const [department, setDepartment] = useState<Department>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openCantDeleteDialog, setOpenCantDeleteDialog] = useState<boolean>(false);
  const [showDepartmentEdit, setShowDepartmentEdit] = useState<boolean>(false);
  const siteApi = useSiteApi();
  const deptApi = useDepartmentApi();
  const apiStatus = deptApi.state.department.apiStatus;

  /**
   * Initializes the department fetch if the user has the necessary permission.
   */
  useEffect(() => {
    initDepartmentFetch();  
  }, []);

  /**
   * Initializes department and department data fetching.
   */
  const initDepartmentFetch = () => {
    siteApi.fetchSiteList();
    deptApi.fetchDepartmentList();
  }

  /**
   * Handles department selection and opens the department detail dialog.
   *
   * Sets the selected `department` and opens the dialog.
   *
   * @param {Department} department - The selected department object.
   */
  const onItemSelected = (department: Department) => {
    setDepartment(department);
    if (department.id) {
      setOpenDeptViewPanel(true);
    } else {
      setOpenCreateDeptDialog(true);
    }
  }

  /**
   * Handles the click event on a sub-menu item for a department.
   * 
   * @param {Department} department - The department object associated with the menu.
   * @param {MenuData} menu - The clicked menu item data.
   */
  const handleSubMenuItemClick = (department: Department, menu: MenuData) => {
    setDepartment(department);
    if (menu.id === DepartmentSubMenuItem.Edit) {
      setShowDepartmentEdit(true);
    } else if (DepartmentSubMenuItem.Delete) {
      if ((department.memberCount ?? 0) > 0) {
        setOpenCantDeleteDialog(true);
      } else {
        setOpenDeleteDialog(true);
      }
    }
  }

  /**
   * Sorts and filters the department list based on search and sort criteria.
   *
   * @returns {Department[]} - The sorted and filtered department list.
   */
  const sortAndFilterDepartments = () => {
    return (deptApi.state.department.departmentList || []).filter((department) =>
      department.name.toLowerCase().includes(searchKey.toLowerCase())
    ).sort((a, b) => {
      const comparison = a.name.localeCompare(b.name);

      return sortBy === SortByAlphabet.A_Z ? comparison : -comparison;
    });
  };

  const departmentList = sortAndFilterDepartments();

  /**
   * Handles the close event for the department view panel.
   */
  const onDeptViewPanelClose = () => {
    setDepartment(undefined);
    setOpenDeptViewPanel(false);
  };

  /**
   * Handles the click event on the delete button and call dept delete API.
   */
  const onDeleteClick = () => {
    setOpenDeleteDialog(false);
    if (department) {
      deptApi.deleteDepartment(department);
    }
  }

  return (
    <Box sx={styles.container}>
      <SearchAndSortTopBar
        title={t('departments')}
        subTitle={t('manageDepartments')}
        actionText={LoginUtil.hasPermission(AppFeature.ManageDepartment) ? t('new') : undefined}
        actionIcon={<AddIcon />}
        onActionClick={() => onItemSelected(deptInitialValue)}
        sortBy={sortBy}
        onSortChange={setSortBy}
        onSearchChange={setSearchKey}
      />
      <InlineAlert sx={styles.alertWrapper} message={Util.getApiError([FETCH_DEPT_LIST, DELETE_DEPT], apiStatus)} />
      {Util.isArrayEmpty(departmentList) ? (
        <NoDataFound message={t('noDepartmentsFound')} />
      ) : (
        <Grid sx={styles.contentWrapper}>
          <Masonry columns={numOfColumns === 0 ? 1 : numOfColumns} spacing={2} sx={styles.content} sequential>
            {departmentList.map((department) => (
              <DepartmentCard
                department={department}
                key={department.id}
                onItemSelected={onItemSelected}
                handleSubMenuItemClick={menu => handleSubMenuItemClick(department, menu)}
              />
            ))}
          </Masonry>
        </Grid>
      )}
      {openCreateDeptDialog &&
        <CreateDepartmentDialog
          open={openCreateDeptDialog}
          siteList={siteApi.state.site.siteList}
          onClose={() => setOpenCreateDeptDialog(false)}
        />
      }
      <DepartmentViewPanel
        open={Boolean(department && openDeptViewPanel)}
        department={department}
        onClose={onDeptViewPanelClose}
      />
      <DepartmentEditPanel
        open={Boolean(department && showDepartmentEdit)}
        department={department}
        onClose={() => setShowDepartmentEdit(false)}
      />
      <AlertDialogSmall
        open={openDeleteDialog}
        title={t('deleteDept')}
        titleIcon={<BinIcon />}
        message={t('deleteDeptMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeleteDialog(false)}
        primaryLabel={t('delete')}
        onPrimaryAction={onDeleteClick}
        isDestructive
        onClose={() => setOpenDeleteDialog(false)}
      />
      <AlertDialogSmall
        open={openCantDeleteDialog}
        title={t('cantDeleteDept')}
        message={t('cantDeleteDeptMsg')}
        primaryLabel={t('ok')}
        onPrimaryAction={() => setOpenCantDeleteDialog(false)}
        onClose={() => setOpenCantDeleteDialog(false)}
      />
      <CircularProgressBar show={Util.isApiLoading([FETCH_DEPT_LIST, DELETE_DEPT], apiStatus)} />
    </Box>
  );
};

export default DepartmentScreen;
