import React, { createContext, useState } from 'react';

import { AppFeature } from '../utils/constants';
import LoginUtil from '../utils/login-util';

/**
 * Interface defining the context value for session expiration.
 *
 * @interface SessionExpirationContextValue
 * @property {boolean} isSessionExpired Indicates if the session is expired.
 * @property {function(boolean)} setSessionExpired Setter function to update the session expiration state.
 */
interface SessionExpirationContextValue {
  isSessionExpired: boolean;
  setSessionExpired: (value: boolean) => void;
}

/**
 * Creates a context for managing session expiration state.
 */
export const SessionExpirationContext = createContext<SessionExpirationContextValue>({
  isSessionExpired: false,
  // eslint-disable-next-line
  setSessionExpired: () => {}
});

/**
 * Session Expiration Provider Component
 *
 * This component provides the session expiration state and setter function to its child components through the `SessionExpirationContext`.
 *
 * @param {React.PropsWithChildren<unknown>} props Component props.
 * @returns {JSX.Element} The JSX element for the provider.
 */
const SessionExpirationProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const setSessionExpired = (value: boolean) => {
    setIsSessionExpired(value);
  };

  return (
    <SessionExpirationContext.Provider value={{ isSessionExpired, setSessionExpired }}>
      {children}
    </SessionExpirationContext.Provider>
  );
};

export default SessionExpirationProvider;