import { SortOrder } from '../../utils/constants';
import React, { useContext } from 'react';
import { apiFailure, initSearch, resetErrorState, resetSearchState, searchSuccess } from './actions';
import {  doPost } from '../../service';
import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import { DirectoryRecord as Record } from '../../types/directory-record';
import { DirectorySearchRequest as SearchRequest } from '../../types/directory-search-request';
import { DirectorySearchResponse as SearchResponse } from '../../types/directory-search-response';

import { HttpStatusCode } from 'axios';
import { SessionExpirationContext } from '../../store/session-expiration-provider';
import { Store } from '../../store/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for interacting with the search API.
 * 
 * This hook provides functions to:
 * - Search directories based on a request object.
 * - Reset the search error state in the Redux store.
 * - Reset the entire search state in the Redux store.
 * - Dispatch an API failure action with an error message.
 * 
 * @returns {object} An object containing functions for interacting with the search API and the current search state.
 */
export function useSearchApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();
	const { setSessionExpired } = useContext(SessionExpirationContext);

	/**
	 * Initiates a search based on the provided request object.
	 * 
	 * Dispatches an initial search action, then performs a POST request to the API.
	 * Updates the search state with the retrieved data or dispatches an error action upon failure.
	 * 
	 * @param {SearchRequest} request The search request object.
	 */
	const searchRecords = useCallback(async (request: SearchRequest) => {
		dispatch(initSearch());
		try {
			const response: SearchResponse = await doPost(APIConfig.directorySearch, request);
			let recordList: Array<Record> = state.search.recordList;
			if (response.data) {
				const newPageRecordList = [...(response.data.users || []), ...(response.data.groups || []), ...(response.data.teams || [])];
				if (request.page.pageNumber === 0) {
					recordList = newPageRecordList;
				} else {
					recordList = [...state.search.recordList, ...newPageRecordList];
				}
				// Remove duplicate records if any as pagination might give duplicate results.				
				const uniqueRecordsMap = new Map();
				recordList.forEach(record => {
					// Use record.email for users and record.groupKey for groups and record.teamKey for teams as the unique key
					const uniqueKey = record.email || record.groupRoomKey || record.teamKey;
					if (uniqueKey && !uniqueRecordsMap.has(uniqueKey)) {
						uniqueRecordsMap.set(uniqueKey, record);
					}
				});
				
				// Convert the map back to an array
				recordList = Array.from(uniqueRecordsMap.values());
				recordList.sort((a, b) => {
					const nameA = a.name || a.teamName;
					const nameB = b.name || b.teamName;
					if (request.page.sort.order === SortOrder.Asc) {
						return nameA.localeCompare(nameB);
					} else {
						return nameB.localeCompare(nameA);
					}
				});
			}
			dispatch(searchSuccess(recordList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.search.recordList]);

	/**
	 * Resets the search error state in the Redux store.
	 */
	const resetError = () => {
		if (state.search.apiStatus?.error) {
			dispatch(resetErrorState());
		}
	}

	/**
	 * Resets the search state in the Redux store.
	 */
	const resetSearch = () => {
		dispatch(resetSearchState());
	}

	/**
	 * Dispatches an API failure action with an error message.
	 * Handles potential missing error message and uses a default message from translation.
	 *
	 * @param {any} error - The error object (optional).
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(apiFailure(new AppError(error?.code, message)));
		if (error?.code === HttpStatusCode.Unauthorized) {
			setSessionExpired(true);
		}
	}

	return {
		searchRecords,
		resetError,
		resetSearch,
		state
	};
}