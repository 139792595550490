import AppError from '../../exception/app-error';
import Team from '../../types/team';

export const FETCH_TEAM_LIST = 'FETCH_TEAM_LIST';
export const FETCH_TEAM_DETAIL = 'FETCH_TEAM_DETAIL';
export const CREATE_TEAM = 'CREATE_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const DELETE_TEAM = 'DELETE_TEAM';
export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER';
export const ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER';
export const TEAM_LIST_SUCCESS = 'TEAM_LIST_SUCCESS';
export const API_FAILURE = 'TEAM_API_FAILURE';
export const RESET_TEAM_STATE = 'RESET_TEAM_STATE';
export const RESET_ERROR = 'RESET_ERROR';

export interface FetchTeamListAction {
	type: typeof FETCH_TEAM_LIST;
}

export interface CreateTeamAction {
	type: typeof CREATE_TEAM;
}

export interface UpdateTeamAction {
	type: typeof UPDATE_TEAM;
}

export interface FetchTeamDetailAction {
	type: typeof FETCH_TEAM_DETAIL;
}

export interface DeleteTeamAction {
	type: typeof DELETE_TEAM;
}

export interface RemoveTeamMemberAction {
	type: typeof REMOVE_TEAM_MEMBER;
}

export interface AddTeamMemberAction {
	type: typeof ADD_TEAM_MEMBER;
}

export interface TeamListSuccessAction {
	type: typeof TEAM_LIST_SUCCESS;
	payload: Array<Team>;
}

export interface ApiFailureAction {
	type: typeof API_FAILURE;
	payload: AppError;
}

export interface TeamStateResetAction {
  type: typeof RESET_TEAM_STATE;
}

export interface ResetErrorStateAction {
	type: typeof RESET_ERROR
}

export type TeamActionTypes = FetchTeamListAction | CreateTeamAction | UpdateTeamAction | TeamListSuccessAction
	| DeleteTeamAction | RemoveTeamMemberAction | AddTeamMemberAction | FetchTeamDetailAction | ApiFailureAction | TeamStateResetAction | ResetErrorStateAction;
