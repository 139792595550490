import { useTheme } from "@mui/material";

/**
 * Styles for the PermissionTableRow component.
 * Defines the styles for the checkbox element within the table cell.
 *
 * @returns {Object} The styles object.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    btn: {
      p: 0,
      bgcolor: 'transparent',
      color: `${theme.palette.error.main} !important`,
      '&:hover': {
        bgcolor: 'transparent'
      },
      '&.Mui-disabled': {
        bgcolor: 'transparent !important',
        color: `${theme.palette.error.light} !important`
      }
    }
  };

  return styles;
};

export default useStyles;