import useCommonStyles from '../../../theme/use-common-styles';

/**
 * Custom hook to generate styles for the SearchableUserList component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const commonStyles = useCommonStyles();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    contentWrapper: {
      ...commonStyles.noScrollBar,
      overflowY: 'auto'
    }
  };

  return styles;
};

export default useStyles;
