import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the checkmark icon.
 * 
 * @returns An object containing the styled class.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    form: {
      display: 'flex',
      justifyContent: 'center'
    },
    formContent: {
      display: 'table'
    },
    spacer: {
      height: Dimens.spacing.container
    }
  };

  return styles;
};

export default useStyles;