import Dimens from './dimens';
import { useTheme } from '@mui/material/styles';

/**
 * Custom hook to generate common styles based on the current theme.
 *
 * This hook utilizes the `useTheme` hook from Material-UI to access the current theme 
 * and generates a set of common styles. It provides a function to create styles for truncating 
 * text with ellipsis based on a specified line count.
 * 
 * @returns {object} - An object containing common styles.
 */
const useCommonStyles = () => {

  const theme = useTheme();

  const styles = {
    textEllipsis: (lineCount: number) => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: `${lineCount}`,
      display: '-webkit-box',
      wordWrap: 'break-word',
      WebkitBoxOrient: 'vertical',
      overflowWrap: 'anywhere'
    }),
    smIcon: {
      fontSize: `${Dimens.icon.sm} !important`
    },
    noScrollBar: {
      MsOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    defaultBorder: {
      border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderRadius: Dimens.radius.md
    },
    borderBottom: {
      borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`
    }
  };

  return styles;
};

export default useCommonStyles;