import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the EditProfileSubMenu component.
 *
 */
const useStyles = () => {
	const { xs, lg } = Dimens.spacing;
	const theme = useTheme();
	const commonStyles = useCommonStyles();

	const styles = {
		formInnerWrapper: {
			display: 'flex',
			flexDirection: 'column',
			overflowY: 'auto',
			flex: 1
		},
		form: {
			flex: 1,
			justifyContent: 'space-between',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden'
		},
		content: {
			[ theme.breakpoints.down('sm') ]: {
				p: lg
			}
		},
		saveBtn: {
			display: 'flex',
			m: xs
		},
		smIcon: {
			...commonStyles.smIcon
		}
	};

	return styles;
};

export default useStyles;
