import { Box, Card, CardContent, Dialog, DialogProps, Grid, IconButton, Stack, Typography } from '@mui/material';

import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import { DASHBOARD_ITEM_LIST } from '../../../utils/ui-constants';
import MenuData from '../../../types/ui/menu-data';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Props interface for the DashboardItemDialog component.
 */
interface Props extends DialogProps {
	onClose: () => void;
	handleMenuItemClick: (menu: MenuData) => void;
}

/**
 * DashboardItemDialog Component
 * 
 * This component renders a dialog displaying a list of dashboard items.
 * Users can select an item from the list, triggering an event with the selected item.
 *
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const DashboardItemDialog: React.FC<Props> = (props: Props) => {

	const { onClose, handleMenuItemClick, ...rest } = props;
	const styles = useStyles();
	const { t } = useTranslation();

	/**
	 * Handles closing the dialog by invoking the onClose callback.
	 */
	const handleClose = () => {		
			onClose();
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>
					{ t('addItems') }
				</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>			
			<Grid container spacing={2} padding={0} sx={styles.cardContainer}>
      {DASHBOARD_ITEM_LIST.map((item) => (
        <Grid item xs={12} sm={6} lg={4} key={item.id}>
          <Card sx={styles.card} onClick={() => handleMenuItemClick(item)}>
            <CardContent sx={styles.content}>
							<Box sx={styles.iconWrapper}>
								{item.icon}
							</Box>
							<Typography variant='h6' sx={styles.title}>
								{item.value}
							</Typography>              
            </CardContent>
          </Card>
				</Grid>
			))}
			</Grid>
		</Dialog>
	);
};

export default DashboardItemDialog;