import React, { useEffect, useState } from 'react';

import AppUtil from '../../../../utils/app-util';
import { Box } from '@mui/material';
import CheckIcon from '../../../../components/CustomIcons/CheckIcon';
import MenuList from '../../SettingsMenuList';
import { SETTINGS_LANGUAGE_MENU_OPTIONS } from '../../../../utils/ui-constants';
import SettingsMenuData from '../../../../types/ui/settings_menu_data';
import useStyles from './styles';

/**
 * LanguageSubMenu Component
 * 
 * This component renders a list of language options for the user to select from. 
 * It initially checks the user's current language preference and dynamically updates 
 * the icon for the selected language. Upon selecting a new language, the preference 
 * is saved, and the page reloads to apply the changes.
 * 
 * @component
 * @returns {JSX.Element} JSX element representing the language submenu.
 */
const LanguageSubMenu: React.FC = () => {

	const styles = useStyles();
	const languagePreference = AppUtil.getAppLanguage(); // Get the current language preference from app utilities
	const [ menuOptions, setMenuOptions ] = useState<Array<SettingsMenuData>>(SETTINGS_LANGUAGE_MENU_OPTIONS);

	/**
 * useEffect Hook
 * 
 * Runs when the component mounts and whenever `languagePreference` changes.
 * Updates the language menu options to assign a check icon and update description
 * for the currently selected language.
 * 
 * @param {string | number} languagePreference - The current language preference ID.
 */
	useEffect(() => {
		const updatedMenuOptions = SETTINGS_LANGUAGE_MENU_OPTIONS.map(option => ({
			...option,
			icon: option.id === languagePreference ? <CheckIcon /> : undefined
		}));
		setMenuOptions(updatedMenuOptions);
	}, [ languagePreference ]);

	/**
	 * handleLanguageChange
	 * 
	 * Handles the language selection process. When the user selects a language from the menu,
	 * it updates the app's language preference, saves it, and reloads the page to reflect the changes.
	 * 
	 * @param {SettingsMenuData} item - The selected language menu item.
	 */
	const handleLanguageChange = (item: SettingsMenuData) => {
		AppUtil.saveAppLanguage(item.id.toString());
		location.reload();
	};

	return (
		<Box sx={styles.container}>
			<MenuList
				menuItems={menuOptions}
				onItemClick={handleLanguageChange}
			/>
		</Box>
	);
};

export default LanguageSubMenu;
