import { Box, Card, CardContent, IconButton, ListItemButton, Typography } from '@mui/material';

import DotsVerticalIcon from '../../../components/CustomIcons/DotsVerticalIcon';
import MenuData from '../../../types/ui/menu-data';
import MenuSelectDialog from '../../../components/MenuSelectDialog';
import React from 'react';
import { TEAM_SUB_MENU } from '../../../utils/ui-constants';
import Team from '../../../types/team';
import TeamIcon from '../../../components/CustomIcons/TeamIcon';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface defining props for the TeamCard component.
 * 
 * @interface Props
 */
interface Props {
	team: Team;
	onItemSelected: (param: Team) => void;
	handleSubMenuItemClick: (menu: MenuData) => void;
}

/**
 * TeamCard component for displaying information about a team.
 *
 * This component renders a Material-UI Card with details about a team, including its name, 
 * and member count. It utilizes custom icons and 
 * handles clicks to trigger a callback function for team selection.
 *  
 * @param {Props} props - Component properties including team data and selection callback.
 * 
 * @returns {JSX.Element} - The rendered TeamCard component.
 */
const TeamCard: React.FC<Props> = (props: Props) => {

	const { team, onItemSelected, ...rest } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

	/**
	 * Handles the click event on a sub-menu item.
	 *
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The click event object.
	 * 
	 * Prevents event propagation and sets the anchor element for the sub-menu.
	 */
	const onSubMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		setSubMenuAnchorEl(event.currentTarget);
	}

	/**
	 * Handles the click event on a sub-menu item.
	 *
	 * @param {MenuData} menu - The clicked menu item data.
	 * 
	 * Closes the sub-menu and delegates handling the sub-menu item click to the parent component.
	 */
	const handleSubMenuItemClick = (menu: MenuData) => {
		setSubMenuAnchorEl(null);
		props.handleSubMenuItemClick(menu);
	}

	return (
		<>
			<Card sx={styles.card} onClick={_ => onItemSelected(team)}>
				<ListItemButton sx={styles.cardActionArea} disableRipple>
					<CardContent sx={styles.cardContent}>
						<Box sx={styles.iconWrapper}>
							<TeamIcon />
						</Box>
						<Box sx={styles.content}>
							<Box sx={styles.contentText}>
								<Typography variant='p1' sx={styles.title}>
									{team.teamName}
								</Typography>
								<Typography variant='footer' sx={styles.textEllipsis(1)}>
									{`${team.teamMembers?.length ?? 0} ${t('members').toLowerCase()}`}
								</Typography>
							</Box> 
								<IconButton onClick={onSubMenuClick}>
									<DotsVerticalIcon />
								</IconButton>							
						</Box>
					</CardContent>
				</ListItemButton>
			</Card>
			<MenuSelectDialog
				anchorEl={subMenuAnchorEl}
				open={Boolean(subMenuAnchorEl)}
				onClose={() => setSubMenuAnchorEl(null)}
				menuList={TEAM_SUB_MENU}
				onMenuItemSelected={handleSubMenuItemClick}
			/>
		</>
	);
};

export default TeamCard;