import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DeactivatedIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5781_4803)'>
          <path d='M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM5.75 7.25H10.25C10.6656 7.25 11 7.58437 11 8C11 8.41562 10.6656 8.75 10.25 8.75H5.75C5.33437 8.75 5 8.41562 5 8C5 7.58437 5.33437 7.25 5.75 7.25Z' fill='currentColor' />
        </g>
        <defs>
          <clipPath id='clip0_5781_4803'>
            <rect width='16' height='16' fill='transparent' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default DeactivatedIcon;