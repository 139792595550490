import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the MenuSelectDialog component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const {sm, md} = Dimens.radius;
	const theme = useTheme();
	
	const styles = {
    menuPaperProps: {
			mt: Dimens.spacing.xxs,	
      borderRadius: `${md} ${md} ${sm} ${md}`,
      backgroundImage: 'none',
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				justifyContent: 'space-between'
			}
    },
    menu: {
      padding: 0
    },
    menuItem: (isDestructive = false, isPrimary = false) => ({
      p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
      minWidth: Dimens.menuItemMinWidth,
			backgroundColor: theme.palette.background.paper,
			color: () => {
				let color = theme.palette.text.primary;
				if (isDestructive) {
					color = theme.palette.error.main;
				} else if (isPrimary) {
					color = theme.palette.primary.main;
				}

				return color;
			},
			'&:hover': {
				backgroundColor: theme.palette.background.default
			},
			'&.Mui-selected': {
				backgroundColor: theme.palette.background.paper,
				'&:hover': {
					backgroundColor: theme.palette.background.default
				}
			},
			[ theme.breakpoints.down('sm') ]: {
				display: 'flex',
        maxWidth: '90%'
      }
		}),
		iconWrapper: (isDestructive = false, isPrimary = false) => ({
      '&.MuiListItemIcon-root': {
        minWidth: '0 !important',
				mr: Dimens.spacing.base,
				color: () => {
					let color = 'inherit';
					if (isDestructive) {
						color = theme.palette.error.main;
					} else if (isPrimary) {
						color = theme.palette.primary.main;
					}
	
					return color;
				},
				'& .MuiSvgIcon-root': {
					fontSize: Dimens.icon.sm
				}
      }
    }),
		divider: {
			my: '0 !important',
      borderBottomWidth: Dimens.menuBorderWidth,
      borderColor: theme.palette.background.default
		}
	};

	return styles;
};

export default useStyles;