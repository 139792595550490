import { AppBar, Avatar, Box, IconButton, Toolbar, Typography } from '@mui/material';

import CustomToggleButtonGroup from '../../../../components/CustomToggleButtonGroup';
import DotsVerticalIcon from '../../../../components/CustomIcons/DotsVerticalIcon';
import React from 'react';
import Util from '../../../../utils/util';
import { getInboxTabs } from '../../../../utils/constants';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  name: string;
  profileImage?: string;
  selectedTab: string;
  handleTabChange: (value: string) => void;
}

/**
 * InboxListHeader Component
 * 
 * This component renders the header section for the inbox list screen.
 * It displays the title, optional user avatar and name, segment toggle buttons for filtering, and a menu icon.
 */
const InboxListHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <AppBar position='fixed' sx={styles.appBar}>
      <Toolbar>
        <Box sx={styles.titleWrapper}>
          <Typography variant='h1' sx={styles.title}>
            {props.title}
          </Typography>
        </Box>
        <Box>
          {/* <IconButton sx={styles.iconBtn}>
            <DotsVerticalIcon sx={styles.icon} />
          </IconButton> */}
        </Box>
      </Toolbar>
      <Box sx={styles.footer}>
        <Avatar sx={styles.avatar} src={props.profileImage}>
          {!props.profileImage &&
            <Typography variant='footer' >
              {Util.getInitialsFromName(props.name, 1)}
            </Typography>
          }
        </Avatar>
        <Typography variant='footer' component={'span'} sx={styles.footerCaption}>
          {`${t('messagingAs')}: `}
          <Typography variant='footer' sx={styles.footerName}>
            {props.name}
          </Typography>
        </Typography>
      </Box>
      <Box sx={styles.toggleGroupContainer}>
        <CustomToggleButtonGroup
          fullWidth
          value={props.selectedTab}
          data={getInboxTabs()}
          onChange={(_, value) => value && props.handleTabChange(value)}
        />
      </Box>
    </AppBar>
  );
};

export default InboxListHeader;
