import { AppFeature, DashboardPermission } from '../../../utils/constants';
import { Box, Dialog, DialogActions, DialogContent, DialogProps, Divider, IconButton, Stack, Typography } from '@mui/material';
import { CREATE_DASHBOARD, DELETE_DASHBOARD, UPDATE_DASHBOARD } from '../../../data/dashboard/action-types';
import { Dashboard, SharedUser } from '../../../types/dashboard';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import BinIcon from '../../../components/CustomIcons/BinIcon';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import CustomSelect from '../../../components/CustomSelect';
import { DASHBOARD_PERMISSION_MENU } from '../../../utils/ui-constants';
import { DashboardRequest } from '../../../types/dashboard-request';
import InlineAlert from '../../../components/InlineAlert';
import LoginUtil from '../../../utils/login-util';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import UserAutocompleteChipSelect from '../UserAutocompleteChipSelect';
import UserPermissionList from './UserPermissionList';
import Util from '../../../utils/util';
import { dashboardSchema } from '../../../utils/validation-schema';
import { useDashboardApi } from '../../../data/dashboard/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface defining the props for the CreateEditDashboardDialog component.
 * Extends the `DialogProps` interface from Material UI.
 * @property {function} onClose - Callback function to be called when the dialog is closed.
 * @property {Dashboard} dashboard - The dashboard object to be created or edited.
 */
interface Props extends DialogProps {
	onClose: () => void;
	dashboard: Dashboard;
}

/**
 * CreateEditDashboardDialog component provides a dialog for creating or editing a dashboard.
 * It handles form submission, user permissions, and dashboard deletion.
 * @param {Props} props - The props passed to the component.
 * @returns {JSX.Element} The rendered CreateEditDashboardDialog component.
 */
const CreateEditDashboardDialog: React.FC<Props> = (props: Props) => {

	const { onClose, ...rest } = props;
	const [showEditForm, setShowEditForm] = useState<boolean>(false);
	const styles = useStyles(showEditForm);
	const { t } = useTranslation();
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(Boolean(props.dashboard.id));
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const formikRef = React.createRef<FormikProps<Dashboard>>();
	const [permission, setPermission] = useState<DashboardPermission>(DashboardPermission.CanView);
	const dashboardApi = useDashboardApi();
	const apiStatus = dashboardApi.state.dashboard.apiStatus;

	/**
	 * Fetches dashboard permissions and sets initial state when the component mounts or the `props.dashboard` changes.
	 */
	useEffect(() => {
		if (LoginUtil.hasPermission(AppFeature.ManageDashboard) && props.dashboard.id
			&& props.dashboard.canEdit && !props.dashboard.isPermissionFetched) {
			dashboardApi.fetchDashboardPermissions(props.dashboard.id);
		}
		setShowEditForm(Boolean(props.dashboard.id));
	}, [props.dashboard]);

	/**
	 * Retrieves the dashboard details, either from props or from the API state if it's an existing dashboard.
	 * @returns {Dashboard} The dashboard object with details.
	 */
	const getDashboardDetail = (): Dashboard => {
		let dashboard = props.dashboard;
		if (dashboard.id) {
			dashboard = dashboardApi.state.dashboard.dashboardList.find(item => item.id === dashboard.id) ?? dashboard;
		}

		return dashboard;
	}

	const dashboardDetail = getDashboardDetail();

	/**
	 * Gets the list of existing users with access to the dashboard.
	 * If it's a new dashboard, it includes the current user as the owner.
	 * @returns {Array<SharedUser>} An array of users with access to the dashboard.
	 */
	const getExistingUserList = (): Array<SharedUser> => {
		let existingUserList = [];
		if (dashboardDetail.id) {
			existingUserList = (dashboardDetail.permissions ?? [])
				.map(item => ({
					profileId: item.profile.profileId,
					permission: item.permission
				}));
		} else {
			existingUserList = [{
				profileId: LoginUtil.getProfileId(),
				permission: DashboardPermission.Owner
			}];
		}

		return existingUserList;
	}

	/**
	 * Resets the dialog state and closes the dialog.
	 */
	const resetStateAndClose = () => {
		setPermission(DashboardPermission.CanView);
		setShowSaveDialog(false);
		onClose();
	}

	/**
	 * Handles the dialog close event.
	 * If the form is dirty (has unsaved changes), it shows the "Save Changes?" dialog.
	 * Otherwise, it resets the state and closes the dialog.
	 */
	const handleClose = () => {
		if (formikRef.current?.dirty) {
			setShowSaveDialog(true);
		} else {
			resetStateAndClose();
		}
	};

	/**
	 * Handles the form submission.
	 * Prepares the permission list and calls the `initApi` function to create or update the dashboard.
	 * @param {Dashboard} dashboard - The dashboard data from the form.
	 */
	const onSubmit = (dashboard: Dashboard) => {
		let permissionList: Array<SharedUser> = [];
		if (!Util.isArrayEmpty(dashboard.permissionRequest)) {
			// eslint-disable-next-line
			permissionList = dashboard.permissionRequest!.map(user => ({
				profileId: user.profileId,
				permission: permission
			}));
		}
		if (!Util.isArrayEmpty(dashboard.permissions)) {
			// eslint-disable-next-line
			const existingPermissionList = dashboard.permissions!
				.filter(item => item.permission !== DashboardPermission.Owner
					&& item.permission !== DashboardPermission.Remove
				)
				.map(item => ({
					profileId: item.profile.profileId,
					permission: item.permission
				}));
			permissionList = permissionList.concat(existingPermissionList);
		}
		dashboard.permissionRequest = permissionList;
		initApi(dashboard);
	};

	/**
	 * Initializes the API call to create or update the dashboard.
	 * Calls `resetStateAndClose` after a successful API call.
	 * @param {Dashboard} request - The dashboard data to be sent to the API.
	 */
	const initApi = (dashboard: Dashboard) => {
		const request: DashboardRequest = {
			id: dashboard.id,
			name: dashboard.name,
			permissions: dashboard.permissionRequest
		};
		if (dashboard.id) {
			dashboardApi.updateDashboard(request).then((tab?: Dashboard) => {
				if (tab) {
					resetStateAndClose();
				}
			});
		} else {
			dashboardApi.createDashboard(request).then((tab?: Dashboard) => {
				if (tab) {
					resetStateAndClose();
				}
			});
		}
	}

	/**
	 * Handles the confirmation of deleting a dashboard.
	 * Calls the API to delete the dashboard and then calls `resetStateAndClose`.
	 */
	const onDeleteClick = () => {
		setOpenDeleteDialog(false);
		dashboardApi.deleteDashboard(dashboardDetail)
			.then((dashboard) => {
				if (dashboard) {
					resetStateAndClose();
				}
			});
	}

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>
					{dashboardDetail.name ? t('editDashboard') : t('newDashboard')}
				</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<InlineAlert message={Util.getApiError([CREATE_DASHBOARD, UPDATE_DASHBOARD, DELETE_DASHBOARD], apiStatus)} />
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validationSchema={dashboardSchema}
				initialValues={dashboardDetail}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							<Box sx={styles.contentLeft}>
								<Field
									name='name'
									label={t('name')}
									placeholder={t('name')}
									component={CustomInput}
								/>
								<Field
									name='permissionRequest'
									label={t('shareWith')}
									placeholder={t('search')}
									component={UserAutocompleteChipSelect}
									multiple
									noOptionsText={t('noUsers')}
									exisitingUserList={getExistingUserList()}
								/>
								<Box sx={styles.permissionInfo}>
									<Typography variant='footer' component='span'>{`${t('peopleInvited')}:`}</Typography>
									<CustomSelect
										showCaretDownIcon
										showCheckedIcon
										menu={DASHBOARD_PERMISSION_MENU}
										value={permission}
										onChange={(e) => setPermission(e.target.value as DashboardPermission)}
										positionStyle={styles.selectPosition as React.CSSProperties}
									/>
								</Box>
							</Box>
							<Divider sx={styles.divider} />
							<Box sx={styles.contentRight}>
								<Field name='permissions' label={t('usersWithAccess')} component={UserPermissionList} />
							</Box>
						</DialogContent>
						<DialogActions>
							<Box sx={styles.buttonBox}>
								<CustomButton
									sx={styles.nextBtn}
									title={t('delete')}
									color='secondary'
									destructive
									startIcon={<BinIcon sx={styles.smIcon} />}
									onClick={() => setOpenDeleteDialog(true)}
								/>
								<CustomButton
									sx={styles.saveBtn}
									type='submit'
									title={t('save')}
									color='primary'
									disabled={!dirty || !isValid}
									endIcon={<SaveIcon sx={styles.smIcon} />}
								/>
							</Box>
						</DialogActions>
					</Form>
				)}
			</Formik>
			<CircularProgressBar show={Util.isApiLoading([CREATE_DASHBOARD, DELETE_DASHBOARD], apiStatus)} />
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={resetStateAndClose}
				onClose={() => setShowSaveDialog(false)}
			/>
			<AlertDialogSmall
				open={openDeleteDialog}
				title={t('deleteDashboard')}
				titleIcon={<BinIcon />}
				message={t('deleteDashboardMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setOpenDeleteDialog(false)}
				primaryLabel={t('delete')}
				onPrimaryAction={onDeleteClick}
				isDestructive
				onClose={() => setOpenDeleteDialog(false)}
			/>
		</Dialog>
	);
};

export default CreateEditDashboardDialog;