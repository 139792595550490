import CloseIconDark from '../../assets/close_icon_dark.svg';
import CloseIconLight from '../../assets/close_icon_light.svg';
import Dimens from '../../theme/dimens';
import ThemeUtil from '../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SearchBar component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (searchKey = '', longWidth = false) => {

  const { xs, base, md, lg } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    inputWrapper: {
      p: `${md} ${lg}`,
      display: 'flex',
      backgroundColor: theme.palette.background.default,
      borderRadius: Dimens.radius.md,
      minWidth: longWidth? Dimens.searchBarMinWidth : 'auto',      
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto'
      },
      '& input[type="search"]::-webkit-search-cancel-button': {
        appearance: 'none',
        backgroundImage: `url(${ThemeUtil.isLightTheme(theme) ? CloseIconLight : CloseIconDark})`,
        backgroundSize: 'contain',
        width: lg,
        height: lg,
        cursor: 'pointer',
        opacity: 1,
      }
    },
    input: {
      ...theme.typography.p1,
      padding: 0,
      marginLeft: xs
    },
    icon: {
      fontSize: Dimens.icon.sm,
      color: theme.palette.text.secondary
    }
  };

  return styles;
};

export default useStyles;
