import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DotsVerticalIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3615_11657)'>
          <path d='M10.6895 3.10735C10.6895 3.45491 10.8275 3.78823 11.0733 4.03399C11.319 4.27976 11.6524 4.41782 11.9999 4.41782C12.3475 4.41782 12.6808 4.27976 12.9266 4.03399C13.1723 3.78823 13.3104 3.45491 13.3104 3.10735C13.3104 2.75979 13.1723 2.42647 12.9266 2.1807C12.6808 1.93494 12.3475 1.79688 11.9999 1.79688C11.6524 1.79688 11.319 1.93494 11.0733 2.1807C10.8275 2.42647 10.6895 2.75979 10.6895 3.10735Z' fill='currentColor' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M10.6895 12.3945C10.6895 12.742 10.8275 13.0753 11.0733 13.3211C11.319 13.5669 11.6524 13.7049 11.9999 13.7049C12.3475 13.7049 12.6808 13.5669 12.9266 13.3211C13.1723 13.0753 13.3104 12.742 13.3104 12.3945C13.3104 12.0469 13.1723 11.7136 12.9266 11.4678C12.6808 11.2221 12.3475 11.084 11.9999 11.084C11.6524 11.084 11.319 11.2221 11.0733 11.4678C10.8275 11.7136 10.6895 12.0469 10.6895 12.3945Z' fill='currentColor' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M10.6895 21.6835C10.6895 22.0311 10.8275 22.3644 11.0733 22.6102C11.319 22.8559 11.6524 22.994 11.9999 22.994C12.3475 22.994 12.6808 22.8559 12.9266 22.6102C13.1723 22.3644 13.3104 22.0311 13.3104 21.6835C13.3104 21.336 13.1723 21.0026 12.9266 20.7569C12.6808 20.5111 12.3475 20.373 11.9999 20.373C11.6524 20.373 11.319 20.5111 11.0733 20.7569C10.8275 21.0026 10.6895 21.336 10.6895 21.6835Z' fill='currentColor' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_3615_11657'>
            <rect width='24' height='24' fill='white' transform='translate(0 0.394531)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default DotsVerticalIcon;