/**
 * useStyles custom hook generates MUI theme dependent styles for the ContentLayout component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const styles = {
    contentWrapper: {
			height: 'inherit',
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			overflow: 'auto'
		},
		content: {
			height: 'inherit',
			overflow: 'hidden'
		}
  }

  return styles;
}

export default useStyles;
