import DarkLogo from '../../assets/brand_logo_dark.svg';
import Dimens from '../../theme/dimens';
import LightLogo from '../../assets/brand_logo_light.svg';
import ThemeUtil from '../../theme/theme-util';
import Welcome1LGDark from '../../assets/welcome_1_lg_dark.svg';
import Welcome1LGLight from '../../assets/welcome_1_lg_light.svg';
import Welcome1MDDark from '../../assets/welcome_1_md_dark.svg';
import Welcome1MDLight from '../../assets/welcome_1_md_light.svg';
import Welcome1SMDark from '../../assets/welcome_1_sm_dark.svg';
import Welcome1SMLight from '../../assets/welcome_1_sm_light.svg';
import Welcome2LGDark from '../../assets/welcome_2_lg_dark.svg';
import Welcome2LGLight from '../../assets/welcome_2_lg_light.svg';
import Welcome2MDDark from '../../assets/welcome_2_md_dark.svg';
import Welcome2MDLight from '../../assets/welcome_2_md_light.svg';
import Welcome2SMDark from '../../assets/welcome_2_sm_dark.svg';
import Welcome2SMLight from '../../assets/welcome_2_sm_light.svg';
import Welcome3LGDark from '../../assets/welcome_3_lg_dark.svg';
import Welcome3LGLight from '../../assets/welcome_3_lg_light.svg';
import Welcome3MDDark from '../../assets/welcome_3_md_dark.svg';
import Welcome3MDLight from '../../assets/welcome_3_md_light.svg';
import Welcome3SMDark from '../../assets/welcome_3_sm_dark.svg';
import Welcome3SMLight from '../../assets/welcome_3_sm_light.svg';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the WelcomeScreen component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const { xs, base, lg, section, container } = Dimens.spacing;
  const theme = useTheme();
  const isLightTheme = ThemeUtil.isLightTheme(theme);

  const styles = {
    wrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      bgcolor: theme.palette.background.paper,
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'unset'
      }
    },
    logo: {
      position: 'absolute',
      zIndex: 1,
      top: section,
      left: section,
      content: `url(${ThemeUtil.isLightTheme(theme) ? LightLogo : DarkLogo})`,
      width: Dimens.brangLogoWidth.xs,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    langWrapper: {
      position: 'absolute',
      zIndex: 1,
      top: base,
      right: base,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    contentWrapper: {
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      px: lg,
      [theme.breakpoints.up('md')]: {
        flex: 0.5,
      }
    },
    contentBtnWrapper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: Dimens.carousel.contentWidth
      },
      [theme.breakpoints.up('md')]: {
        p: `${container} 0 ${xs} 0`
      }
    },
    welcome1: {
      content: `url(${isLightTheme ? Welcome1SMLight : Welcome1SMDark})`,
      [theme.breakpoints.up('sm')]: {
        content: `url(${isLightTheme ? Welcome1MDLight : Welcome1MDDark})`
      },
      [theme.breakpoints.up('md')]: {
        content: `url(${isLightTheme ? Welcome1LGLight : Welcome1LGDark})`
      }
    },
    welcome2: {
      content: `url(${isLightTheme ? Welcome2SMLight : Welcome2SMDark})`,
      [theme.breakpoints.up('sm')]: {
        content: `url(${isLightTheme ? Welcome2MDLight : Welcome2MDDark})`
      },
      [theme.breakpoints.up('md')]: {
        content: `url(${isLightTheme ? Welcome2LGLight : Welcome2LGDark})`,
      }
    },
    welcome3: {
      content: `url(${isLightTheme ? Welcome3SMLight : Welcome3SMDark})`,
      [theme.breakpoints.up('sm')]: {
        content: `url(${isLightTheme ? Welcome3MDLight : Welcome3MDDark})`
      },
      [theme.breakpoints.up('md')]: {
        content: `url(${isLightTheme ? Welcome3LGLight : Welcome3LGDark})`
      }
    },
    spacer: {
      height: xs,
      [theme.breakpoints.up('md')]: {
        height: base
      }
    },
    versionWrapper: {
      position: 'absolute',
      bottom: section,
      left: section,
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  };

  return styles;
};

export default useStyles;