import Dimens from '../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the ProfileDetail component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {
	const { lg, section } = Dimens.spacing;
	const theme = useTheme();

	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			flex: 0.8,
			overflowY: 'auto',
			p: section,
			[ theme.breakpoints.down('md') ]: {
				p: lg,
				flex: 1
			}
		},
		form: {
			display: 'flex',
			flex: 1,
			justifyContent: 'space-between',
			flexDirection: 'column',
			overflowY: 'auto'
		},
		formInnerWrapper: {
			display: 'flex',
			flexDirection: 'column',
			overflowY: 'auto',
			flex: 1
		}
	};

	return styles;
};

export default useStyles;
