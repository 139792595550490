import React, { useCallback, useContext } from 'react';
import {
	apiFailure,
	dashboardListSuccess,
	emergencyCallSuccess,
	initDashboardCreate,
	initDashboardDelete,
	initDashboardItemCreate,
	initDashboardItemDelete,
	initDashboardItemFetch,
	initDashboardItemUpdate,
	initDashboardListFetch,
	initDashboardPermissionFetch,
	initDashboardUpdate,
	initEmergencyCall,
	resetDashboardState,
	resetErrorState
} from './actions';
import { doDelete, doGet, doMultipartPost, doPost, doPut } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import BaseResponse from '../../types/base-response';
import ConversationData from '../../types/conversation-data';
import { Dashboard } from '../../types/dashboard';
import DashboardDetailResponse from '../../types/dashboard-tab-detail-response';
import { DashboardItem } from '../../types/dashboard-item';
import DashboardItemResponse from '../../types/dashboard-item-response';
import DashboardListResponse from '../../types/dashboard-list-Response';
import { DashboardRequest } from '../../types/dashboard-request';
import DboardPermissionDetailResponse from '../../types/dboard-permission-detail-response';
import { EmergencyCallRequest } from '../../types/emergency-call-request';
import { EmergencyTeamCallRequest } from '../../types/emergency-team-call-request';
import FileUploadResponse from '../../types/file-upload-response';
import { HttpStatusCode } from 'axios';
import { InteractionType } from '../../utils/constants';
import SendMessageResponse from '../../types/send-message-response';
import { SessionExpirationContext } from '../../store/session-expiration-provider';
import { Store } from '../../store/store';
import { interactionHistorySuccess } from '../inbox/actions';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to manage dashboard list data and actions.
 *
 * @returns An object containing functions for fetching dashboard list and the dashboard list state.
 */
export function useDashboardApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();
	const { setSessionExpired } = useContext(SessionExpirationContext);

	/**
	 * Fetches the dashboard list from the API and dispatches actions based on the result.
	 */
	const fetchDashboardList = useCallback(async () => {
		dispatch(initDashboardListFetch());
		try {
			const response: DashboardListResponse = await doGet(APIConfig.dashboard);
			dispatch(dashboardListSuccess(response.data ?? []));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Creates a new dashboard by sending a POST request to the API.
	 *
	 * @param {dashboard} dashboard - The dashboard object to be created.
	 *
	 * @returns {dashboardDetailResponse} The response object containing the created dashboard details.
	 */
	const createDashboard = useCallback(async (request: DashboardRequest) => {
		dispatch(initDashboardCreate())
		try {
			const response: DashboardDetailResponse = await doPost(APIConfig.dashboard, request);
			response.data.isFetched = true;
			response.data.canEdit = true;
			const tabList = [...state.dashboard.dashboardList];
			tabList.push(response.data);
			dispatch(dashboardListSuccess(tabList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error)
		}
	}, [state.dashboard.dashboardList]);

	/**
	 * Updates an existing dashboard by sending a POST request to the API.
	 * 
	 * @param {dashboard} dashboard - The dashboard object with updated details.
	 * 
	 * @returns {dashboardDetailResponse} The response object containing the updated dashboard details.
	 */
	const updateDashboard = useCallback(async (request: DashboardRequest) => {
		dispatch(initDashboardUpdate());
		try {
			const response: DashboardDetailResponse = await doPut(`${APIConfig.dashboard}/${request.id}`, request);
		
			const dashboardList = [...state.dashboard.dashboardList];
			const dashboard = dashboardList.find(data => data.id === request.id);
			if (dashboard) {
				dashboard.name = response.data.name;
				dashboard.permissionRequest = [];
				dashboard.permissions = response.data.permissions;
			}
			dispatch(dashboardListSuccess(dashboardList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.dashboard.dashboardList]);

	/**
	 * @description A callback function to delete a dashboard.
	 * This function dispatches actions to manage the deletion process and update the dashboard list.
	 *
	 * @param {Dashboard} tab - The dashboard object to be deleted.  It is assumed that the dashboard object has an `id` property.
	 * @returns {Promise<BaseResponse | undefined>} - A promise that resolves with the API response if successful, or undefined if an error occurs.
	 */
	const deleteDashboard = useCallback(async (tab: Dashboard) => {
		dispatch(initDashboardDelete());
		try {
			await doDelete(`${APIConfig.dashboard}/${tab.id}`);		
			const tabList = [...state.dashboard.dashboardList.filter(item => item.id !== tab.id)];
			dispatch(dashboardListSuccess(tabList));

			return tab;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.dashboard.dashboardList]);

	/**
	 * Fetches the list of dashboard items for a given dashboard ID.
	 * Updates the dashboard list in the state with the fetched items.
	 * @param {string} dashboardId - The ID of the dashboard.
	 * @returns {Promise<DashboardDetailResponseData | undefined>} A promise that resolves to the dashboard detail data or undefined if there is an error.
	 */
	const fetchDashboardItemList = useCallback(async (dashboardId: string) => {
		dispatch(initDashboardItemFetch());
		try {
			const response: DashboardDetailResponse = await doGet(`${APIConfig.dashboard}/${dashboardId}`);
			response.data.isFetched = true;
			const dashboardList = [...state.dashboard.dashboardList];
			const dashboard = dashboardList.find(data => data.id === dashboardId);
			if (dashboard) {
				dashboard.isFetched = true;
				dashboard.items = response.data.items;
			}
			dispatch(dashboardListSuccess(dashboardList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.dashboard.dashboardList]);

	/**
	 * Creates a new dashboard item for a given dashboard ID.
	 * Updates the dashboard list in the state with the new item.
	 * @param {string} dashboardId - The ID of the dashboard.
	 * @param {DashboardItem} item - The dashboard item data to create.
	 * @returns {Promise<DashboardItemResponseData | undefined>} A promise that resolves to the created dashboard item data or undefined if there is an error.
	 */
	const createDashboardItem = useCallback(async (dashboardId: string, item: DashboardItem) => {
		dispatch(initDashboardItemCreate())
		try {
			const url = APIConfig.addDashboardItem.replace('{dashboard_id}', dashboardId);
			const response: DashboardItemResponse = await doPost(url, item);
			const dashboardList = [...state.dashboard.dashboardList];
			const dashboard = dashboardList.find(data => data.id === dashboardId);
			if (dashboard) {
				dashboard.items = dashboard.items ?? [];
				dashboard.items.push(response.data);
			}
			dispatch(dashboardListSuccess(dashboardList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error)
		}
	}, [state.dashboard.dashboardList]);

	/**
	 * Update dashboard item for a given item id and dashboard ID.
	 * Updates the dashboard item list in the state with modified item.
	 * @param {string} dashboardId - The ID of the dashboard.
	 * @param {DashboardItem} item - The dashboard item data to create.
	 * @returns {Promise<DashboardItemResponseData | undefined>} A promise that resolves to the created dashboard item data or undefined if there is an error.
	 */
	const updateDashboardItem = useCallback(async (dashboardId: string, item: DashboardItem) => {
		dispatch(initDashboardItemUpdate())
		try {
			const url = APIConfig.updateDashboardItem.replace('{item_id}', item.id);
			const response: DashboardItemResponse = await doPut(url, item);
			const dashboardList = [...state.dashboard.dashboardList];
			const dashboard = dashboardList.find(data => data.id === dashboardId);
			const dashboardItem = dashboard?.items?.find(data => data.id === item.id);
			if (response.data && dashboardItem) {
				dashboardItem.icon = response.data.icon;
				dashboardItem.title = response.data.title;
				dashboardItem.teamUuids = response.data.teamUuids;
				dashboardItem.teams = response.data.teams;
			}
			dispatch(dashboardListSuccess(dashboardList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error)
		}
	}, [state.dashboard.dashboardList]);

	/**
	 * Deletes a dashboard item for a given dashboard ID and item ID.
	 * Updates the dashboard list in the state by removing the deleted item.
	 * @param {string} dashboardId - The ID of the dashboard.
	 * @param {string} dashboardItemId - The ID of the dashboard item to delete.
	 * @returns {Promise<BaseResponse | undefined>} A promise that resolves to the base response or undefined if there is an error.
	 */
	const deleteDashboardItem = useCallback(async (dashboardId: string, dashboardItemId: string) => {
		dispatch(initDashboardItemDelete());
		try {
			const response: BaseResponse = await doDelete(`${APIConfig.dashboardItem}/${dashboardItemId}`);
			const dashboardList = [...state.dashboard.dashboardList];
			const dashboard = dashboardList.find(data => data.id === dashboardId);
			if (dashboard) {
				dashboard.items = (dashboard.items ?? []).filter(item => item.id !== dashboardItemId);
			}
			dispatch(dashboardListSuccess(dashboardList));

			return response;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.dashboard.dashboardList]);

	/**
	 * Fetches permissions for a specific dashboard by making a GET request.
	 *
	 * @param {string} dashboardId - The ID of the dashboard.
	 * @returns {Promise<DboardPermissionDetailResponse>} The response object containing permission details.
	 */
	const fetchDashboardPermissions = useCallback(async (dashboardId: string) => {
		dispatch(initDashboardPermissionFetch());
		try {
			const url = APIConfig.dashboardPermissions.replace('{dashboard_id}', dashboardId);
			const response: DboardPermissionDetailResponse = await doGet(url);
			const dashboardList = [...state.dashboard.dashboardList];
			const dashboard = dashboardList.find(data => data.id === dashboardId);
			if (dashboard) {
				dashboard.isPermissionFetched = true;
				dashboard.permissions = response.data;
			}
			dispatch(dashboardListSuccess(dashboardList));

			return response.data;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.dashboard.dashboardList]);

	/**
	 * Sends an emergency message with an optional file attachment.
	 *
	 * This function first uploads the file if provided and if the request object does not already contain an attachment key. 
	 * After that, it sends the emergency message request to the server.
	 *
	 * @param {EmergencyCallRequest} request - The emergency message request object.
	 * @param {File} [file] - An optional file to be uploaded before sending the request.
	 * @returns {Promise<BaseResponse>} The response object confirming the success of the emergency message.
	 */
	const sendEmergencyMessage = useCallback(async (request: EmergencyCallRequest, file?: File) => {
    dispatch(initEmergencyCall());
    try {
      if (file && !request.attachmentKey) {
        const formData = new FormData();
        formData.append('file', file);
        const response: FileUploadResponse = await doMultipartPost(APIConfig.chatFileUpload, formData);
        request.attachmentKey = response.data;
      }
      const response: BaseResponse = await doPost(APIConfig.sendEmergencyCall, request);
			dispatch(emergencyCallSuccess())
      return response;
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

	/**
	 * Sends an emergency message with an optional file attachment.
	 *
	 * This function first uploads the file if provided and if the request object does not already contain an attachment key. 
	 * After that, it sends the emergency message request to the server.
	 *
	 * @param {EmergencyTeamCallRequest} request - The emergency message request object.
	 * @param {File} [file] - An optional file to be uploaded before sending the request.
	 * @returns {Promise<BaseResponse>} The response object confirming the success of the emergency message.
	 */
	const sendTeamEmergencyMessage = useCallback(async (request: EmergencyTeamCallRequest, hasLoggedInUser: boolean, file?: File) => {
		dispatch(initEmergencyCall());
    try {
      if (file) {
        const formData = new FormData();
        formData.append('file', file);
        const response: FileUploadResponse = await doMultipartPost(APIConfig.chatFileUpload, formData);
        request.attachmentKey = response.data;
      }
      const response: SendMessageResponse = await doPost(APIConfig.sendEmergencyTeamCall, request);
			dispatch(emergencyCallSuccess());
			const messageData = response.data;
			messageData.file = file;
			if (messageData && hasLoggedInUser) {
				const groupRoomResponse: ConversationData = {
					...messageData,
					groupRoomKey: messageData.groupRoomKey ?? '',
					count: 0,
					interactionType: InteractionType.EmergencyGroup,
					name: messageData.contentText,
					createdByLoginId: '',
					createdAt: messageData.createdAt ?? '',
					lastContentType: messageData.contentType,
					lastContentText: (messageData.file?.name || messageData.attachmentKey) ?? messageData.contentText,
					lastContentTime: messageData.createdAt ?? '',
					chatHistory: {
						total: 0,
						content: [
							messageData
						]
					}
				};
				const conversationList = state.inbox.messageList ?? [];
				conversationList.push(groupRoomResponse);
				dispatch(interactionHistorySuccess(conversationList));
			}
			
      return response;
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

	/**
	 * Resets the dashboard error state in the Redux store.
	 */
	const resetDashboard = () => {
		dispatch(resetDashboardState());
	}

	/**
		 * Resets the API error state in the store.
		 */
		const resetError = () => {
			if (state.dashboard.apiStatus?.error) {
				dispatch(resetErrorState());
			}
		}

	/**
	 * Dispatches an API failure action with an error message.
	 *
	 * @param {any} error - The error object.
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(apiFailure(new AppError(error?.code, message)));
		if (error?.code === HttpStatusCode.Unauthorized) {
			setSessionExpired(true);
		}
	}

	return {
		fetchDashboardList,
		createDashboard,
		updateDashboard,
		deleteDashboard,
		fetchDashboardPermissions,
		fetchDashboardItemList,
		createDashboardItem,
		updateDashboardItem,
		deleteDashboardItem,
		sendEmergencyMessage,
		sendTeamEmergencyMessage,
		resetDashboard,
		resetError,
		state
	};
}
