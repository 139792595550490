import { Avatar, AvatarGroup, Box, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import { AppFeature } from '../../../utils/constants';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomInput from '../../../components/CustomInput';
import Department from '../../../types/department';
import DepartmentEditPanel from '../DepartmentEditPanel';
import DepartmentMemberPanel from '../DepartmentMemberPanel';
import { FETCH_DEPT_DETAIL } from '../../../data/department/action-types';
import LoginUtil from '../../../utils/login-util';
import PencilIcon from '../../../components/CustomIcons/PencilIcon';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SiteMapIcon from '../../../components/CustomIcons/SiteMapIcon';
import Util from '../../../utils/util';
import { useDepartmentApi } from '../../../data/department/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	department?: Department;
	onClose: () => void;
}

/**
 * Renders a drawer displaying department information, actions, and potentially an edit panel.
 *
 * @param {Props} props - Component props containing open state, navigation mode, department data, and close callback.
 * 
 * @returns JSX element representing the department view panel.
 */
const DepartmentViewPanel: React.FC<Props> = (props: Props) => {

	const { open, department, onClose } = props;
	const styles = useStyles(department?.memberCount);
	const { t } = useTranslation();
	const [showDepartmentEdit, setShowDepartmentEdit] = useState<boolean>(false);
	const [showDeptMembers, setShowDeptMembers] = useState<boolean>(false);
	const deptApi = useDepartmentApi();
	const apiStatus = deptApi.state.department.apiStatus;

	/**
	 * Fetches department details if the provided department object is incomplete.
	 */
	useEffect(() => {
		const deptDetail = getDeptDetail();
		if (open && department && (!deptDetail || !deptDetail?.isFetched)) {
			deptApi.fetchDeptDetail(department.id);
		}
	}, [open]);

	/**
	 * Fetches department details if not already available or incomplete.
	 *
	 * @returns {Department | null} The fetched department details or null if not found or loading.
	 */
	const getDeptDetail = () => {
		const detail = deptApi.state.department.departmentList.find(item => item.id === department?.id);

		return detail;
	}

	const deptDetail = getDeptDetail();

	/**
	 * Renders a surplus value with a "+" sign and formatted number.
	 *
	 * @param {number} surplus - The surplus value to be rendered.
	 * 
	 * @returns JSX element representing the surplus value.
	 */
	const renderSurplus = (surplus: number) => (
		<Typography variant='footer' color='primary'>
			+{Util.formatNumber(surplus)}
		</Typography>
	);

	const onDeptEditClose = (action?: string) => {
		setShowDepartmentEdit(false);
		if (action) {
			props.onClose();
		}
	}

	return (
		<RightDrawerLayout
			hasDefaultBg
			open={open}
			rightIcon={(LoginUtil.hasPermission(AppFeature.ManageDepartment) && deptDetail && deptDetail.isFetched) ? <PencilIcon /> : undefined}
			onActionClick={() => setShowDepartmentEdit(true)}
			onClose={onClose}
		>
			{(open && deptDetail && deptDetail.isFetched && !showDepartmentEdit) &&
				<Box sx={styles.contentWrapper}>
					<Box sx={styles.content}>
						<Box sx={styles.deptHeader}>
							<Box sx={styles.avatarWrapper}>
								<Box sx={styles.iconWrapper}>
									<SiteMapIcon />
								</Box>
							</Box>
							<Box sx={styles.deptContent}>
								<Typography variant='h2' sx={styles.title}>{deptDetail.name}</Typography>
								{!Util.isArrayEmpty(deptDetail.members) &&
									<AvatarGroup
										max={3}
										sx={styles.avatarGroup}
										renderSurplus={renderSurplus}
										onClick={() => setShowDeptMembers(true)}
									>
										{(deptDetail.members ?? []).map((member, index) => (
											<Avatar key={`dept-member-${index}`} alt={member.name} src={member.imageUrl ?? 'test'} />
										))}
									</AvatarGroup>
								}
							</Box>
						</Box>
						<Box sx={styles.deptInfo}>
							<Formik enableReinitialize initialValues={deptDetail} onSubmit={() => {/*Do Nothing*/ }}>
								<Form>
									{deptDetail.description && 
										<Field
											name='description'
											label={t('description')}
											placeholder={t('deptDescPlaceholder')}
											component={CustomInput}
											maxRows={Number.MAX_SAFE_INTEGER}
											readOnly
											multiline
										/>
									}									
									{!Util.isArrayEmpty(deptDetail.sites) &&
										<Field
											name='sites'
											label={t('sites')}
											placeholder={t('findSites')}
											component={CustomAutocomplete}
											multiple
											menu={[]}
											readOnly
										/>
									}
								</Form>
							</Formik>
						</Box>
					</Box>
					<DepartmentMemberPanel
						open={showDeptMembers}
						department={deptDetail}
						onClose={() => setShowDeptMembers(false)}
					/>
				</Box>
			}
			{deptDetail && deptDetail.isFetched &&
				<DepartmentEditPanel
					open={showDepartmentEdit}
					department={deptDetail}
					onClose={onDeptEditClose}
				/>
			}
			<CircularProgressBar show={Util.isApiLoading([FETCH_DEPT_DETAIL], apiStatus)} />
		</RightDrawerLayout>
	);
};

export default DepartmentViewPanel;