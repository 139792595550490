import { Box, Dialog, DialogActions, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import BinIcon from '../../../components/CustomIcons/BinIcon';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import Department from '../../../types/department';
import EditIcon from '../../../components/CustomIcons/EditIcon';
import { FETCH_SITE_LIST } from '../../../data/site/action-types';
import InlineAlert from '../../../components/InlineAlert';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import SegmentedButton from '../../../components/SegmentedButton';
import Site from '../../../types/site';
import Util from '../../../utils/util';
import { siteInitialValue } from '../../../utils/constants';
import { siteSchema } from '../../../utils/validation-schema';
import { useSiteApi } from '../../../data/site/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props extends DialogProps {
	site: Site;
	deptList: Array<Department>;
	onClose: () => void;
}

/**
 * SiteDetailDialog component for managing site details.
 *
 * This component renders a modal dialog for viewing and editing site information.
 * It allows users to toggle edit mode, enter details, and save changes. Additionally,
 * it provides options for deleting the site or closing the dialog.
 *
 * @props {Props} - Props passed to the component.
 * @returns {JSX.Element} - The rendered SiteDetailDialog component.
 */
const SiteDetailDialog: React.FC<Props> = (props: Props) => {

	const { site, deptList, onClose, ...rest } = props;
	const { t } = useTranslation();
	const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const [siteDetail, setSiteDetail] = useState<Site>(siteInitialValue);
	const styles = useStyles(Boolean(siteDetail.id));
	const formikRef = React.createRef<FormikProps<Site>>();
	const siteApi = useSiteApi();
	const apiStatus = siteApi.state.site.apiStatus;

	/**
	 * Fetches site details and sets read-only state based on provided site ID.
	 *
	 * This effect runs whenever the `props.site` changes. It determines the
	 * read-only state based on the existence of a site ID and fetches details
	 * if necessary. Finally, it updates the `siteDetail` state.
	 */
	useEffect(() => {
		initSiteFetch();
	}, [props.site]);

	/**
	 * Initializes site detail fetching and read-only state.
	 *
	 * This function sets the read-only state based on the presence of a site ID.
	 * If an ID exists and the site details haven't been fetched yet, it fetches
	 * them using the siteApi. Additionally, it calls the `findSite` function to
	 * update the `siteDetail` state with the appropriate site data.
	 */
	const initSiteFetch = () => {
		setIsReadOnly(props.site.id ? true : false);
		if (props.site.id && !site.isFetched) {
			siteApi.fetchSiteDetail(props.site.id).then((site: Site | undefined) => {
				if (site) {
					setSiteDetail(site);
				}
			});
		}
		findSite();
	}

	/**
	 * Finds the site object from the site list based on its ID.
	 *
	 * This function iterates through the `siteApi.state.site.siteList` and
	 * finds the site matching the provided ID from `props.site`. If found, it
	 * updates the `siteDetail` state with the matching site object. Otherwise,
	 * it sets the `siteDetail` to the initial value.
	 */
	const findSite = () => {
		const site = siteApi.state.site.siteList.find(item => item.id === props.site.id) || siteInitialValue;
		setSiteDetail(site);
	};

	/**
	 * Generates the dialog title based on the site ID and read-only state.
	 *
	 * This function returns a dynamic title for the dialog. If the site has
	 * an ID, it displays "New Site" in read-only mode and "Edit Site" otherwise.
	 * For sites without an ID, it displays "New Site".
	 *
	 * @returns {string} - The title for the dialog.
	 */
	const getTitle = (): string => {
		let title = t('newSite');
		if (siteDetail.id) {
			title = isReadOnly ? siteDetail.name : t('editSite');
		}

		return title;
	}

	/**
	 * Handles closing the dialog based on form state.
	 * 
	 * If the form is dirty (changes have been made), shows a confirmation dialog to save changes.
	 * Otherwise, closes the dialog directly.
	 */
	const handleClose = () => {
		if (formikRef.current?.dirty) {
			setShowSaveDialog(true);
		} else {
			onClose();
		}
	};

	/**
	 * Handles form submission for editing/creating site.
	 *
	 * This function is called when the form is submitted. It updates/creates the site
	 * information using the `siteApi.createOrUpdateSite` method. If the update/create
	 * is successful, the component sets the `isReadOnly` state to true and updates
	 * the `siteDetail` state with the updated site data.
	 *
	 * @param {Site} values - The updated site data from the form.
	 */
	const onSubmit = (values: Site) => {
		siteApi.createOrUpdateSite(values).then((site: Site | undefined) => {
			if (site) {
				setIsReadOnly(true);
				setSiteDetail(site);
			}
		});
	};

	/**
	 * Handles the click event for the delete button.
	 *
	 * This function closes the delete confirmation dialog and triggers the
	 * site deletion using `siteApi.deleteSite`. If successful, the dialog is closed.
	 */
	const onDeleteClick = () => {
		setOpenDeleteDialog(false);
		siteApi.deleteSite(siteDetail)
			.then((site) => {
				if (site) {
					onClose();
				}
			});
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>{getTitle()}</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<InlineAlert message={apiStatus?.task === FETCH_SITE_LIST ? undefined : apiStatus?.error} />
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validationSchema={siteSchema}
				initialValues={siteDetail}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							{!isReadOnly &&
								<Field
									name='name'
									label={t('name')}
									placeholder={t('name')}
									component={CustomInput}
									readOnly={isReadOnly}
								/>
							}
							{((isReadOnly && siteDetail.address) || !isReadOnly) &&
							<Field
								name='address'
								label={t('address')}
								subLabel={t('optional')}
								placeholder={t('address')}
								component={CustomInput}
								readOnly={isReadOnly}
							/>
							}
							{((isReadOnly && siteDetail.phoneNumber) || !isReadOnly) &&
							<Field
								name='phoneNumber'
								label={t('telephoneNumber')}
								subLabel={t('optional')}
								placeholder={t('telephoneNumber')}
								component={CustomInput}
								readOnly={isReadOnly}
							/>
							}
							{((isReadOnly && !Util.isArrayEmpty(siteDetail.departments)) || !isReadOnly) &&
								<Field
									name='departments'
									label={t('departments')}
									subLabel={t('optional')}
									placeholder={t('findDepartments')}
									component={CustomAutocomplete}
									noOptionsText={t('noDepts')}
									multiple
									menu={deptList}
									readOnly={isReadOnly}
								/>
							}
						</DialogContent>
						<DialogActions>
							{
								isReadOnly ? (
									<SegmentedButton
										leftIcon={<EditIcon />}
										leftTitle={t('edit')}
										onLeftButtonClick={() => setIsReadOnly(false)}
										rightIcon={<BinIcon />}
										onRightButtonClick={() => setOpenDeleteDialog(true)}
									/>
								) : (
									<Box sx={styles.actionContainer}>
										{siteDetail.id &&
											<CustomButton
												title={t('delete')}
												color='secondary'
												destructive
												startIcon={<BinIcon sx={styles.smIcon} />}
												onClick={() => setOpenDeleteDialog(true)}
											/>
										}
										<CustomButton
											type='submit'
											title={t('save')}
											color='primary'
											disabled={!dirty || !isValid}
											startIcon={<SaveIcon sx={styles.smIcon} />}
										/>
									</Box>
								)}
						</DialogActions>
					</Form>
				)}
			</Formik>
			<AlertDialogSmall
				open={openDeleteDialog}
				title={t('deleteSite')}
				titleIcon={<BinIcon />}
				message={t('deleteSiteMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setOpenDeleteDialog(false)}
				primaryLabel={t('delete')}
				onPrimaryAction={onDeleteClick}
				isDestructive
				onClose={() => setOpenDeleteDialog(false)}
			/>
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={onClose}
				onClose={() => setShowSaveDialog(false)}
			/>
			<CircularProgressBar show={apiStatus?.task !== FETCH_SITE_LIST && apiStatus?.isLoading} />
		</Dialog>
	);
};

export default SiteDetailDialog;