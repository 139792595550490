import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import Dimens from '../../../theme/dimens';
import React from 'react';
import useStyles from './styles';

export interface CarouselProps {
  title: string;
  desc: string;
  image: object;
}

/**
 * CarouselItem Component
 *
 * This component renders a single item displayed within the Carousel component.
 * It receives props containing the title, description, and image styles for the item.
 * The component uses media queries to adjust text size based on screen size.
 *
 * @props { CarouselProps } - The component properties.
 * @property { string } title - The title text to be displayed.
 * @property { string } desc - The description text to be displayed.
 * @property { object } image - The styles object for the image element.
 *
 * @returns {JSX.Element} - The rendered JSX element for the carousel item.
 */
const CarouselItem: React.FC<CarouselProps> = (props: CarouselProps) => {

  const styles = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={styles.content}>
      <Box sx={styles.imageContainer}>
        <Box component='img' sx={{ ...props.image, ...styles.image }} />
      </Box>
      <Stack sx={styles.contentTextWrapper}>
        <Typography variant={isDesktop ? 'display2' : 'h2'} fontWeight={Dimens.fontWeight.bold}>{props.title}</Typography>
        <Typography variant={isDesktop ? 'p2' : 'p1'}>{props.desc}</Typography>
      </Stack>
    </Box>
  );
};

export default CarouselItem;
