import Dimens from '../../../../theme/dimens';
import { Opacity } from '@mui/icons-material';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles Hook for AttachmentLayout Styles
 * 
 * This hook defines the styles used by the AttachmentLayout component.
 */
const useStyles = (isEditMode = false) => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { sm, lg, section } = Dimens.spacing;

  const styles = {
    attachmentLayout: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 1200,
      bgcolor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '& #react-doc-viewer': {
        overflowY: 'auto',
        background: theme.palette.background.default,
        bgcolor: theme.palette.background.default,
        '& #proxy-renderer': {
          display: 'flex',
          flex: 1
        },
        '& #pdf-controls': {
          zIndex: 3,
          bgcolor: 'transparent',
          '& #pdf-pagination-info': {
            ...theme.typography.p1,
            color: theme.palette.text.primary
          },
          '& #pdf-download': {
            display: 'none'
          }
        }
      }
    },
    viewheader: {
      ...commonStyles.borderBottom,
      p: section,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& button': {
        p: 0,
        'svg': {
          ...commonStyles.smIcon
        }
      },
      '& h3': {
        ...commonStyles.textEllipsis(1),
        textAlign: 'center',
        flex: 1
      }
    },
    editHeader: {
      p: section,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .MuiIconButton-root': {
        p: 0
      },
      '& h3': {
        ...commonStyles.textEllipsis(1),
        textAlign: 'center',
        flex: 1
      }
    },
    titleLayout: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '& p': {
        ...commonStyles.textEllipsis(1)
      },
      '& span': {
        ...commonStyles.textEllipsis(1),
        color: theme.palette.text.secondary
      }
    },
    videoView: {
      maxWidth: '100%',
      objectFit: 'contain',
      width: 'auto',
      height: 'auto',
      overflow: 'auto',
      flex: 1,
      bgcolor: theme.palette.background.default
    },
    imageView: {
      height: 'auto',
      width: 'auto',
      objectFit: 'contain',
      maxHeight: '100%',
      overflow: 'auto',
      flex: 1,
      p: sm,
      bgcolor: theme.palette.background.default
    },
    imageEditorLayout: {
      display: 'flex',
      flex: 1,
      '& .tui-image-editor-header-logo': {
        display: 'none'
      },
      '& .tui-image-editor-header-buttons': {
        display: 'none'
      },
      '& .tui-image-editor-help-menu': {
        width: '200px !important',
        '.tui-image-editor-item': {
          display: 'none'
        },
        '& .tie-btn-undo': {
          display: 'inline-block !important'
        },
        '& .tie-btn-redo': {
          display: 'inline-block !important'
        },
        '& .tie-btn-reset': {
          display: 'inline-block !important'
        }
      },
      '& .tui-image-editor-controls': {
        bgcolor: theme.palette.background.paper
      },
      '& .tui-image-editor-submenu-style': {
        // opacity: '0.5 !important'
      }
    }
  };

  return styles;
};

export default useStyles;