import Colors from '../../../../../theme/colors';
import Dimens from '../../../../../theme/dimens';
import ThemeUtil from '../../../../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the VoiceRecorder component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (file?: File) => {

	const { mini, xxs, sm, lg} = Dimens.spacing;
	const theme = useTheme();

	const styles = {
		container: {
			flex: 1
		},
		msgLayout: {
			display: 'flex',
			flexDirection: 'row',
			flex: 1,
			alignItems: 'center',
			width: '100%'
		},
		recorderContainer: {
			display: 'flex',
			flexDirection: 'row',
			flex: 1,
			padding: `${mini}`,
			borderTopLeftRadius: Dimens.radius.md,
			borderBottomLeftRadius: Dimens.radius.md,
			backgroundColor: theme.palette.background.default,
			alignItems: 'center',
			minHeight: Dimens.singleInput.height,
			width: '100%'
		},
		iconBtn: {
			background: theme.palette.background.default,
			p: sm,
			m: 0,
			radius: Dimens.radius.md,
			'& svg': {
				color: theme.palette.text.primary,
				fontSize: Dimens.icon.sm
			}
		},
		iconBox: {
			width: Dimens.singleInput.width,
			minHeight: Dimens.singleInput.height,
			backgroundColor: file? theme.palette.error.main : theme.palette.primary.main,
			color: ThemeUtil.isLightTheme(theme) ? Colors.white : Colors.palette.secondary[600],
			p: sm,
			borderTopRightRadius: Dimens.radius.md,
			borderBottomRightRadius: Dimens.radius.md,
			'& svg': {
				fontSize: Dimens.icon.sm,
			}
		},
		iconContainer:{ 
			display: 'flex', 
			justifyContent: 'center', 
			alignItems: 'center', 
			pt: Dimens.spacing.xs,
			cursor: 'pointer'
		},
		audioPlayer: {
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			ml: xxs,
		},
		timeInfo: {
			textAlign: 'center',
			ml: lg,
			mr: lg 
		},
		slider: {
			display: 'flex',
			flex: 1,
			ml: lg,
			'& .MuiSlider-rail': {
        opacity: 0.5
      }
		}
	};

	return styles;
};

export default useStyles;