import {
  Box,
  Stack,
} from '@mui/material';

import CustomLabel from '../../../../components/CustomLabel';
import { DBoardUserPermission } from '../../../../types/dboard-user-permission';
import { DashboardPermission } from '../../../../utils/constants';
import { FieldProps } from 'formik';
import React, { } from 'react';
import UserPermissionCard from '../UserPermissionCard';
import useStyles from './styles';

/**
 * Interface defining the props for the UserPermissionList component.
 * It combines props from Formik and custom props for labels.
 * @property {string} label - The main label for the component.
 * @property {string} subLabel - A secondary label for the component.
 */
type Props = {
  label?: string;
  subLabel?: string;
} & FieldProps;

/**
 * UserPermissionList component displays a list of user permission cards, allowing management of user permissions.
 * It uses Formik for form management and integrates with UserPermissionCard components.
 * @param {Props} props - The props passed to the component.
 * @returns {JSX.Element} The rendered UserPermissionList component.
 */
const UserPermissionList: React.FC<Props> = (props: Props) => {
  const {
    field: { name, value },
    form: { setFieldValue },
    label,
    subLabel
  } = props;
  const styles = useStyles();

  /**
   * Handles changes in user permissions.
   * Updates the permission of the specified user in the Formik field value.
   * @param {DBoardUserPermission} item - The user permission object.
   * @param {DashboardPermission} permission - The new permission level for the user.
   */
  const onPermissionChange = (item: DBoardUserPermission, permission: DashboardPermission) => {
    const updatedPermissions = value.map((perm: DBoardUserPermission) =>
      perm.id === item.id ? { ...perm, permission: permission } : perm
    );
    setFieldValue(name, updatedPermissions);
  }

  return (
    <Stack sx={styles.wrapper}>
      <CustomLabel label={label} subLabel={subLabel} />
      <Box sx={styles.contentWrapper}>
        {value?.map((item: DBoardUserPermission) => (
          <UserPermissionCard
            key={item.id}
            userPermission={item}
            onPermissionChange={permission => onPermissionChange(item, permission)}
          />
        ))}
      </Box>
    </Stack>
  );
};

export default UserPermissionList;