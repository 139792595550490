import { Box, IconButton, InputAdornment, Typography, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import ChallengeData from '../../types/challenge-data';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import EyeIcon from '../../components/CustomIcons/EyeIcon';
import EyeOffIcon from '../../components/CustomIcons/EyeOffIcon';
import PasswordRequest from '../../types/password-request';
import PwdImageDark from '../../assets/sign_in_password_dark.svg';
import PwdImageLight from '../../assets/sign_in_password_light.svg';
import SignInLayout from '../../layouts/SignInLayout';
import ThemeUtil from '../../theme/theme-util';
import { signInPwdSchema } from '../../utils/validation-schema';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * SignInPasswordScreen Component
 *
 * This component renders the sign-in screen for entering a password.
 * It leverages the SignInLayout component for a consistent layout.
 * 
 * @returns {JSX.Element} - The rendered JSX element for the sign-in password screen.
 */
const SignInPasswordScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const challengeData: ChallengeData = location.state as ChallengeData;
  const signInApi = useSignInApi();
  const initialValues: PasswordRequest = {
    password: ''
  };
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // Handle initial state on mount
  useEffect(() => {
    if (!challengeData) {
      // If the user tries to access this page directly, redirect him to sign-in page.
      navigate(APP_NAV.SIGN_IN);
    }
  }, []);

  /**
   * Handles form submission.
   * 
   * This function submits the password to the sign-in API using the challenge path and password value.
   * 
   * @param {PasswordRequest} values - The object containing the submitted form values (password).
   * @returns {void}
   */
  const onSubmit = (value: PasswordRequest) => {
    signInApi.performPasswordChallenge(challengeData, { ...value });
  }

  /**
   * Handles forgot password click action. Navigate to forgot password screen.
   * 
   * @returns {void}
   */
  const onForgotPwdClick = () => {
    navigate(APP_NAV.FORGOT_PASSWORD, {
      state: {
        loginId: challengeData.loginId
      }
    });
  }

  /**
   * Handles email support click action (currently disabled).
   * 
   * @returns {void}
   */
  const onEmailSupportClick = () => {
    // TODO:
  }

  return (
    <SignInLayout
      error={signInApi.state.apiStatus?.error}
      showProgressBar={signInApi.state.apiStatus?.isLoading}
      headerTitle={t('enterPassword')}
      headerDesc={t('signInPwdDesc')}
      // footerCaption={t('signInPwdFtrCaption')}
      // footerAction={t('emailSupport')}
      image={isLightTheme ? PwdImageLight : PwdImageDark}
      // onFooterClick={onEmailSupportClick}
      showLogo={true}
    >
      <Formik
        validateOnMount
        validationSchema={signInPwdSchema}
        initialValues={initialValues}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid, values }) => (
          <Form>
            <Field
              name='password'
              placeholder={t('password')}
              component={CustomInput}
              hasFooter
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                values.password &&
                <InputAdornment position='end'>
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                    {showPassword ? <EyeOffIcon sx={styles.icon} /> : <EyeIcon sx={styles.icon} />}
                  </IconButton>
                </InputAdornment>
              } />
            <Typography variant={'footer'} sx={styles.footer} onClick={onForgotPwdClick}>{t('forgotPwd')}</Typography>
            <Box sx={styles.spacer} />
            <CustomButton type='submit' title={t('signIn')} color='primary' disabled={!dirty || !isValid} fullWidth />
          </Form>
        )}
      </Formik>
    </SignInLayout>
  );
};

export default SignInPasswordScreen;
