import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PriorityIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3615_12063)'>
          <path d='M11.2046 13.125L9.62305 13.125' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M13.5769 8.6875L9.62305 8.6875' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M15.9491 4.25L9.62305 4.25' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M6.53516 6.08594L4.26159 3.81237L1.98802 6.08594' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M4.26172 13.5625L4.26172 3.81251' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_3615_12063'>
            <rect width='16' height='16' fill='white' transform='translate(0.949219 0.25)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default PriorityIcon;