import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook for DirectorySortFilter styling.
 *
 * @returns {object} - An object containing styled classes for the dialog.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { xl, section, lg } = Dimens.spacing;

  const styles = {
    filterContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    sortFilterHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      py: section,
      mx: section,
      borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      [theme.breakpoints.down('md')]: {
        border: 0,
        mx: xl,
        pt: xl,
        pb: 0
      },
      [theme.breakpoints.down('sm')]: {
        mx: lg,
        pt: lg
      }
    },
    resetBtn: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.dark
      }
    },
    form: {
      overflow: 'hidden',
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    sortFilterContent: {
      ...commonStyles.noScrollBar,
      flex: 1,
      overflowY: 'auto',
      p: section,
      [theme.breakpoints.down('md')]: {
        p: xl
      },
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    },
    divider: {
      my: section,
      borderBottomWidth: Dimens.menuBorderWidth
    },
    applyBtn: {
      m: section,
      [theme.breakpoints.down('md')]: {
        m: xl,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }
    }
  };

  return styles;
};

export default useStyles;