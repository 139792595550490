import { DEACTIVATE_ACCOUNT, UPDATE_ACCOUNT_LOCK_STATUS } from '../../../../data/user/action-types';
import React, { useState } from 'react';

import AlertDialogSmall from '../../../../components/AlertDialogSmall';
import BinIcon from '../../../../components/CustomIcons/BinIcon';
import { Box } from '@mui/material';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomButton from '../../../../components/CustomButton';
import DeactivateIcon from '../../../../components/CustomIcons/DeactivateIcon';
import InlineAlert from '../../../../components/InlineAlert';
import LockedIcon from '../../../../components/CustomIcons/LockedIcon';
import ReloadIcon from '../../../../components/CustomIcons/ReloadIcon';
import UnlockIcon from '../../../../components/CustomIcons/UnlockIcon';
import { User } from '../../../../types/user';
import { UserProfileStatus } from '../../../../utils/constants';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../../data/user/api';

interface Props {
  user: User;
}

/**
 * SettingsTab component for managing user account settings (lock/unlock, deactivate/reactivate, delete).
 *
 * @param {Props} props - Component props containing user details.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const SettingsTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [openDeactivateDialog, setOpenDeactivateDialog] = useState<boolean>(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState<boolean>(false);
  const useApi = useUserApi();
  const apiStatus = useApi.state.user.apiStatus;

  return (
    <Box sx={styles.wrapper}>
      <InlineAlert message={Util.getApiError([UPDATE_ACCOUNT_LOCK_STATUS, DEACTIVATE_ACCOUNT], apiStatus)} />
      <Box sx={styles.contentWrapper}>
        {props.user?.status === UserProfileStatus.Active &&
          <CustomButton
            title={props.user?.locked ? t('unlock') : t('lockAccount')}
            color='secondary'
            startIcon={props.user?.locked ? <UnlockIcon /> : <LockedIcon />}
            fullWidth
          />
        }
        <CustomButton
          title={props.user?.status === UserProfileStatus.Active ? t('deactivateAccount') : t('reactivateAccount')}
          color='secondary'
          destructive={props.user?.status === UserProfileStatus.Active}
          startIcon={props.user?.status === UserProfileStatus.Active ? <DeactivateIcon /> : <ReloadIcon />}
          fullWidth
          onClick={() => setOpenDeactivateDialog(true)}
        />
        {props.user?.status !== UserProfileStatus.Active &&
          <CustomButton
            title={t('deleteAccount')}
            color='secondary'
            startIcon={<BinIcon />}
            destructive
            fullWidth
            onClick={() => setOpenDeleteUserDialog(true)}
          />
        }
      </Box>
      <AlertDialogSmall
        open={openDeactivateDialog}
        title={t('deactivateUser')}
        titleIcon={<DeactivateIcon />}
        message={t('deactivateUserMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeactivateDialog(false)}
        primaryLabel={t('deactivate')}
        onPrimaryAction={() => setOpenDeactivateDialog(false)}
        isDestructive
        onClose={() => setOpenDeactivateDialog(false)}
      />
      <AlertDialogSmall
        open={openDeleteUserDialog}
        title={t('deleteUser')}
        titleIcon={<BinIcon />}
        message={t('deleteUserMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeleteUserDialog(false)}
        primaryLabel={t('delete')}
        onPrimaryAction={() => setOpenDeleteUserDialog(false)}
        isDestructive
        onClose={() => setOpenDeleteUserDialog(false)}
      />
      <CircularProgressBar show={Util.isApiLoading([UPDATE_ACCOUNT_LOCK_STATUS, DEACTIVATE_ACCOUNT], apiStatus)} />
    </Box>
  );
};

export default SettingsTab;