import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the WebCamMediaCapture component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (isCapturing = false) => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();

	const styles = {
		container: {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			zIndex: 1300,
			bgcolor: theme.palette.background.default,
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			flexDirection: 'column'
		},
		footerLayout: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			bgcolor: Colors.semiTransparent,
			flex: 1,
			width: '100%',
			pb: Dimens.spacing.lg
		},
		mediaTypeLayout: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			flex: 1,
			color: theme.palette.text.primary,
			'& p': {
				p: Dimens.spacing.sm,
				textTransform: 'uppercase',
				fontWeight: Dimens.fontWeight.semiBold,
				cursor: 'pointer'
			}
		},
		recordBtn: {
			width: isCapturing ? Dimens.icon.md : Dimens.icon.lg,
			height: isCapturing ? Dimens.icon.md : Dimens.icon.lg,
			borderRadius: isCapturing ? Dimens.radius.md : Dimens.radius.xxl,
			bgcolor: theme.palette.error.main
		},
		closeBtn: {
			position: 'absolute',
			zIndex: 1,
			right: 0,
			top: 0,
			'& svg': {
				...commonStyles.smIcon
			}
		}
	};

	return styles;
};

export default useStyles;