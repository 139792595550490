import {
  API_FAILURE,
  ApiFailureAction,
  CHAT_HISTORY_SUCCESS,
  CHAT_SEND_API,
  CHAT_SEND_FAILED,
  CHAT_SEND_SUCCESS,
  ChatHistorySuccessAction,
  ChatSendAPIAction,
  ChatSendFailureAction,
  ChatSendSuccessAction,
  FETCH_CHAT_HISTORY,
  FETCH_INTERACTION_HISTORY,
  FETCH_MESSAGE_INFO,
  FETCH_UNDELIVERED_INTERACTION_HISTORY,
  FetchChatHistoryAPIAction,
  FetchInteractionHistoryAPIAction,
  FetchMessageInfoAction,
  FetchUndeliveredInteractionHistoryAPIAction,
  INTERACTION_HISTORY_SUCCESS,
  InteractionHistorySuccessAction,
  MARK_CHAT_AS_DELIVERED,
  MARK_CHAT_AS_READ,
  MARK_RECEIVED_CHAT_AS_DELIVERED_SUCCESS,
  MARK_RECEIVED_CHAT_AS_READ_SUCCESS,
  MARK_SENT_CHAT_AS_DELIVERED_SUCCESS,
  MARK_SENT_CHAT_AS_READ_SUCCESS,
  MESSAGE_INFO_SUCCESS,
  MarkChatAsDeliveredAction,
  MarkChatAsReadAction,
  MarkReceivedChatAsDeliveredSuccessAction,
  MarkReceivedChatAsReadSuccessAction,
  MarkSentChatAsDeliveredSuccessAction,
  MarkSentChatAsReadSuccessAction,
  MessageInfoSuccessAction,
  RESET_INBOX_STATE,
  ResetInboxStateAction,
  UNDELIVERED_INTERACTION_HISTORY_SUCCESS,
  UPDATE_MESSAGE_ATTACHMENT,
  UPDATE_SESSION_STATUS,
  UPDATE_SESSION_STATUS_SUCCESS,
  UndeliveredInteractionHistorySuccessAction,
  UpdateMessageAttachmentAction,
  UpdateSessionStatusAction,
  UpdateSessionStatusSuccessAction
} from './action-types';

import AppError from '../../exception/app-error';
import ChatHistoryDispatchPayload from '../../types/chat-history-dispatch-payload';
import ConversationData from '../../types/conversation-data';
import MarkChatAsDeliveredReadRequest from '../../types/mark-chat-as-delivered-read-request';
import MarkChatAsDeliveredReadResponse from '../../types/mark-chat-as-delivered-read-response';
import MessageData from '../../types/message-data';
import SessionStatusRequest from '../../types/session-status-request';

/**
 * Action creator to indicate that the chat session status is being updated.
 *
 * @returns {UpdateSessionStatusAction} - The action to update session status.
 */
export const updateSessionStatus = (): UpdateSessionStatusAction => ({
  type: UPDATE_SESSION_STATUS
});

/**
 * fetchInteractionHistoryAPI action creator
 *
 * This function creates a Redux action object to signal the initiation of an API call
 * to fetch interaction history data.
 *
 * @returns {FetchInteractionHistoryAPIAction} Redux action object with type
 *                                            `FETCH_INTERACTION_HISTORY`.
 */
export const fetchInteractionHistoryAPI = (): FetchInteractionHistoryAPIAction => ({
  type: FETCH_INTERACTION_HISTORY
});

/**
 * fetchUndeliveredInteractionHistoryAPI action creator
 *
 * This function creates a Redux action object to signal the initiation of an API call
 * to fetch undelivered interaction history data. It is intended for scenarios where
 * specific data related to undelivered interactions needs to be retrieved from the backend.
 *
 * @returns {FetchUndeliveredInteractionHistoryAPIAction} Redux action object with type
 *                                                        `FETCH_UNDELIVERED_INTERACTION_HISTORY`.
 */
export const fetchUndeliveredInteractionHistoryAPI = (): FetchUndeliveredInteractionHistoryAPIAction => ({
  type: FETCH_UNDELIVERED_INTERACTION_HISTORY
});

/**
 * fetchChatHistoryAPI action creator
 *
 * This function creates a Redux action object to signal the initiation of an API call
 * to fetch chat history data.
 *
 * @returns {FetchChatHistoryAPIAction} Redux action object with type
 *                                            `FETCH_CHAT_HISTORY`.
 */
export const fetchChatHistoryAPI = (): FetchChatHistoryAPIAction => ({
  type: FETCH_CHAT_HISTORY
});

/**
 * chatSendAPI action creator
 *
 * This function creates a Redux action object to signal the initiation of an API call
 * to send a chat message.
 *
 * @returns {ChatSendAPIAction} Redux action object with type `CHAT_SEND_API`.
 */
export const chatSendAPI = (message: MessageData): ChatSendAPIAction => ({
  type: CHAT_SEND_API,
  payload: message
});

/**
 * markChatAsReadAPI action creator
 *
 * This function creates a Redux action object to signal the initiation of an API call
 * to mark unread messages as read.
 *
 * @returns {MarkChatAsReadAction} Redux action object with type `MARK_CHAT_AS_READ`.
 */
export const initMarkChatAsReadApi = (): MarkChatAsReadAction => ({
  type: MARK_CHAT_AS_READ
});

/**
 * markChatAsDeliveredAPI action creator
 *
 * This function creates a Redux action object to signal the initiation of an API call
 * to mark a chat as delivered.
 *
 * @returns {MarkChatAsDeliveredAction} Redux action object with type `MARK_CHAT_AS_DELIVERED`.
 */
export const initMarkChatAsDeliveredApi = (): MarkChatAsDeliveredAction => ({
  type: MARK_CHAT_AS_DELIVERED
});

/**
 * Action creator to initiate fetching message info.
 *
 * @returns {FetchMessageInfoAction} An action object indicating the start of fetching message info.
 */
export const initMessageInfoFetch = (): FetchMessageInfoAction => ({
  type: FETCH_MESSAGE_INFO
});

/**
 * Action creator to indicate successful update of chat session status.
 *
 * @param {SessionStatusRequest} request - The session status request object.
 * @returns {UpdateSessionStatusSuccessAction} - The action to indicate successful update with the request as payload.
 */
export const updateSessionStatusSuccess = (request: SessionStatusRequest): UpdateSessionStatusSuccessAction => ({
  type: UPDATE_SESSION_STATUS_SUCCESS,
  payload: request
});

/**
 * interactionHistorySuccess action creator
 *
 * This function creates a Redux action object to signal the successful retrieval of
 * interaction history data and update the store with the received conversation list.
 *
 * @param conversationList {Array<ConversationData>} Array containing conversation data objects.
 *
 * @returns {InteractionHistorySuccessAction} Redux action object with type
 * `INTERACTION_HISTORY_SUCCESS` and payload containing the conversation list.
 */
export const interactionHistorySuccess = (
  conversationList: Array<ConversationData>
): InteractionHistorySuccessAction => ({
  type: INTERACTION_HISTORY_SUCCESS,
  payload: conversationList
});

/**
 * chatHistorySuccess action creator
 *
 * This function creates a Redux action object of type `CHAT_HISTORY_SUCCESS`
 * signifying the successful retrieval of chat history data. It takes a
 * `chatHistory` object as payload, containing the fetched conversation history.
 *
 * @param {ChatHistoryDispatchPayload} chatHistory - Fetched conversation history data.
 * @returns {ChatHistorySuccessAction} - Redux action object.
 */
export const chatHistorySuccess = (chatHistory: ChatHistoryDispatchPayload): ChatHistorySuccessAction => ({
  type: CHAT_HISTORY_SUCCESS,
  payload: chatHistory
});

/**
 * chatSendSuccess action creator
 *
 * This function creates a Redux action object to signal a successful chat message send.
 *
 * @param messageData {MessageData} Object containing the sent message data.
 *
 * @returns {ChatSendSuccessAction} Redux action object with type `CHAT_SEND_SUCCESS`
 *                                  and payload containing the message data.
 */
export const chatSendSuccess = (messageData: MessageData): ChatSendSuccessAction => ({
  type: CHAT_SEND_SUCCESS,
  payload: messageData
});

/**
 * Action creator to indicate a failed chat message send attempt.
 *
 * @param {MessageData} messageData - The message data that failed to send.
 *
 * @returns {ChatSendFailureAction} An action object containing the failed message data.
 */
export const chatSendFailed = (messageData: MessageData): ChatSendFailureAction => ({
  type: CHAT_SEND_FAILED,
  payload: messageData
});

/**
 * Action creator for marking received chat messages as read successfully.
 * @param {MarkChatAsDeliveredReadRequest} request - The request object containing information about the marked messages.
 * @returns {MarkReceivedChatAsReadSuccessAction} An action object with the success type and payload.
 */
export const markReceivedChatAsReadSuccess = (request: MarkChatAsDeliveredReadRequest): MarkReceivedChatAsReadSuccessAction => ({
  type: MARK_RECEIVED_CHAT_AS_READ_SUCCESS,
  payload: request
});

/**
 * Action creator for marking sent chat messages as read successfully.
 * @param {MarkChatAsDeliveredReadResponse} request - The request object containing information about the marked messages.
 * @returns {MarkSentChatAsReadSuccessAction} An action object with the success type and payload.
 */
export const markSentChatAsReadSuccess = (request: MarkChatAsDeliveredReadResponse): MarkSentChatAsReadSuccessAction => ({
  type: MARK_SENT_CHAT_AS_READ_SUCCESS,
  payload: request
});

/**
 * Action creator for marking received chat messages as delivered successfully.
 * @param {MarkChatAsDeliveredReadRequest} request - The request object containing information about the marked messages.
 * @returns {MarkReceivedChatAsDeliveredSuccessAction} An action object with the success type and payload.
 */
export const markReceivedChatAsDeliveredSuccess = (request: MarkChatAsDeliveredReadRequest): MarkReceivedChatAsDeliveredSuccessAction => ({
  type: MARK_RECEIVED_CHAT_AS_DELIVERED_SUCCESS,
  payload: request
});

/**
 * Action creator for marking sent chat messages as delivered successfully.
 * @param {MarkChatAsDeliveredReadResponse} request - The request object containing information about the marked messages.
 * @returns {MarkSentChatAsDeliveredSuccessAction} An action object with the success type and payload.
 */
export const markSentChatAsDeliveredSuccess = (request: MarkChatAsDeliveredReadResponse): MarkSentChatAsDeliveredSuccessAction => ({
  type: MARK_SENT_CHAT_AS_DELIVERED_SUCCESS,
  payload: request
});

/**
 * Action creator to indicate successful fetching of message info.
 *
 * @param {MessageData} messageData - The fetched message data.
 *
 * @returns {MessageInfoSuccessAction} An action object containing the message data.
 */
export const messageInfoSuccess = (messageData: MessageData): MessageInfoSuccessAction => ({
  type: MESSAGE_INFO_SUCCESS,
  payload: messageData
});

/**
 * Action creator to update the attachment status of a message.
 *
 * @param {MessageData} messageData - The message data with updated attachment information.
 *
 * @returns {UpdateMessageAttachmentAction} An action object containing the updated message data.
 */
export const updateMessageAttachment = (messageData: MessageData): UpdateMessageAttachmentAction => ({
  type: UPDATE_MESSAGE_ATTACHMENT,
  payload: messageData
});

/**
 * Creates an action object to indicate an API call failure with an error message.
 *
 * This function takes an error message as input and returns an action object
 * with the type `API_FAILURE` and the error message in the payload.
 *
 * @param {AppError} error - The error message encountered during the API call.
 * @returns {ApiFailureAction} - The action object for API call failure.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
  type: API_FAILURE,
  payload: error
});

/**
 * resetInboxState action creator
 *
 * This function creates a Redux action object to reset the state of the Inbox section.
 *
 * @returns {ResetInboxStateAction} Redux action object with type `RESET_INBOX_STATE`.
 */
export const resetInboxState = (): ResetInboxStateAction => ({
  type: RESET_INBOX_STATE
});

/**
 * undeliveredInteractionHistorySuccess action creator
 *
 * This function creates a Redux action object to indicate the successful retrieval of
 * undelivered interaction history data.
 *
 * @returns {UndeliveredInteractionHistorySuccessAction} Redux action object with type
 * `UNDELIVERED_INTERACTION_HISTORY_SUCCESS`.
 */
export const undeliveredInteractionHistorySuccess = (): UndeliveredInteractionHistorySuccessAction => ({
  type: UNDELIVERED_INTERACTION_HISTORY_SUCCESS
});
