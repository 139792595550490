import { Box, Dialog, DialogActions, DialogContent, DialogProps, Divider, IconButton, Stack, Typography } from '@mui/material';
import { ContentType, DirectoryRecordType, MAX_FILE_SIZE } from '../../../../utils/constants';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import AlertCircleIcon from '../../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../../components/AlertDialogSmall';
import { CREATE_EMERGENCY_CALL } from '../../../../data/dashboard/action-types';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import CrossIcon from '../../../../components/CustomIcons/CrossIcon';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import CustomLabel from '../../../../components/CustomLabel';
import { DashboardItem } from '../../../../types/dashboard-item';
import { DirectoryRecord } from '../../../../types/directory-record';
import { EmergencyCallRequest } from '../../../../types/emergency-call-request';
import { EmergencyTeamCallRequest } from '../../../../types/emergency-team-call-request';
import InlineAlert from '../../../../components/InlineAlert';
import InviteIcon from '../../../../components/CustomIcons/InviteIcon';
import LoginUtil from '../../../../utils/login-util';
import SearchableUserAndTeamList from './SearchableUserAndTeamList';
import Team from '../../../../types/team';
import Toast from '../../../../components/Toast';
import Util from '../../../../utils/util';
import VoiceRecorder from './VoiceRecorder';
import { emergencyCallRequestSchema } from '../../../../utils/validation-schema';
import { getEmergencyTypeList } from '../../../../utils/ui-constants';
import { useDashboardApi } from '../../../../data/dashboard/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Props interface for SendEmergencyCallDialog component.
 */
interface Props extends DialogProps {
	onClose: () => void;
	item?: DashboardItem;
	team?: Team;
}

/**
 * `SendEmergencyCallDialog` is a dialog component that allows users to send an emergency call.
 * 
 * This component provides:
 * - Form validation using Formik
 * - API interactions for submitting the form
 * - A voice recorder for audio messages
 * - A searchable user and team for selecting members
 * 
 * @param {Props} props - Component properties.
 * @returns {JSX.Element} The rendered component.
 */
const SendEmergencyCallDialog: React.FC<Props> = (props: Props) => {

	const { onClose, item, team, ...rest } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const formikRef = React.createRef<FormikProps<EmergencyCallRequest>>();
	const dashboardApi = useDashboardApi();
	const apiStatus = dashboardApi.state.dashboard.apiStatus;
	const [isAudioActive, setIsAudioActive] = useState(false);
	const [recordedFile, setRecordedFile] = useState<File | undefined>(undefined);
	const [toastMsg, setToastMsg] = useState<string>('');
	const [emergencyCallRequest, setEmergencyCallRequest] = useState<EmergencyCallRequest>({
		itemKey: props.item ? props.item.id : '',
		title: '',
		members: [],
		teams: [],
		callMemberList: []
	});
	/**
	 * Updates the `memberList` state whenever `item` or `team` changes.
	 * - Combines `item.teams` (if available) and `team` into a new list.
	 * - Ensures `team` is included if it exists.
	 * - Resets the dashboard API error on cleanup.
	 */
	useEffect(() => {
		setEmergencyCallRequest(prev => ({
			...prev,
			callMemberList: [
				...((item?.teams as DirectoryRecord[]) ?? []),
				...(team ? [team as DirectoryRecord] : []),
			],
		}));

		return dashboardApi.resetError();
	}, [item, team]);

	/**
	 * `existingTeamList` is an array that combines teams from two sources:
	 * - `item?.teams`: If the `item` object exists and contains a `teams` array, it will be included in the list.
	 * - `team`: If the `team` object is provided, it will be added to the list.
	 * 
	 * @type {Team[]} - A combined list of `Team` objects.
	 */
	const existingTeamList: Team[] = [
		...(item?.teams ?? []),
		...(team ? [team] : []),
	];

	/**
	 * Handles closing the dialog.
	 * If the form is dirty (unsaved changes), show a confirmation dialog.
	 */
	const handleClose = () => {
		if (formikRef.current?.dirty || recordedFile) {
			setShowSaveDialog(true);
		} else {
			onClose();
		}
	};

	/**
	 * Updates the audio activity status.
	 * @param {boolean} isActive - The new audio activity status.
	 */
	const handleAudioActivityChange = (isActive: boolean) => {
		setIsAudioActive(isActive);
	};

	/**
	 * Handles the ready file from the voice recorder.
	 * @param {File} file - The recorded audio file.
	 */
	const handleFileReady = (file: File) => {
		if (file.size < MAX_FILE_SIZE) {
			setRecordedFile(file);
		} else {
			setToastMsg(t('fileSizeExceed'));
		}
	};

	/**
	 * Handles the deletion of the recorded file.
	 */
	const handleFileDeleted = () => {
		setRecordedFile(undefined);
	};

	/**
	 * Handles the form submission for emergency calls. 
	 * Determines whether to send a dashboard card request or a team emergency call request.
	 * 
	 * @param {EmergencyCallRequest} request - The form data containing emergency call details.
	 */
	const onSubmit = (request: EmergencyCallRequest) => {
		item && item.id ? sendDashboardCardRequest(request) : sendTeamEmergencyCallRequest(request);
	};

	/**
	 * Sends an emergency message via identity module.
	 * Populates the request with teams and members from `memberList` before sending.
	 * 
	 * @param {EmergencyCallRequest} request - The emergency call request data.
	 */
	const sendDashboardCardRequest = (request: EmergencyCallRequest) => {
		const { callMemberList, ...rest } = request;
		callMemberList?.forEach(item => {
			if (item.teamKey) {
				request.teams.push(item.teamKey);
			} else if (item.email) {
				request.members.push(item.email)
			}
		});
		dashboardApi.sendEmergencyMessage(rest, recordedFile).then(() => {
			setRecordedFile(undefined);
			onClose();
		});
	};

	/**
	 * Cancels the save action by closing the save dialog and triggering `onClose()`.
	 */
	const handleCancelSaveAction = () => {
		setShowSaveDialog(false);
		onClose();
	}

	/**
	 * Checks if the currently logged-in user exists in the member list.
	 *
	 * This function retrieves the login ID of the current user and checks whether 
	 * they exist in `memberList`. A user is considered part of the list if:
	 * - They are listed as a `teamMember` with a matching `loginId`.
	 * - Their `email` matches the login ID.
	 *
	 * @returns {boolean} - Returns `true` if the user is found in `memberList`, otherwise `false`.
	 */
	const hasLoggedInUser = (memberList: Array<DirectoryRecord>) => {
		const userLogin = LoginUtil.getLoginId();

		return memberList.some(item =>
			(item.teamMembers?.some(member => member.loginId === userLogin)) ||
			(item.email === userLogin)
		);
	};

	/**
	 * Sends an emergency team call request via chat module.
	 * Constructs a `EmergencyTeamCallRequest` and determines recipients from `memberList`.
	 * 
	 * @param {EmergencyCallRequest} request - The emergency call request data.
	 */
	const sendTeamEmergencyCallRequest = (request: EmergencyCallRequest) => {
		const teamCallRequest: EmergencyTeamCallRequest = {
			title: request.title,
			description: request.title,
			senderLoginId: LoginUtil.getLoginId(),
			senderDeviceId: LoginUtil.getClientId(),
			contentType: recordedFile ? ContentType.ContentAudio : ContentType.ContentText,
			recipients: []
		};
		const members = request.callMemberList ?? [];
		members?.forEach(item => {
			if (item.teamKey) {
				teamCallRequest.recipients.push({
					recipientType: DirectoryRecordType.Team,
					key: item.teamKey
				});
			} else if (item.email) {
				teamCallRequest.recipients.push({
					recipientType: DirectoryRecordType.User,
					key: item.email
				});
			}
		});

		dashboardApi.sendTeamEmergencyMessage(teamCallRequest, hasLoggedInUser(members), recordedFile).then(() => {
			setRecordedFile(undefined);
			onClose();
		});
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose} >
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>
					{t('emergencyCall')}
				</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<InlineAlert message={Util.getApiError([CREATE_EMERGENCY_CALL], apiStatus)} />
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validationSchema={emergencyCallRequestSchema}
				initialValues={emergencyCallRequest}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							<Box sx={styles.contentLeft}>
								<Field
									name='title'
									label={t('title')}
									placeholder={t('title')}
									component={CustomInput}
								/>
								<Box sx={styles.voiceBox}>
									<CustomLabel label={t('addVoice')} subLabel={t('optional')} />
									<VoiceRecorder
										onAudioActivityChange={handleAudioActivityChange}
										onFileReady={handleFileReady}
										onFileDeleted={handleFileDeleted}
									/>
								</Box>
							</Box>
							<Divider sx={styles.divider} />
							<Box sx={styles.contentRight}>
								<Field
									name='callMemberList'
									typeList={getEmergencyTypeList()}
									existingTeamList={existingTeamList}
									value={emergencyCallRequest.callMemberList}
									subHeading={t('members')}
									component={SearchableUserAndTeamList}
								/>
							</Box>
						</DialogContent>
						<DialogActions>
							<Box sx={styles.buttonBox}>
								<CustomButton
									sx={styles.saveBtn}
									type='submit'
									title={t('send')}
									color='primary'
									disabled={!dirty || !isValid || isAudioActive || !emergencyCallRequest.callMemberList?.length}
									startIcon={<InviteIcon sx={styles.smIcon} />}
								/>
							</Box>
						</DialogActions>
					</Form>
				)}
			</Formik>
			<CircularProgressBar show={Util.isApiLoading([CREATE_EMERGENCY_CALL], apiStatus)} />
			<Toast open={Boolean(toastMsg)} severity='error' title={toastMsg} onClose={() => setToastMsg('')} />
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={handleCancelSaveAction}
				onClose={() => setShowSaveDialog(false)}
			/>
		</Dialog>
	);
};

export default SendEmergencyCallDialog;