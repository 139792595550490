import { TableCell, TableRow, Typography } from '@mui/material';

import CaretDownIcon from '../../../components/CustomIcons/CaretDownIcon';
import CaretUpIcon from '../../../components/CustomIcons/CaretUpIcon';
import Dimens from '../../../theme/dimens';
import Permission from '../../../types/permission';
import React from 'react';
import useStyles from './styles';

interface Props {
  featureHeader: Permission;
  colSpan: number;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
}

/**
 * Vertical Permission Table Header Component
 * This component renders a header row for the vertical permission table, displaying the feature label and an expandable/collapsible arrow.
 *
 * @param {Props} props Component props containing the feature header and column span information.
 * 
 * @returns {JSX.Element} The JSX element for the table header row.
 */
const VPermissionTableHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles(props.isCollapsed);

  return (
    <TableRow sx={styles.rowHeader}>
      <TableCell sx={styles.titleRow}>
        <Typography variant='p1' fontWeight={Dimens.fontWeight.medium}>{props.featureHeader.label}</Typography>
      </TableCell>
      <TableCell colSpan={props.colSpan} align="right" onClick={props.onToggleCollapse} style={styles.toggleRow}>
        {/* {props.isCollapsed ? <CaretDownIcon /> : <CaretUpIcon />} // CaretUpIcon need from RS */}
         <CaretDownIcon style={{ transform: props.isCollapsed ? 'rotate(0deg)': 'rotate(-180deg)' }} />
      </TableCell>
    </TableRow>
  );
};

export default VPermissionTableHeader;
