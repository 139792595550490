import { Avatar, Box, Stack, Typography } from '@mui/material';

import React, { } from 'react';
import { User } from '../../../types/user';
import UserIcon from '../../../components/CustomIcons/UserIcon';
import { UserProfileStatus } from '../../../utils/constants';
import Util from '../../../utils/util';
import useStyles from './styles';

interface Props {
  user: User;
}

/**
 * Renders a user card component displaying user name, email, and initials.
 *
 * @param {Props} props - Component props containing user details.
 * 
 * @returns {JSX.Element} JSX element representing the user card.
 */
const UserCard: React.FC<Props> = (props: Props) => {

  const { name, email, status, image } = props.user;
  const styles = useStyles();
  const isPendingStatus = (status === UserProfileStatus.Pending);

  return (
    <Box sx={styles.content}>
      {isPendingStatus ? (
        <Box sx={styles.iconWrapper}>
          <UserIcon />
        </Box>
      ) : (
        <Avatar sx={styles.avatar} src={image?.thumbnail}>
          <Typography variant='p3' >
            {Util.getInitialsFromName(name)}
          </Typography>
        </Avatar>
      )}
      <Stack sx={styles.contentText}>
        {!isPendingStatus &&
          <Typography variant='p1'>
            {name}
          </Typography>
        }
        <Typography variant='p1'>
          {email}
        </Typography>
      </Stack>
    </Box>
  );
};

export default UserCard;
