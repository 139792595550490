import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const StarIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_6868_7276)'>
          <path d='M8.67288 12.8828L4.34642 15.1575L5.17288 10.3396L1.66797 6.92797L6.50474 6.22699L8.66797 1.84375L10.8312 6.22699L15.668 6.92797L12.1631 10.3396L12.9895 15.1575L8.67288 12.8828Z' fill='currentColor' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_6868_7276'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.667969 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default StarIcon;