import {
  InputProps,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { MAX_MULTI_LINE_CHAR_COUNT, MULTI_LINE_MAX_ROWS } from '../../utils/constants';

import CustomLabel from '../CustomLabel';
import { FieldProps } from 'formik';
import React, { } from 'react';
import ValidationItem from '../../types/ui/validation-item';
import ValidationItemView from '../ValidationItemView';
import useStyles from './styles';

export type Props = {
  label?: string;
  subLabel?: string;
  maxCharCount?: number;
  hasFooter?: boolean;
  validationList?: Array<ValidationItem>;
  onChangeText?: (value: string) => void;
} & FieldProps & TextFieldProps & InputProps;

/**
 * Reusable custom input component with validation feedback.
 *
 * This component extends the Material UI `TextField` and provides additional functionalities:
 * - Supports optional label, sub-label, and footer section.
 * - Handles form integration using `formik` props (name, onChange, errors, etc.).
 * - Displays validation errors from the form state (if any).
 * - Accepts an optional `validationList` prop to display real-time validation feedback
 *   based on provided validation items (label and isValid flag). These validation items
 *   can be used to indicate if the entered value meets password complexity criteria,
 *   email format, etc.
 * - Uses custom icons for checkboxes and adjusts text styles based on validation status.
 *
 * @props {Props} - Component properties.
 *   - `label` (string, optional): The main label for the input field.
 *   - `subLabel` (string, optional): A secondary label below the main label.
 *   - `hasFooter` (bool, optional): Flag to enable a footer section (default: false).
 *   - `validationList` (ValidationItem[], optional): Array of validation items for feedback.
 *   - `onChangeText` (func, optional): Function to handle additional text change events.
 *   - Rest of the props are spread to the underlying `TextField` and `InputProps`.
 *
 * @returns {JSX.Element} - The rendered custom input component.
 */
const CustomInput: React.FC<Props> = (props: Props) => {

  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched },
    label,
    subLabel,
    maxCharCount,
    hasFooter,
    validationList,
    onChangeText,
    ...inputProps
  } = props;
  const styles = useStyles(props);

  /**
   * Determines if the input field has an error based on form state and multiline configuration.
   * 
   * This function checks for errors in two scenarios:
   *  - For multiline inputs with a character limit: if the current value length exceeds the limit.
   *  - For regular inputs: if there's an error for the field name in the `errors` object from formik 
   *    and the field has been touched (meaning the user interacted with it).
   *
   * @returns {boolean} - True if the input has an error, False otherwise.
   */
  const hasError = () => {
    let isError = false;
    if (props.multiline && maxCharCount) {
      isError = (value ?? '').length > maxCharCount
    } else {
      isError = Boolean(errors[name] && touched[name]);
    }

    return isError;
  }

  /**
   * Retrieves the helper text to be displayed based on input type and validation status.
   * 
   * This function provides different helper text depending on the scenario:
   *  - For multiline inputs: shows the current character count and total limit (e.g., "100/250").
   *  - For regular inputs with validation errors: retrieves the error message from the formik `errors` object.
   * 
   * @returns {string} - The helper text to be displayed, or an empty string.
   */
  const getHelperText = () => {
    let helperText = '';
    if (props.multiline) {
      helperText = `${(value ?? '').length}/${props.maxCharCount ?? MAX_MULTI_LINE_CHAR_COUNT}`;
    } else {
      if (!validationList && hasError() && errors[name]) {
        helperText = errors[name]?.toString() ?? '';
      }
    }

    return helperText;
  }

  /**
   * Handles text change events for the input field.
   * 
   * This function updates the form state with the new value and also triggers
   * an optional `onChangeText` function if provided.
   *
   * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event - The change event object.
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(name)(event.target.value);
    onChangeText && onChangeText(event.target.value);
  }

  return (
    <Stack sx={{ ...styles.wrapper, ...(hasFooter && styles.noPad) }}>
      <CustomLabel label={label} subLabel={subLabel} isReadOnly={inputProps.readOnly} />
      <TextField
        autoComplete='off'
        maxRows={props.maxRows ?? MULTI_LINE_MAX_ROWS}
        error={hasError()}
        helperText={props.readOnly ? '' : getHelperText()}
        sx={styles.textField}
        InputProps={{
          ...inputProps,
          value: value || '',
          onChange: handleChange,
          onBlur: () => {
            setFieldTouched(name);
            onBlur(name);
          },
          sx: styles.outerInput
        }}
        inputProps={{ sx: styles.innerInput }}
        FormHelperTextProps={{
          sx: styles.fieldError
        }}
      />
      {validationList && validationList.map((item, index) => (
        <ValidationItemView key={`${props.label}-${index}`} label={item.label} isValid={item.isValid} />
      ))}
    </Stack>
  );
};

export default CustomInput;
