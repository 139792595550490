import {
  Drawer,
  List,
  Typography
} from '@mui/material';

import ChildDrawerItem from './ChildDrawerItem';
import DrawerItemData from '../../../../../types/ui/drawer-item-data';
import React from 'react';
import SubDrawerItemData from '../../../../../types/ui/sub-drawer-item-data';
import { useLocation } from 'react-router-dom';
import useStyles from './styles';

interface Props {
  childDrawerItem: SubDrawerItemData | undefined;
  isChildDrawerOpen: boolean;
  subMenuToggleMap: Map<string, boolean>;
  onSubmenuHeaderClick: (label: string) => void;
  onDrawerItemClick: (item: DrawerItemData) => void;
  onClose: () => void;
}

/**
 * ChildDrawer component
 * 
 * This component renders a child drawer within the parent drawer navigation.
 * It displays a header based on the provided sub-drawer item data 
 * and a list of child drawer items for further navigation.
 * 
 * @param {Props} props - An object containing properties for the child drawer.
 * @returns {JSX.Element} - The JSX element representing the child drawer.
 */
const ChildDrawer: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const location = useLocation();

  /**
   * Function to check if the provided route matches the current path prefix.
   * 
   * @param {string} route - The route path to check.
   * @returns {boolean} - True if the route matches the current path prefix, false otherwise.
   */
  const isActiveRoute = (route: string): boolean => {
    
    return location.pathname.startsWith(route);
  };

  return (
    <Drawer
      variant='temporary'
      anchor='left'
      hideBackdrop
      open={props.isChildDrawerOpen}
      onClose={props.onClose}
      sx={styles.drawer}
    >
      {props.childDrawerItem &&
        <>
          <Typography variant='h3' sx={styles.header}>{props.childDrawerItem.label}</Typography>
          <List>
            {props.childDrawerItem.menuList?.map((item, index) => (
              <ChildDrawerItem
                key={`child_item_${index}`}
                {...item}
                onChildDrawerItemClick={() => props.onDrawerItemClick(item)}
                onChildDrawerSubItemClick={props.onDrawerItemClick}
                subMenuToggleMap={props.subMenuToggleMap}
                onSubmenuHeaderClick={props.onSubmenuHeaderClick}
                isActive={isActiveRoute(item.route)}
              />
            ))}
          </List>
        </>
      }
    </Drawer>
  );
};

export default ChildDrawer;
