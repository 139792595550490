import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BellIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M27.8813 21.5492C27.1947 19.8115 25.945 18.0331 25.3957 16.2547C25.1156 15.4249 24.9178 14.5701 24.8052 13.7024C24.7228 12.8471 24.7091 11.9647 24.6679 11.0959C24.7381 8.66645 23.8406 6.30693 22.1686 4.52516C21.3641 3.72142 20.4058 3.08417 19.3495 2.65063C18.2932 2.21708 17.1601 1.99592 16.0164 2.00006C14.8726 1.99592 13.7395 2.21708 12.6832 2.65063C11.6269 3.08417 10.6686 3.72142 9.86414 4.52516C8.20347 6.31384 7.30776 8.66864 7.36481 11.0959C7.36481 11.9647 7.36481 12.8471 7.22748 13.7024C7.10612 14.571 6.89915 15.4259 6.60951 16.2547C6.06021 18.0331 4.81054 19.8115 4.12391 21.5492C3.8782 22.0608 3.81061 22.6384 3.93165 23.1919C4.04705 23.5241 4.26882 23.8101 4.56335 24.0065C4.94656 24.2165 5.37504 24.3329 5.81302 24.3459C7.18628 24.4409 15.6868 24.4137 16.0026 24.4137C16.3185 24.4137 24.8052 24.4137 26.1922 24.3459C26.6297 24.3294 27.0573 24.2132 27.4419 24.0065C27.7365 23.8132 27.9547 23.5256 28.0599 23.1919C28.1928 22.6403 28.1298 22.0603 27.8813 21.5492Z' stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.9023 24.4004C10.9023 25.8856 11.4398 27.31 12.3964 28.3602C13.353 29.4104 14.6504 30.0004 16.0032 30.0004C17.3561 30.0004 18.6535 29.4104 19.6101 28.3602C20.5667 27.31 21.1041 25.8856 21.1041 24.4004' stroke='currentColor' strokeWidth='3' strokeMiterlimit='10' />
      </svg>
    </SvgIcon>
  );
}

export default BellIcon;