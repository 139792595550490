import { Avatar, Box, Stack, Typography } from '@mui/material';

import CustomSelect from '../../../../components/CustomSelect';
import { DBoardUserPermission } from '../../../../types/dboard-user-permission';
import { DashboardPermission } from '../../../../utils/constants';
import React, { } from 'react';
import Util from '../../../../utils/util';
import { getDashboardAccessMenu } from '../../../../utils/ui-constants';
import useStyles from './styles';

/**
 * Interface defining the props for the UserPermissionCard component.
 * @property {DBoardUserPermission} userPermission - The user permission object to display.
 * @property {function} onPermissionChange - Callback function triggered when the permission selection changes.
 */
interface Props {
  userPermission: DBoardUserPermission;
  onPermissionChange: (permission: DashboardPermission) => void;
}

/**
 * UserPermissionCard component displays a card with user information and a select dropdown to manage their permissions.
 * @param {Props} props - The props passed to the component.
 * @returns {JSX.Element} The rendered UserPermissionCard component.
 */
const UserPermissionCard: React.FC<Props> = (props: Props) => {

  const { userPermission, onPermissionChange } = props;
  const styles = useStyles(userPermission.permission);

  return (
    <Box sx={styles.content}>
      <Avatar sx={styles.avatar} src={userPermission.profile.image?.thumbnail}>
        <Typography variant='p3' >
          {Util.getInitialsFromName(userPermission.profile.name ?? '')}
        </Typography>
      </Avatar>
      <Box sx={styles.contentRight}>
        <Stack sx={styles.contentText}>
          <Typography variant='p1'>
            {userPermission.profile.name}
          </Typography>
          <Typography variant='p1'>
            {userPermission.profile.position}
          </Typography>
        </Stack>
        <Box sx={styles.permissionInfo}>
          <CustomSelect
            showCaretDownIcon
            showCheckedIcon
            menu={getDashboardAccessMenu(userPermission.permission === DashboardPermission.Owner)}
            value={userPermission.permission}
            onChange={(e) => onPermissionChange(e.target.value as DashboardPermission)}
            positionStyle={styles.selectPosition as React.CSSProperties}
            disabled={userPermission.permission === DashboardPermission.Owner}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserPermissionCard;
