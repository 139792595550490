import { DATE_PATTERN_DD_MM_YY, DATE_PATTERN_HH_MM } from '../../../../../utils/constants';

import React, {  } from 'react';
import { Typography } from '@mui/material';
import Util from '../../../../../utils/util';
import useStyles from './styles';

interface Props {
	date?: string;
}

/**
 * Renders a component displaying the formatted date and time of a message.
 *
 * @param {Props} props - Component props containing the optional `date` string.
 * 
 * @returns {JSX.Element} JSX element representing the MessageDateItem component.
 */
const MessageDateItem: React.FC<Props> = (props: Props) => {

	const styles = useStyles();

	return (
		<>
			<Typography variant='caption' sx={styles.date}>
				{Util.formatUTCtoLocal(props.date, DATE_PATTERN_DD_MM_YY)}
			</Typography>
			<Typography variant='caption'>
				{Util.formatUTCtoLocal(props.date, DATE_PATTERN_HH_MM)}
			</Typography>
		</>
	);
};

export default MessageDateItem;