import {
	API_FAILURE,
	ApiFailureAction,
	RESET_ERROR_STATE,
	RESET_SEARCH_STATE,
	ResetErrorStateAction,
	SEARCH_RECORDS,
	SEARCH_RECORDS_SUCCESS,
	SearchAction,
	SearchStateResetAction,
	SearchSuccessAction,
} from './action-types';

import AppError from '../../exception/app-error';
import { DirectoryRecord } from '../../types/directory-record';

/**
 * Creates an action to reset the error state.
 * 
 * @returns {ResetErrorStateAction} The action to be dispatched.
 */
export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR_STATE
});

/**
 * Creates an action to initiate a search.
 * 
 * @returns {SearchAction} The action to be dispatched.
 */
export const initSearch = (): SearchAction => ({
	type: SEARCH_RECORDS
});

/**
 * Creates an action to indicate successful search with retrieved record list.
 * 
 * @param {Array<DirectoryRecord>} recordList The list of directory records.
 * 
 * @returns {SearchSuccessAction} The action to be dispatched.
 */
export const searchSuccess = (recordList: Array<DirectoryRecord>): SearchSuccessAction => ({
	type: SEARCH_RECORDS_SUCCESS,
	payload: recordList
});

/**
 * Creates an action to handle API failure with an error object.
 * 
 * @param {AppError} error The error object.
 * 
 * @returns {ApiFailureAction} The action to be dispatched.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error
});

/**
 * Creates an action to reset the search state.
 * 
 * @returns {SearchStateResetAction} The action to be dispatched.
 */
export const resetSearchState = (): SearchStateResetAction => ({
	type: RESET_SEARCH_STATE
});