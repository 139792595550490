import { Box, Typography } from '@mui/material';
import { DATE_PATTERN_DD_MMM_YYYY, DATE_PATTERN_HH_MM_SS, InteractionType, MessageOptions } from '../../../../utils/constants';

import ChatUtil from '../../../../utils/chat-util';
import Dimens from '../../../../theme/dimens';
import GroupMemberBaseInfo from '../../../../types/group-member-base-info';
import LeftChatItem from './LeftChatItem';
import MessageData from '../../../../types/message-data';
import React from 'react';
import RightChatItem from './RightChatItem';
import { format } from 'date-fns';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  nextMessageData?: MessageData;
  messageData: MessageData;
  isCurrentUser: boolean;
  interactionType: InteractionType;
  senderInfo: GroupMemberBaseInfo;
  disableMessageClick?: boolean;
  handleMsgActionClick: (action: MessageOptions) => void;
  onAttachmentClick: () => void;
}

/**
 * ChatItem Component
 * 
 * This component renders a single chat message item, handling both 
 * left-aligned messages (from other users) and right-aligned messages 
 * (from the current user). It displays the message content, a date header 
 * when the message date differs from the previous message, and utilizes 
 * props to determine alignment and message details.
 * 
 * The component leverages the following:
 *   - `ChatUtil.getDateHeader` to format the date header based on relative position.
 *   - `RightChatItem` and `LeftChatItem` components for specific message rendering.
 */
const ChatItem: React.FC<Props> = (props: Props) => {

  const { nextMessageData, messageData, isCurrentUser, interactionType } = props;
  const styles = useStyles();
  const { t } = useTranslation();

  /**
   * getDateHeader function
   * 
   * This function determines if a date header needs to be displayed 
   * for the current message. It checks the presence of a message creation 
   * date and compares it to the previous message's date (if available). 
   * If the dates differ, it calls `ChatUtil.getDateHeader` to format the 
   * current message's date for the header.
   * 
   * @returns {string} The formatted date header or an empty string.
   */
  const getDateHeader = (): string => {
    let dateHeader = '';
    if (messageData && messageData.createdAt) {
      if (nextMessageData && nextMessageData.createdAt) {
        if (format(nextMessageData.createdAt,
          DATE_PATTERN_DD_MMM_YYYY) !== format(messageData.createdAt, DATE_PATTERN_DD_MMM_YYYY)) {
          dateHeader = ChatUtil.getDateHeader(messageData.createdAt);
        }
      } else {
        dateHeader = ChatUtil.getDateHeader(messageData.createdAt);
      }
      if (interactionType === InteractionType.EmergencyGroup) {
        dateHeader = messageData.senderName;
      }
    }

    return dateHeader;
  }

  /**
   * Gets the header label for a message in an emergency group chat.
   * The label includes the date and time of the message.
   * @returns {string} The formatted header label or an empty string if not applicable.
   */
  const getHeaderLabel = () => {
    let label = '';
    const dateHeader = getDateHeader();
    if (dateHeader && messageData.createdAt && interactionType === InteractionType.EmergencyGroup) {
      label = t('emergencyChatItemHeader', { 
        date: ChatUtil.getDateHeader(messageData.createdAt).toLowerCase(),
        time: format(messageData.createdAt, DATE_PATTERN_HH_MM_SS)
      });
    }

    return label;
  }

  const dateHeader = getDateHeader();

  const dateHeaderLabel = getHeaderLabel();

  return (
    <Box>
      {dateHeader &&
        <Box sx={styles.dateHeaderWrapper}>
          {dateHeaderLabel &&
            <Typography variant='caption' sx={styles.dateHeaderLabel}>
              {dateHeaderLabel}
            </Typography>
          }
          <Typography variant='caption' fontWeight={Dimens.fontWeight.medium}>{dateHeader}</Typography>
        </Box>
      }
      {isCurrentUser ? <RightChatItem {...props} /> : <LeftChatItem {...props} />}
    </Box>
  );
};

export default ChatItem;
