import { Box, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import InlineAlert from '../../../../components/InlineAlert';
import LoginUtil from '../../../../utils/login-util';
import Team from '../../../../types/team';
import { UPDATE_TEAM } from '../../../../data/team/action-types';
import Util from '../../../../utils/util';
import {teamSchema} from '../../../../utils/validation-schema';
import useStyles from './styles';
import { useTeamApi } from '../../../../data/team/api';
import { useTranslation } from 'react-i18next';

interface Props {
  team: Team;
  onChange?:(isChanged: boolean) => void;
}

/**
 * DetailsTab component for managing team details (name).
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const DetailsTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [team, setTeam] = useState<Team>(props.team);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const teamApi = useTeamApi();
  const apiStatus = teamApi.state.team.apiStatus;
  
  /**
   * Handles form submission for updating team details.
   *
   * @param {team} Team - The updated team data from the form.
   */
  const onSubmit = (team: Team) => {
    team.updatedByLoginId = LoginUtil.getLoginId();
    teamApi.updateTeam(team).then((data) => {
      if (data) {
        setTeam(team);
        setIsChanged(false);
      }
    });
  }

    /**
     * Handles form change detection.
     *
     * @param {boolean} dirty - Indicates whether the form has unsaved changes.
     */
    const handleFormChange = (dirty: boolean) => {
      setIsChanged(dirty);
      props.onChange?.(dirty);
    };

  return (
    <Grid sx={styles.wrapper}>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={teamSchema}
        initialValues={team}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid }) => {
          handleFormChange(dirty);
          return(
            <Form style={styles.form as React.CSSProperties}>
              <Box>
                <InlineAlert message={Util.getApiError([UPDATE_TEAM], apiStatus)} />
                <Field
                  name='teamName'
                  label={t('name')}
                  placeholder={t('name')}
                  component={CustomInput}
                />
              </Box>
              <CustomButton
                type='submit'
                title={t('save')}
                color='primary'
                disabled={!dirty || !isValid}
                fullWidth
              />
            </Form>
          );
        }}
      </Formik>
      <CircularProgressBar show={Util.isApiLoading([UPDATE_TEAM], apiStatus)} />
    </Grid>
  );
};

export default DetailsTab;