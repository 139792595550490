import * as yup from 'yup';

import {
  MAX_MULTI_LINE_CHAR_COUNT,
  MAX_SINGLE_LINE_CHAR_COUNT,
  REGEX_PATTERN_DIGITS,
  REGEX_PATTERN_EMAIL_DOMAIN,
  REGEX_PATTERN_NO_NUMBERS,
  REGEX_PATTERN_PHONE_NUMBER
} from './constants';

import { t } from 'i18next';

/**
 * Validation schema definitions for all forms used in the application.
 * 
 * The schemas utilize Yup validation methods and leverage i18next for localized error messages.
 */

// SignInEmailScreen Validation
export const signInEmailSchema = yup.object().shape({
  loginId: yup.string().trim()
    .required(t('fieldRequired', { field: t('email') }))
    .email(t('fieldInvalid', { field: t('email') }))
});

// SignInPasswordScreen Validation
export const signInPwdSchema = yup.object().shape({
  password: yup.string().trim()
    .required(t('fieldRequired', { field: t('password') }))
});

// RegisterPasswordScreen Validation
export const registeredPwdSchema = yup.object().shape({
  newPassword: yup.string().trim()
    .required(t('pwdRequired'))
});

// RegisterDepartmentScreen Validation
export const registerDepartmentSchema = yup.object().shape({
  department: yup.object()
    .required(t('fieldRequired', { field: t('department') }))
});

// RegisterNewDepartmentScreen Validation
export const registerNewDeptSchema = yup.object().shape({
  name: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('name')
    }))
    .required(t('fieldRequired', { field: t('name') })),
  description: yup.string().trim()
    .max(MAX_MULTI_LINE_CHAR_COUNT)
});

// RegisterProfileScreen Validation
export const registerProfileSchema = yup.object().shape({
  firstName: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('firstName')
    }))
    .matches(REGEX_PATTERN_NO_NUMBERS, t('numberNotAllowed'))
    .required(t('fieldRequired', { field: t('firstName') })),
  lastName: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('lastName')
    }))
    .matches(REGEX_PATTERN_NO_NUMBERS, t('numberNotAllowed'))
    .required(t('fieldRequired', { field: t('lastName') })),
  position: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('position')
    }))
    .matches(REGEX_PATTERN_NO_NUMBERS, t('numberNotAllowed'))
    .required(t('fieldRequired', { field: t('position') })),
  about: yup.string().trim()
    .max(MAX_MULTI_LINE_CHAR_COUNT)
});

// SiteDetailDialog Validation
export const siteSchema = yup.object().shape({
  name: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('name')
    }))
    .required(t('fieldRequired', { field: t('name') })),
  address: yup.string().trim()
    .max(MAX_MULTI_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_MULTI_LINE_CHAR_COUNT,
      field: t('address')
    })),
  phoneNumber: yup.string().trim()
    .matches(REGEX_PATTERN_PHONE_NUMBER, t('invalidField', {
      field: t('telephoneNumber')
    })),
  departments: yup.array().of(yup.object()).optional(),
});

export const groupSchema = yup.object().shape({
  name: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('name')
    }))
    .required(t('fieldRequired', { field: t('name') })),
  description: yup.string().trim()
    .max(MAX_MULTI_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_MULTI_LINE_CHAR_COUNT,
      field: t('description')
    }))
});

export const userSchema = yup.object().shape({
  name: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('name')
    }))
    .matches(REGEX_PATTERN_NO_NUMBERS, t('numberNotAllowed'))
    .required(t('fieldRequired', { field: t('name') })),
  email: yup.string().trim()
    .required(t('fieldRequired', { field: t('email') }))
    .email(t('fieldInvalid', { field: t('email') })),
  position: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('position')
    }))
    .matches(REGEX_PATTERN_NO_NUMBERS, t('numberNotAllowed'))
    .required(t('fieldRequired', { field: t('position') })),
  about: yup.string().trim()
    .max(MAX_MULTI_LINE_CHAR_COUNT),
  type: yup.string().trim()
    .required(t('fieldRequired', { field: t('userType') })),
  department: yup.array().of(yup.object())
    .min(1, t('fieldRequired', { field: t('department') })),
  userCode: yup.string().trim()
    .test('len', t('digitValidation', { value: 5 }),
      val => (parseInt(val ?? '0') >= 10000 && parseInt(val ?? '0') <= 99999)
    ).required(t('fieldRequired', { field: t('userId') })),
});

export const deptSchema = yup.object().shape({
  name: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('name')
    }))
    .required(t('fieldRequired', { field: t('name') })),
  description: yup.string().trim()
    .max(MAX_MULTI_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_MULTI_LINE_CHAR_COUNT,
      field: t('description')
    })),
  sites: yup.array().of(yup.object()).optional()
});

export const domainSchema = yup.object().shape({
  domain: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('domain')
    }))
    .required(t('fieldRequired', { field: t('domain') }))
    .matches(REGEX_PATTERN_EMAIL_DOMAIN, t('invalidField', {
      field: t('domain')
    }))
});

export const pagerSchema = yup.object().shape({
  name: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('name')
    }))
    .required(t('fieldRequired', { field: t('name') })),
  destination: yup.string()
    .required(t('fieldRequired', { field: t('identifier') }))
    .matches(REGEX_PATTERN_DIGITS, t('onlyNumbersAllowed')),
  pagerType: yup.object()
    .required(t('fieldRequired', { field: t('type') })),
});

export const userTypeSchema = (userTypeList: Array<string>) => yup.object().shape({
  userType: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('name')
    }))
    .required(t('fieldRequired', { field: t('name') }))
    .test('unique-user-type', t('nameAlreadyExist'), (value) => !userTypeList.includes(value.toLowerCase()))
});

export const callLogSchema = yup.object().shape({
  startDate: yup.string()
    .trim()
    .required(t('fieldRequired', { field: t('startDate') })),
  endDate: yup.string()
    .trim()
    .required(t('fieldRequired', { field: t('endDate') }))
});

export const removeDeptUserSchema = yup.object().shape({
  department: yup.object()
    .required(t('fieldRequired', { field: t('department') }))
});

export const teamSchema = yup.object().shape({
  teamName: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('name')
    }))
    .required(t('fieldRequired', { field: t('name') }))
});

// Register NewDashboardTab Schema Validation
export const dashboardSchema = yup.object().shape({
  name: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('name')
    }))
    .required(t('fieldRequired', { field: t('name') }))
});

// Emergency Call Request Schema Validation
export const emergencyCallRequestSchema = yup.object().shape({
  title: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('title')
    }))
    .required(t('fieldRequired', { field: t('title') })),
  callMemberList: yup.array().min(1, t('fieldRequired', { field: t('members') })),
});

// Register NewDashboardTab Schema Validation
export const emergemcyCallItemSchema = yup.object().shape({
  title: yup.string().trim()
    .max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
      count: MAX_SINGLE_LINE_CHAR_COUNT,
      field: t('title')
    }))
    .required(t('fieldRequired', { field: t('title') })),
  icon: yup.string().required(t('fieldRequired', { field: t('name') })),
  teams: yup.array().min(1, t('fieldRequired', { field: t('members') })),
});