import {
	API_FAILURE,
	CHALLENGE_SUCCESS,
	INIT_API,
	SIGN_IN_SUCCESS,
	SIGN_OUT_SUCCESS,
	SignInActionTypes
} from './action-types';

import SignInState from '../../types/states/sign-in-state';

export const signInInitialState: SignInState = {
	isSignedIn: false,
	challengeList: [],
	loginInfo: undefined,
	apiStatus: undefined
};

/**
 * Sign-in reducer for Sign-in state management.
 * 
 * Manages sign-in state (logged in, challenges, info, API status).
 * Handles actions (init, challenges, success, failure) to update state.
 */
const signInReducer = (state: SignInState, action: SignInActionTypes): SignInState => {
  switch (action.type) {
		case INIT_API:
			return {
				...signInInitialState,
				apiStatus: {
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
		case CHALLENGE_SUCCESS:
			return {
				...state,
				challengeList: action.payload,
				apiStatus: {
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case SIGN_IN_SUCCESS:
			return {
				...state,
				isSignedIn: true,
				loginInfo: action.payload,
				apiStatus: {
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case SIGN_OUT_SUCCESS:
			return {
				...state,
				isSignedIn: false,
				apiStatus: {
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					isLoading: false,
					data: action.payload,
					error: action.payload.message,
					isCompleted: true
				}
			};
		default:
			return state;
  }
};

export default signInReducer;