import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Defines the styles for the PermissionScreen component.
 *
 * This hook provides styles for various elements within the component, including the container, content, table container, table headers, and table cells.
 * The styles are based on the Material UI theme and utilize common styles defined in `useCommonStyles`.
 *
 * @returns {Object} The styles object.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { lg, section } = Dimens.spacing;

  const styles = {
    container: {
      bgcolor: theme.palette.background.paper,
      height: 'inherit',
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    content: {
      ...commonStyles.noScrollBar,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      p: section,
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    },
    tableContainer: {
      flex: 1,
      backgroundImage: 'none',
      border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderRadius: Dimens.radius.md,
      boxShadow: 'none',
      '& th': {
        bgcolor: theme.palette.background.paper,
        borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        zIndex: 1,
        p: lg,
        borderLeft: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        textAlign: 'center',
        '&:first-of-type': {
          borderLeft: 0,
          minWidth: Dimens.tableHeaderWidth
        }
      },
      '& tbody': {
        '& tr': {
          '& td': {
            textAlign: 'center',
            borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
            p: lg,
            borderLeft: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
            '&:first-of-type': {
              textAlign: 'left',
              borderLeft: 0
            }
          }
        }
      }
    }
  };

  return styles;
};

export default useStyles;