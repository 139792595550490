import {
  Avatar,
  Badge,
  BadgeOrigin,
  Divider,
  ListItemButton,
  Typography
} from '@mui/material';

import { AssignedSessionStatus } from '../../../../../../utils/constants';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoginUtil from '../../../../../../utils/login-util';
import React from 'react';
import Util from '../../../../../../utils/util';
import useStyles from './styles';

/**
 * Interface defining the props accepted by the DrawerItem component.
 */
export interface DrawerItemProps {
  /**
   * Text label to be displayed for the item.
   */
  label?: string;
  /**
   * JSX element representing the icon to be displayed for the item.
   */
  icon?: JSX.Element;
  /**
   * Flag indicating if the item should have a destructive appearance (typically used for negative actions).
   * @default false
   */
  isDestructive?: boolean;
  /**
   * Flag indicating if the item is currently active.
   * @default false
   */
  isActive?: boolean;
  /**
   * Flag indicating if the item should display an avatar instead of an icon.
   * @default false
   */
  isAvatar?: boolean;
  /**
   * URL of the image to be used for the avatar.
   */
  avatarUrl?: string;
  /**
   * Flag indicating if the item should display a badge.
   * @default false
   */
  hasBadge?: boolean;
  /**
   * Flag indicating if the item should be hidden.
   * @default false (item is visible).
   */
  hide?: boolean;
  /**
   * (Optional) Route associated with the drawer item. 
   * This prop can be used for navigation purposes when the item is clicked.
   */
  route?: string;
  /**
   * Function to be called when the item is clicked.
   */
  onClick: () => void;
}

/**
 * ParentDrawerItem Component
 * 
 * This component renders a single item within the parent drawer.
 * It accepts various props to customize its appearance and behavior,
 * including label, icon, avatar, badge, and active state.
 * 
 * @param {DrawerItemProps} props - An object containing properties for the drawer item.
 * @returns {JSX.Element} - The JSX element representing the drawer item.
 */
const ParentDrawerItem: React.FC<DrawerItemProps> = (props: DrawerItemProps) => {

  const userStatus: AssignedSessionStatus = LoginUtil.getUserStatus();
  const styles = useStyles(props, userStatus);

  return (
    <ListItemButton sx={styles.listBtn} onClick={props.onClick}>
      {props.isAvatar ? (
        <Badge
          variant='dot'
          overlap='circular'
          sx={styles.avatarBadge}
          anchorOrigin={styles.avatarBadgePos as BadgeOrigin}
        >
          <Avatar sx={styles.avatar} src={props.avatarUrl}>
            {props.label &&
              <Typography variant='p3' >
                {Util.getInitialsFromName(props.label)}
              </Typography>
            }
          </Avatar>
          <Divider sx={styles.divider} />
        </Badge>
      ) : (
        <ListItemIcon sx={styles.listIcon}>
          <Badge
            variant='dot'
            overlap='circular'
            sx={styles.iconBadge}
            anchorOrigin={styles.iconBadgePos as BadgeOrigin}
          >
            {props.icon}
          </Badge>
        </ListItemIcon>
      )}
    </ListItemButton>
  );
};

export default ParentDrawerItem;
