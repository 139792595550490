import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DepartmentScreen component.
 * 
 * @returns {object} - An object containing styles for the DepartmentScreen component.
 */
const useStyles = () => {

  const theme = useTheme();
  const { xxs, xs, sm, md, lg, section } = Dimens.spacing;

  const styles = {
    container: {
      bgcolor: theme.palette.background.paper,
      height: 'inherit',
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    contentWrapper: {
      overflowY: 'auto',
      m: md,
      [theme.breakpoints.down('md')]: {
        m: sm
      },
      [theme.breakpoints.down('sm')]: {
        m: xs, 
        mr:xxs
      }
    },
    content: {
      m: 0
    },
    alertWrapper: {
      p: `${lg} ${section} 0 ${section}`
    }
  };

  return styles;
};

export default useStyles;