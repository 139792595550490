import * as yup from 'yup';

import { Box, Dialog, DialogActions, DialogContent, DialogProps, Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import { MAX_SINGLE_LINE_CHAR_COUNT, REGEX_PATTERN_DIGITS } from '../../../utils/constants';
import React, { useEffect, useState } from 'react';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import { CREATE_PAGER } from '../../../data/device/action-types';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import InlineAlert from '../../../components/InlineAlert';
import { Pager } from '../../../types/pager';
import { PagerType } from '../../../types/pager-type';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import SearchableUserList from '../../DirectoryScreen/SearchableUserList';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import { pagerSchema } from '../../../utils/validation-schema';
import { useDeviceApi } from '../../../data/device/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface for props passed to the CreatePagerDialog component.
 */
interface Props extends DialogProps {
	onClose: () => void;
}

/**
 * React component representing a dialog for creating a new pager.
 *
 * @param {Props} props Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CreatePagerDialog: React.FC<Props> = (props: Props) => {

	const { onClose, ...rest } = props;
	const [showDetailForm, setShowDetailForm] = useState<boolean>(true);
	const styles = useStyles(showDetailForm);
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const { t } = useTranslation();
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const pagerInitialValue: Pager = {
		name: '',
		pagerType: undefined,
		destination: ''
	};
	const [pagerMember, setPagerMember] = useState<User>();
	const formikRef = React.createRef<FormikProps<Pager>>();
	const [validationSchema, setValidationSchema] = useState(pagerSchema);
	const deviceApi = useDeviceApi();
	const apiStatus = deviceApi.state.device.apiStatus;

	/**
   * Handles form validation on schema change.
   */
	useEffect(() => {
		formikRef.current?.validateForm();
	}, [validationSchema]);

	/**
   * Updates the validation schema based on the selected pager type.
   *
   * @param {React.SyntheticEvent<Element, Event>} e - The change event.
   * @param {PagerType} pagerType - The selected pager type.
   */
	const handleChange = (e: React.SyntheticEvent<Element, Event>, pagerType: PagerType) => {
		if (pagerType) {
			const schema = yup.object().shape({
				name: yup.string().trim()
					.max(MAX_SINGLE_LINE_CHAR_COUNT, t('charCountExceed', {
						count: MAX_SINGLE_LINE_CHAR_COUNT,
						field: t('name')
					}))
					.required(t('fieldRequired', { field: t('name') })),
				destination: yup.string()
					.required(t('fieldRequired', { field: t('identifier') }))
					.matches(REGEX_PATTERN_DIGITS, t('onlyNumbersAllowed'))
					.test('len', t('digitValidation', { value: pagerType.digits }),
						val => val?.toString().length === pagerType.digits
					),
				pagerType: yup.object()
					.required(t('fieldRequired', { field: t('type') })),
			});
			setValidationSchema(schema);
		}
	}

	/**
   * Handles changes in the assigned user.
   *
   * @param {Array<User>} userList - The list of assigned users.
   */
	const onAssignedUserChange = (userList: Array<User>) => {
		const assignedMember = userList.length > 1 ? userList[1] : userList[0];
		setPagerMember(assignedMember);
	}

	/**
	 * Handles closing the dialog, with confirmation if the form is dirty.
	 */
	const handleClose = () => {
		if (formikRef.current?.dirty) {
			setShowSaveDialog(true);
		} else {
			onClose();
		}
	};

	/**
	 * Handles form submission.
	 *
	 * @param {Pager} pager Form values representing pager data.
	 */
	const onSubmit = (pager: Pager) => {
		pager.assignedTo = pagerMember;
		deviceApi.createPager(pager).then((pager?: Pager) => {
			if (pager) {
				onClose();
			}
		});
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>
					{(isDesktop || showDetailForm) ? t('newDevice') : t('assignTo')}
				</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<InlineAlert message={Util.getApiError([CREATE_PAGER], apiStatus)} />
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validateOnChange
				validationSchema={validationSchema}
				initialValues={pagerInitialValue}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							<Box sx={styles.contentLeft}>
								<Field
									name='name'
									label={t('name')}
									placeholder={t('name')}
									component={CustomInput}
								/>
								<Field
									name='pagerType'
									label={t('type')}
									placeholder={t('select')}
									component={CustomAutocomplete}
									noOptionsText={t('noPagerTypes')}
									menu={deviceApi.state.device.pagerTypeList}
									showDropdownIcon
									onChange={handleChange}
								/>
								<Field
									name='destination'
									label={t('identifier')}
									placeholder={t('ricNumber')}
									component={CustomInput}
								/>
							</Box>
							<Divider sx={styles.divider} />
							<Box sx={styles.contentRight}>
								<SearchableUserList
									userList={pagerMember ? [pagerMember] : []}
									onUserListChange={onAssignedUserChange}
									subHeading={t('assignTo')}
									hasPagerRestriction={true}
								/>
							</Box>
						</DialogContent>
						<DialogActions>
							<CustomButton
								sx={styles.nextBtn}
								title={t('next')}
								color='primary'
								disabled={!dirty || !isValid}
								onClick={() => setShowDetailForm(false)}
							/>
							<CustomButton
								sx={styles.saveBtn}
								type='submit'
								title={t('save')}
								color='primary'
								disabled={!dirty || !isValid}
								startIcon={<SaveIcon sx={styles.smIcon} />}
							/>
						</DialogActions>
					</Form>
				)}
			</Formik>
			<CircularProgressBar show={Util.isApiLoading([CREATE_PAGER], apiStatus)} />
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={onClose}
				onClose={() => setShowSaveDialog(false)}
			/>
		</Dialog>
	);
};

export default CreatePagerDialog;