import { AppBar, Avatar, Box, IconButton, Toolbar, Typography, useMediaQuery } from '@mui/material';

import ChevronLeftIcon from '../../../../components/CustomIcons/ChevronLeftIcon';
import EmergencyIcon from '../../../../components/CustomIcons/EmergencyIcon';
import { InteractionType } from '../../../../utils/constants';
import LandlineIcon from '../../../../components/CustomIcons/LandlineIcon';
import PhoneIcon from '../../../../components/CustomIcons/PhoneIcon';
import React from 'react';
import Util from '../../../../utils/util';
import VideoIcon from '../../../../components/CustomIcons/VideoIcon';
import theme from '../../../../theme/theme';
import useStyles from './styles';

/**
 * Interface for ChatHeader component props
 * 
 * @interface Props - The properties expected by the ChatHeader component.
 */
export interface Props {
  /**
   * Name of the contact displayed in the header.
   */
  name: string;
  /**
   * Status of the contact displayed in the header.
   */
  status: string;
  /**
   * Optional profile image URL for the contact.
   */
  image?: string;
  interactionType: InteractionType;
  onHeaderClick: () => void;
  onBackClick?: () => void;
}

/**
 * ChatHeader Component
 * 
 * This component renders the header section of a chat window.
 * It displays the contact's name, status, avatar, and video/phone call buttons.
 */
const ChatHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles(props);
  const isMediumDevice = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  /**
   * Gets the appropriate avatar element based on the interaction type.
   * Returns an icon for emergency or telephony interactions, otherwise returns an avatar with initials.
   * @returns {JSX.Element} The avatar element to display.
   */
  const getAvatar = (): JSX.Element => {
    let element: JSX.Element;
    if (props.interactionType === InteractionType.EmergencyGroup) {
      element = <EmergencyIcon />;
    } else if (props.interactionType === InteractionType.Telephony) {
      element = <LandlineIcon />;
    } else {
      element = (<Typography variant='p3' >
        {Util.getInitialsFromName(props.name, props.interactionType === InteractionType.Group ? 1 : 2)}
      </Typography>);
    }

    return element;
  }

  /**
   * Handles the click event on the header.
   * Calls the `onHeaderClick` prop function only for private or group interactions.
   */
  const onHeaderClick = () => {
    if (props.interactionType === InteractionType.Private || props.interactionType === InteractionType.Group) {
      props.onHeaderClick();
    }
  }

  return (
    <AppBar position='fixed' sx={styles.appBar}>
      <Toolbar>
        {/* Back Button for Small Devices */}
        {isMediumDevice && props.onBackClick && (
          <IconButton onClick={props.onBackClick} sx={styles.backBtn}>
            <ChevronLeftIcon sx={styles.icon} />
          </IconButton>
        )}
        <Avatar sx={styles.avatar} src={props.image} onClick={onHeaderClick}>
          {getAvatar()}
        </Avatar>
        <Box sx={styles.titleWrapper} onClick={onHeaderClick}>
          <Typography variant={isSmallDevice ? 'h4' : 'h2'} sx={styles.title}>
            {props.name}
          </Typography>
          <Typography variant={isSmallDevice ? 'caption' : 'subheading'} sx={styles.subTitle}>
            {Util.toPascalCase(props.status)}
          </Typography>
        </Box>
        {/* <Box>
          <IconButton sx={styles.iconBtn}>
            <VideoIcon sx={styles.icon} />
          </IconButton>
          <IconButton sx={styles.iconBtn}>
            <PhoneIcon sx={styles.icon} />
          </IconButton>
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};

export default ChatHeader;
