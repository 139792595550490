import AppError from '../../exception/app-error';
import { AssignedSessionStatus } from '../../utils/constants';
import { UPDATE_ACCOUNT_LOCK_STATUS } from '../user/action-types';
import { User } from '../../types/user';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const API_FAILURE = 'PROFILE_API_FAILURE';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const RESET_PROFILE_STATE = 'RESET_PROFILE_STATE';
export const RESET_ERROR_STATE = 'RESET_PROFILE_ERROR_STATE';
export const UPDATE_PROFILE_STATUS = 'UPDATE_PROFILE_STATUS';
export const UPDATE_PROFILE_STATUS_SUCCESS = 'UPDATE_PROFILE_STATUS_SUCCESS';

export interface ResetErrorStateAction {
  type: typeof RESET_ERROR_STATE;
}

export interface FetchProfileAction {
  type: typeof FETCH_PROFILE;
}

export interface UpdateProfileAction {
  type: typeof UPDATE_PROFILE;
}

export interface UpdateProfileImageAction {
  type: typeof UPDATE_PROFILE_IMAGE;
}

export interface ProfileSuccessAction {
  type: typeof PROFILE_SUCCESS;
  payload: User;
}

export interface UpdateProfileStatusAction {
  type: typeof UPDATE_PROFILE_STATUS;
}

export interface UpdateProfleStatusSuccessAction {
  type: typeof UPDATE_PROFILE_STATUS_SUCCESS;
  payload: AssignedSessionStatus;
}

export interface ApiFailureAction {
  type: typeof API_FAILURE;
  payload: AppError;
}

export interface ProfileStateResetAction {
  type: typeof RESET_PROFILE_STATE;
}

export type ProfileActionTypes = ResetErrorStateAction | FetchProfileAction | UpdateProfileAction | ProfileSuccessAction
  | UpdateProfileStatusAction | UpdateProfleStatusSuccessAction | ApiFailureAction | ProfileStateResetAction
  | UpdateProfileImageAction;