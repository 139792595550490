import { Box } from '@mui/material';
import React from 'react';
import useStyles from './styles';

const ProfileRoles: React.FC = () => {

	const styles = useStyles();

	return (
		<Box sx={styles.container}>
			{'Current Roles'}
		</Box>
	);
};

export default ProfileRoles;
