import Dimens from '../../../theme/dimens';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SignInHeader component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const styles = {
    header: {
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      pb: Dimens.spacer,
      'h1': {
        fontWeight: Dimens.fontWeight.bold,
        pb: Dimens.spacing.lg
      }
    }
  };

  return styles;
};

export default useStyles;