import { AUDIO_EXT_WAV, GroupType, MessagePriority, VOICE_DURATION } from '../../../../utils/constants';
import { Box, Fab, IconButton, LinearProgress, Slider, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import AlertDialogSmall from '../../../../components/AlertDialogSmall';
import BinIcon from '../../../../components/CustomIcons/BinIcon';
import ChatUtil from '../../../../utils/chat-util';
import ConversationData from '../../../../types/conversation-data';
import MenuData from '../../../../types/ui/menu-data';
import MenuSelectDialog from '../../../../components/MenuSelectDialog';
import PauseIcon from '../../../../components/CustomIcons/PauseIcon';
import PlayIcon from '../../../../components/CustomIcons/PlayIcon';
import PriorityIcon from '../../../../components/CustomIcons/PriorityIcon';
import SendIcon from '../../../../components/CustomIcons/SendIcon';
import StopIcon from '../../../../components/CustomIcons/StopIcon';
import Util from '../../../../utils/util';
import { getMessagePriorityList } from '../../../../utils/ui-constants';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  conversation?: ConversationData;
  priority: MessagePriority;
  onPriorityChange: (message: MessagePriority) => void;
  onSendAudio: (file: File) => void;
  onClose: () => void;
}

/**
 * Chat Voice Recorder Component
 *
 * This component provides functionality for recording, playing, and sending voice messages within a chat conversation.
 * It includes controls for recording, playback, priority selection, and sending the recorded audio file.
 *
 * @param {Props} props Component props.
 */
const ChatVoiceRecorder: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(true);
  const styles = useStyles(ChatUtil.isUrgent(props.priority), isRecording);
  const [priorityAnchorEl, setPriorityAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(VOICE_DURATION);
  const [file, setFile] = useState<File>();
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [showAccessDeniedAlert, setShowAccessDeniedAlert] = useState<boolean>(false);

  /**
   * Initializes the recording process.
   *
   * This effect starts the recording process and sets up event listeners for audio data and playback.
   * It also cleans up resources on unmount.
   */
  useEffect(() => {
    startRecording();

    return () => {
      clearInterval(intervalId);
      stopRecording();
    }
  }, []);

  /**
   * Handles audio playback events and updates state accordingly.
   */
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', () => {
        if (audioRef.current?.currentTime) {
          setCurrentTime(audioRef.current.currentTime);
        }
      });
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
      });
      if (audioRef.current.duration && Number.isFinite(audioRef.current.duration)) {
        setDuration(audioRef.current.duration);
      }
    }
  }, [audioRef, audioRef.current?.duration]);

  /**
   * Starts the audio recording process.
   */
  const startRecording = () => {

    const chunks: Blob[] = [];
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        const startTime = new Date();
        mediaRecorder.ondataavailable = (event) => {
          chunks.push(event.data)
        };
        mediaRecorder.onstop = () => {
          setIsRecording(false);
          setIsPlaying(false);
          setIsPaused(false);
          clearInterval(intervalId);
          setCurrentTime(0);
          if (startTime) {
            const dif = new Date().getTime() - startTime.getTime();
            setDuration(dif / 1000);
          }
          const tracks = stream.getTracks();
          tracks.forEach(track => track.stop());
          const fileName = `audio_${new Date().getTime().toString()}${AUDIO_EXT_WAV}`;
          Util.convertWebmToWav(chunks[0], fileName).then(blob => {
            const file = Util.convertBlobToFile(blob, fileName);
            setFile(file);
            const url = URL.createObjectURL(blob);
            setAudioUrl(url);
          });
        };
        mediaRecorder.start();
        const intervalId = setInterval(() => {
          setCurrentTime((prevSeconds) => {
            if (prevSeconds >= VOICE_DURATION) {
              stopRecording();

              return 0;
            }

            return (isRecording ? prevSeconds + 0.01 : 0);
          });
        }, 10);
        setIntervalId(intervalId);
      })
      .catch((_) => {
        setShowAccessDeniedAlert(true);
      });
  };

  /**
   * Handles closing the access denied alert and the parent dialog.
   */
  const onAccessDeniedAlertClose = () => {
    setShowAccessDeniedAlert(false);
    props.onClose();
  }

  /**
   * Stops the audio recording process.
   */
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  /**
   * Starts audio playback.
   */
  const playRecording = () => {
    if (audioRef.current) {
      setIsPlaying(true);
      setIsPaused(false);
      setTimeout(() => audioRef.current?.play(), 500);
    }
  };

  /**
   * Pauses audio playback.
   */
  const pausePlayback = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
      setIsPaused(true);
    }
  };

  /**
   * Formats the given seconds into a formatted time string.
   *
   * @param {number} seconds The number of seconds to format.
   * 
   * @returns {string} The formatted time string.
   */
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(Math.round(seconds) / 60);
    const secondsRemaining = Math.round(seconds) % 60;

    return `${minutes.toString().padStart(2, '0')}:${secondsRemaining.toFixed(0).padStart(2, '0')}`;
  };

  /**
   * Handles changes to the audio playback position.
   * Updates the current playback time of the audio element based on the provided value.
   * 
   * @param {number} value - The new playback time in seconds.
   */
  const handleChange = (value: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setCurrentTime(value);
    }
  }

  /**
   * Handles changes in the priority selection menu.
   *
   * @param {MenuData} menuItem The selected menu item.
   */
  const handlePriorityChange = (menuItem: MenuData) => {
    setPriorityAnchorEl(null);
    props.onPriorityChange(menuItem.id as MessagePriority);
  }

  /**
   * Handles the click event on the audio send button.
   */
  const onAudioSendClick = () => {
    if (isRecording) {
      stopRecording();
    } else {
      if (file) {
        props.onSendAudio(file);
      }
    }
  }

  return (
    <Box sx={styles.footer}>
      <Box sx={styles.msgLayout}>
        <Fab color='secondary' sx={styles.fab} onClick={props.onClose}>
          <BinIcon />
        </Fab>
        <Box sx={styles.recorderContainer}>
          <Box sx={styles.audioPlayer}>
            {!isRecording &&
              <IconButton sx={styles.iconBtn} onClick={(_) => isPlaying ? pausePlayback() : playRecording()}>
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </IconButton>
            }
            <audio ref={audioRef} src={audioUrl} />
            <Slider
              sx={styles.slider}
              defaultValue={0}
              size='small'
              onChange={(_, value) => handleChange(value as number)}
              value={currentTime}
              max={duration}
            />
            <Typography variant='footer' sx={styles.timeInfo}>
              {(isRecording || (file && (isPlaying || isPaused))) ? formatTime(currentTime) : formatTime(duration)}
            </Typography>
          </Box>
          {!isRecording &&
            <IconButton sx={styles.priorityBtn} onClick={(event) => setPriorityAnchorEl(event.currentTarget)}>
              <PriorityIcon />
            </IconButton>
          }
        </Box>
        <Fab
          color={ChatUtil.isUrgent(props.priority) ? 'error' : 'primary'}
          sx={styles.fab}
          onClick={onAudioSendClick}
        >
          {isRecording ? <StopIcon /> : <SendIcon />}
        </Fab>
      </Box>
      <MenuSelectDialog
        anchorEl={priorityAnchorEl}
        open={Boolean(priorityAnchorEl)}
        onClose={() => setPriorityAnchorEl(null)}
        menuList={getMessagePriorityList(props.conversation?.groupType === GroupType.Mixed)}
        onMenuItemSelected={handlePriorityChange}
      />
      <AlertDialogSmall
        open={showAccessDeniedAlert}
        title={t('micAccessDeniedTitle')}
        message={t('micAccessDeniedMsg')}
        primaryLabel={t('close')}
        onPrimaryAction={onAccessDeniedAlertClose}
        onClose={onAccessDeniedAlertClose}
      />
    </Box>
  );
};

export default ChatVoiceRecorder;
