import { AppFeature, DATE_PATTERN_DD_MMM_YYYY, InteractionType, MessageOptions } from '../../../../../utils/constants';
import { Avatar, Box, Divider, Typography } from '@mui/material';

import ChatUtil from '../../../../../utils/chat-util';
import DocumentView from '../DocumentView';
import ForwardIcon from '../../../../../components/CustomIcons/ForwardIcon';
import GroupMemberBaseInfo from '../../../../../types/group-member-base-info';
import LoginUtil from '../../../../../utils/login-util';
import MenuData from '../../../../../types/ui/menu-data';
import MenuSelectDialog from '../../../../../components/MenuSelectDialog';
import MessageData from '../../../../../types/message-data';
import React from 'react';
import Util from '../../../../../utils/util';
import { format } from 'date-fns';
import { getMessageActionList } from '../../../../../utils/ui-constants';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export interface Props {
  nextMessageData?: MessageData;
  messageData: MessageData;
  interactionType: InteractionType;
  senderInfo: GroupMemberBaseInfo;
  handleMsgActionClick: (action: MessageOptions) => void;
  onAttachmentClick: () => void;
}

/**
 * LeftChatItem Component
 * 
 * This component renders a left-aligned chat message item, representing 
 * messages received from other users. It displays the sender's initials 
 * (if group chat) or full name, the message content, and a timestamp 
 * using `ChatUtil.getChatFormatTime`.
 * 
 */
const LeftChatItem: React.FC<Props> = (props: Props) => {

  const { nextMessageData, senderInfo, messageData, interactionType } = props;
  const styles = useStyles(props);
  const { t } = useTranslation();
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  /**
   * Handles the click event for an item in the action menu.
   *
   * @param {MenuData} item - The clicked menu item object.
   * 
   * Closes the action menu and delegates handling the action to the parent component through props.
   */
  const handleActionMenuClick = (item: MenuData) => {
    setActionMenuAnchorEl(null);
    props.handleMsgActionClick(item.id as MessageOptions);
  }

  /**
   * Handles the click event on a context menu trigger (likely a message bubble).
   * Opens the action menu if the message has no attachment or if it has an attachment and the user has the 'MultimediaForwarding' permission.
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e - The mouse event object.
   */
  const onContextMenuClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((interactionType === InteractionType.Private || interactionType === InteractionType.Group)
      && (!messageData.attachmentKey || (messageData.attachmentKey
        && LoginUtil.hasPermission(AppFeature.MultimediaForwarding)))) {
      setActionMenuAnchorEl(e.currentTarget);
    }
  }

  /**
   * Checks if the current and next message data represent the same chat user (same sender and sent on the same day).
   *
   * @returns {boolean} True if the messages are from the same user sent on the same day, false otherwise.
   */
  const isChatUserSame = () => {
    let isSame = false;
    if (nextMessageData && nextMessageData.createdAt) {
      if (messageData && messageData.createdAt) {
        if (format(nextMessageData.createdAt,
          DATE_PATTERN_DD_MMM_YYYY) === format(messageData.createdAt, DATE_PATTERN_DD_MMM_YYYY)
          && nextMessageData.senderLoginId === messageData.senderLoginId) {
          isSame = true;
        }
      }
    }

    return isSame;
  }

  return (
    <Box>
      {messageData.contentType.startsWith('FORWARD_') &&
        <Box sx={styles.fwdHeader}>
          <ForwardIcon />
          <Typography variant='footer'>{t('forwarded')}</Typography>
        </Box>
      }
      <Box sx={styles.wrapper} onContextMenu={(e) => e.preventDefault()}>
        <Avatar sx={{ ...styles.avatar, opacity: isChatUserSame() ? 0 : 1 }} src={senderInfo.thumbnailImageKey}>
          <Typography variant='caption'>{Util.getInitialsFromName(senderInfo.name ?? '')}</Typography>
        </Avatar>
        <Box
          sx={styles.content}
          onContextMenu={onContextMenuClick}
        >
          <Divider orientation='vertical' sx={styles.priorityLine} />
          <Box>
            {!isChatUserSame() &&
              <Typography variant='p1' sx={styles.header}>{senderInfo.name}</Typography>
            }
            <Typography variant='p1' sx={styles.contentText}>{messageData.contentText}</Typography>
            <DocumentView messageData={messageData} onAttachmentClick={props.onAttachmentClick} />
            <Typography variant='caption' sx={styles.date}>{ChatUtil.getChatFormatTime(messageData.createdAt)}</Typography>
          </Box>
        </Box>
        <MenuSelectDialog
          anchorEl={actionMenuAnchorEl}
          open={Boolean(actionMenuAnchorEl)}
          onClose={() => setActionMenuAnchorEl(null)}
          menuList={getMessageActionList(false, true)}
          onMenuItemSelected={handleActionMenuClick}
        />
      </Box>
    </Box>
  );
};

export default LeftChatItem;
