import Colors from '../../../theme/colors';
import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DepartmentCard component.
 * 
 * @returns {object} - An object containing styles for the DepartmentCard component.
 */
const useStyles = () => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();
	const { mini, xxs, xs, base, lg, section } = Dimens.spacing;

	const styles = {
		...commonStyles,
		card: {
			borderRadius: Dimens.radius.md,
			boxShadow: `0px ${xxs} ${xs} 0px ${Colors.shadow}`,
			'&:hover': {
				cursor: 'pointer'
			}
		},
		cardActionArea: {
			p: `${section} ${base} ${section} ${section}`
		},
		cardContent: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
			flex: 1,
			'&.MuiCardContent-root': {
				p: 0
			}
		},
		iconWrapper: {
			width: Dimens.deptIconWidth,
			height: Dimens.deptIconWidth,
			border: `${Dimens.menuBorderWidth} dashed ${theme.palette.text.secondary}`,
			borderRadius: Dimens.radius.xxl,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.text.secondary,
			mr: section, 
			pb:xxs
		},
		content: {
			display: 'flex',
			flexDirection: 'row',
			flex: 1,
			justifyContent: 'space-between',
			'& button': {
				'& svg': {
					...commonStyles.smIcon
				}
			}
		},
		contentText: {
			display: 'flex',
			flexDirection: 'column',
			color: theme.palette.text.primary
		},
		title: {
			...commonStyles.textEllipsis(1),
			pb: mini
		},
		icon: {
			color: theme.palette.primary.main,
			fontSize: Dimens.icon.sm,
			mt: xxs,
			mr: lg, 
		}
	};
	
	return styles;
};

export default useStyles;