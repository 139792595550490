import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DeviceViewPanel component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { xxs, xs, base, lg, xl, section, box } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    contentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'hidden'
    },
    content: {
      ...commonStyles.noScrollBar,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflowY: 'auto'
    },
    deviceHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    avatarWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flex: 1,
      width: '100%',
      background: `linear-gradient(to bottom, ${theme.palette.background.default} 50%, transparent 50%)`,
      p: `0 ${section} ${section} ${section}`,
      [theme.breakpoints.down('sm')]: {
        p: `${xl} ${lg}`
      }
    },
		iconWrapper: {
			width: Dimens.avatarSize.section,
			height: Dimens.avatarSize.section,
			border: `${Dimens.menuBorderWidth} dashed ${theme.palette.text.primary}`,
			borderRadius: '50%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.text.primary,
      bgcolor: theme.palette.background.paper,
      '& svg': {
        fontSize: Dimens.icon.xl
      }
		},
    deviceContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: `0 ${section} ${section} ${section}`,
      [theme.breakpoints.down('sm')]: {
        p: `0 ${lg} ${box} ${lg}`
      }
    },
    title: {
      fontWeight: Dimens.fontWeight.bold,
      textAlign: 'center'
    },
    subTitle: {
      color: theme.palette.text.secondary,
      textAlign: 'center',
    },
    footer: {
      p: `${xs} ${base}`,
      mt: xs,
      bgcolor: theme.palette.background.default,
      borderRadius: Dimens.radius.md
    },
    deviceInfo: {
      p: `${base} ${section} ${section} ${section}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    }
  };

  return styles;
};

export default useStyles;
