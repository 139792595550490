import { Box, DialogActions, DialogContent } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useEffect } from 'react';

import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import InlineAlert from '../../../../components/InlineAlert';
import LoginUtil from '../../../../utils/login-util';
import SaveIcon from '../../../../components/CustomIcons/SaveIcon';
import { UPDATE_PROFILE } from '../../../../data/profile/action-types';
import { User } from '../../../../types/user';
import Util from '../../../../utils/util';
import { deptSchema } from '../../../../utils/validation-schema';
import { useDepartmentApi } from '../../../../data/department/api';
import { useProfileApi } from '../../../../data/profile/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * React component displaying for edit Profile on Mobile.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const EditProfileSubMenu: React.FC = () => {

	const styles = useStyles();
	const { t } = useTranslation();
	const formikRef = React.createRef<FormikProps<User>>();
	const deptApi = useDepartmentApi();
	const profileApi = useProfileApi();
	const apiStatus = deptApi.state.department.apiStatus;
	const user = profileApi.state.profile.user;

	/**
	 * Fetches department list and user profile details using the stored login ID (email) from local storage.
	 */
	useEffect(() => {
		const emailId = LoginUtil.getLoginId();

		if (emailId) {
			profileApi.fetchLogedInUserDetails();
		}
		deptApi.fetchDepartmentList();
	}, []);

	/**
	 * Handles form submission by calling updateProfileDetail API and updating local state.
	 *
	 * @param {User} values - User data from the submitted form.
	 */
	const onSubmit = (values: User) => {
		profileApi.updateProfileDetail(values);
	};

	return (
		<>
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validationSchema={deptSchema}
				initialValues={user}
				onSubmit={(values) => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent sx={styles.content}>
							<Box sx={styles.formInnerWrapper}>
								<InlineAlert message={Util.getApiError([ UPDATE_PROFILE ], apiStatus)} />
								<Field
									name='name'
									label={t('name')}
									placeholder={t('name')}
									component={CustomInput}
								/>
								<Field
									name='position'
									label={t('position')}
									placeholder={t('position')}
									component={CustomInput}
								/>
								<Field
									name='about'
									label={t('aboutYou')}
									placeholder={t('aboutYouPlaceholder')}
									multiline
									component={CustomInput}
								/>
								<Field
									name='departments'
									label={t('departments')}
									placeholder={t('findDepartments')}
									component={CustomAutocomplete}
									noOptionsText={t('noDepts')}
									multiple
									defaultOptionId={user.defaultDepartmentId}
									menu={deptApi.state.department.departmentList}
								/>
							</Box>
						</DialogContent>
						<DialogActions >
							<CustomButton
								sx={styles.saveBtn}
								type='submit'
								title={t('save')}
								color='primary'
								fullWidth
								disabled={!dirty || !isValid}
								startIcon={<SaveIcon sx={styles.smIcon} />}
							/>
						</DialogActions>
					</Form>
				)}
			</Formik>
			<CircularProgressBar show={Util.isApiLoading([ UPDATE_PROFILE ], apiStatus)} />
		</>
	);
};

export default EditProfileSubMenu;