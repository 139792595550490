import { useEffect } from 'react';

/**
 * Custom hook to disable back navigation.
 * 
 * This hook prevents the user from navigating back using the browser's back button
 * by pushing the current state to the history stack and adding a popstate event listener.
 * 
 * @returns {void}
 */
export const useDisableBackNav = () => {
  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.pushState(null, '', window.location.href);
    };

    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
};