import { Box } from '@mui/material';
import React from 'react';
import { useChatSubscription } from '../../../../hooks/use-chat-subscription';
import useStyles from './styles';

interface Props {
  children: React.ReactNode;
}

/**
 * ContentLayout component
 * 
 * This component serves as a layout container for the main content area 
 * within the chat application. It utilizes Material UI's `Box` component for styling 
 * and subscribes to all chat events using the `useChatSubscription` hook 
 * (assumed to be imported from a separate file `../../../../hooks/use-chat-subscription`).
 * 
 * The component renders its children within a `main` section styled using CSS classes 
 * defined in the `styles` object obtained from the `useStyles` hook. 
 * 
 * @props {Props} - An object containing the following property:
 *   - children: React nodes representing the content to be rendered within the layout.
 */
const ContentLayout: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  // Subscribe to all chat events after login
  useChatSubscription();

  return (
    <Box sx={styles.contentWrapper}>
      <Box component='main' sx={styles.content}>
        {props.children}
      </Box>
    </Box>
  );
};

export default ContentLayout;
