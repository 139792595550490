import {
	API_FAILURE,
	RESET_ERROR_STATE,
	RESET_SEARCH_STATE,
	SearchActionTypes,
	SEARCH_RECORDS,
	SEARCH_RECORDS_SUCCESS

} from './action-types';

import SearchState from '../../types/states/search-state';

export const searchInitialState: SearchState = {
	apiStatus: undefined,
	recordList: []
};

/**
 * Search reducer handles actions related to search data.
 * 
 * @param {SearchState} state - The current search state.
 * @param {SearchActionTypes} action - The action to be handled.
 * 
 * @returns {SearchState} The updated search state.
 */
const searchReducer = (state: SearchState, action: SearchActionTypes): SearchState => {
	switch (action.type) {
		case RESET_ERROR_STATE:
			return {
				...state,
				apiStatus: undefined
			};
		case SEARCH_RECORDS:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					error: undefined,
					isCompleted: false
				}
			};
		case SEARCH_RECORDS_SUCCESS:
			return {
				...state,
				recordList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					isCompleted: true
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_SEARCH_STATE:
			return {
				...searchInitialState
			};
		default:
			return state;
	}
}

export default searchReducer;