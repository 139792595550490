import { Box, Tab } from '@mui/material';
import React, {useState} from 'react';
import { TAB_USER_EDIT_DEVICE, TAB_USER_EDIT_PROFILE, TAB_USER_EDIT_SETTINGS } from '../../../utils/constants';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import DeviceTab from './DeviceTab';
import { FormikProps } from 'formik';
import ProfileTab from './ProfileTab';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SettingsTab from './SettingsTab';
import { USER_EDIT_TAB_LIST } from '../../../utils/ui-constants';
import { User } from '../../../types/user';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

interface Props {
	open: boolean;
	user: User;
	onClose: () => void;
}

/**
 * Renders a drawer with tabs for editing user profile and settings.
 *
 * @param {Props} props - Component props containing open state, user data, and close callback.
 * 
 * @returns JSX element representing the user edit panel.
 */
const UserEditPanel: React.FC<Props> = (props: Props) => {

	const { open, user, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState(TAB_USER_EDIT_PROFILE);
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const formikRef = React.createRef<FormikProps<User>>();
	const userApi = useUserApi();

	/**
   * Renders the appropriate TabPanel content based on the selected tab ID.
   *
   * @param {string} tabId - ID of the selected tab.
	 * 
   * @returns {JSX.Element} The content for the TabPanel.
   */
	const getTabPanel = (tabId: string) => {
		switch (tabId) {
			case TAB_USER_EDIT_PROFILE:
				return <ProfileTab user={user} onClose={onClose} formikRef={formikRef} />;
			case TAB_USER_EDIT_DEVICE:
				return <DeviceTab user={user} />;
			case TAB_USER_EDIT_SETTINGS:
				return <SettingsTab user={user} />;
		}
	}

	/**
	 * Handles the logic for closing the tab.
	 * If there are unsaved changes (`detailsEdited` is true), it prompts the user with a save dialog.
	 * Otherwise, it resets the state and closes the tab.
	 */
	const onTabClose = () => {
		if (formikRef.current?.dirty) {
				setShowSaveDialog(true);
		} else {
				setShowSaveDialog(false);
				userApi.resetError();
				onClose();
		}
	};
	
	/**
		* Handles closing the tab without saving.
		* Resets the state and closes the tab immediately.
		*/
	const onLeaveWithoutSaving = () => {
		setShowSaveDialog(false);
		onClose();
	};

	return (
		<RightDrawerLayout open={open} title={t('editUser')} onClose={onTabClose}>
			{open && user &&
				<TabContext value={selectedTab}>
					<TabList variant='scrollable' onChange={(_, value) => setSelectedTab(value)} sx={styles.tabList}>
						{
							USER_EDIT_TAB_LIST.map((item, index) => (
								<Tab key={`use_edit_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
							))
						}
					</TabList>
					<Box sx={styles.tabListWrapper}>
						{
							USER_EDIT_TAB_LIST.map((item, index) => (
								<TabPanel key={`user_edit_tab_panel_${index}`} value={item.id} sx={styles.tabPanel}>
									{getTabPanel(item.id)}
								</TabPanel>
							))
						}
					</Box>
				</TabContext>
			}
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={onLeaveWithoutSaving}
				onClose={() => setShowSaveDialog(false)}
			/>
		</RightDrawerLayout>
	);
};

export default UserEditPanel;