import Dimens from '../../../../../theme/dimens';
import ThemeUtil from '../../../../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the MessageInfoBody component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    infoLayout: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      pb: Dimens.spacing.base,
    },
    avatar: {
      width: Dimens.avatarSize.sm,
      height: Dimens.avatarSize.sm,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary
    },
    username : {
      lineHeight: ThemeUtil.pxToRem(10)
    },
    name: {
      px: Dimens.spacing.base,
      flex: 1
    }
  };

  return styles;
};

export default useStyles;
