import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MessageIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_2904_2015)'>
          <path d='M14.8092 25.2865L15.8699 24.2259L15.4305 23.7865H14.8092V25.2865ZM18.5342 29.0115L17.4736 30.0722L18.5342 29.0115ZM21.9485 25.2865V23.7865H20.4485V25.2865H21.9485ZM3.2207 10.084C3.2207 6.49414 6.13085 3.58398 9.7207 3.58398V0.583984C4.474 0.583984 0.220703 4.83729 0.220703 10.084H3.2207ZM3.2207 17.2865V10.084H0.220703V17.2865H3.2207ZM9.72071 23.7865C6.13086 23.7865 3.2207 20.8764 3.2207 17.2865H0.220703C0.220703 22.5332 4.47401 26.7865 9.72071 26.7865V23.7865ZM14.8092 23.7865H9.72071V26.7865H14.8092V23.7865ZM19.5949 27.9509L15.8699 24.2259L13.7486 26.3472L17.4736 30.0722L19.5949 27.9509ZM20.4485 27.5973C20.4485 28.0428 19.9099 28.2659 19.5949 27.9509L17.4736 30.0722C19.6785 32.2771 23.4485 30.7155 23.4485 27.5973H20.4485ZM20.4485 25.2865V27.5973H23.4485V25.2865H20.4485ZM22.2777 23.7865H21.9485V26.7865H22.2777V23.7865ZM28.7777 17.2865C28.7777 20.8764 25.8675 23.7865 22.2777 23.7865V26.7865C27.5244 26.7865 31.7777 22.5332 31.7777 17.2865H28.7777ZM28.7777 10.084V17.2865H31.7777V10.084H28.7777ZM22.2777 3.58398C25.8675 3.58398 28.7777 6.49414 28.7777 10.084H31.7777C31.7777 4.83728 27.5244 0.583984 22.2777 0.583984V3.58398ZM9.7207 3.58398H22.2777V0.583984H9.7207V3.58398Z' fill='currentColor' />
          <path d='M8.86133 19.041H13.3234H17.7854' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
          <path d='M22.2461 19.041H22.6923H23.1385' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
          <path d='M23.1387 13.6855L18.6766 13.6855L14.2146 13.6855' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
          <path d='M9.75391 13.6855L9.3077 13.6855L8.8615 13.6855' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
          <path d='M8.86133 8.33203H13.3234L17.7854 8.33203' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
          <path d='M22.2461 8.33203H22.6923H23.1385' stroke='currentColor' strokeWidth='3' strokeLinecap='round' />
        </g>
        <defs>
          <clipPath id='clip0_2904_2015'>
            <rect width='32' height='32' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default MessageIcon;