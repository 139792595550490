import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DetailsTab component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const {lg, section} = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  
  const styles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      p: section,
      overflowY: 'auto',
      [theme.breakpoints.down('sm')]: {
        p: lg
      },
      '& .MuiButton-root': {
        mt: section,
        [theme.breakpoints.down('sm')]: {
          mt: lg
        },
      }
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    formInnerWrapper: {
      ...commonStyles.noScrollBar,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flex: 1
    }
  };

  return styles;
};

export default useStyles;