import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook for SiteDetailDialog styling.
 *
 * @returns {object} - An object containing styled classes for the dialog.
 */
const useStyles = (isEdit = false) => {

  const { base, lg, xl } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    ...commonStyles,
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        minWidth: Dimens.alertDialogLargeWidth.lg,
        p: `${xl} ${xl} ${lg} ${xl}`,
        [theme.breakpoints.down('md')]: {
          minWidth: Dimens.alertDialogLargeWidth.md
        },
        [theme.breakpoints.down('sm')]: {
          minWidth: '100%',
          height: '100%',
          minHeight: '100%',
          borderRadius: 0,
          justifyContent: 'center',
          p: lg
        }
      },
      '& .MuiDialogContent-root': {
        ...commonStyles.noScrollBar,
        p: 0,
        minHeight: Dimens.dialogContentMaxHeight,
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
          maxHeight: '100%'
        }
      },
      '& .MuiDialogActions-root': {
        p: 0,
        pt: Dimens.spacing.lg,
        borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        alignItems: 'center',
        justifyContent: 'end',
        '& .MuiButton-colorSecondary': {
          display: 'flex',
          [theme.breakpoints.down('sm')]: {
            mb: Dimens.spacing.xs
          }
        },
        '& .MuiButton-colorPrimary': {
          display: 'flex',
          [theme.breakpoints.down('sm')]: {
            margin: 0
          }
        }
      }
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base,
        flex: 'none'
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    title: {
      fontWeight: Dimens.fontWeight.bold
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column'
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      justifyContent: isEdit ? 'space-between' : 'end',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    }
  };

  return styles;
};

export default useStyles;
