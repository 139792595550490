import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the CustomDateTimePicker component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();

	const styles = {
		wrapper: {
			flexDirection: 'column',
			display: 'flex',
			flex: 1,
			pb: Dimens.spacing.base
		},
		datePicker: {
			minHeight: 0,
			height: 'inherit',
			pt: 0,
			borderRadius: Dimens.radius.md,
			'& .MuiFormControl-root': {
				flex: 1
			},
			'& .MuiInputLabel-root': {
				...theme.typography.p1,
				lineHeight: theme.typography.footer.lineHeight
			},
			'& .MuiInputLabel-shrink': {
				display: 'none'
			},
			'& .MuiOutlinedInput-root': {
				minHeight: 0,
				p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
				display: 'flex',
				alignItems: 'center',
				backgroundColor: theme.palette.background.default,
				borderRadius: Dimens.radius.md,
				borderWidth: Dimens.inputBorderWidth
			},
			'& .MuiOutlinedInput-input': {
				minHeight: 0,
				p: 0,
				display: 'flex',
				alignItems: 'center'
			},
			'& fieldset': {
				borderWidth: Dimens.inputBorderWidth,
				borderColor: theme.palette.background.default,
				top: 0,
				p: 0,
				'legend': {
					display: 'none'
				}
			},
			'&:hover fieldset': {
				borderColor: 'none',
				borderWidth: 0
			},
			'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
				borderWidth: Dimens.inputBorderWidth
			},
			'&.Mui-error fieldset': {
				borderWidth: Dimens.inputBorderWidth,
				borderColor: theme.palette.error.main
			},
			'&:hover .Mui-error fieldset': {
				borderWidth: Dimens.inputBorderWidth,
				borderColor: theme.palette.error.main
			}
		},
		fieldError: {
			...theme.typography.footer,
			m: 0,
			pt: Dimens.spacing.xs,
			color: theme.palette.error.main
		},
		timeLayout: {
			'.MuiDateTimePickerToolbar-timeDigitsContainer': {
				alignItems: 'center'
			}
		}

	};

	return styles;
};

export default useStyles;