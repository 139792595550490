import { ActualSessionStatus, InteractionType } from '../../../../utils/constants';

import Dimens from '../../../../theme/dimens';
import { Props } from '.';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the ChatHeader component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (props: Props) => {

  const { sm, lg, xl, section } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const isPrivate = props.interactionType === InteractionType.Private;
  const isGroup = props.interactionType === InteractionType.Group;
  const isEmergency = props.interactionType === InteractionType.EmergencyGroup;
  const isTelephony = props.interactionType === InteractionType.Telephony;

  const styles = {
		appBar: {
			zIndex: 0,
      bgcolor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      backgroundImage: 'none',
      boxShadow: 'none',
			borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      p: section,
			position: 'relative',
			'& .MuiToolbar-root': {
				p: 0,
        minHeight: 'unset'
			},
      [theme.breakpoints.down('sm')]: {
        p: `${lg} ${xl}`
      }
    },
		avatar: {
      width: Dimens.avatarSize.xl,
      height: Dimens.avatarSize.xl,
      color: theme.palette.primary.contrastText,
      bgcolor: isEmergency ? theme.palette.error.main : theme.palette.text.secondary,
      cursor: (isPrivate || isGroup) ? 'pointer' : 'unset',
      [theme.breakpoints.down('sm')]: {
        width: Dimens.avatarSize.lg,
        height: Dimens.avatarSize.lg,
      },
      'svg': {
        fontSize: Dimens.icon.lg
      }
    },
		titleWrapper: {
			flexDirection: 'column',
			mx: section,
			flex: 1,
      cursor: (isPrivate || isGroup) ? 'pointer' : 'unset',
      [theme.breakpoints.down('sm')]: {
        mx: lg
      }
		},
		title: {
      ...commonStyles.textEllipsis(1),
			fontWeight: Dimens.fontWeight.bold
		},
		subTitle: {
			overflow: 'hidden',
			textOverflow: 'auto',
			whiteSpace: 'nowrap',
      display: isTelephony ? 'none' : 'unset',
			color: () => {
        let color = theme.palette.warning.main;
        if (isGroup) {
          color = theme.palette.text.secondary;
        } else if (props.status === ActualSessionStatus.Online) {
          color = theme.palette.success.main;
        }
    
        return color;
      }
		},
		iconBtn: {
      p: sm,
      radius: Dimens.radius.md
    },
    backBtn: {
      radius: Dimens.radius.md,
      p: `0 ${lg} 0 0`
    },
    icon: {
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.md
    }
  };

  return styles;
};

export default useStyles;