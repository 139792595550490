import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles hook defines the styling for the DashboardTabCard component.
 * It uses Material UI's `useTheme` hook to access the current theme,
 * custom dimensions from `Dimens`, and common styles from `useCommonStyles`.
 * @returns {object} An object containing the style definitions.
 */
const useStyles = (canEditDashboard = false) => {

	const theme = useTheme();
	const commonStyles = useCommonStyles();
	const { sm, lg, section } = Dimens.spacing;

	const styles = {
		...commonStyles,
		card: {
			borderRadius: Dimens.radius.lg,
			boxShadow: 'none',
			height: Dimens.dashboardCardMaxHeight,
			display: 'flex',
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			backgroundImage: 'none',     
      '&:hover': {
				cursor: 'pointer',
        'button': {
          display: canEditDashboard ? 'flex' : 'none'
        }
      }
		},
		cardActionArea: {
			p: lg,
			alignItems: 'center',
			justifyContent: 'center'
		},
		cardContent: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'column',
			'&.MuiCardContent-root': {
				p: 0
			}
		},
		title: {
			...commonStyles.textEllipsis(1),
			color: theme.palette.text.primary,
			fontWeight: Dimens.fontWeight.semiBold,
			textAlign: 'center',
			pt: section
		},
		iconToggle: {
      display: 'none',
      ml: sm,
			position: 'absolute',
			right: 0,
			top: 0,
			'svg': {
				...commonStyles.smIcon
			}
    }
	};
	
	return styles;
};

export default useStyles;