import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PaperClipIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_8097_11536)'>
          <path d='M6.80651 4.43788L6.80651 10.9405C6.80651 11.3385 6.96461 11.7202 7.24603 12.0016C7.52745 12.2831 7.90914 12.4412 8.30712 12.4412C8.70511 12.4412 9.0868 12.2831 9.36822 12.0016C9.64964 11.7202 9.80774 11.3385 9.80774 10.9405L9.80774 4.43788C9.80774 3.6419 9.49154 2.87852 8.9287 2.31568C8.36586 1.75284 7.60248 1.43664 6.80651 1.43664C6.01053 1.43664 5.24716 1.75284 4.68432 2.31568C4.12148 2.87852 3.80527 3.6419 3.80527 4.43788L3.80527 10.9405C3.80527 12.1345 4.27958 13.2796 5.12384 14.1238C5.9681 14.9681 7.11316 15.4424 8.30712 15.4424C9.50109 15.4424 10.6462 14.9681 11.4904 14.1238C12.3347 13.2796 12.809 12.1345 12.809 10.9405L12.809 4.43788' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_8097_11536'>
            <rect width='16' height='16' fill='transparent' transform='translate(0 0.433594)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default PaperClipIcon;