import { Box } from '@mui/material';
import CircularProgressBar from '../../../components/CircularProgressBar';
import InlineAlert from '../../../components/InlineAlert';
import ListItemAddRemoveCard from '../../../components/ListItemAddRemoveCard';
import { Pager } from '../../../types/pager';
import React from 'react';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SearchBar from '../../../components/SearchBar';
import { UPDATE_PAGER } from '../../../data/device/action-types';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import { useDeviceApi } from '../../../data/device/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

interface Props {
	open: boolean;
	pager: Pager;
	onClose: () => void;
}

/**
 * Device Assign Panel Component
 *
 * This component allows users to assign a new user to a pager.
 * It displays a list of available users and provides a search bar for filtering.
 *
 * @param {Props} props Component props.
 */
const AssignMemberPanel: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const { t } = useTranslation();
	const userApi = useUserApi();
	const deviceApi = useDeviceApi();
	const apiStatus = deviceApi.state.device.apiStatus;

	/**
   * Filters the user list, excluding the currently assigned user.
   * 
   * @returns {Array<User>} Filtered user list.
   */
	const getFilteredUserList = (): Array<User> => {
		let userList: Array<User> = [];
		if (!Util.isArrayEmpty(userApi.state.user.searchedUserList)) {
			userList = userApi.state.user.searchedUserList.filter(user => props.pager.assignedTo?.email !== user.email)
				.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
		}

		return userList;
	}

	// Get the filtered user list based on search and sorting logic.
	const filteredUserList = getFilteredUserList();

	/**
   * Handles assigning a user to the pager.
   *
   * @param {User} user User to be assigned.
   */
	const assignMember = (user: User): void => {
		const request: Pager = {
			...props.pager,
			assignedTo: user
		};
		deviceApi.updatePager(request).then(data => {
			if (data) {
				props.onClose();
			}
		});
	}

	/**
	 * Handles changes in the search bar input, updating the search query 
	 * and fetching users based on the new criteria.
	 *
	 * @param {string} searchKey Updated search keyword.
	 */
	const onSearchChange = (searchKey: string) => {
		if (searchKey) {
			userApi.searchUserList(searchKey, 0, true);
		}
	}

	return (
		<RightDrawerLayout open={props.open} title={props.pager.assignedTo ? t('reassignTo') : t('assignTo')} onClose={props.onClose}>
			<Box sx={styles.container}>
				<InlineAlert message={Util.getApiError([UPDATE_PAGER], apiStatus)} />
				<SearchBar placeholder={t('search')} handleChange={onSearchChange} />
				<Box sx={styles.contentWrapper}>
					{filteredUserList.map((user, index) => (
						<ListItemAddRemoveCard
							key={`user-profile-${index}`}
							title={user.name}
							subTitle={user.position ?? ''}
							imageUrl={user.image?.thumbnail}
							isExistingItem={false}
							onActionClick={() => assignMember(user)}
						/>
					))}
				</Box>
				<CircularProgressBar show={Util.isApiLoading([UPDATE_PAGER], apiStatus)} />
			</Box>
		</RightDrawerLayout>
	);
};

export default AssignMemberPanel;