import { Box, Dialog, DialogActions, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material';

import CrossIcon from '../CustomIcons/CrossIcon';
import CustomButton from '../CustomButton';
import React from 'react';
import useStyles from './styles';

/**
 * Interface defining the properties accepted by the AlertDialogLarge component.
 * These properties allow for customization of the dialog's appearance and behavior.
 */
export interface Props extends DialogProps {
	title: string;
	titleIcon: React.ReactNode | string;
	message: string;
	/**
	 * The type of primary button.
	 * @default false
	 */
	isDestructive?: boolean;
	footerCaption?: string;
	footerAction?: string;
	primaryLabel?: string;
	secondaryLabel?: string;
	onPrimaryAction?: () => void;
	onSecondaryAction?: () => void;
	onFooterClick?: () => void;
	onClose: () => void;
}

/**
 * Functional component implementation for the AlertDialogLarge component.
 * It renders the dialog structure, content, buttons, and handles user interactions like button clicks and closing the dialog.
 */
const AlertDialogLarge: React.FC<Props> = (props: Props) => {

	const {
		title,
		titleIcon,
		message,
		isDestructive,
		footerCaption,
		footerAction,
		primaryLabel,
		secondaryLabel,
		onPrimaryAction,
		onSecondaryAction,
		onFooterClick,
		onClose,
		...rest
	} = props;
	const styles = useStyles(props);

	return (
		<Dialog {...rest} sx={styles.dialog}>
			<Stack sx={styles.header}>
				<IconButton onClick={onClose} >
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<DialogContent>
				<Typography variant={'h1'} sx={styles.title}>{title}</Typography>
				<Box component='img' alt='logo' src={titleIcon as string} sx={styles.headerImage} />
				<Typography variant={'h2'} sx={styles.title}>{title}</Typography>
				<Typography variant='p1' sx={styles.message}>{message}</Typography>
			</DialogContent>
			<DialogActions>
				<CustomButton title={secondaryLabel} color='secondary' onClick={onSecondaryAction} />
				<CustomButton title={primaryLabel} color='primary' destructive={isDestructive} onClick={onPrimaryAction} />		
			</DialogActions>
			<Stack sx={styles.footer}>
				<Typography component={'span'} variant='footer'>
					{`${footerCaption} `}
					<Typography variant='footer' sx={styles.footerTxt} onClick={onFooterClick}>
						{footerAction}
					</Typography>
				</Typography>
			</Stack>
		</Dialog>
	);
};

export default AlertDialogLarge;