import APP_NAV from '../routes/app-nav';
import {Dashboard} from '../types/dashboard';
import {DashboardItem} from '../types/dashboard-item';
import Department from '../types/department';
import MenuData from '../types/ui/menu-data';
import Site from '../types/site';
import TabData from '../types/ui/tab-data';
import Team from '../types/team';
import { t } from 'i18next';

/**
 * constants.ts
 *
 * This file is used to define general constants that do not include JSX components.
 * If the constants has any JSX dependency, define that in ui-constants.ts.
 * It helps in centralizing and organizing constants used throughout the application.
 */

// General App Constants
export const MAX_SINGLE_LINE_CHAR_COUNT = 64;
export const MAX_MULTI_LINE_CHAR_COUNT = 400;
export const MULTI_LINE_MAX_ROWS = 4;
export const SEARCH_DEBOUNCE_DELAY = 500;
export const CARD_MAX_WIDTH = 400;
export const TOAST_DURATION = 5000; // 5 seconds
export const DEFAULT_PAGE_SIZE = 100;
export const DEFAULT_DIRECTORY_PAGE_SIZE = 25;
export const DIRECTORY_CARD_MAX_WIDTH = 185;
export const DASHBOARD_CARD_MAX_WIDTH = 185;
export const PAGER_MSG_LENGTH = 120;
export const VOICE_DURATION = 58; // Seconds

// Login Storage keys
export const KEY_APP_CLIENT_ID = 'app_client_id';
export const KEY_APP_LOGIN_STATUS = 'app_login_status';
export const KEY_SESSION_INFO = 'session_info';
export const KEY_APP_USER_INFO = 'app_user_info';
export const KEY_LAST_ACTIVE_TIME = 'last_active_time';
export const KEY_ORG_DOMAIN = 'org_domain';
export const KEY_USER_STATUS = 'user_status';

// App Storage keys
export const KEY_APP_LANGUAGE = 'app_language';

// Encryption and decryption credentials
export const SIGN_UP_STORAGE_IV = process.env.REACT_APP_SIGN_UP_STORAGE_IV ?? '';
export const SIGN_UP_STORAGE_SECRET = process.env.REACT_APP_SIGN_UP_STORAGE_SECRET ?? '';
export const LOGIN_STORAGE_IV = process.env.REACT_APP_LOGIN_STORAGE_IV ?? '';
export const LOGIN_STORAGE_SECRET = process.env.REACT_APP_LOGIN_STORAGE_SECRET ?? '';
export const APP_STORAGE_IV = process.env.REACT_APP_APP_STORAGE_IV ?? '';
export const APP_STORAGE_SECRET = process.env.REACT_APP_APP_STORAGE_SECRET ?? '';
export const PASSWORD_IV = process.env.REACT_APP_PASSWORD_IV ?? '';
export const PASSWORD_SECRET = process.env.REACT_APP_PASSWORD_SECRET ?? '';

// Session Handling Constants
export const SESSION_TIME_OUT = 20 * 60 * 1000; // 20 Minutes
export const SESSION_PROMPT_TIME_OUT = 2 * 60 * 1000; // 2 Minutes
export const SESSION_DEBOUNCE_TIME = 500;
export const REFRESH_TOKEN_DEBOUNCE_TIME = 15000; // 15 Seconds

export const PLATFORM = 'WEB';

// Welcome Page Constants
export const CAROUSEL_DEFAULT_INTERVAL = 5000; // 5 seconds

// Sign up constants
export const SIGN_UP_PAGE_LIST = [
  APP_NAV.SPLASH_SCREEN,
  APP_NAV.SIGN_UP_PASSWORD,
  APP_NAV.SIGN_UP_DEPARTMENT,
  APP_NAV.SIGN_UP_NEW_DEPARTMENT,
  APP_NAV.SIGN_UP_PROFILE
];

export const enum SignUpChallengeType {
  SetPassword = 'SET_PASSWORD',
  SetDepartment = 'SET_DEPARTMENT',
  SetProfile = 'SET_PROFILE'
}

// Sign In Page constants
export const OTP_LENGTH = 6;
export const PWD_MIN_LENGTH = 10;
export const SIGN_IN_INVITE = 'LINK';
export const SIGN_IN_PASSWORD = 'PASSWORD';
export const RESEND_EMAIL = 'RESEND_EMAIL';
export const enum ControlType {
  MainView = 'MAIN_VIEW',
  Subscription = 'SUBSCRIPTION',
  SetPassword = 'SET_PASSWORD',
  SetDepartments = 'SET_DEPARTMENT',
  SetProfile = 'SET_PROFILE',
  PasswordReset = 'PASSWORD_RESET',
  PostPasswordRequest = 'POST_PASSWORD_RESET'
}

// Regex Patterns
export const REGEX_PATTERN_LOWER_CASE = /[a-z]/;
export const REGEX_PATTERN_UPPER_CASE = /[A-Z]/;
export const REGEX_PATTERN_NUMBER = /\d/;
export const REGEX_PATTERN_PWD_SPECIAL_CHAR = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/;
export const REGEX_PATTERN_NO_NUMBERS = /^[^\d]+$/;
export const REGEX_PATTERN_PHONE_NUMBER = /^\+?[0-9\- ]+$/;
export const REGEX_PATTERN_EMAIL = /\S+@\S+\.\S+/;
export const REGEX_PATTERN_EMAIL_DOMAIN = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
export const REGEX_PATTERN_PATH_EXTENSION = /\.([a-zA-Z0-9]+)(?:\?.*)?$/;
export const REGEX_PATTERN_DIGITS = /^\d+$/;

// Date Patterns
export const DATE_PATTERN_DD_MM_YYYY = 'dd/MM/yyyy';
export const DATE_PATTERN_DD_MMM_YYYY = 'dd MMM yyyy';
export const DATE_PATTERN_DD_MMM = 'dd MMM';
export const TIME_PATTERN_HH_MM_AAA = 'hh:mmaaa';
export const DATE_PATTERN_MMM_YYYY = 'MMM yyyy';
export const DATE_PATTERN_MMMM_YYYY = 'MMMM yyyy';
export const DATE_PATTERN_DD_MM_YY = 'dd/MM/yy';
export const DATE_PATTERN_HH_MM = 'HH:mm';
export const DATE_PATTERN_HH_MM_SS = 'HH:mm:ss';
export const DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_SS = 'dd/MM/yyyy, HH:mm:ss';
export const DATE_PATTERN_MMM_DD = 'MMM dd';

export const DATE_PICKER_FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';
export const DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_A = 'DD/MM/YYYY, hh:mma';

//ThemeMode maps to the MUI PaletteMode
export const enum ThemeMode {
  Light = 'light',
  Dark = 'dark'
}
// Language preferences supported by the application.
export const APP_DEFAULT_LANGUAGE = 'en';
export const languageList = [
  {
    id: 'en',
    value: 'English'
  },
  {
    id: 'fr',
    value: 'French'
  },
  {
    id: 'de',
    value: 'German'
  },
  {
    id: 'es',
    value: 'Spanish'
  }
];

export const DRAWER_HIDDEN_PATH_LIST = [ `${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`, `${APP_NAV.SETTINGS}` ];

// Inbox constants
export const TAB_ALL = '1';
export const TAB_PRIORITY = '2';
export const TAB_UNREAD = '3';
export const TAB_STARRED = '4';
// This is written as function in order to address the translation.
// Otherwise translation will not work;
export const getInboxTabs = (): Array<TabData> => [
  {
    id: TAB_ALL,
    label: t('all')
  },
  {
    id: TAB_PRIORITY,
    label: t('priority')
  },
  {
    id: TAB_UNREAD,
    label: t('unread')
  }
  // {
  //   id: TAB_STARRED,
  //   label: t('starred')
  // }
];

export const enum ConversationSubMenu {
  Archive = 'ARCHIVE',
  Star = 'STAR',
  Mute = 'MUTE',
  MarkAsRead = 'MARK_AS_READ'
}

export const enum InteractionType {
  Group = 'GROUP',
  Private = 'PRIVATE',
  Telephony = 'TELEPHONY',
  EmergencyGroup = 'EMERGENCY_GROUP'
}

export const enum MessageType {
  TelephonyMessage = 'TELEPHONY_MESSAGE',
  EmergencyMessage = 'EMERGENCY_MESSAGE'
}

export const enum SessionType {
  Actual = 'ACTUAL',
  Assigned = 'ASSIGNED'
}

export const enum ActualSessionStatus {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  NotDefined = 'NOT_DEFINED'
}

export const enum AssignedSessionStatus {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
  Available = 'ONLINE',
  Away = 'AWAY',
  DoNotDisturb = 'DO_NOT_DISTURB',
  NotDefined = 'NOT_DEFINED'
}

export const enum UserProfileStatus {
  All = 'ALL',
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Deactivated = 'DEACTIVATED'
}

export const enum MessagePriority {
  Urgent = 'URGENT',
  Normal = 'NORMAL',
  Pager = 'PAGER'
}

export const enum ContentType {
  ContentText = 'CONTENT_TEXT',
  ContentAudio = 'CONTENT_AUDIO',
  ContentVideo = 'CONTENT_VIDEO',
  ContentPdf = 'CONTENT_PDF',
  ContentImage = 'CONTENT_IMAGE',
  ContentFile = 'CONTENT_FILE',
  ForwardText = 'FORWARD_TEXT',
  ForwardAudio = 'FORWARD_AUDIO',
  ForwardVideo = 'FORWARD_VIDEO',
  ForwardPdf = 'FORWARD_PDF',
  ForwardImage = 'FORWARD_IMAGE',
  ReplyText = 'REPLY_TEXT'
}

export const enum AttachmentType {
  Camera = 'CAMERA',
  Photo = 'PHOTO',
  Video = 'VIDEO',
  Document = 'DOCUMENT'
}
// Action types received along with subscription messages for chat
export const enum MessageActionType {
  CreatedGroupRoom = 'CREATED_GROUP_ROOM',
  UpdatedGroupRoom = 'UPDATED_GROUP_ROOM',
  AddedMember = 'ADDED_MEMBER',
  RemovedMember = 'REMOVED_MEMBER',
  MuteMember = 'MUTE_MEMBER',
  UnMuteMember = 'UNMUTE_MEMBER',
  SetRoleMember = 'SET_ROLE_MEMBER',
  ContentText = 'CONTENT_TEXT',
  ContentAudio = 'CONTENT_AUDIO',
  ContentVideo = 'CONTENT_VIDEO',
  ContentPdf = 'CONTENT_PDF',
  ContentImage = 'CONTENT_IMAGE',
  MarkedMessage = 'MARKED_MESSAGE',
  UpdatedSessionAction = 'UPDATED_SESSION_STATUS',
  ForwardText = 'FORWARD_TEXT',
  ForwardAudio = 'FORWARD_AUDIO',
  ForwardVideo = 'FORWARD_VIDEO',
  ForwardPdf = 'FORWARD_PDF',
  ForwardImage = 'FORWARD_IMAGE',
  UpdatedGroupType = 'UPDATED_GROUP_TYPE',
  MarkedDeliveredMessage = 'MARKED_DELIVERED_MESSAGE',
  MarkedReadMessage = 'MARKED_READ_MESSAGE',
  CreatedEmergencyGroupRoom = 'CREATED_EMERGENCY_GROUP_ROOM',
  TelephonyContentAudio = 'TELEPHONY_CONTENT_AUDIO',
  TelephonyContentText = 'TELEPHONY_CONTENT_TEXT'
}

export const CHAT_PAGE_SIZE = 100; // No. of items in each page for pagination.

export enum SortByAlphabet {
  A_Z = 'A_Z',
  Z_A = 'Z_A'
}

// TODO: Check how to apply localization here.
export const SORT_BY_ALPHABET_MENU: Array<MenuData> = [
  {
    id: SortByAlphabet.A_Z,
    value: 'Name (A - Z)'
  },
  {
    id: SortByAlphabet.Z_A,
    value: 'Name (Z - A)'
  }
];

// Site Constants
export const siteInitialValue: Site = {
  id: '',
  name: '',
  address: '',
  phoneNumber: '',
  departments: []
};

export const enum DirectoryRecordType {
  User = 'USER',
  Group = 'GROUP',
  Team = 'TEAM'
}

export const enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export const enum ActionType {
  BackAction = 'BACK_ACTION',
  PrimaryAction = 'PRIMARY_ACTION'
}

export const enum ChatRole {
  Admin = 'ADMIN',
  User = 'USER'
}

// Edit Group
export const TAB_GROUP_DETAIL = '1';
export const TAB_GROUP_MEMBER = '2';
export const TAB_GROUP_SETTING = '3';

export const TAB_GROUP_MEMBER_ALL = '1';
export const TAB_GROUP_MEMBER_ADMIN = '2';

export const enum AppFeature {
  ManageUser = 'admin.user.mur',
  InviteUser = 'admin.invite.inv',
  ManageDepartment = 'admin.department.dpt',
  ManageSystemSetting = 'admin.settings.stg',
  ManageGroup = 'messaging.group.mgp',
  MultimediaForwarding = 'messaging.multimedia.mtm',
  ManageIdleTime = 'system.idle.idl',
  ActivateUser ='admin.activate_user.act',
  DeactivateUser= 'admin.deactivate_user.dea',
  ReactivateUser= 'admin.reactivate_user.rea',
  LockUser = 'admin.lock_user.lck',
  ManagePager = 'pager_management.pager.aup',
  VoiceCall ='messaging.voice_call.vcc',
  ManagePagerType = 'pager_management.pager_type.apt',
  VideoCall = 'messaging.video_call.vdc',
  MediaEditing = 'messaging.media_editing.med',
  ManageTeam = 'dashboard_management.create_edit_team.cet',
  ManageDashboard = 'dashboard_management.create_edit_dashboard.ced'
}

// Edit User
export const TAB_USER_EDIT_PROFILE = '1';
export const TAB_USER_EDIT_DEVICE = '2';
export const TAB_USER_EDIT_SETTINGS = '3';
// View User
export const TAB_USER_VIEW_PROFILE = '1';
export const TAB_USER_VIEW_DEVICE = '2';

export const enum MessageOptions {
  Info = 'INFO',
  Forward = 'FORWARD',
  Reply = 'REPLY'
}

// Department constants
export const deptInitialValue: Department = {
  id: '',
  name: '',
  description: '',
  sites: [],
  members: []
};

export const enum DepartmentSubMenuItem {
  Edit = 'EDIT',
  Delete = 'DELETE'
}

export const TAB_DEPT_EDIT_DETAIL = '1';
export const TAB_DEPT_EDIT_MEMBER = '2';
export const TAB_DEPT_EDIT_SETTINGS = '3';

// Admin User constants
export const TAB_USER_ALL = '1';
export const TAB_USER_ACTIVE = '2';
export const TAB_USER_PENDING = '3';
export const TAB_USER_DEACTIVATED = '4';

export const enum UserTableHeader {
  UserType = 'USER_TYPE',
  Department = 'DEPARTMENT',
  Position = 'POSITION',
  Invited = 'INVITED'
}

export const enum UserListSubMenu {
  Edit = 'EDIT',
  Unlock = 'UNLOCK',
  Deactivate = 'DEACTIVATE',
  Reactivate = 'REACTIVATE',
  ResendInvite = 'RESEND_INVITE',
  Delete = 'DELETE'
}

export const enum MessageSendStatus {
  FileUpload = 'FILE_UPLOADING',
  InProgress = 'IN_PROGRESS',
  Sent = 'SENT',
  Failed = 'FAILED'
}

export const enum GroupType {
  Normal = 'NORMAL_GROUP',
  Mixed = 'MIXED_GROUP'
}

export const enum EntityType {
  User = 'USER'
}

// User Profile Tabs
export const TAB_USER_PROFILE = '1';
export const TAB_USER_ROLES = '2';

export const enum ProfileSubMenuItem {
  Available = 'AVAILABLE',
  Away = 'AWAY',
  DoNotDisturb = 'DONOTDISTURB'
}

export const enum SettingsSubMenu {
  Settings = 'SETTINGS',
  Appearance = 'APPEARANCE',
  EditProfile = 'EDIT_PROFILE',
  Security = 'SECURITY',
  Notifications = 'NOTIFICATIONS',
  Language = 'LANGUAGE',
  Help = 'HELP',
  ChangePassword = 'CHANGE_PASSWORD',
  AllowNotifications = 'ALLOW_NOTIFICATIONS',
  ContactSupport = 'CONTACT_SUPPORT',
  PrivacyPolicy = 'PRIVACY_POLICY',
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
  System = 'SYSTEM',
  Light = 'LIGHT',
  Dark = 'Dark'
}

export const fileTypes: { [key: string]: ContentType } = {
  'pdf': ContentType.ContentPdf,
  'jpg': ContentType.ContentImage,
  'jpeg': ContentType.ContentImage,
  'png': ContentType.ContentImage,
  'mp4': ContentType.ContentVideo,
  'wav': ContentType.ContentAudio
};

export const enum AttachmentStatus {
  NOT_DOWNLOADED = 'NOT_DOWNLOADED',
  DOWNLOADING = 'DOWNLOADING',
  DOWNLOADED = 'DOWNLOADED'
}

export const mimeTypes: { [key: string]: string } = {
  'html': 'text/html',
  'css': 'text/css',
  'js': 'application/javascript',
  'json': 'application/json',
  'xml': 'application/xml',
  'pdf': 'application/pdf',
  'jpg': 'image/jpeg',
  'jpeg': 'image/jpeg',
  'png': 'image/png',
  'gif': 'image/gif',
  'svg': 'image/svg+xml',
  'mp3': 'audio/mpeg',
  'mp4': 'video/mp4',
  'zip': 'application/zip',
  'rar': 'application/vnd.rar',
  'csv': 'text/csv',
  'doc': 'application/msword',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'ppt': 'application/vnd.ms-powerpoint',
  'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'xls': 'application/vnd.ms-excel',
  'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'txt': 'text/plain',
  'wav': 'audio/wav',
  'avi': 'video/x-msvideo'
};
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
export const ACCEPTED_DOC_EXTENSIONS = '.pdf';
export const ACCEPTED_IMAGE_EXTENSIONS = '.jpg,.jpeg,.png';
export const ACCEPTED_VIDEO_EXTENSIONS = '.mp4,.mpeg4';
export const VIDEO_MIME_TYPE = 'video/mp4';
export const IMAGE_MIME_TYPE = 'image/jpeg';
export const AUDIO_MIME_TYPE = 'audio/wav';
export const IMAGE_EXT_JPEG = '.jpeg';
export const VIDEO_EXT_MP4 = '.mp4';
export const VIDEO_INPUT = 'videoinput';
export const AUDIO_EXT_WAV = '.wav';
export const AUDIO_INPUT = 'audioinput';
export const MAX_PROFILE_IMG_SIZE = 3 * 1024 * 1024; // 3 MB

export const enum DeviceTableHeader {
  Identifier = 'IDENTIFIER',
  Name = 'NAME',
  DeviceType = 'DEVICE_TYPE',
  AssignedTo = 'ASSIGNED_TO'
}

export const enum DeviceListSubMenu {
  Edit = 'EDIT',
  Delete = 'DELETE'
}

export const enum DeviceSortBy {
  Name = 'name',
  Identifier = 'destination'
}

export const TAB_DEVICE_EDIT_DETAIL = '1';
export const TAB_DEVICE_EDIT_ASSIGN = '2';

export const USER_TYPE_ADMIN = 'ADMIN';
export const USER_TYPE_USER = 'USER';

export const enum PagerStatus {
  Closed = 'CLOSED',
  Progress = 'PROGRESS',
  Error = 'ERROR'
}

export const enum ReceiverCategory {
  App = 'APP',
  Pager = 'PAGER'
}

export const enum CallLogTableHeader {
  DateNTime = 'DATE_N_TIME',
  Content = 'CONTENT',
  Recipients = 'RECIPIENTS',
  Received = 'RECEIVED',
  Read = 'READ'
}

export const enum CallLogDetailTableHeader {
  Recipients = 'RECIPIENTS',
  SentOrReceived = 'SENT_OR_RECEIVED',
  Read = 'READ',
  Responded = 'RESPONDED'
}

export const enum InteractionMarkingType {
  Delivered = 'MARKING_DELIVERED_MESSAGE',
  Read = 'MARKING_READ_MESSAGE'
}

export const enum ChatRoomKey {
  Private = 'privateRoomKey',
  Group = 'groupRoomKey'  
}

// Team constants
export const teamInitialValue: Team = {
  teamName: '',
  teamMembers: [],
  createdByLoginId: '',
  createdAt: ''
};

export const enum DashboardItemType {
  Note = 'NOTE',
  EmergencyCall = 'EMERGENCY_CALL',
  HyperLink = 'HYPERLINK'
}

// EmergencyCall constants
export const emergencyCallInitialValue: DashboardItem = {
  id: '',
  title: '',
  icon: undefined,
  teams: [],
  teamUuids: [],
  type: DashboardItemType.EmergencyCall,
  position: {}
}

export const dashboardInitialValue: Dashboard = {
  id: '',
  name: '',
  canEdit: true,
  permissions: undefined
}

export const enum TeamSubMenuItem {
  Edit = 'EDIT',
  Delete = 'DELETE'
}

// Edit Team
export const TAB_TEAM_DETAIL = '1';
export const TAB_TEAM_MEMBER = '2';

export const enum DashboardPermission {
  CanEdit = 'can_edit',
  CanView = 'can_view',
  Owner = 'owner',
  Remove = 'remove'
}

export const TAB_DASHBOARD_NEW = 'NEW';

export const enum DashbaordTabSubMenuItem {
  Edit = 'EDIT',
  Delete = 'DELETE'
}

export const enum ListItemType {
  User = 'USER',
  Device = 'DEVICE',
  Team = 'TEAM'
}

export const enum DirectoryCreateItemMenu {
  Group = 'GROUP',
  Team = 'TEAM'
}