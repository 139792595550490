import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the RightDrawerLayout component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { xxs, xs } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    drawer: {
      '& >.MuiPaper-root': {
        backgroundImage: 'none',
        boxShadow: `0px ${xxs} ${xs} 0px ${Colors.shadow}`,
        width: Dimens.rightDrawerWidth,
        [theme.breakpoints.down('sm')]: {
          width: '100%'
        }
      }
    },
    contentWrapper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden'
    }
  };

  return styles;
};

export default useStyles;