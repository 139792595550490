import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Hook to generate styles for the Call Log message data component.
 *
 * @returns {Object} An object containing the styles for the component.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { xs, md, lg } = Dimens.spacing;

  const styles = {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    },
    innerWrapper: {
      p: `${md} ${lg}`,
      display: 'flex',
      flexDirection: 'row',
      bgcolor: theme.palette.background.default,
      borderRadius: Dimens.radius.md,
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: lg,
      '& svg': {
        ...commonStyles.smIcon,
        mr: xs
      }
    },
    audioContainer: {
      p: `${md} ${lg}`,
      bgcolor: theme.palette.background.default,
      minWidth: Dimens.attachment.defaultWidth,
      borderRadius: Dimens.radius.md,
      '.MuiSlider-root': {
        color: theme.palette.primary.main
      }
    }
  };

  return styles;
};

export default useStyles;
