import { ControlType, REFRESH_TOKEN_DEBOUNCE_TIME, RESEND_EMAIL } from '../../utils/constants';
import { apiFailure, challengeSuccess, initApi, signInSuccess, signOutSuccess } from './actions';
import { differenceInMilliseconds, isAfter, subMilliseconds } from 'date-fns';
import { useCallback, useReducer } from 'react';

import APIConfig from '../../service/api-config';
import APP_NAV from '../../routes/app-nav';
import AppError from '../../exception/app-error';
import BaseResponse from '../../types/base-response';
import BearerToken from '../../types/bearer-token';
import Challenge from '../../types/challenge';
import ChallengeData from '../../types/challenge-data';
import ChallengeResponse from '../../types/challenge-response';
import LoginUtil from '../../utils/login-util';
import PasswordRequest from '../../types/password-request';
import SignInRequest from '../../types/sign-in-request';
import SignInResponse from '../../types/sign-in-response';
import SignUpUtil from '../../utils/sign-up-util';
import Util from '../../utils/util';
import VerifyCodeRequest from '../../types/verify-code-request';
import VerifyTokenRequest from '../../types/verify-token-request';
import { doPost } from '../../service';
import { signInInitialState } from './reducer';
import signInReducer from './reducer';
import { useAppSignUpControl } from '../../hooks/use-app-sign-up-control';
import { useDashboardApi } from '../dashboard/api';
import { useDepartmentApi } from '../department/api';
import { useDirectoryApi } from '../directory/api';
import { useDomainApi } from '../domain/api';
import { useInboxApi } from '../inbox/api';
import { useNavigate } from 'react-router-dom';
import { useProfileApi } from '../profile/api';
import { useSiteApi } from '../site/api';
import { useTeamApi } from '../team/api';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../user/api';
import { useUserTypeApi } from '../user-type/api';

/**
 * Custom hook for managing sign-in functionalities and access token refresh logic.
 *
 * This hook provides functions for various sign-in related actions and manages access token refresh.
 * LoginUtil is used for handling login data and session management.
 *
 * @returns {Object} An object containing functions for sign-in, sign-out, and access token refresh,
 *                   as well as the current state of the sign-in process.
 */
export function useSignInApi() {

  const [state, dispatch] = useReducer(signInReducer, signInInitialState);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const inboxApi = useInboxApi();
  const directoryApi = useDirectoryApi();
  const userApi = useUserApi();
  const siteApi = useSiteApi();
  const deptApi = useDepartmentApi();
  const userTypeApi = useUserTypeApi();
  const domainApi = useDomainApi();
  const profileApi = useProfileApi();
  const dashboardApi = useDashboardApi();
  const teamApi = useTeamApi();

  /**
   * This hook is called to handle the sign up initiation, if the user have not validated the email link
   * and do not have the sign up info. Here the false boolean represents whether this hooks can init sign up
   * process directly. Here the sign up process will be initiated by calling appSignUpControl.initiSignUpProcess()
   * after verifying the email link and sign up info is received.
   */
  const appSignUpControl = useAppSignUpControl(false);

  /**
   * Submit email for validation and get sign-in challenges(password, email invite).
   *
   * @param {SignInRequest} request - The sign-in request object containing login details.
   * @returns {void}
   */
  const performSignIn = useCallback(async (request: SignInRequest) => {
    dispatch(initApi());
    try {
      request.clientId = LoginUtil.getClientId();
      const response: ChallengeResponse = await doPost(`${APIConfig.baseURL}${APIConfig.login}`, request);
      if (response.control && response.control.length > 0) {
        dispatch(challengeSuccess(response.control));
      } else {
        dispatchFailureAction();
      }
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Sends an email using the provided endpoint.
   * (Uses useCallback for performance optimization)
   *
   * @param {string} endpoint - The API endpoint for sending the email.
   * 
   * @returns {Promise<void>} A promise that resolves on successful email sending, rejects on error.
   */
  const sendEmail = useCallback(async (endpoint: string) => {
    dispatch(initApi());
    try {
      const response: ChallengeResponse = await doPost(endpoint);
      if (response.control && response.control.length > 0) {
        response.control.map((challenge: Challenge) => {
          challenge.type = RESEND_EMAIL;
        });
        dispatch(challengeSuccess(response.control));
      } else {
        dispatchFailureAction();
      }
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Performs a password challenge using the provided endpoint and request data.
   *
   * @param {ChallengeData} challengeData - The API endpoint URL for the password challenge and the login Id.
   * @param {PasswordRequest} request - The password challenge request object.
   * @returns {void}
   */
  const performPasswordChallenge = useCallback(async (challengeData: ChallengeData, request: PasswordRequest) => {
    dispatch(initApi());
    try {
      request.password = LoginUtil.encryptPassword(request.password || '');
      const response: ChallengeResponse = await doPost(challengeData.challengePath, request);
      if (response.control && response.control.length > 0) {
        navigate(APP_NAV.SIGN_IN_VERIFY, {
          state: {
            challengePath: response.control[0]._links.next.href,
            loginId: challengeData.loginId,
            resendPath: response.control[0]._links.proof.href
          }
        });
      } else {
        dispatchFailureAction();
      }
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Performs verification using the provided endpoint and request data.
   *
   * @param {string} endpoint - The API endpoint URL for verification.
   * @param {VerifyCodeRequest} request - The verification code request object.
   * @returns {void}
   */
  const performCodeVerification = useCallback(async (endpoint: string, request: VerifyCodeRequest) => {
    dispatch(initApi());
    try {
      const response: SignInResponse = await doPost(endpoint, request);
      manageSignInResponse(response);
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Performs email validation for password reset.
   *
   * This function dispatches an action to initialize the API call and then attempts
   * to perform email validation using a POST request.
   *
   * @param {SignInRequest} request - The request object containing the user's login ID.
   *
   * @returns {Promise<void>} - A promise that resolves after the API call completes.
   */
  const performEmailValidation = useCallback(async (request: SignInRequest) => {
    dispatch(initApi());
    try {
      request.clientId = LoginUtil.getClientId();
      const response: ChallengeResponse = await doPost(`${APIConfig.baseURL}${APIConfig.reset}`, request);
      if (response.control && response.control.length > 0) {
        navigate(APP_NAV.SIGN_IN_VERIFY, {
          replace: true,
          state: {
            skipAPICall: true,
            challengePath: response.control[0]._links.next.href,
            loginId: request.loginId,
            resendPath: response.control[0]._links.proof.href
          }
        });
      } else {
        dispatchFailureAction();
      }
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Performs password reset.
   * 
   * This function dispatches an action to initialize the API call and then attempts
   * to reset the user's password using a POST request.
   * 
   * @param {string} endpoint - The API endpoint URL for password reset.
   * @param {ResetPasswordRequest} request - The request object containing the new password.
   * 
   * @returns {Promise<void>} - A promise that resolves after the API call completes.
   */
  const performPasswordReset = useCallback(async (endpoint: string, request: PasswordRequest) => {
    dispatch(initApi());
    try {
      request.newPassword = LoginUtil.encryptPassword(request.newPassword ?? '');
      const response: BaseResponse = await doPost(endpoint, request);
      navigate(APP_NAV.SIGN_IN);
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Performs email verification using the provided endpoint and request data.
   *
   * @param {string} endpoint - The API endpoint URL for verification.
   * @param {string} request - The verification token.
   * @returns {void}
   */
  const performTokenVerification = useCallback(async (endpoint: string, token: string) => {
    dispatch(initApi());
    try {
      const request: VerifyTokenRequest = {
        token: token,
        clientId: LoginUtil.getClientId()
      };
      const response: SignInResponse = await doPost(endpoint, request);
      manageSignInResponse(response);
    } catch (error: any) { /* eslint-disable-line */
      dispatchFailureAction(error);
    }
  }, []);

  /**
   * Performs user sign-out by making a POST request to the logout API endpoint
   * and clearing session data.
   * 
   * @param {boolean} isSessionTimeOut - Flag indicating if sign-out is due to session timeout.
   * 
   * @throws {Error} - Re-throws any errors encountered during the API call or processing.
   */
  const performSignOut = useCallback(async (isSessionTimeOut: boolean) => {
    dispatch(initApi());
    try {
      await doPost(APIConfig.logOut);
      clearSessionData(isSessionTimeOut);
    } catch (error: any) { /* eslint-disable-line */
      clearSessionData(isSessionTimeOut, error);
    }
  }, []);

  /**
   * Automatic Refresh Token Logic
   *
   * This code implements logic for automatically refreshing access tokens used for API calls.
   * It ensures uninterrupted access by proactively obtaining a new access token before the current one expires.
   * The refresh process considers a debounce time (`REFRESH_TOKEN_DEBOUNCE_TIME`) to avoid excessive refresh requests
   * close to the expiry and ensure a smooth user experience.
   */
  const refreshToken = useCallback(async () => {
    try {
      const bearerToken: BearerToken | undefined = LoginUtil.getBearerToken();
      if (bearerToken) {
        // Checking whethere the access token is still valid..
        const accessTokenThreshold = subMilliseconds(Util.UTCtoLocalTime(bearerToken.access_token.expiresAt),
          REFRESH_TOKEN_DEBOUNCE_TIME);
        if (isAfter(accessTokenThreshold, new Date())) {
          refreshTokenScheduler(bearerToken.access_token.expiresAt);
          return;
        }
        // Refresh access token if the refresh token is not expired.
        if (bearerToken.refresh_token && isAfter(Util.UTCtoLocalTime(bearerToken.refresh_token.expiresAt), new Date())) {
          const response: SignInResponse = await doPost(APIConfig.refreshToken);
          if (response.bearer_tokens && response.bearer_tokens.length > 0) {
            const bearerToken: BearerToken = response.bearer_tokens[0];
            LoginUtil.saveLoginInfo(response);
            refreshTokenScheduler(bearerToken.access_token.expiresAt);
            return;
          }
        }
      }
      clearSessionData(true);
    } catch (error: any) { /* eslint-disable-line */
      clearSessionData(true, error);
    }
  }, []);

  /**
   * Schedules Refresh Token Call
   *
   * This function calculates the appropriate delay for the next refresh attempt based on the provided access token expiry time
   * and the configured `REFRESH_TOKEN_DEBOUNCE_TIME`. It uses `setTimeout` to schedule the execution of the `refreshToken` function
   * after the calculated delay. The `REFRESH_TOKEN_DEBOUNCE_TIME` introduces a buffer period before the actual expiry to avoid
   * excessive refresh requests close to expiry and ensure a smooth user experience.
   *
   * @param {string} expiryTimeStr - The expiry time string of the current access token.
   */
  const refreshTokenScheduler = (expiryTimeStr: string) => {
    const delay = differenceInMilliseconds(Util.UTCtoLocalTime(expiryTimeStr), new Date()) - REFRESH_TOKEN_DEBOUNCE_TIME;
    setTimeout(() => {
      refreshToken();
    }, delay);
  }

  /**
   * Handles a successful sign-in response from the API.
   *
   * The function performs the following actions:
   *
   * 1. Checks if the response has bearer tokens:
   *    - If there are bearer tokens:
   *        - Extracts the first bearer token.
   *        - Saves the login information using `LoginUtil.saveLoginInfo` (likely stores tokens and user data).
   *        - Dispatches a `signInSuccess` action to indicate successful sign-in in the Redux store.
   *        - Navigates the user to the inbox using `navigate` (likely a navigation function).
   *    - If there are no bearer tokens:
   *        - Dispatches a `dispatchFailureAction` to indicate a sign-in failure.
   *
   * @param {SignInResponse} response - The sign-in response object from the API.
   * @returns {void}
   */
  const manageSignInResponse = (response: SignInResponse) => {
    if (Util.isArrayEmpty(response.control)) {
      dispatchFailureAction();
    } else {
      if (response.control[0].type === ControlType.PostPasswordRequest) {
        navigate(APP_NAV.RESET_PASSWORD, {
          replace: true,
          state: {
            challengePath: response.control[0]._links.next.href
          }
        });
      } else if (response.bearer_tokens && response.bearer_tokens.length > 0) {
        if (response.control[0].type === ControlType.MainView) {
          LoginUtil.saveLoginInfo(response);
          dispatch(signInSuccess(response));
          navigate(APP_NAV.INBOX);
        } else {
          // Initiate Sign up process
          SignUpUtil.saveSignUpInfo(response);
          dispatch(challengeSuccess(response.control));
          appSignUpControl.initSignUpProcess();
        }
      } else {
        dispatchFailureAction();
      }
    }
  }

  /**
   * Resets the state of multiple APIs to their initial states.
   */
  const resetStore = () => {
    inboxApi.resetInbox();
    directoryApi.resetDirectory();
    userApi.resetUser();
    deptApi.resetDept();
    siteApi.resetSite();
    domainApi.resetDomain();
    userTypeApi.resetUserType();
    profileApi.resetProfile();
    teamApi.resetTeam();
    dashboardApi.resetDashboard();
  }

  /**
   * Handles clearing session data (bearer tokens, etc.) and navigating to the sign-in page
   * based on the `isSessionTimeOut` flag and any potential errors.
   * 
   * @param {boolean} isSessionTimeOut - Flag indicating if sign-out is due to session timeout.
   * @param {Error} error - Optional error object if encountered during sign-out.
   */
  const clearSessionData = (isSessionTimeOut: boolean, error?: any) => { /* eslint-disable-line */
    error ? dispatchFailureAction(error) : dispatch(signOutSuccess());
    resetStore();
    LoginUtil.clearAll();
    navigate(APP_NAV.SIGN_IN, {
      state: {
        isSessionTimeOut: isSessionTimeOut
      }
    });
  }

  /**
   * Dispatches an `apiFailure` action with an error message for failure scenarios during sign-in/out.
   * 
   * @param {Error} error - Optional error object encountered during sign-in/out processes.
   */
  const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
    const message: string = error?.message || t('defaultErrorMsg');
    dispatch(apiFailure(new AppError(error?.code, message)));
  }

  return {
    performSignIn,
    sendEmail,
    performPasswordChallenge,
    performCodeVerification,
    performEmailValidation,
    performPasswordReset,
    performTokenVerification,
    performSignOut,
    refreshToken,
    state
  };
}