/**
 * Generates MUI styles for elements used in the RegisterNewDepartmentScreen component.
 */
const useStyles = () => {

  const styles = {
    form: {
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      flex: 1
    }
  };

  return styles;
};

export default useStyles;