import DarkLogo from '../../assets/brand_logo_dark.svg';
import Dimens from '../../theme/dimens';
import LightLogo from '../../assets/brand_logo_light.svg';
import ThemeUtil from '../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SplashScreen component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    wrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      bgcolor: theme.palette.background.paper,
      flexGrow: 1
    },
    logo: {
      content: `url(${ThemeUtil.isLightTheme(theme) ? LightLogo : DarkLogo})`,
      width: Dimens.brangLogoWidth.lg,
      [theme.breakpoints.down('sm')]: {
        width: Dimens.brangLogoWidth.sm,
      }
    }
  };

  return styles;
};

export default useStyles;