import { useEffect, useState } from 'react';

/**
 * Custom hook to manage infinite scrolling behavior.
 *
 * @param {React.MutableRefObject<HTMLDivElement | null>} containerRef - A reference to the scrollable container element.
 * @returns {boolean} A boolean value indicating whether to load more items.
 */
export function useLoadMore(containerRef: React.MutableRefObject<HTMLDivElement | null>) {

  const [isLoadMore, setLoadMore] = useState<boolean>(false);

  /**
   * Attaches a scroll event listener to the container element.
   *
   * This effect runs when the `containerRef.current` changes (i.e., when the container element is mounted or updated).
   * It adds a scroll event listener to the container to trigger the `handleScroll` function on scroll.
   * The listener is removed in the cleanup function to avoid memory leaks.
   */
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [containerRef.current]);

  /**
   * Handles the scroll event on the container element.
   *
   * Calculates the scroll position and determines if the user has scrolled near the bottom of the container.
   * Updates the `isLoadMore` state accordingly.
   */
  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      setLoadMore(scrollTop + clientHeight >= scrollHeight - 100);
    }
  };

  return isLoadMore;
}
