import * as TablerIcons from '@tabler/icons-react';

import { Box, Card, InputBase } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import CustomLabel from '../CustomLabel';
import Dimens from '../../theme/dimens';
import { FieldProps } from 'formik';
import {IconProps} from '@tabler/icons-react';
import NoDataFound from '../NoDataFound';
import { SEARCH_DEBOUNCE_DELAY } from '../../utils/constants';
import SearchIcon from '../CustomIcons/SearchIcon';
import { TablerIconType } from '../../types/tabler-icon-type';
import theme from '../../theme/theme';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  placeholder?: string;
  subLabel?: string;
  readOnly?: boolean;
//  iconName?: TablerIconType;
  onIconSelect?: (iconName: TablerIconType) => void;
} & FieldProps;

/**
 * TablerIconPicker component allows users to search and select an icon from the Tabler Icons library.
 * It supports debounced search functionality and displays matching icons as the user types.
 */
const TablerIconPicker: React.FC<Props>= (props: Props) => {
  const {
    field: { name, value },
    form: { setFieldValue },
    label,
    subLabel,
    placeholder,
    readOnly,
    onIconSelect,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIcon, setSelectedIcon] = useState<TablerIconType | null>(null);
  const styles = useStyles();
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setSelectedIcon(value || null);
  }, [value]);

  // Dynamically generate a list of all available icon names starting with "Icon"
  const iconNames = useMemo(() => Object.keys(TablerIcons).filter(name => name.startsWith('Icon')) as TablerIconType[], []);
  
  // Memoized filtered list of icons based on the search term
  const filteredIcons = useMemo(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return iconNames.filter(iconName =>
      iconName.toLowerCase().includes(lowerCaseSearchTerm)
    );
  }, [searchTerm, iconNames]);

  /**
   * Handles the click event when an icon is selected from the list.
   * It sets the selected icon and triggers the `onIconSelect` callback.
   */
  const handleIconClick = (iconName: TablerIconType) => {
    setSelectedIcon(iconName);
    setSearchTerm('');
    props.onIconSelect && props.onIconSelect(iconName);
    setFieldValue(name, iconName);
  };

  /**
   * Handles the input change event for the search bar.
   * Implements debouncing by clearing any existing timeout and setting a new one.
   */
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    // Clear the previous timeout if it exists
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      handleChange(value);
    }, SEARCH_DEBOUNCE_DELAY);

    setDebounceTimeout(timeout);
  };

  /**
   * Updates the search term after debounce delay.
   * Called by the timeout in `handleSearchChange`.
   */
  const handleChange = (searchKey: string) => {
    setSearchTerm(searchKey);
  };

  /**
   * Renders an individual icon component based on the icon name.
   */
  const renderIcon = (iconName: TablerIconType) => {
    const IconComponent = TablerIcons[iconName as keyof typeof TablerIcons] as React.FC<IconProps>;

    if(!IconComponent) {
      return null;
    }
    return <IconComponent size={Dimens.icon.md} stroke={Dimens.icon.xxs} color={theme.palette.text.primary}/>
  };

  return (
    <Box sx={styles.container}>
      <CustomLabel label={label} subLabel={subLabel} isReadOnly={readOnly} />
      <Box sx={styles.inputWrapper}>
        {(selectedIcon && !searchTerm) &&
          <Box sx={styles.selectedIconBox}>
            {renderIcon(selectedIcon)}
          </Box>
        }
        <InputBase
          type='search'
          sx={styles.input}
          inputProps={{ style: styles.input, readOnly: props.readOnly }}
          value={searchTerm}
          placeholder={!selectedIcon ? placeholder || label : ''}
          onChange={handleSearchChange}
          disabled={readOnly}
          onFocus={() => setSelectedIcon(null)}
          startAdornment={!selectedIcon && <SearchIcon sx={styles.icon} />}
        />
      </Box>
      {searchTerm && (
      <Card sx={styles.iconListCard}>
        {filteredIcons.length === 0 ? (
          <NoDataFound  />          
        ) : (
          filteredIcons.map((iconName) => (
            <Box key={iconName} sx={styles.iconBox} onClick={() => handleIconClick(iconName)}>
              {renderIcon(iconName)}
            </Box>
          ))
        )}
      </Card>
      )
    }
    </Box>
  );
};

export default TablerIconPicker;
