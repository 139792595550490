import { AppFeature, DASHBOARD_CARD_MAX_WIDTH, DashbaordTabSubMenuItem } from '../../../utils/constants';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import AlertDialogSmall from '../../../components/AlertDialogSmall';
import BinIcon from '../../../components/CustomIcons/BinIcon';
import { DELETE_DASHBOARD } from '../../../data/dashboard/action-types';
import { Dashboard } from '../../../types/dashboard';
import { DashboardItem } from '../../../types/dashboard-item';
import DashboardTabCard from './DashboardTabCard';
import CreateEditEmergencyCallItemDialog from '../CreateEditEmergencyCallItemDialog';
import InlineAlert from '../../../components/InlineAlert';
import LoginUtil from '../../../utils/login-util';
import { Masonry } from '@mui/lab';
import MenuData from '../../../types/ui/menu-data';
import SearchBar from '../../../components/SearchBar';
import SendEmergencyCallDialog from './SendEmergencyCallDialog';
import Util from '../../../utils/util';
import { useContainerSize } from '../../../hooks/use-container-size';
import { useDashboardApi } from '../../../data/dashboard/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  dashboard: Dashboard;
}

/**
 * DashboardTab component displays the content of a single dashboard tab, including dashboard items and a delete confirmation dialog.
 * @param {Props} props - The props passed to the component.
 * @returns {JSX.Element} The rendered DashboardTab component.
 */
const DashboardTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [width] = useContainerSize(containerRef);
  const numOfColumns = Math.floor(width / DASHBOARD_CARD_MAX_WIDTH);
  const [showEmergencyCallDialog, setShowEmergencyCallDialog] = useState<boolean>(false);
  const [sendEmergencyCallDialog, setSendEmergencyCallDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [selectedDashboardItem, setSelectedDashboardItem] = useState<DashboardItem>();
  const [searchKey, setSearchKey] = useState<string>('');
  const dashboardApi = useDashboardApi();
  const apiStatus = dashboardApi.state.dashboard.apiStatus;
  const dashboardDetail = dashboardApi.state.dashboard.dashboardList.find(item => item.id === props.dashboard.id);

  /**
   * Fetches the dashboard item list when the component mounts or the `dashboardDetail` changes.
   */
  useEffect(() => {
    if (dashboardDetail && !dashboardDetail.isFetched) {
      dashboardApi.fetchDashboardItemList(dashboardDetail.id);
    }
  }, []);

  /**
   * Checks if the user has permission to edit the dashboard.
   * The user can edit the dashboard if they have the 'ManageDashboard' permission AND the dashboard itself allows editing (`canEdit` property).
   * @returns {boolean} True if the user can edit the dashboard, false otherwise.
   */
  const canEditDashboard = (): boolean => {

    return LoginUtil.hasPermission(AppFeature.ManageDashboard) && Boolean(dashboardDetail?.canEdit);
  }

  /**
   * Handles the selection of a submenu item in a DashboardTabCard.
   * @param {MenuData} menuItem - The selected menu item.
   * @param {DashboardItem} data - The data of the dashboard item associated with the submenu.
   */
  const onSubmenuItemSelected = (menuItem: MenuData, data: DashboardItem) => {
    setSelectedDashboardItem(data);
    if (menuItem.id === DashbaordTabSubMenuItem.Delete) {
      setOpenDeleteDialog(true);
    } else if (menuItem.id === DashbaordTabSubMenuItem.Edit) {
      setShowEmergencyCallDialog(true);
    }
  }

  /**
   * Handles the click event on a DashboardTabCard.
   * @param {DashboardItem} data - The data of the selected dashboard item.
   */
  const onItemSelected = (data: DashboardItem) => {
    setSelectedDashboardItem(data);
    setSendEmergencyCallDialog(true);
  }

  /**
   * Handles the confirmation of deleting a dashboard item.
   */
  const onDeleteClick = () => {
    if (dashboardDetail && selectedDashboardItem) {
      setOpenDeleteDialog(false);
      dashboardApi.deleteDashboardItem(dashboardDetail.id, selectedDashboardItem.id).then(data => {
        if (data) {
          setSelectedDashboardItem(undefined);
        }
      });
    }
  }

  /**
   * Filters the list of dashboard items based on a search key.
   * It converts both the item title and the search key to lowercase for case-insensitive comparison.
   * @returns {DashboardItem} An array of dashboard items that match the search key.
   */
  const filteredDashboardItemList = () => {

    return (dashboardDetail?.items ?? []).filter(item => item.title.toLowerCase().includes(searchKey.toLowerCase()));
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.searchWrapper}>
        <SearchBar handleChange={setSearchKey} placeholder={t('search')} />
      </Box>
      <InlineAlert message={Util.getApiError([DELETE_DASHBOARD], apiStatus)} />
      <Box sx={styles.innerWrapper}>
        <Grid sx={styles.contentWrapper} ref={containerRef}>
          <Masonry columns={numOfColumns} spacing={isMobile ? 1 : 2} sx={styles.content} sequential>
            {filteredDashboardItemList().map((data, index) => (
              <DashboardTabCard
                key={`dashboard-item-${index}`}
                data={data}
                onItemSelected={onItemSelected}
                canEditDashboard={canEditDashboard()}
                onSubmenuItemSelected={(menu) => onSubmenuItemSelected(menu, data)}
              />
            ))}
          </Masonry>
        </Grid>
      </Box>
      <AlertDialogSmall
        open={openDeleteDialog}
        title={t('deleteDashboardItem')}
        titleIcon={<BinIcon />}
        message={t('deleteDashboardItemMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeleteDialog(false)}
        primaryLabel={t('delete')}
        onPrimaryAction={onDeleteClick}
        isDestructive
        onClose={() => setOpenDeleteDialog(false)}
      />
      {dashboardDetail && selectedDashboardItem &&
        <CreateEditEmergencyCallItemDialog
          dashboardItem={selectedDashboardItem}
          dashboard={dashboardDetail}
          onClose={() => setShowEmergencyCallDialog(false)}
          open={showEmergencyCallDialog} />
        }
        {sendEmergencyCallDialog && selectedDashboardItem && 
          <SendEmergencyCallDialog 
            open = {sendEmergencyCallDialog}
            onClose={() => setSendEmergencyCallDialog(false)}
            item = {selectedDashboardItem}
          />
        }
    </Box>
  );
};

export default DashboardTab;