import { Box, Typography } from '@mui/material';
import { ContentType, MessagePriority } from '../../../../utils/constants';
import React, { useEffect } from 'react';

import AudioPlayer from '../../../../components/AudioPlayer';
import { CallLogSummary } from '../../../../types/call-log-summary';
import CustomLabel from '../../../../components/CustomLabel';
import PaperClipIcon from '../../../../components/CustomIcons/PaperClipIcon';
import { useLogApi } from '../../../../data/log/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  messageId: string;
  summary: CallLogSummary;
}

/**
 * CallLogMessageData component displays the message data from a call log summary, including text, attachments, and audio players.
 * @param {Props} props - The props passed to the component.
 * @returns {JSX.Element} The rendered CallLogMessageData component.
 */
const CallLogMessageData: React.FC<Props> = (props: Props) => {

  const { contentText, contentType, attachmentKey, priority, file } = props.summary;
  const styles = useStyles();
  const { t } = useTranslation();
  const logApi = useLogApi();

  useEffect(() => {
    if (attachmentKey && contentType === ContentType.ContentAudio && !file) {
      logApi.downloadAttachment(props.messageId, props.summary);
    }
  }, []);
  

   /**
   * Checks if the call log has an attachment (excluding audio attachments).
   * @returns {boolean} True if there's an attachment, false otherwise.
   */
  const hasAttachment = () => {

    return Boolean(attachmentKey && contentType !== ContentType.ContentAudio);
  }

  /**
   * Handles the click event on an attachment.
   * If the attachment is an audio file and hasn't been downloaded yet, it initiates the download.
   */
  const onAttachmentClick = () => {
    if (attachmentKey && contentType === ContentType.ContentAudio && !file) {
      logApi.downloadAttachment(props.messageId, props.summary);
    }
  }

  return (
    <Box sx={styles.wrapper}>
      <CustomLabel label={t('message')} />
      {(contentText || hasAttachment()) &&
        <Box sx={styles.innerWrapper}>
          <Typography variant='p1'>{contentText || '-'}</Typography>
          {hasAttachment() && <PaperClipIcon />}
        </Box>
      }
      {Boolean(attachmentKey && contentType === ContentType.ContentAudio) &&
        <Box sx={styles.audioContainer}>
          <AudioPlayer
            src={file ? URL.createObjectURL(file) : undefined}
            onDownloadClick={onAttachmentClick}
            isReceived={true}
            priority={MessagePriority.Normal}
          />
        </Box>
      }
    </Box>
  );
};

export default CallLogMessageData;
