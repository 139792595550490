import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const MicIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M12.9578 7.28906C12.9578 10.027 10.7383 12.2465 8.00039 12.2465C5.26248 12.2465 3.04297 10.027 3.04297 7.28906' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
        <path d='M8 15.0002V12.2461' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
        <rect x='6.0957' y='1' width='3.80977' height='7.61955' rx='1.90489' stroke='currentColor' strokeWidth='2' />
      </svg>
    </SvgIcon>
  );
}

export default MicIcon;