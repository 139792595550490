import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ClockIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_8139_8816)'>
          <path d='M1.49999 7.99999C1.49999 8.91924 1.68105 9.82949 2.03283 10.6788C2.38461 11.5281 2.90023 12.2997 3.55024 12.9497C4.20025 13.5997 4.97193 14.1154 5.8212 14.4671C6.67048 14.8189 7.58074 15 8.49999 15C9.41924 15 10.3295 14.8189 11.1788 14.4671C12.0281 14.1154 12.7997 13.5997 13.4497 12.9497C14.0997 12.2997 14.6154 11.5281 14.9671 10.6788C15.3189 9.82949 15.5 8.91924 15.5 7.99999C15.5 6.14347 14.7625 4.363 13.4497 3.05024C12.137 1.73749 10.3565 0.999989 8.49999 0.999989C6.64347 0.999989 4.863 1.73749 3.55024 3.05024C2.23749 4.363 1.49999 6.14347 1.49999 7.99999Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M8.49999 4.11113V8.00002L10.8333 10.3334' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_8139_8816'>
            <rect width='16' height='16' fill='transparent' transform='translate(0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default ClockIcon;