import Dimens from '../../../../theme/dimens';
import useCommonStyles from '../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the DepartmentMemberCard component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const { lg } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    content: {
      ...commonStyles.borderBottom,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
      p: `${lg} 0`
    },
    avatar: {
      width: Dimens.avatarSize.lg,
      height: Dimens.avatarSize.lg,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary
    },
    contentText: {
      flexDirection: 'column',
      display: 'flex',
      px: lg,
      '& p': {
        ...commonStyles.textEllipsis(1)
      }
    },
    role: {
      color: theme.palette.text.secondary,
      pr: lg
    }
  };

  return styles;
};

export default useStyles;
