import { Avatar, Box, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ChatRole, DATE_PATTERN_MMMM_YYYY, InteractionType } from '../../../utils/constants';
import { Field, Formik } from 'formik';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../../routes/app-nav';
import ChatUtil from '../../../utils/chat-util';
import ConversationData from '../../../types/conversation-data';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import { Group } from '../../../types/group';
import GroupEditPanel from '../GroupEditPanel';
import GroupMemberPanel from '../GroupMemberPanel';
import LoginUtil from '../../../utils/login-util';
import MediaIcon from '../../../components/CustomIcons/MediaIcon';
import MessageIcon from '../../../components/CustomIcons/MessageIcon';
import PencilIcon from '../../../components/CustomIcons/PencilIcon';
import PhoneIcon from '../../../components/CustomIcons/PhoneIcon';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SearchIcon from '../../../components/CustomIcons/SearchIcon';
import Util from '../../../utils/util';
import { useDirectoryApi } from '../../../data/directory/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	group: Group;
	onClose: () => void;
}

/**
 * GroupViewPanel component to view group detail.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const GroupViewPanel: React.FC<Props> = (props: Props) => {

	const { open, group, onClose } = props;
	const styles = useStyles(group?.isMember);
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [showGroupMembers, setShowGroupMembers] = useState<boolean>(false);
	const [showGroupEdit, setShowGroupEdit] = useState<boolean>(false);
	const isChatPage = location.pathname === `${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`;
	const directoryApi = useDirectoryApi();

	/**
	 * Handles the click event on the group avatar.
	 *
	 * If the user is a member of the group, opens the group members panel.
	 */
	const onAvatarClick = () => {
		if (group.isMember) {
			setShowGroupMembers(true);
		}
	}

	/**
	 * Checks if the logged-in user is an admin of a group.
	 *
	 * First, it finds the group information record in the directory state that matches the provided group's group room key.
	 * Then, it checks if the record has group members and if the logged-in user is a member of that group.
	 * Finally, it verifies if the logged-in user's chat role within the group is "Admin".
	 *
	 * @returns {boolean} True if the logged-in user is an admin of the group, false otherwise.
	 */
	const isGroupAdmin = () => {
		let isAdmin = false;
		const record = directoryApi.state.directory.recordList.find(record => record.groupRoomKey === group?.groupRoomKey);
		if (record && !Util.isArrayEmpty(record.groupMembers)) {
			const user = record.groupMembers?.find(member => ChatUtil.isLoggedInUser(member.loginId));
			isAdmin = (user?.chatRole === ChatRole.Admin);
		}

		return isAdmin;
	}

	/**
	 * Handles the click event for the message button.
	 *
	 * This function prepares the conversation data and navigates to the message detail page.
	 */
	const onMessageBtnClick = () => {
		const conversation: ConversationData = {
			...group,
			interactionType: InteractionType.Group,
			senderLoginId: LoginUtil.getLoginId(),
			senderName: LoginUtil.getUserFullName(),
			count: 0
		};
		const navPath = isDesktop ? `${APP_NAV.INBOX}${APP_NAV.INBOX_DETAIL_REL_PATH}`
			: APP_NAV.INBOX_DETAIL_REL_PATH.replace('/', '');
		navigate(navPath, {
			state: conversation
		});
	}

	return (
		<RightDrawerLayout
			hasDefaultBg
			open={open}
			rightIcon={isGroupAdmin() ? <PencilIcon /> : undefined}
			onActionClick={() => setShowGroupEdit(true)}
			onClose={onClose}
		>
			{open && group && !showGroupEdit &&
				<Box sx={styles.contentWrapper}>
					<Box sx={styles.content}>
						<Box sx={styles.groupHeader}>
							<Box sx={styles.avatarWrapper}>
								<Avatar sx={styles.avatar} onClick={onAvatarClick} src={group.imageKey}>
									<Typography variant='h1'>{Util.getInitialsFromName(group.name, 1)}</Typography>
								</Avatar>
							</Box>
							<Box sx={styles.groupContent}>
								<Typography variant='h2' sx={styles.title}>{group.name}</Typography>
								<Typography variant='subheading' sx={styles.subTitle}>
									{`${t('created')} ${Util.formatUTCtoLocal(group.createdAt, DATE_PATTERN_MMMM_YYYY)}`}
								</Typography>
								<Box sx={styles.actionLayout}>
									{group.isMember &&
										<>
											{/* <IconButton><PhoneIcon /></IconButton> */}
											{!isChatPage &&
												<IconButton onClick={onMessageBtnClick}><MessageIcon /></IconButton>
											}
										</>
									}
									{/* uncomment when functionality is available
									{isChatPage &&
										<>
											<IconButton><MediaIcon /></IconButton>
											<IconButton><SearchIcon /></IconButton>
										</>
									} */}
								</Box>
							</Box>
						</Box>
						<Box sx={styles.groupInfo}>
							<Formik initialValues={group} onSubmit={() => {/*Do Nothing*/ }}>
								{group.description && 
									<Field
										name='description'
										label={t('description')}
										placeholder={t('description')}
										component={CustomInput}
										maxRows={Number.MAX_SAFE_INTEGER}
										readOnly
										multiline
									/>
								}								
							</Formik>
						</Box>
					</Box>
					{/* <Box sx={styles.footer}>
						<CustomButton
							title={group.isMember ? t('leaveGroup') : t('requestToJoin')}
							destructive={group.isMember}
							fullWidth
						/>
					</Box> */}
				</Box>
			}
			{group?.isMember &&
				<GroupMemberPanel
					open={showGroupMembers}
					group={group}
					onClose={() => setShowGroupMembers(false)}
				/>
			}
			{group?.isAdmin &&
				<GroupEditPanel
					open={showGroupEdit}
					group={group}
					onClose={() => setShowGroupEdit(false)}
				/>
			}
		</RightDrawerLayout>
	);
};

export default GroupViewPanel;