import Dimens from '../../../theme/dimens'
import useCommonStyles from '../../../theme/use-common-styles'
import { useTheme } from '@mui/material'

/**
 * Custom hook to generate styles for the EditProfileDialog component.
 *
 */
const useStyles = () => {
  const { base, lg, section, xl } = Dimens.spacing
  const theme = useTheme()
  const commonStyles = useCommonStyles()

  const styles = {
    ...commonStyles,
    wrapper: {
      display: 'flex',
      flex: 1,
      p: section,
    },
    formInnerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      flex: 1
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base,
        flex: 'none'
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    title: {
      fontWeight: Dimens.fontWeight.bold
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    content: {
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    },
    saveBtn: {
      display: 'flex',
      [theme.breakpoints.down('lg')]: {
        mt: section
      }
    }
  }

  return styles
}

export default useStyles
