import {
  INIT_REGISTRATION,
  REGISTRATION_FAILURE,
  REGISTRATION_SUCCESS,
  SignUpActionTypes
} from './action-types';

import SignUpState from '../../types/states/sign-up-state';

export const signUpInitialState: SignUpState = {
  isLoading: false,
  error: undefined
};

/**
 * signUpReducer function
 * 
 * This function is the reducer for the user registration state. It handles different 
 * action types dispatched during the registration process and updates the state accordingly.
 * 
 * @param {SignUpState} state - Current registration state.
 * @param {SignUpActionTypes} action - Dispatched Redux action object.
 * 
 * @returns {SignUpState} - Updated registration state based on the dispatched action.
 */
const signUpReducer = (state: SignUpState, action: SignUpActionTypes): SignUpState => {
  switch (action.type) {
		case INIT_REGISTRATION:
			return {
				isLoading: true,
        error: undefined
			};
		case REGISTRATION_SUCCESS:
			return {
				isLoading: true,
        error: undefined
			};
		case REGISTRATION_FAILURE:
			return {
				isLoading: false,
        error: action.payload
			};
		default:
			return state;
  }
};

export default signUpReducer;