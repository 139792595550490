import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DirectoryIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5686_674)'>
          <mask id='path-1-inside-1_5686_674' fill='white'>
            <path d='M10.5377 4.11596C10.5377 3.66848 10.1911 3.30573 9.76342 3.30573C9.33579 3.30573 8.98912 3.66848 8.98912 4.11596V4.84266C8.98912 5.29014 9.33579 5.65289 9.76342 5.65289C10.1911 5.65289 10.5377 5.29014 10.5377 4.84266V4.11596Z' />
          </mask>
          <path d='M10.5377 4.11596C10.5377 3.66848 10.1911 3.30573 9.76342 3.30573C9.33579 3.30573 8.98912 3.66848 8.98912 4.11596V4.84266C8.98912 5.29014 9.33579 5.65289 9.76342 5.65289C10.1911 5.65289 10.5377 5.29014 10.5377 4.84266V4.11596Z' fill='currentColor' />
          <path d='M9.76342 5.30573C9.00197 5.30573 8.53773 4.68659 8.53773 4.11596H12.5377C12.5377 2.65037 11.3802 1.30573 9.76342 1.30573V5.30573ZM8.53773 4.11596V4.84266H12.5377V4.11596H8.53773ZM8.53773 4.84266C8.53773 4.27203 9.00197 3.65289 9.76342 3.65289V7.65289C11.3802 7.65289 12.5377 6.30825 12.5377 4.84266H8.53773ZM9.76342 3.65289C10.5249 3.65289 10.9891 4.27203 10.9891 4.84266H6.98912C6.98912 6.30825 8.1467 7.65289 9.76342 7.65289V3.65289ZM10.9891 4.84266V4.11596H6.98912V4.84266H10.9891ZM10.9891 4.11596C10.9891 4.68659 10.5249 5.30573 9.76342 5.30573V1.30573C8.1467 1.30573 6.98912 2.65037 6.98912 4.11596H10.9891Z' fill='currentColor' mask='url(#path-1-inside-1_5686_674)' />
          <mask id='path-3-inside-2_5686_674' fill='white'>
            <path d='M13.6349 4.11596C13.6349 3.66848 13.2883 3.30573 12.8606 3.30573C12.433 3.30573 12.0863 3.66848 12.0863 4.11596V4.84266C12.0863 5.29014 12.433 5.65289 12.8606 5.65289C13.2883 5.65289 13.6349 5.29014 13.6349 4.84266V4.11596Z' />
          </mask>
          <path d='M13.6349 4.11596C13.6349 3.66848 13.2883 3.30573 12.8606 3.30573C12.433 3.30573 12.0863 3.66848 12.0863 4.11596V4.84266C12.0863 5.29014 12.433 5.65289 12.8606 5.65289C13.2883 5.65289 13.6349 5.29014 13.6349 4.84266V4.11596Z' fill='currentColor' />
          <path d='M12.8606 5.30573C12.0992 5.30573 11.6349 4.68659 11.6349 4.11596H15.6349C15.6349 2.65037 14.4774 1.30573 12.8606 1.30573V5.30573ZM11.6349 4.11596V4.84266H15.6349V4.11596H11.6349ZM11.6349 4.84266C11.6349 4.27203 12.0992 3.65289 12.8606 3.65289V7.65289C14.4774 7.65289 15.6349 6.30825 15.6349 4.84266H11.6349ZM12.8606 3.65289C13.6221 3.65289 14.0863 4.27202 14.0863 4.84266H10.0863C10.0863 6.30825 11.2439 7.65289 12.8606 7.65289V3.65289ZM14.0863 4.84266V4.11596H10.0863V4.84266H14.0863ZM14.0863 4.11596C14.0863 4.68659 13.6221 5.30573 12.8606 5.30573V1.30573C11.2439 1.30573 10.0863 2.65036 10.0863 4.11596H14.0863Z' fill='currentColor' mask='url(#path-3-inside-2_5686_674)' />
          <mask id='path-5-inside-3_5686_674' fill='white'>
            <path d='M10.5377 7.63653C10.5377 7.18905 10.1911 6.82629 9.76342 6.82629C9.33579 6.82629 8.98912 7.18905 8.98912 7.63653V8.36323C8.98912 8.81071 9.33579 9.17346 9.76342 9.17346C10.1911 9.17346 10.5377 8.81071 10.5377 8.36323V7.63653Z' />
          </mask>
          <path d='M10.5377 7.63653C10.5377 7.18905 10.1911 6.82629 9.76342 6.82629C9.33579 6.82629 8.98912 7.18905 8.98912 7.63653V8.36323C8.98912 8.81071 9.33579 9.17346 9.76342 9.17346C10.1911 9.17346 10.5377 8.81071 10.5377 8.36323V7.63653Z' fill='currentColor' />
          <path d='M9.76342 8.82629C9.00197 8.82629 8.53773 8.20716 8.53773 7.63653H12.5377C12.5377 6.17094 11.3802 4.82629 9.76342 4.82629V8.82629ZM8.53773 7.63653V8.36323H12.5377V7.63653H8.53773ZM8.53773 8.36323C8.53773 7.7926 9.00197 7.17346 9.76342 7.17346V11.1735C11.3801 11.1735 12.5377 9.82882 12.5377 8.36323H8.53773ZM9.76342 7.17346C10.5249 7.17346 10.9891 7.79259 10.9891 8.36323H6.98912C6.98912 9.82882 8.1467 11.1735 9.76342 11.1735V7.17346ZM10.9891 8.36323V7.63653H6.98912V8.36323H10.9891ZM10.9891 7.63653C10.9891 8.20716 10.5249 8.82629 9.76342 8.82629V4.82629C8.1467 4.82629 6.98912 6.17094 6.98912 7.63653H10.9891Z' fill='currentColor' mask='url(#path-5-inside-3_5686_674)' />
          <mask id='path-7-inside-4_5686_674' fill='white'>
            <path d='M13.6349 7.63653C13.6349 7.18905 13.2883 6.82629 12.8606 6.82629C12.433 6.82629 12.0863 7.18905 12.0863 7.63653V8.36323C12.0863 8.81071 12.433 9.17346 12.8606 9.17346C13.2883 9.17346 13.6349 8.81071 13.6349 8.36323V7.63653Z' />
          </mask>
          <path d='M13.6349 7.63653C13.6349 7.18905 13.2883 6.82629 12.8606 6.82629C12.433 6.82629 12.0863 7.18905 12.0863 7.63653V8.36323C12.0863 8.81071 12.433 9.17346 12.8606 9.17346C13.2883 9.17346 13.6349 8.81071 13.6349 8.36323V7.63653Z' fill='currentColor' />
          <path d='M12.8606 8.82629C12.0992 8.82629 11.6349 8.20716 11.6349 7.63653H15.6349C15.6349 6.17094 14.4774 4.82629 12.8606 4.82629V8.82629ZM11.6349 7.63653V8.36323H15.6349V7.63653H11.6349ZM11.6349 8.36323C11.6349 7.7926 12.0992 7.17346 12.8606 7.17346V11.1735C14.4774 11.1735 15.6349 9.82882 15.6349 8.36323H11.6349ZM12.8606 7.17346C13.6221 7.17346 14.0863 7.79259 14.0863 8.36323H10.0863C10.0863 9.82882 11.2439 11.1735 12.8606 11.1735V7.17346ZM14.0863 8.36323V7.63653H10.0863V8.36323H14.0863ZM14.0863 7.63653C14.0863 8.20716 13.6221 8.82629 12.8606 8.82629V4.82629C11.2439 4.82629 10.0863 6.17093 10.0863 7.63653H14.0863Z' fill='currentColor' mask='url(#path-7-inside-4_5686_674)' />
          <mask id='path-9-inside-5_5686_674' fill='white'>
            <path d='M10.5377 11.1575C10.5377 10.71 10.1911 10.3472 9.76342 10.3472C9.33579 10.3472 8.98912 10.71 8.98912 11.1575V11.8842C8.98912 12.3316 9.33579 12.6944 9.76342 12.6944C10.1911 12.6944 10.5377 12.3316 10.5377 11.8842V11.1575Z' />
          </mask>
          <path d='M10.5377 11.1575C10.5377 10.71 10.1911 10.3472 9.76342 10.3472C9.33579 10.3472 8.98912 10.71 8.98912 11.1575V11.8842C8.98912 12.3316 9.33579 12.6944 9.76342 12.6944C10.1911 12.6944 10.5377 12.3316 10.5377 11.8842V11.1575Z' fill='currentColor' />
          <path d='M9.76342 12.3472C9.00197 12.3472 8.53773 11.7281 8.53773 11.1575H12.5377C12.5377 9.69187 11.3802 8.34723 9.76342 8.34723V12.3472ZM8.53773 11.1575V11.8842H12.5377V11.1575H8.53773ZM8.53773 11.8842C8.53773 11.3135 9.00197 10.6944 9.76342 10.6944V14.6944C11.3802 14.6944 12.5377 13.3498 12.5377 11.8842H8.53773ZM9.76342 10.6944C10.5249 10.6944 10.9891 11.3135 10.9891 11.8842H6.98912C6.98912 13.3498 8.1467 14.6944 9.76342 14.6944V10.6944ZM10.9891 11.8842V11.1575H6.98912V11.8842H10.9891ZM10.9891 11.1575C10.9891 11.7281 10.5249 12.3472 9.76342 12.3472V8.34723C8.1467 8.34723 6.98912 9.69187 6.98912 11.1575H10.9891Z' fill='currentColor' mask='url(#path-9-inside-5_5686_674)' />
          <mask id='path-11-inside-6_5686_674' fill='white'>
            <path d='M13.6349 11.1575C13.6349 10.71 13.2883 10.3472 12.8606 10.3472C12.433 10.3472 12.0863 10.71 12.0863 11.1575V11.8842C12.0863 12.3316 12.433 12.6944 12.8606 12.6944C13.2883 12.6944 13.6349 12.3316 13.6349 11.8842V11.1575Z' />
          </mask>
          <path d='M13.6349 11.1575C13.6349 10.71 13.2883 10.3472 12.8606 10.3472C12.433 10.3472 12.0863 10.71 12.0863 11.1575V11.8842C12.0863 12.3316 12.433 12.6944 12.8606 12.6944C13.2883 12.6944 13.6349 12.3316 13.6349 11.8842V11.1575Z' fill='currentColor' />
          <path d='M12.8606 12.3472C12.0992 12.3472 11.6349 11.7281 11.6349 11.1575H15.6349C15.6349 9.69187 14.4774 8.34723 12.8606 8.34723V12.3472ZM11.6349 11.1575V11.8842H15.6349V11.1575H11.6349ZM11.6349 11.8842C11.6349 11.3135 12.0992 10.6944 12.8606 10.6944V14.6944C14.4774 14.6944 15.6349 13.3498 15.6349 11.8842H11.6349ZM12.8606 10.6944C13.6221 10.6944 14.0863 11.3135 14.0863 11.8842H10.0863C10.0863 13.3498 11.2439 14.6944 12.8606 14.6944V10.6944ZM14.0863 11.8842V11.1575H10.0863V11.8842H14.0863ZM14.0863 11.1575C14.0863 11.7281 13.6221 12.3472 12.8606 12.3472V8.34723C11.2439 8.34723 10.0863 9.69187 10.0863 11.1575H14.0863Z' fill='currentColor' mask='url(#path-11-inside-6_5686_674)' />
          <path d='M5.45236 11.5209C6.24985 11.5209 6.89634 10.878 6.89634 10.0849C6.89634 9.29183 6.24985 8.64893 5.45236 8.64893C4.65487 8.64893 4.00838 9.29183 4.00838 10.0849C4.00838 10.878 4.65487 11.5209 5.45236 11.5209Z' stroke='currentColor' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' />
          <line x1='5.65874' y1='11.7833' x2='5.65874' y2='14.3113' stroke='currentColor' strokeWidth='1.5' />
          <path d='M1.48264 6.97479V13.6306C1.48264 14.1828 1.93035 14.6306 2.48264 14.6306L14.4826 14.6305C15.0349 14.6305 15.4826 14.1828 15.4826 13.6305V2.36939C15.4826 1.8171 15.0349 1.36939 14.4826 1.36939L8.15055 1.36942C7.59827 1.36942 7.15055 1.81714 7.15055 2.36942V4.97479C7.15055 5.52708 6.70284 5.97479 6.15055 5.97479H2.48264C1.93035 5.97479 1.48264 6.42251 1.48264 6.97479Z' stroke='currentColor' strokeWidth='1.5' />
        </g>
        <defs>
          <clipPath id='clip0_5686_674'>
            <rect width='16' height='16' fill='white' transform='translate(0.482635)' />
          </clipPath>
        </defs>
      </svg>

    </SvgIcon>
  );
}

export default DirectoryIcon;