import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PhotoIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10.334 5.50976H10.3418' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1 5.00349C1 4.30792 1.24583 3.64085 1.68342 3.14901C2.121 2.65717 2.71449 2.38086 3.33333 2.38086H12.6667C13.2855 2.38086 13.879 2.65717 14.3166 3.14901C14.7542 3.64085 15 4.30792 15 5.00349V11.9972C15 12.6927 14.7542 13.3598 14.3166 13.8516C13.879 14.3435 13.2855 14.6198 12.6667 14.6198H3.33333C2.71449 14.6198 2.121 14.3435 1.68342 13.8516C1.24583 13.3598 1 12.6927 1 11.9972V5.00349Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1 11.5091L4.11111 8.39797C4.46582 8.05664 4.86819 7.87695 5.27778 7.87695C5.68736 7.87695 6.08973 8.05664 6.44444 8.39797L9.55556 11.5091' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8.77734 10.7309L10.3329 9.17531C10.6876 8.83399 11.09 8.6543 11.4996 8.6543C11.9092 8.6543 12.3115 8.83399 12.6662 9.17531L14.9996 11.5086' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default PhotoIcon;