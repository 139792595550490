import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AlertCircleIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='27' height='28' viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M1.5 14C1.5 15.5759 1.81039 17.1363 2.41345 18.5922C3.0165 20.0481 3.90042 21.371 5.01472 22.4853C6.12902 23.5996 7.45189 24.4835 8.9078 25.0866C10.3637 25.6896 11.9241 26 13.5 26C15.0759 26 16.6363 25.6896 18.0922 25.0866C19.5481 24.4835 20.871 23.5996 21.9853 22.4853C23.0996 21.371 23.9835 20.0481 24.5866 18.5922C25.1896 17.1363 25.5 15.5759 25.5 14C25.5 12.4241 25.1896 10.8637 24.5866 9.4078C23.9835 7.95189 23.0996 6.62902 21.9853 5.51472C20.871 4.40042 19.5481 3.5165 18.0922 2.91345C16.6363 2.31039 15.0759 2 13.5 2C11.9241 2 10.3637 2.31039 8.9078 2.91345C7.45189 3.5165 6.12902 4.40042 5.01472 5.51472C3.90042 6.62902 3.0165 7.95189 2.41345 9.4078C1.81039 10.8637 1.5 12.4241 1.5 14Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.5 9.25275V14.6709' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.5 18.7346V18.7472' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default AlertCircleIcon;