import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { ContentType, MAX_PROFILE_IMG_SIZE, TAB_USER_PROFILE, TAB_USER_ROLES, VIDEO_INPUT } from '../../../utils/constants';
import React, { useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';

import MenuData from '../../../types/ui/menu-data';
import { PROFILE_EDIT_TAB_LIST } from '../../../utils/ui-constants';
import ProfileAvatar from './ProfileAvatar';
import ProfileDetails from './ProfileDetails';
import ProfileRoles from './ProfileRoles';
import ProfileStatus from './ProfileStatus';
import Toast from '../../../components/Toast';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import WebCamMediaCapture from '../../../components/WebCamMediaCapture';
import theme from '../../../theme/theme';
import useMediaDeviceAvailability from '../../../hooks/use-media-device-availability';
import { useProfileApi } from '../../../data/profile/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	initialUser: User;
	status: MenuData;
}
/**
 * ViewProfile Component
 *
 * This component is responsible for displaying the user's profile details and roles.
 * It adjusts the layout based on the device's screen size, showing either a side-by-side layout
 * for larger screens or a tabbed layout for smaller devices like tablets or mobiles.
 * It also allows the user to update their profile image.
 * 
 * The component also allows the user to change their profile status and switch between different tabs.
 *
 * @component.
 */
const ViewProfile: React.FC<Props> = (props: Props) => {

	const { t } = useTranslation();
	const styles = useStyles();
	const { initialUser, status } = props;
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	const [selectedTab, setSelectedTab] = useState(TAB_USER_PROFILE);
	const profileApi = useProfileApi();
	const [toastMsg, setToastMsg] = useState<string>('');
	const [showWebCam, setShowWebCam] = useState<boolean>(false);
	const { checkDeviceAvailability } = useMediaDeviceAvailability();
	const [user, setUser] = useState<User>(initialUser);

	// Synchronize the user state when `initialUser` prop changes
	useEffect(() => {
		setUser(initialUser);
	}, [initialUser]);

	/**
	 * Function to render the appropriate tab panel based on the selected tab.
	 * @param {string} tabId - Identifier of the selected tab.
	 */
	const getTabPanel = (tabId: string) => {
		switch (tabId) {
			case TAB_USER_PROFILE:
				return <ProfileDetails />;
			case TAB_USER_ROLES:
				return <ProfileRoles />;
		}
	};

	/**
	 * Initiates camera access for profile image capture.
	 * If the camera is unavailable, displays an error toast.
	 */
	const handleCameraClick = () => {
		checkDeviceAvailability(VIDEO_INPUT).then(isCamAvailable => {
			if (isCamAvailable) {
				setShowWebCam(true);
			} else {
				setToastMsg(t('cameraNotAvailable'));
			}
		});
	};

	/**
	 * Callback after successful capture from the camera.
	 * @param {File} file - Captured image file.
	 */
	const onCaptureComplete = (file: File) => {
		setShowWebCam(false);
		handleFile(file);
	};

	/**
	 * Processes the captured or uploaded file for profile image update.
	 * Checks file type and size constraints before proceeding.
	 *
	 * @param {File} file - The file to be processed.
	 */
	const handleFile = async (file: File) => {
		const contentType = Util.getFileType(file.name);

		if (contentType === ContentType.ContentFile) {
			setToastMsg(t('fileNotSupported'));
		} else {
			if (file.size < MAX_PROFILE_IMG_SIZE) {
				handleFileUpload(file);
			}
			else {
				setToastMsg(t('profileImgSizeExceed'));
			}
		}
	};

	/**
	 * Uploads the selected file as the user's profile image.
	 * Updates user state upon success or displays an error toast on failure.
	 *
	 * @param {File} file - The file to be uploaded.
	 */
	const handleFileUpload = async (file: File) => {
		try {
			const updatedUser = await profileApi.updateProfileImage(file);
			if (updatedUser) {
				setUser(updatedUser);
			} else {
				setToastMsg(t('imageUploadFailed')); // Display a generic error if updatedUser is null or undefined
			}
		} catch (error: any) { /* eslint-disable-line */
			// Display a generic error message if something goes wrong
			setToastMsg(t('imageUploadFailed'));
		}
	};

	return (
		<Box sx={styles.container}>
			<Box sx={styles.userHeader}>
				<ProfileAvatar
					user={user}
					onImageUpload={handleFile}
					handleCameraClick={handleCameraClick}
				/>
				{showWebCam &&
					<WebCamMediaCapture
						onCaptureComplete={onCaptureComplete}
						onClose={() => setShowWebCam(false)}
						enableVideo={false} />
				}
				<Toast open={Boolean(toastMsg)} severity='error' title={toastMsg} onClose={() => setToastMsg('')} />
				<Box sx={styles.userInfo}>
					<Typography variant='h2' sx={styles.title}>{user.name}</Typography>
					<Typography variant='subheading' sx={styles.subTitle}>{user.email}</Typography>
					<Box sx={styles.subheadingWrapper}>
						<Typography variant='p1'>{user.type}</Typography>
						<Divider />
						<Typography variant='p1'>{user.position}</Typography>
						<Divider />
						<Typography variant='p1'>{user.userCode}</Typography>
					</Box>
					{!isMobile &&
						<ProfileStatus initialStatus={status} />
					}
				</Box>
			</Box>
			{/* Profile Info: Conditional layout */}
			{!isTablet ? (
				// Large screen: show profile and roles side by side
				<Box sx={styles.profileInfo}>
					<ProfileDetails />
					{/* Profile roles hidden <ProfileRoles /> */}
				</Box>
			) : (
				// Small/medium screen: show tabs
				<TabContext value={selectedTab} >
					{/*Hide the tabs as Roles UI is not needed just yet. */}
					{/* <TabList onChange={(_, newValue) => setSelectedTab(newValue)} aria-label="profile roles tabs" sx={styles.tabList}>
						{
							PROFILE_EDIT_TAB_LIST.map((item, index) => (
								<Tab key={`profile_edit_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
							))
						}
					</TabList> */}
					{
						PROFILE_EDIT_TAB_LIST.map((item, index) => (
							<TabPanel key={`profile_edit_tab_panel_${index}`} value={item.id} sx={styles.tabPanel}>
								{getTabPanel(item.id)}
							</TabPanel>
						))
					}
				</TabContext>
			)}
		</Box>
	);
};

export default ViewProfile;
