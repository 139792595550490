/**
 * Custom hook to check the availability of media devices (audio/video).
 *
 * @returns {Object} An object with a `checkDeviceAvailability` function.
 */
const useMediaDeviceAvailability = () => {

  /**
   * Checks the availability of a specific media device type.
   *
   * @param {MediaDeviceKind} deviceType - The type of media device to check (e.g., 'audioinput', 'videoinput').
   * 
   * @returns {Promise<boolean>} A promise that resolves to true if the device is available, false otherwise.
   */
  const checkDeviceAvailability = async (deviceType: MediaDeviceKind): Promise<boolean> => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const isDevicePresent = devices.some(device => device.kind === deviceType);
      return isDevicePresent;
    } catch (error) {
      return false;
    }
  };

  return {
    checkDeviceAvailability,
  };
};

export default useMediaDeviceAvailability;
