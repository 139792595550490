import { Box, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import ChallengeData from '../../types/challenge-data';
import CustomButton from '../../components/CustomButton';
import OTPInput from '../../components/OTPInput';
import { OTP_LENGTH } from '../../utils/constants';
import SignInLayout from '../../layouts/SignInLayout';
import ThemeUtil from '../../theme/theme-util';
import Toast from '../../components/Toast';
import Util from '../../utils/util';
import VerifyCodeRequest from '../../types/verify-code-request';
import VerifyImageDark from '../../assets/sign_in_verification_dark.svg';
import VerifyImageLight from '../../assets/sign_in_verification_light.svg';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * VerificationScreen Component
 *
 * This component renders the verification screen for entering an OTP code.
 * It leverages the SignInLayout component for a consistent layout.
 * 
 * @returns {JSX.Element} - The rendered JSX element for the verification screen.
 */
const VerificationScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const challengeData: ChallengeData = location.state as ChallengeData;
  const signInApi = useSignInApi();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [showToast, setShowToast] = useState<boolean>(false);

  // Handle initial state and challenge list on mount
  useEffect(() => {
    if (!challengeData) {
      // If the user tries to access this page directly, redirect him to sign-in page.
      navigate(APP_NAV.SIGN_IN);
    } else if(!Util.isArrayEmpty(signInApi.state.challengeList)) {
      setShowToast(true);
    }
  }, [signInApi.state.challengeList]);

  /**
   * Handles form submission.
   * 
   * This function submits the entered verification code to the sign-in API using the challenge path.
   * 
   * @param {React.FormEvent<HTMLFormElement>} event - The form submit event object.
   * @returns {void}
   */
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let request: VerifyCodeRequest;
    if (challengeData.skipAPICall) {
      request = {
        resetCode: verificationCode
      };
    } else {
      request = {
        code: verificationCode
      };
    }
    signInApi.performCodeVerification(challengeData.challengePath, request);
  }

  /**
   * Handles resend code click action.
   * 
   * This function triggers the sign-in API to resend the verification code to the user's email.
   * 
   * @returns {void}
   */
  const onResendClick = () => {
    signInApi.sendEmail(challengeData.resendPath);
  }

  return (
    <SignInLayout
      error={signInApi.state.apiStatus?.error}
      showProgressBar={signInApi.state.apiStatus?.isLoading}
      headerTitle={t('verifyYou')}
      headerDesc={`${t('verifyCodeMsg')} ${challengeData?.loginId || t('yourEmail')}`}
      footerCaption={t('signInVerifyFtrCaption')}
      footerAction={t('resend')}
      image={isLightTheme ? VerifyImageLight : VerifyImageDark}
      onFooterClick={onResendClick}
    >
      <form onSubmit={e => onSubmit(e)} style={styles.form}>
        <Box sx={styles.formContent}>
        <OTPInput length={OTP_LENGTH} value={verificationCode} onChange={setVerificationCode} />
        <Box sx={styles.spacer} />
        <CustomButton
          type='submit'
          title={challengeData.skipAPICall ? t('submit') : t('signIn')}
          color='primary'
          disabled={verificationCode.length !== OTP_LENGTH}
          fullWidth
        />
        </Box>
      </form>
      <Toast open={showToast} title={t('verificationResent')} onClose={() => setShowToast(false)}/>
    </SignInLayout>
  );
};

export default VerificationScreen;
