import { Box, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TAB_DEPT_EDIT_DETAIL, TAB_DEPT_EDIT_MEMBER, TAB_DEPT_EDIT_SETTINGS } from '../../../utils/constants';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import CircularProgressBar from '../../../components/CircularProgressBar';
import { DEPT_EDIT_TAB_LIST } from '../../../utils/ui-constants';
import Department from '../../../types/department';
import { FETCH_DEPT_DETAIL } from '../../../data/department/action-types';
import InlineAlert from '../../../components/InlineAlert';
import MembersTab from './MembersTab';
import ProfileTab from './DetailsTab';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SettingsTab from './SettingsTab';
import Util from '../../../utils/util';
import { useDepartmentApi } from '../../../data/department/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	department?: Department;
	onClose: (action?: string) => void;
}

/**
 * Renders a drawer with tabs for editing department, add/remove members and settings.
 *
 * @param {Props} props - Component props containing open state, department data, and close callback.
 * 
 * @returns JSX element representing the department edit panel.
 */
const DepartmentEditPanel: React.FC<Props> = (props: Props) => {

	const { open, department, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState(TAB_DEPT_EDIT_DETAIL);
	const [detailsEdited, setDetailsEdited] = useState<boolean>(false);
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const deptApi = useDepartmentApi();
	const apiStatus = deptApi.state.department.apiStatus;

	/**
	 * Fetches department details if the provided department object is incomplete.
	 */
	useEffect(() => {
		const deptDetail = getDeptDetail();
		if (open && department && (!deptDetail || !deptDetail?.isFetched)) {
			deptApi.fetchDeptDetail(department.id);
		}
	}, [open]);

	/**
	 * Fetches department details if not already available or incomplete.
	 *
	 * @returns {Department | null} The fetched department details or null if not found or loading.
	 */
	const getDeptDetail = () => {
		const detail = deptApi.state.department.departmentList.find(item => item.id === department?.id);

		return detail;
	}

	const deptDetail = getDeptDetail();


	/**
   * Renders the appropriate TabPanel content based on the selected tab ID.
   *
   * @param {string} tabId - ID of the selected tab.
	 * 
   * @returns {JSX.Element} The content for the TabPanel.
   */
	const getTabPanel = (tabId: string) => {
		if (deptDetail) {
			switch (tabId) {
				case TAB_DEPT_EDIT_DETAIL:
					return <ProfileTab department={deptDetail} onChange={setDetailsEdited} />;
				case TAB_DEPT_EDIT_MEMBER:
					return <MembersTab department={deptDetail} />;
				case TAB_DEPT_EDIT_SETTINGS:
					return <SettingsTab department={deptDetail} onClose={onClose} />;
			}
		}
	}

	/**
	 * Handles the logic for closing the tab.
	 * If there are unsaved changes (`detailsEdited` is true), it prompts the user with a save dialog.
	 * Otherwise, it resets the state and closes the tab.
	 */
	const onTabClose = () => {
		if (detailsEdited) {
				setShowSaveDialog(true); // Show save confirmation dialog if changes exist
		} else {
				setDetailsEdited(false); // Reset edit state
				setShowSaveDialog(false); // Ensure save dialog is hidden
				onClose(); // Proceed with closing the tab
		}
	};

	/**
	* Handles closing the tab without saving.
	* Resets the state and closes the tab immediately.
	*/
	const onLeaveWithoutSaving = () => {
		setDetailsEdited(false); // Reset edit state
		setShowSaveDialog(false); // Hide save confirmation dialog
		onClose(); // Close the tab
	};

	return (
		<RightDrawerLayout open={open} title={t('editDepartment')} onClose={onTabClose}>
			<InlineAlert message={Util.getApiError([FETCH_DEPT_DETAIL], apiStatus)} />
			{open && deptDetail?.isFetched &&
				<TabContext value={selectedTab}>
					<TabList variant='scrollable' onChange={(_, value) => setSelectedTab(value)} sx={styles.tabList}>
						{
							DEPT_EDIT_TAB_LIST.map((item, index) => (
								<Tab key={`use_edit_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
							))
						}
					</TabList>
					<Box sx={styles.tabListWrapper}>
						{
							DEPT_EDIT_TAB_LIST.map((item, index) => (
								<TabPanel key={`department_edit_tab_panel_${index}`} value={item.id} sx={styles.tabPanel}>
									{getTabPanel(item.id)}
								</TabPanel>
							))
						}
					</Box>
				</TabContext>
			}
			<CircularProgressBar show={Util.isApiLoading([FETCH_DEPT_DETAIL], apiStatus)} />
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={onLeaveWithoutSaving}
				onClose={() => setShowSaveDialog(false)}
			/>
		</RightDrawerLayout>
	);
};

export default DepartmentEditPanel;