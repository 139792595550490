import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const ArrowLeftIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_5612_14062)'>
          <path d='M1.66406 12.5H23.1641' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M1.66406 12.5L10.8783 21.7143' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M1.66406 12.4999L10.8783 3.28564' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_5612_14062'>
            <rect width='24' height='24' fill='transparent' transform='translate(0.414062 0.5)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default ArrowLeftIcon;