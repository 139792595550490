import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the CustomLabel component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

	const theme = useTheme();
	
	const styles = {
		header: {
			flexDirection: 'row',
			pb: Dimens.spacing.xs
		},
		noPad: {
			pb: 0
		},
		subLabel: {
			pl: Dimens.spacing.xxs,
			color: theme.palette.text.secondary,
			fontWeight: Dimens.fontWeight.semiBold
		}
	};

	return styles;
};

export default useStyles;