import {
	API_FAILURE,
	ApiFailureAction,
	FETCH_PROFILE,
	FetchProfileAction,
	PROFILE_SUCCESS,
	ProfileStateResetAction,
	ProfileSuccessAction,
	RESET_ERROR_STATE,
	RESET_PROFILE_STATE,
	ResetErrorStateAction,
	UPDATE_PROFILE,
	UPDATE_PROFILE_IMAGE,
	UPDATE_PROFILE_STATUS,
	UPDATE_PROFILE_STATUS_SUCCESS,
	UpdateProfileAction,
	UpdateProfileImageAction,
	UpdateProfileStatusAction,
	UpdateProfleStatusSuccessAction,
} from './action-types';

import AppError from '../../exception/app-error';
import { AssignedSessionStatus } from '../../utils/constants';
import { User } from '../../types/user';

/**
 * resetErrorState
 *
 * Action creator to reset the error state within the Redux store.
 * 
 * This is dispatched when there is a need to clear any existing error messages or
 * reset the error-related state to its initial condition.
 *
 * @function
 * @returns {ResetErrorStateAction} An action object with the type RESET_ERROR_STATE.
 */
export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR_STATE
});

/**
 * initProfileFetch
 *
 * Action creator to initiate the process of fetching the profile information.
 * 
 * This action is dispatched when a request to fetch the profile begins.
 * It signals the start of an asynchronous operation to retrieve the profile data.
 *
 * @function
 * @returns {FetchProfileAction} An action object with the type FETCH_PROFILE.
 */
export const initProfileFetch = (): FetchProfileAction => ({
	type: FETCH_PROFILE,
});

/**
 * initProfileUpdate
 *
 * Action creator to initiate the process of updating the profile information.
 * 
 * This action is dispatched when a request to update the profile begins.
 * It signals the start of an asynchronous operation to update the profile data.
 *
 * @function
 * @returns {UpdateProfileAction} An action object with the type UPDATE_PROFILE.
 */
export const initProfileUpdate = (): UpdateProfileAction => ({
	type: UPDATE_PROFILE,
});

/**
 * initProfileImageUpdate
 *
 * Action creator to initiate the process of updating the profile image.
 * 
 * This action is dispatched when a request to update the profile image begins.
 * It signals the start of an asynchronous operation to update the user's profile image.
 *
 * @function
 * @returns {UpdateProfileImageAction} An action object with the type UPDATE_PROFILE_IMAGE.
 */
export const initProfileImageUpdate = (): UpdateProfileImageAction => ({
	type: UPDATE_PROFILE_IMAGE,
});

/**
 * Action creator for initializing a profile status update.
 * @returns {UpdateProfileStatusAction} An action object with the update profile status type.
 */
export const initProfileStatusUpdate = (): UpdateProfileStatusAction => ({
	type: UPDATE_PROFILE_STATUS,
});

/**
 * profileSuccess
 *
 * Action creator to handle the successful retrieval of the profile.
 * 
 * This action is dispatched when the user's profile has been successfully fetched
 * from the server, and it contains the user's profile data as a payload.
 *
 * @function
 * @param {User} user - The user object representing the fetched profile data.
 * 
 * @returns {ProfileSuccessAction} An action object with the type PROFILE_SUCCESS and the user's profile as payload.
 */
export const profileSuccess = (user: User): ProfileSuccessAction => ({
	type: PROFILE_SUCCESS,
	payload: user
});

/**
 * Action creator for a successful profile status update.
 * @param {AssignedSessionStatus} status - The updated profile status.
 * @returns {UpdateProfleStatusSuccessAction} An action object with the success type and payload.
 */
export const profileStatusUpdateSuccess = (status: AssignedSessionStatus): UpdateProfleStatusSuccessAction => ({
	type: UPDATE_PROFILE_STATUS_SUCCESS,
	payload: status
});

/**
 * apiFailure
 *
 * Action creator to handle API failures.
 * 
 * This action is dispatched when an API call fails, and it carries the error information
 * (encapsulated in an `AppError` object) as the payload.
 *
 * @function
 * @param {AppError} error - The error object that contains information about the API failure.
 * 
 * @returns {ApiFailureAction} An action object with the type API_FAILURE and the error as payload.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error
});

/**
 * resetProfileState
 *
 * Action creator to reset the profile state in the Redux store.
 * 
 * This is typically dispatched when the profile state needs to be cleared or reset
 * to its initial condition, such as when logging out or navigating away from the profile.
 *
 * @function
 * @returns {ProfileStateResetAction} An action object with the type RESET_PROFILE_STATE.
 */
export const resetProfileState = (): ProfileStateResetAction => ({
	type: RESET_PROFILE_STATE
});