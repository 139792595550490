import { Box, ListItemButton, Typography } from '@mui/material';
import React, { useState } from 'react';

import AlertDialogSmall from '../../../../components/AlertDialogSmall';
import BinIcon from '../../../../components/CustomIcons/BinIcon';
import CircularProgressBar from '../../../../components/CircularProgressBar';
import InlineAlert from '../../../../components/InlineAlert';
import ListItemAddRemoveCard from '../../../../components/ListItemAddRemoveCard';
import { REMOVE_TEAM_MEMBER } from '../../../../data/team/action-types';
import SearchBar from '../../../../components/SearchBar';
import Team from '../../../../types/team';
import TeamAddMemberPanel from './TeamAddMemberPanel';
import TeamMemberBaseInfo from '../../../../types/team-member-base-info';
import TeamMemberChangeRequest from '../../../../types/team-members-change-request';
import UserAddIcon from '../../../../components/CustomIcons/UserAddIcon';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTeamApi } from '../../../../data/team/api';
import { useTranslation } from 'react-i18next';

interface Props {
  team: Team;
}

/**
 * MembersTab component for managing team members.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const MembersTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [showAddMemberPanel, setShowAddMemberPanel] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<TeamMemberBaseInfo>();
  const [searchKey, setSearchKey] = useState<string>('');
  const teamApi = useTeamApi();
  const apiStatus = teamApi.state.team.apiStatus;

 
  /**
   * Handles the click event on the "Remove" menu item for a member.
   * Opens the confirmation dialog for removing the member.
   *
   * @param {TeamMemberBaseInfo} member - Member object to be removed.
   */
  const onRemoveMemberClick = (member: TeamMemberBaseInfo) => {
    setSelectedMember(member);
    setOpenDeleteDialog(true);
  }

  /**
   * Handles the click event on the confirmation dialog's "OK" button.
   * Removes the selected member from the team.
   */
  const onDeleteClick = () => { 
    const request: TeamMemberChangeRequest = {
			loginId: selectedMember?.loginId,
			teamKey: props.team.teamKey 
		};
    
    if (selectedMember) {  
      teamApi.addOrRemoveTeamMember(request).then(data => {
        if (data) {
          setSelectedMember(undefined);
        }
      });
    }
    setOpenDeleteDialog(false);
  }

  /**
   * Sort & Filters the team members based on search criteria.
   *
   * @returns The sorted and filtered member list.
   */
    const getFilteredMemberList = () => {

      return (props.team.teamMembers || [])
        .filter((member) =>
          member.name?.toLowerCase().includes(searchKey.toLowerCase())
          || member.loginId?.toLowerCase().includes(searchKey.toLowerCase())
        ).sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    };
  
    const memberList = getFilteredMemberList();

  return (
    <Box sx={styles.wrapper}>
      <InlineAlert message={Util.getApiError([REMOVE_TEAM_MEMBER], apiStatus)} />
      <Box sx={styles.contentWrapper}>
        <SearchBar sx={styles.searchBar} placeholder={t('search')} handleChange={setSearchKey} />        
          <ListItemButton sx={styles.addMemberLayout} onClick={() => setShowAddMemberPanel(true)}>
            <Box sx={styles.addMemberBtn}><UserAddIcon /></Box>
            <Typography variant='p1' sx={styles.addMemberText}>{t('addMembers')}</Typography>
          </ListItemButton>        
        {memberList.map((member, index) => (
          <ListItemAddRemoveCard
            key={`team-member-${index}`}
            title={member.name ?? ''}
            subTitle={member.loginId}
            imageUrl={member.thumbnailImageKey}
            isExistingItem={true}
            onActionClick={() => onRemoveMemberClick(member)}
          />
        ))}
      </Box>
      <AlertDialogSmall
        open={openDeleteDialog}
        title={t('removeTeamMbr')}
        titleIcon={<BinIcon />}
        message={t('removeTeamMbrMsg')}
        secondaryLabel={t('cancel')}
        onSecondaryAction={() => setOpenDeleteDialog(false)}
        primaryLabel={t('ok')}
        onPrimaryAction={onDeleteClick}
        isDestructive
        onClose={() => setOpenDeleteDialog(false)}
      />
      <TeamAddMemberPanel
        open={showAddMemberPanel}
        team={props.team}
        onClose={() => setShowAddMemberPanel(false)}
      />
      <CircularProgressBar show={Util.isApiLoading([REMOVE_TEAM_MEMBER], apiStatus)} />
    </Box>
  );
};

export default MembersTab;