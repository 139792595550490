import Dimens from '../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the ProfileRoles component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {
	const { section } = Dimens.spacing;
	const theme = useTheme();

	const styles = {
		container: {
			p: section,
			display: 'flex',
			flexDirection: 'column',
			flex: 0.3,
			height: 'inherit',
			border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			borderRadius: Dimens.radius.md,
			[ theme.breakpoints.down('md') ]: {
				flex: 1,
				p: section
			}
		}
	};

	return styles;
};

export default useStyles;