import { Box, IconButton, LinearProgress, Slider, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import DownloadIcon from '../CustomIcons/DownloadIcon';
import { MessagePriority } from '../../utils/constants';
import PauseIcon from '../CustomIcons/PauseIcon';
import PlayIcon from '../CustomIcons/PlayIcon';
import useStyles from './styles';

export enum Visualizer {
  Wav = 'WAV',
  Line = 'LINE'
}

export interface Props {
  /**
   * @default NORMAL
   */
  priority?: MessagePriority;
  src?: string;
  /**
   * @default Line
   */
  visualizerType?: Visualizer;
  isReceived?: boolean;
  onDownloadClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

/**
 * Renders an audio player component.
 * 
 * @param {Props} props - Component props.
 * @returns {JSX.Element} - The rendered audio player component.
 */
const AudioPlayer: React.FC<Props> = (props: Props) => {

  const styles = useStyles(props.priority, props.isReceived);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (props.src) {
      setCurrentTime(0);
      setDuration(0);
    }
  }, [props.src, props.isReceived]);

  /**
   * Handles audio playback events and updates state accordingly.
   */
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('timeupdate', () => {
        if (audioRef.current?.currentTime) {
          setCurrentTime(audioRef.current.currentTime);
        }
      });
      audioRef.current.addEventListener('loadedmetadata', () => {
        if (audioRef.current?.duration) {
          setDuration(audioRef.current.duration);
        }
      });
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
      });
    }
  }, [audioRef]);

  /**
   * Gets the appropriate icon for playback control.
   * 
   * @returns {JSX.Element} - The icon to display (Play, Pause, or Download).
   */
  const getPlayIcon = () => {
    let icon = <DownloadIcon />;
    if (props.src) {
      icon = isPlaying ? <PauseIcon /> : <PlayIcon />
    }

    return icon;
  }

  /**
   * Handles playback control (play, pause, or download).
   * 
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - Click event.
   */
  const managePlayback = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.src) {
      if (isPlaying) {
        pauseAudio();
      } else {
        playAudio();
      }
    } else {
      e.preventDefault();  // Prevent page navigation on download
      props.onDownloadClick(e);
    }
  }

  /**
  * Starts audio playback.
  */
  const playAudio = () => {
    if (audioRef.current) {
      setIsPlaying(true);
      setTimeout(() => audioRef.current?.play(), 500);
    }
  };

  /**
   * Pauses audio playback.
   */
  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  /**
   * Formats the given seconds into a formatted time string.
   *
   * @param {number} seconds The number of seconds to format.
   * 
   * @returns {string} The formatted time string.
   */
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(Math.round(seconds) / 60);
    const secondsRemaining = Math.round(seconds) % 60;

    return `${minutes.toString().padStart(2, '0')}:${secondsRemaining.toFixed(0).padStart(2, '0')}`;
  };

  /**
   * Handles changes to the audio playback position.
   * Updates the current playback time of the audio element based on the provided value.
   * 
   * @param {number} value - The new playback time in seconds.
   */
  const handleChange = (value: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = value;
      setCurrentTime(value);
    }
  }

  return (
    <Box sx={styles.audioPlayer}>
      <IconButton sx={styles.iconBtn} onClick={managePlayback}>
        {getPlayIcon()}
      </IconButton>
      <audio ref={audioRef} src={props.src} preload='auto' />
      <Slider
        defaultValue={0}
        size='small'
        sx={styles.slider}
        onChange={(_, value) => handleChange(value as number)}
        value={currentTime}
        min={0}
        max={duration}
      />
      <Typography variant='caption' sx={styles.timeInfo}>
        {`${formatTime(currentTime)} / ${formatTime(duration)}`}
      </Typography>
    </Box>
  );
};

export default AudioPlayer;
