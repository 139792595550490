import Dimens from '../../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * This hook creates styles for the ChildDrawer component based on theme and dimensions.
 * 
 * @returns {object} - An object containing styles for the child drawer and its elements.
 */
const useStyles = () => {

  const theme = useTheme();
  const {xxs, xs, xl, lg} = Dimens.spacing;

  const styles = {
    drawer: {
      bgcolor: theme.palette.background.paper,
      '&.MuiDrawer-root': {
        zIndex: 1,
        boxShadow: 'none',
        width: Dimens.drawer.childWidth
      },
      '& .MuiDrawer-paper': {
        p: `${xl} ${lg}`,
        width: Dimens.drawer.childWidth,
        marginLeft: Dimens.drawer.parentWidth, // Position it right next to the permanent drawer
        zIndex: 0,
        boxShadow: 'none',
        borderRight: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        backgroundImage: 'none'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    header: {
      fontWeight: Dimens.fontWeight.semiBold,
      color: theme.palette.primary.main,
      p: `0 ${xs} 0 ${xxs}`,
      mb: Dimens.spacer
    }
  };

  return styles;
};

export default useStyles;
