import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const EmergencyIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='34' height='30' viewBox='0 0 34 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M14.5447 3.38492L2.38445 23.6857C2.1339 24.1199 2.00136 24.6121 2.00001 25.1134C1.99866 25.6146 2.12854 26.1075 2.37674 26.5431C2.62494 26.9786 2.98282 27.3415 3.41479 27.5959C3.84676 27.8502 4.33778 27.987 4.83902 27.9928H29.161C29.6622 27.987 30.1532 27.8502 30.5852 27.5959C31.0172 27.3415 31.3751 26.9786 31.6233 26.5431C31.8715 26.1075 32.0013 25.6146 32 25.1134C31.9986 24.6121 31.8661 24.1199 31.6156 23.6857L19.4539 3.38492C19.1978 2.9633 18.8374 2.61475 18.4075 2.37286C17.9776 2.13097 17.4926 2.00391 16.9993 2.00391C16.506 2.00391 16.021 2.13097 15.5911 2.37286C15.1612 2.61475 14.8008 2.9633 14.5447 3.38492Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M17.0859 10.668V16.4434' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M17.0859 22.2188H17.0986' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default EmergencyIcon;