import { useEffect, useState } from 'react';

/**
 * useChatScrollHandler custom hook
 *
 * This hook manages scroll behavior and state within a chat conversation UI element. 
 * It takes a reference to the chat container element (`containerRef`) and provides the following functionalities:
 *   - Tracks "scroll to top" button visibility (`showBackToStartBtn`):
 *     - Shows the button when the user has scrolled up from the bottom of the chat history.
 *   - Detects potential need for loading more messages (`isLoadMore`):
 *     - Triggers when the user scrolls close to the bottom of the currently displayed chat history, 
 *       indicating there might be more messages to load.
 *   - Provides a `scrollToBottom` function for smooth scrolling to the bottom of the chat history.
 *
 * The hook utilizes the `useEffect` hook to attach a scroll event listener to the chat container element 
 * and update state based on scroll position. It also uses a timeout within `scrollToBottom` to ensure 
 * the button state is updated correctly after scrolling.
 *
 * @param {React.MutableRefObject<HTMLDivElement | null>} containerRef - Reference to the chat container element.
 *
 * @returns An object containing the following functions and state:
 *   - `scrollToBottom`: Function to scroll the chat history to the bottom.
 *   - `showBackToStartBtn`: State indicating the visibility of the "scroll to top" button (boolean).
 *   - `isLoadMore`: State indicating the potential need for loading more messages (boolean).
 */
export function useChatScrollHandler(containerRef: React.MutableRefObject<HTMLDivElement | null>) {

  const [showBackToStartBtn, setShowBackToStartBtn] = useState<boolean>(false);
  const [isLoadMore, setLoadMore] = useState<boolean>(false);

  /**
   * Attaches a scroll event listener to the chat container element when it's mounted 
   * and removes it on unmount. Updates state based on scroll position within the listener.
   */
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [containerRef.current]);

  /**
   * Smoothly scrolls the chat container element to the bottom.
   * Uses a timeout to ensure the scroll position is accurate after potential re-rendering.
   */
  const scrollToBottom = () => {
    const container = containerRef.current;
    if (container) {
      setTimeout(() => {
        if (container.scrollHeight > container.offsetHeight) {
          container.scrollTop = container.scrollHeight - container.offsetHeight;
        }
        setShowBackToStartBtn(false);
      }, 100);
    }
  }

  /**
   * Handles scroll events on the chat container element.
   * Updates `isLoadMore` to indicate potential need for loading more messages 
   * when near the bottom, and `showBackToStartBtn` for button visibility based on scroll position.
   */
  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      setLoadMore(clientHeight - scrollTop > scrollHeight - 50);
      if (scrollTop < 0) {
        setShowBackToStartBtn(true);
      } else {
        setShowBackToStartBtn(false);
      }
    }
  };

  return { scrollToBottom, showBackToStartBtn, isLoadMore };
}
