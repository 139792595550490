import { ACCEPTED_IMAGE_EXTENSIONS, AttachmentType } from '../../../../utils/constants';
import { Avatar, Badge, IconButton, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';

import CameraIcon from '../../../../components/CustomIcons/CameraIcon';
import MenuData from '../../../../types/ui/menu-data';
import MenuSelectDialog from '../../../../components/MenuSelectDialog';
import { PROFILE_PHOTO_SELECTION_LIST } from '../../../../utils/ui-constants';
import { User } from '../../../../types/user';
import Util from '../../../../utils/util';
import useStyles from './styles';

interface Props {
	user: User;
	onImageUpload: (file: File) => void;
	handleCameraClick: () => void;
}

/**
 * ProfileAvatar Component
 *
 * This component is responsible for displaying the user's profile avatar with a camera badge overlay.
 * It allows the user to upload a new profile image from the camera or photo library.
 * A menu is displayed to select between the camera and photo library when the camera badge is clicked.
 * 
 * The component also includes a hidden file input to handle file selection for uploading profile images.
 *
 * @component
 * @param {Props} props - The component props.
 * @returns {JSX.Element} The rendered ProfileAvatar component.
 */
const ProfileAvatar: React.FC<Props> = (props: Props) => {

	const { user, onImageUpload, handleCameraClick } = props;
	const styles = useStyles();
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(anchorEl);

	/**
   * Closes the menu for selecting the image source.
   */
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	/**
   * Handles the selection of an option from the menu.
   * It triggers either the camera or the file input based on the selected option.
   * 
   * @param {MenuData} option - The selected menu option.
   */
	const handleMenuItemSelected = (option: MenuData) => {
		handleMenuClose();
		if (option.id === AttachmentType.Camera) {
			handleCameraClick();
		} else if (option.id === AttachmentType.Photo) {
			if (fileInputRef.current) {
				fileInputRef.current.click();
			}
		}
	};

	/**
   * Handles changes to the file input, triggered when a new file is selected.
   * The selected file is read as a base64 string and passed to the onImageUpload callback.
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} event - The file input change event.
   */
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			onImageUpload(file); // Send the file to the parent function
		}
	};

	/**
   * Opens the menu for selecting the image source when the camera badge is clicked.
   * 
   * @param {React.MouseEvent<HTMLElement>} event - The click event on the camera badge.
   */
	const handleBadgeClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<Badge
			sx={styles.cameraBadge}
			overlap='circular'
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			badgeContent={
				<IconButton onClick={handleBadgeClick} sx={styles.cameraButton} aria-label='upload picture'>
					<CameraIcon sx={styles.cameraIcon} />
				</IconButton>
			}
		>
			<Avatar sx={styles.avatar} src={ user.image?.thumbnail || undefined}>
				{(!user.image?.thumbnail) && (
					<Typography variant='h1'>{Util.getInitialsFromName(user.name)}</Typography>
				)}
			</Avatar>
			<MenuSelectDialog
				anchorEl={anchorEl}
				open={isMenuOpen}
				onClose={handleMenuClose}
				menuList={PROFILE_PHOTO_SELECTION_LIST}
				onMenuItemSelected={handleMenuItemSelected}
				style= {styles.cameraMenu}
			/>
			<input
				type='file'
				accept={ACCEPTED_IMAGE_EXTENSIONS}
				ref={fileInputRef}
				style={styles.hiddenInput}
				onChange={handleFileChange}
			/>
		</Badge>
	);
};

export default ProfileAvatar;
