import { useLayoutEffect, useState } from 'react';

/**
 * Custom hook to track the dimensions (width and height) of the browser window.
 *
 * This hook uses the `useState` hook to store the current window size and the `useLayoutEffect` 
 * hook to attach and remove a resize event listener. It updates the state whenever the window is resized.
 * 
 * @returns {Array<number>} - An array containing the current width and height of the window.
 */
export function useWindowSize() {

  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    const updateSize = () => setSize([window.innerWidth, window.innerHeight]);
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}