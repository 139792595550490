import { Avatar, Box, Stack, Typography } from '@mui/material';

import CustomButton from '../../../../../components/CustomButton';
import React, { } from 'react';
import Util from '../../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  subTitle: string;
  isSent: boolean;
  onSendClick: () => void;
  isGroup: boolean;
}

/**
 * Renders a card displaying recipient information and a send button.
 *
 * @param {Props} props - Component props containing title, subtitle, sent status, and send click handler.
 * @returns JSX element representing the forward recipient card.
 */
const ForwardRecipientCard: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={styles.content}>
      <Avatar sx={styles.avatar}>
        <Typography variant='p3' >
          {Util.getInitialsFromName(props.title, props.isGroup? 1 : 2)}
        </Typography>
      </Avatar>
      <Box sx={styles.contentRight}>
        <Stack sx={styles.contentText}>
          <Typography variant='p1' sx={styles.primaryText}>
            {props.title}
          </Typography>
          <Typography variant='p1' sx={styles.primaryText}>
            {props.subTitle}
          </Typography>
        </Stack>
        <CustomButton
          small
          title={props.isSent ? t('sent') : t('send')}
          disabled={props.isSent}
          onClick={props.onSendClick}
        />
      </Box>
    </Box>
  );
};

export default ForwardRecipientCard;
