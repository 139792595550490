import { DEFAULT_PAGE_SIZE, DeviceSortBy, ListItemType, SortOrder } from '../../../utils/constants';
import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import CircularProgressBar from '../../../components/CircularProgressBar';
import InlineAlert from '../../../components/InlineAlert';
import ListItemAddRemoveCard from '../../../components/ListItemAddRemoveCard';
import { Pager } from '../../../types/pager';
import { PagerListRequest } from '../../../types/pager-list-request';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import SearchBar from '../../../components/SearchBar';
import { UPDATE_USER_DETAIL } from '../../../data/user/action-types';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import { useDeviceApi } from '../../../data/device/api';
import { useDirectoryApi } from '../../../data/directory/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

interface Props {
	open: boolean;
	user: User;
	onClose: () => void;
}

/**
 * Assign Device Panel Component
 *
 * This component displays a drawer panel for assigning a pager to a user.
 * It allows searching for available pagers, displays a list of pagers, and handles assigning a selected pager to the user.
 *
 * @param {Props} props Component props containing user information and panel state.
 */
const AssignDevicePanel: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const { t } = useTranslation();
	const [searchKey, setSeachKey] = useState<string>('');
	const deviceApi = useDeviceApi();
	const userApi = useUserApi();
	const apiStatus = userApi.state.user.apiStatus;

	useEffect(() => {
		deviceApi.resetDevice();
	}, [props.open]);
	
	/**
   * Filters the pager list based on state and search key.
   *
   * @returns {Array<Pager>} An array of filtered pagers.
   */
	const getFilteredPagerList = (): Array<Pager> => {
		let pagerList: Array<Pager> = [];
		if (!Util.isArrayEmpty(deviceApi.state.device.pagerList)) {
			pagerList = deviceApi.state.device.pagerList.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
		}

		return pagerList;
	}

	const filteredPagerList = getFilteredPagerList();

	/**
   * Assigns the selected pager to the user.
   *
   * @param {Pager} pager The pager to assign.
   */
	const assignDevice = (pager: Pager): void => {
		const request = {
      ...props.user
    };
    request.pager = pager;
		userApi.updateUserDetail(request).then(data => {
			if (data) {
				userApi.resetError();
				props.onClose();
			}
		});
	}

	/**
   * Handles changes in the search bar.
   *
   * @param {string} searchKey The new search keyword.
   */
	const onSearchChange = (searchKey: string) => {
		setSeachKey(searchKey);
		if (searchKey) {
			const request: PagerListRequest = {
				query: searchKey,
				page: 0,
				size: DEFAULT_PAGE_SIZE,
				sortBy: DeviceSortBy.Name,
				order: SortOrder.Asc,
				isPaginationComplete: false,
				isAssigned: false
			};
			deviceApi.fetchPagerList(request);
		}
	}

	/**
	 * Handles closing the drawer and reseting the user error state in store.
	 */
	const onDrawerClose = () => {
		userApi.resetError();
		props.onClose();
	}

	return (
		<RightDrawerLayout open={props.open} title={props.user.pager ? t('reassign') : t('assign')} onClose={onDrawerClose}>
			<Box sx={styles.container}>
			<InlineAlert message={Util.getApiError([UPDATE_USER_DETAIL], apiStatus)} />
				<SearchBar placeholder={t('search')} handleChange={onSearchChange} />
				<Box sx={styles.contentWrapper}>
					{filteredPagerList.map((pager, index) => (
						<ListItemAddRemoveCard
							key={`pager-${index}`}
							title={pager.name}
							subTitle={pager.destination}
							itemType={ListItemType.Device}
							isExistingItem={false}
							onActionClick={() => assignDevice(pager)}
						/>
					))}
				</Box>
				<CircularProgressBar show={Util.isApiLoading([UPDATE_USER_DETAIL], apiStatus)} />
			</Box>
		</RightDrawerLayout>
	);
};

export default AssignDevicePanel;