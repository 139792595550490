import AppError from '../../exception/app-error';
import { DirectoryRecord } from '../../types/directory-record';

export const RESET_ERROR_STATE = 'RESET_SEARCH_ERROR_STATE';
export const SEARCH_RECORDS = 'SEARCH_RECORDS';
export const SEARCH_RECORDS_SUCCESS = 'SEARCH_RECORDS_SUCCESS';
export const API_FAILURE = 'SEARCH_API_FAILURE';
export const RESET_SEARCH_STATE = 'RESET_SEARCH_STATE';

export interface ResetErrorStateAction {
  type: typeof RESET_ERROR_STATE;
}

export interface SearchAction {
  type: typeof SEARCH_RECORDS;
}

export interface SearchSuccessAction {
  type: typeof SEARCH_RECORDS_SUCCESS;
  payload: Array<DirectoryRecord>;
}

export interface ApiFailureAction {
  type: typeof API_FAILURE;
  payload: AppError;
}

export interface SearchStateResetAction {
  type: typeof RESET_SEARCH_STATE;
}

export type SearchActionTypes = ResetErrorStateAction | SearchAction | SearchSuccessAction
| ApiFailureAction | SearchStateResetAction;