import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LinkIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_136_2309)">
					<path d="M7.96936 16.0328L16.0324 7.96973" stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
					<path d="M10.6571 3.93833L11.2793 3.21803C12.5396 1.95795 14.2488 1.25012 16.0309 1.25024C17.8131 1.25037 19.5222 1.95845 20.7822 3.2187C22.0423 4.47896 22.7502 6.18817 22.75 7.97032C22.7499 9.75247 22.0418 11.4616 20.7816 12.7217L20.064 13.3452" stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
					<path d="M13.3447 20.0643L12.8112 20.7819C11.5363 22.0427 9.81553 22.7498 8.02246 22.7498C6.22939 22.7498 4.50866 22.0427 3.23369 20.7819C2.60526 20.1606 2.10634 19.4207 1.76584 18.6051C1.42533 17.7896 1.25 16.9146 1.25 16.0308C1.25 15.147 1.42533 14.272 1.76584 13.4565C2.10634 12.6409 2.60526 11.9011 3.23369 11.2797L3.93787 10.6575" stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
				</g>
				<defs>
					<clipPath id="clip0_136_2309">
						<rect width="24" height="24" fill="white"/>
					</clipPath>
				</defs>
			</svg>    
		</SvgIcon>
  );
}

export default LinkIcon;