import { Box, Chip, IconButton, Stack } from '@mui/material';
import { DATE_PATTERN_DD_MMM, DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_A, DATE_PATTERN_MMM_DD, DATE_PICKER_FORMAT_DD_MM_YYYY } from '../../../utils/constants';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import { CALL_LOG_STATUS_LIST } from '../../../utils/ui-constants';
import CallLogFilter from '../../../types/ui/call-log-filter';
import CustomAutocomplete from '../../../components/CustomAutocomplete';
import CustomButton from '../../../components/CustomButton';
import CustomDatePicker from '../../../components/CustomDatePicker';
import CustomDateTimePicker from '../../../components/CustomDateTimePicker';
import CustomInputSelect from '../../../components/CustomInputSelect';
import FilterIcon from '../../../components/CustomIcons/FilterIcon';
import OptionData from '../../../types/option-data';
import ResultsFoundHeader from '../../../components/ResultsFoundHeader';
import SearchBar from '../../../components/SearchBar';
import SearchIcon from '../../../components/CustomIcons/SearchIcon';
import TopBar from '../../../components/TopBar';
import Util from '../../../utils/util';
import { callLogSchema } from '../../../utils/validation-schema';
import dayjs from 'dayjs';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../data/user/api';

export interface Props {
  filter: CallLogFilter;
  onSearchKeyChange: (value: string) => void;
  onApplyFilter: (values: CallLogFilter) => void;
  totalResults: number;
  isAPILoading: boolean;
}

/**
 * This component provides a search bar and a filter form to allow users to search and filter call logs.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} - JSX element representing the search and filter header.
 */
const CallLogSearchAndFilterHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const formikRef = React.createRef<FormikProps<CallLogFilter>>();
  const [openFilterView, setOpenFilterView] = useState<boolean>(false);
  const userApi = useUserApi();

  const userList: Array<OptionData> = userApi.state.user.searchedUserList.map(user => ({
    id: user.email,
    name: user.name
  }));

  /**
   * Fetches the user list based on the search keyword.
   * 
   * @param {string} searchKey - The search keyword.
   */
  const onSearchUser = (searchKey: string) => {
    userApi.searchUserList(searchKey, 0);
  }

  /**
   * Handles form submission for the search bar, applying the current filter.
   *
   * @param {React.FormEvent<HTMLFormElement>} event - The form submission event.
   */
  const onSearchSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onApplyFilter({ ...props.filter });
  }

  /**
   * Handles the click event on the "Apply Filter" button.
   *
   * @param {CallLogFilter} values - The updated filter values from the form.
   */
  const onApplyFilterClick = (values: CallLogFilter) => {
    setOpenFilterView(false);
    props.onApplyFilter(values);
  }

  /**
   * Calculates the duration between the start and end dates specified in the filter.
   * If both start and end dates are provided, it formats them into a readable string.
   * 
   * @returns {string} The formatted duration string, or an empty string if no dates are provided.
   */
  const getDateDuration = (): string => {
    let duration = '';
    if (props.filter.startDate && props.filter.endDate) {
      const startDate = Util.formatUTCtoLocal(props.filter.startDate, DATE_PATTERN_MMM_DD);
      const endDate = Util.formatUTCtoLocal(props.filter.endDate, DATE_PATTERN_MMM_DD);
      duration = `${startDate} - ${endDate}`;
    }

    return duration;
  }

  return (
    <Box sx={styles.container}>
      <TopBar title={t('callLogs')} subTitle={t('viewAndQueryCallLogs')} />
      <Box sx={styles.tabSearchContainer}>
        <form onSubmit={onSearchSubmit} style={styles.searchLayout as React.CSSProperties}>
          <SearchBar
            sx={styles.searchInput}
            handleChange={props.onSearchKeyChange}
            placeholder={t('search')}
            endAdornment={
              <IconButton sx={styles.filterBtn} onClick={() => setOpenFilterView(!openFilterView)}>
                <FilterIcon />
              </IconButton>
            }
          />
          <CustomButton type='submit' startIcon={<SearchIcon />} sx={styles.searchButton} />
        </form>
      </Box>
      {openFilterView &&
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={props.filter}
          validationSchema={callLogSchema}
          validateOnChange
          validateOnBlur
          onSubmit={onApplyFilterClick}>
          {({ dirty, values, isValid }) => (
            <Form style={styles.form as React.CSSProperties}>
              <Box sx={styles.sortFilterContent}>
                <Box sx={styles.content}>
                  <Field
                    name='status'
                    label={`${t('status')}:`}
                    placeholder={t('select')}
                    menu={CALL_LOG_STATUS_LIST}
                    component={CustomInputSelect}
                    hasBottomSpacing
                  />
                  <Box sx={styles.spacer} />
                  <Field
                    name='startDate'
                    label={t('startDate')}
                    placeholder={DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_A}
                    format={DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_A}
                    component={CustomDateTimePicker}
                    maxDateTime={values.endDate ? dayjs(values.endDate) : dayjs()}
                  />
                  <Box sx={styles.spacer} />
                  <Field
                    name='endDate'
                    label={t('endDate')}
                    placeholder={DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_A}
                    format={DATE_PATTERN_DD_MM_YYYY_COMMA_HH_MM_A}
                    component={CustomDateTimePicker}
                    minDateTime={values.startDate ? dayjs(values.startDate) : undefined}
                    maxDateTime={dayjs()}
                  />
                </Box>
                <Box sx={styles.content}>
                  <Field
                    name='senderInfo'
                    label={t('from')}
                    placeholder={t('search')}
                    component={CustomAutocomplete}
                    onSearchChange={onSearchUser}
                    noOptionsText={t('noUsers')}
                    menu={userList}
                  />
                  <Box sx={styles.spacer} />
                  <Field
                    name='recipientInfo'
                    label={t('to')}
                    placeholder={t('search')}
                    component={CustomAutocomplete}
                    onSearchChange={onSearchUser}
                    noOptionsText={t('noUsers')}
                    menu={userList.filter(user => user.id != values.senderInfo?.id)}
                  />
                  <Box sx={styles.spacer} />
                  <Stack />
                </Box>
              </Box>
              <Box sx={styles.applyBtn}>
                <CustomButton type='submit' title={t('apply')} disabled={!dirty || !isValid} />
              </Box>
            </Form>
          )}
        </Formik>
      }
      <Box sx={styles.filterList}>
        {props.filter.status &&
          <Chip sx={styles.chip} label={Util.toPascalCase(props.filter.status)} />
        }
        <Chip sx={styles.chip} label={getDateDuration()} />
        {props.filter.senderInfo &&
          <Chip sx={styles.chip} label={`${t('from')}: ${props.filter.senderInfo?.name}`} />
        }
        {props.filter.recipientInfo &&
          <Chip sx={styles.chip} label={`${t('to')}: ${props.filter.recipientInfo?.name}`} />
        }
      </Box>
      {!props.isAPILoading &&
        <Box sx={styles.resultsFoundWrapper}>
          <ResultsFoundHeader searchKey={props.filter.content} count={props.totalResults} />
        </Box>
      }
    </Box>
  );
};

export default CallLogSearchAndFilterHeader;
