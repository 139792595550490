import { Box, Dialog, DialogActions, DialogContent, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useState } from 'react';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import { CREATE_TEAM } from '../../../data/team/action-types';
import CircularProgressBar from '../../../components/CircularProgressBar';
import CrossIcon from '../../../components/CustomIcons/CrossIcon';
import CustomButton from '../../../components/CustomButton';
import CustomInput from '../../../components/CustomInput';
import InlineAlert from '../../../components/InlineAlert';
import SaveIcon from '../../../components/CustomIcons/SaveIcon';
import SearchableUserList from '../../DirectoryScreen/SearchableUserList';
import Team from '../../../types/team';
import { User } from '../../../types/user';
import Util from '../../../utils/util';
import { teamInitialValue } from '../../../utils/constants';
import { teamSchema } from '../../../utils/validation-schema';
import useStyles from './styles';
import { useTeamApi } from '../../../data/team/api';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import LoginUtil from '../../../utils/login-util';

/**
 * Interface for props passed to the CreateTeamDialog component.
 */
interface Props extends DialogProps {
	onClose: () => void;
}

/**
 * React component representing a dialog for creating a new team.
 *
 * @param {Props} props Component props.
 * @returns {JSX.Element} The rendered component.
 */
const CreateTeamDialog: React.FC<Props> = (props: Props) => {

	const { onClose, ...rest } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const [teamMemberList, setTeamMemberList] = useState<Array<User>>([]);
	const formikRef = React.createRef<FormikProps<Team>>();
	const teamApi = useTeamApi();
	const apiStatus = teamApi.state.team.apiStatus;

	/**
	 * Handles closing the dialog, with confirmation if the form is dirty.
	 */
	const handleClose = () => {
		if (formikRef.current?.dirty) {
			setShowSaveDialog(true);
		} else {
			onClose();
		}
	};

	/**
	 * Handles form submission.
	 *
	 * @param {Team} team Form values representing team data.
	 */
	const onSubmit = (team: Team) => {
		const teamMembers = teamMemberList.map(user => ({
			loginId: user.email,
			name: user.name
		}));
		team.createdByLoginId = LoginUtil.getLoginId();
		team.teamMembers = teamMembers;
		teamApi.createTeam(team).then((team?: Team) => {
			if (team) {
				onClose();
			}
		});
	};

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={handleClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>
					{t('createTeam')}
				</Typography>
				<IconButton onClick={handleClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<InlineAlert message={Util.getApiError([CREATE_TEAM], apiStatus)} />
			<Formik
				innerRef={formikRef}
				enableReinitialize
				validationSchema={teamSchema}
				initialValues={teamInitialValue}
				onSubmit={values => onSubmit(values)}>
				{({ dirty, isValid }) => (
					<Form style={styles.form as React.CSSProperties}>
						<DialogContent>
							<Box sx={styles.content}>
								<Field
									name='teamName'
									label={t('name')}
									placeholder={t('name')}
									component={CustomInput}
								/>
								<Box sx={styles.memberContainer}>
									<SearchableUserList
									includeLoggedInUser
									userList={teamMemberList}
									onUserListChange={setTeamMemberList}
									/>
								</Box>								
							</Box>
						</DialogContent>
						<DialogActions>
							<CustomButton
								sx={styles.saveBtn}
								type='submit'
								title={t('save')}
								color='primary'
								disabled={!dirty || !isValid || !teamMemberList.length}
								endIcon={<SaveIcon sx={styles.smIcon} />}
							/>
						</DialogActions>
					</Form>
				)}
			</Formik>
			<CircularProgressBar show={Util.isApiLoading([CREATE_TEAM], apiStatus)} />
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={onClose}
				onClose={() => setShowSaveDialog(false)}
			/>
		</Dialog>
	);
};

export default CreateTeamDialog;