import Dimens from '../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles Hook for InboxListHeader Styles
 * 
 * This hook defines the styles used by the InboxListHeader component.
 */
const useStyles = () => {

  const { xs, base, lg, xl, section } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
		appBar: {
			zIndex: 0,
      bgcolor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      backgroundImage: 'none',
      boxShadow: 'none',
      p: section,
			position: 'relative',
			'& .MuiToolbar-root': {
				p: 0,
        minHeight: 'unset'
			},
      [theme.breakpoints.down('sm')]: {
        p: `${lg} ${xl}`
      }
    },
		avatar: {
      width: Dimens.avatarSize.sm,
      height: Dimens.avatarSize.sm,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary
    },
		titleWrapper: {
			flexDirection: 'column',
			flex: 1
		},
		title: {
			fontWeight: Dimens.fontWeight.bold,
			overflow: 'hidden',
			textOverflow: 'auto',
			whiteSpace: 'nowrap'
		},
		iconBtn: {
      p: 0,
      radius: Dimens.radius.md
    },
    icon: {
      color: theme.palette.text.secondary,
      fontSize: Dimens.icon.md
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
      pt: lg,
      [theme.breakpoints.down('sm')]: {
        pt: base
      }
    },
    footerCaption: {
      color: theme.palette.text.secondary,
      pl: xs
    },
    footerName: {
      color: theme.palette.text.primary
    },
    toggleGroupContainer: {
      pt: section,
      [theme.breakpoints.down('sm')]: {
        pt: lg
      }
    }
  };

  return styles;
};

export default useStyles;