import Dimens from '../../../../theme/dimens';
import theme from '../../../../theme/theme';

/**
 * useStyles custom hook generates MUI theme dependent styles for the HelpSubMenu component.
 *
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const { lg, section } = Dimens.spacing;

	const styles = {
		container: {
			display: 'flex',
			flex: 1,
			flexDirection: 'column',
			justifyContent: 'space-between',
			p: `0 ${section} ${section} ${section}`,
			[ theme.breakpoints.down('sm') ]: {
				p: `${lg}`
			}
		},
		versionWrapper: {
      display: 'flex',			
			justifyContent: 'center',
			alignItems:'center',
      color: theme.palette.text.primary
    }
	};

	return styles;
};

export default useStyles;
