import {
	API_FAILURE,
	ApiFailureAction,
	CREATE_DASHBOARD,
	CREATE_DASHBOARD_ITEM,
	CREATE_EMERGENCY_CALL,
	CreateDashboardAction,
	CreateDashboardItemAction,
	CreateEmergencyCallAction,
	DASHBOARD_LIST_SUCCESS,
	DELETE_DASHBOARD,
	DELETE_DASHBOARD_ITEM,
	DashboardListSuccessAction,
	DashboardStateResetAction,
	DeleteDashboardAction,
	DeleteDashboardItemAction,
	EMERGENCY_CALL_SUCCESS,
	EmergencyCallSuccessAction,
	FETCH_DASHBOARD_ITEM,
	FETCH_DASHBOARD_LIST,
	FETCH_DASHBOARD_PERMISSION,
	FetchDashboardItemAction,
	FetchDashboardListAction,
	FetchDashboardPermissionAction,
	RESET_DASHBOARD_STATE,
	ResetErrorStateAction,
	UPDATE_DASHBOARD,
	UpdateDashboardAction,
	RESET_ERROR_STATE,
	UpdateDashboardItemAction,
	UPDATE_DASHBOARD_ITEM
} from './action-types';

import AppError from '../../exception/app-error';
import {Dashboard} from '../../types/dashboard';

/**
 * Action creator to fetch the dashboard  list.
 *
 * @returns {FetchDashboardListAction} - The action to fetch the dashboard  list.
 */
export const initDashboardListFetch = (): FetchDashboardListAction => ({
	type: FETCH_DASHBOARD_LIST
});

/**
 * Action creator to initiate the dashboard  creation process.
 *
 * @returns {CreateDashboardAction} An action object indicating the start of dashboard  creation.
 */
export const initDashboardCreate = (): CreateDashboardAction => ({
	type: CREATE_DASHBOARD
});

/**
 * Action creator to initiate the dashboard  update process.
 *
 * @returns {UpdateDashboardAction} An action object indicating the start of dashboard  update.
 */
export const initDashboardUpdate = (): UpdateDashboardAction => ({
	type: UPDATE_DASHBOARD
});

/**
 * Action creator to initiate fetching dashboard items.
 *
 * @returns {FetchDashboardItemAction} An action object indicating the start of fetching dashboard items.
 */
export const initDashboardItemFetch = (): FetchDashboardItemAction => ({
	type: FETCH_DASHBOARD_ITEM
});

/**
 * Action creator to initiate the dashboard item creation process.
 *
 * @returns {CreateDashboardItemAction} An action object indicating the start of dashboard item creation.
 */
export const initDashboardItemCreate = (): CreateDashboardItemAction => ({
	type: CREATE_DASHBOARD_ITEM
});

/**
 * Action creator to initiate the dashboard item update process.
 *
 * @returns {UpdateDashboardItemAction} An action object indicating the start of dashboard item update.
 */
export const initDashboardItemUpdate = (): UpdateDashboardItemAction => ({
	type: UPDATE_DASHBOARD_ITEM
});

/**
 * Creates an action to indicate the initialization of a dashboard item deletion.
 * @returns {DeleteDashboardItemAction} An action object with the type `DELETE_DASHBOARD_ITEM`.
 */
export const initDashboardItemDelete = (): DeleteDashboardItemAction => ({
	type: DELETE_DASHBOARD_ITEM
});

/**
 * Action creator to initiate fetching dashboard permission.
 *
 * @returns {FetchDashboardPermissionAction} An action object indicating the start of fetching dashboard permissions.
 */
export const initDashboardPermissionFetch = (): FetchDashboardPermissionAction => ({
	type: FETCH_DASHBOARD_PERMISSION
});

/**
 * Action creator to initiate the dashboard  deletion process.
 *
 * @returns {DeleteDashboardAction} An action object indicating the start of dashboard  deletion.
 */
export const initDashboardDelete = (): DeleteDashboardAction => ({
	type: DELETE_DASHBOARD
});

/**
 * Action creator to indicate successful dashboard  list fetching.
 *
 * @param {Array<Dashboard>} List - The fetched dashboard  list.
 * @returns {DashboardListSuccessAction} - The action to indicate successful fetching with the dashboard  list as payload.
 */
export const dashboardListSuccess = (List: Array<Dashboard>): DashboardListSuccessAction => ({
	type: DASHBOARD_LIST_SUCCESS,
	payload: List
});

/**
 * Action creator to initiate the emergency call creation process.
 *
 * @returns {CreateEmergencyCallAction} An action object indicating the start of emergency call creation.
 */
export const initEmergencyCall = (): CreateEmergencyCallAction => ({
	type: CREATE_EMERGENCY_CALL
});

/**
 * Action creator to indicate successful emergency call creation.
 *
 * @returns {EmergencyCallSuccessAction} - The action to indicate successful creation with the emergency call as payload.
 */
export const emergencyCallSuccess = (): EmergencyCallSuccessAction => ({
	type: EMERGENCY_CALL_SUCCESS
});

/**
 * Action creator to handle API failure.
 *
 * @param {AppError} error - The API error object.
 * @returns {ApiFailureAction} - The action to indicate API failure with the error as payload.
 */
export const apiFailure = (error: AppError): ApiFailureAction => ({
	type: API_FAILURE,
	payload: error
});

/**
 * Resets the dashboard state to its initial state.
 *
 * @returns {DashboardStateResetAction} An action to reset the dashboard state.
 */
export const resetDashboardState = (): DashboardStateResetAction => ({
	type: RESET_DASHBOARD_STATE
});

/**
 * Resets the error state.
 *
 * @returns {ResetErrorStateAction} A ResetErrorStateAction object.
 */
export const resetErrorState = (): ResetErrorStateAction => ({
	type: RESET_ERROR_STATE
});
