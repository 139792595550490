import { Alert, AlertColor, Snackbar, SnackbarProps, Typography } from '@mui/material';

import AlertCircleIcon from '../CustomIcons/AlertCircleIcon';
import AlertTriangleIcon from '../CustomIcons/AlertTriangleIcon';
import CheckCircleOutlineIcon from '../CustomIcons/CheckCircleOutlineIcon';
import Dimens from '../../theme/dimens';
import InfoCircleIcon from '../CustomIcons/InfoCircleIcon';
import React from 'react';
import { TOAST_DURATION } from '../../utils/constants';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface defining the properties accepted by the Toast component.
 * These properties allow for customization of the toast's content, appearance, and behavior.
 */
type Props = {
  title: string,
  desc?: string,
  severity?: AlertColor;
  onClose: () => void;
} & SnackbarProps;

/**
 * Toast Component
 * 
 * This component renders a customizable toast notification using Material UI's Snackbar component.
 * It can display various types of messages (success, error, warning, info) with icons and descriptions.
 */

const Toast: React.FC<Props> = (props: Props) => {

  const { title, desc, severity, onClose, ...rest } = props;
  const styles = useStyles(severity);
  const { t } = useTranslation();

  /**
   * Function to determine the appropriate icon based on the severity level.
   * @returns {React.ReactNode} - The React element representing the icon.
   */
  const getIcon = () => {
    let icon = <InfoCircleIcon />;
    switch (severity) {
      case 'warning':
        icon = <AlertTriangleIcon />;
        break;
      case 'error':
        icon = <AlertCircleIcon />;
        break;
      case 'info':
        icon = <InfoCircleIcon />;
        break;
      case 'success':
        icon = <CheckCircleOutlineIcon />;
        break;
    }

    return icon;
  }

  return (
    <Snackbar
      {...rest}
      autoHideDuration={TOAST_DURATION}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <Alert
        icon={getIcon()}
        onClose={onClose}
        severity={severity || 'info'}
        variant='filled'
        sx={styles.alert}
      >
        <Typography variant='p1' fontWeight={Dimens.fontWeight.semiBold}>
          {title}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default Toast;