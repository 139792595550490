import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { useTheme } from '@mui/material';

const PlusCircleIcon = (props: SvgIconProps) => {

  const theme = useTheme();

  return (
    <SvgIcon {...props}>
      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 8C-1.56548e-08 9.05058 0.206926 10.0909 0.608964 11.0615C1.011 12.0321 1.60028 12.914 2.34315 13.6569C3.08601 14.3997 3.96793 14.989 4.93853 15.391C5.90914 15.7931 6.94943 16 8 16C9.05058 16 10.0909 15.7931 11.0615 15.391C12.0321 14.989 12.914 14.3997 13.6569 13.6569C14.3997 12.914 14.989 12.0321 15.391 11.0615C15.7931 10.0909 16 9.05058 16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0C6.94943 0 5.90914 0.206926 4.93853 0.608964C3.96793 1.011 3.08601 1.60028 2.34315 2.34315C1.60028 3.08601 1.011 3.96793 0.608964 4.93853C0.206926 5.90914 -1.56548e-08 6.94943 0 8Z' fill='currentColor' />
        <path d='M5.33203 8H10.6654' stroke={theme.palette.background.default} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 5.33398V10.6673' stroke={theme.palette.background.default} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default PlusCircleIcon;