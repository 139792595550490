import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, Typography } from '@mui/material';

import Dimens from '../../theme/dimens';
import React from 'react';
import TabData from '../../types/ui/tab-data';
import useStyles from './styles';

interface Props extends ToggleButtonGroupProps {
	data: Array<TabData>;
}

/**
 * CustomToggleButtonGroup Component
 * Custom component that renders a toggle button group for tab selection.
 * 
 * @param {Props} props - Component properties.
 * @param {Array<TabData>} props.data - Array of TabData objects containing label and ID for each toggle button.
 * @returns {JSX.Element} The rendered toggle button group component.
 */
const CustomToggleButtonGroup: React.FC<Props> = (props: Props) => {

	const { data, ...rest } = props;
	const styles = useStyles();

	return (
		<ToggleButtonGroup {...rest} exclusive>
			{data.map((item: TabData, index: number) => (
				<ToggleButton key={`toggle_button_${index}`} value={item.id} sx={styles.toggleButton}>
					<Typography variant='caption' fontWeight={Dimens.fontWeight.medium}>{item.label}</Typography>
				</ToggleButton>
			))}
		</ToggleButtonGroup>
	);
};

export default CustomToggleButtonGroup;
