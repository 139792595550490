import { callLogListSuccess, initCallLogListFetch, logFailure } from './actions';
import { doGet, doPost } from '../../service';
import logReducer, { logInitialState } from './reducer';
import { useCallback, useReducer } from 'react';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import { CallLogData } from '../../types/call-log-data';
import CallLogFilterRequest from '../../types/call-log-filter-request';
import { CallLogSummary } from '../../types/call-log-summary';
import React from 'react';
import { Store } from '../../store/store';
import Util from '../../utils/util';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook to handle call log related actions and state.
 *
 * Provides functions to fetch call logs and the current state of the call log list.
 *
 * @returns {{ fetchCallLogList: Function, state: object }} An object containing the `fetchCallLogList` function and the current call log state.
 */
export function useLogApi() {

	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();

	/**
	 * Dispatches a failure action with an error message.
	 *
	 * @param {any} error - The error object.
	 */
	const dispatchFailureAction = (error: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(logFailure(new AppError(error?.code, message)));
	}

	/**
	 * Fetches the call log list based on the provided filter request.
	 *
	 * @param {CallLogFilterRequest} request - The filter request object.
	 */
	const fetchCallLogList = useCallback(async (request: CallLogFilterRequest) => {
		dispatch(initCallLogListFetch());
		try {
			const callLogList: Array<CallLogData> = await doPost(APIConfig.callLog, request);
			callLogList.sort((a, b) => b.summary.createdAt.localeCompare(a.summary.createdAt));
			dispatch(callLogListSuccess(callLogList));
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Downloads an attachment for a call log message.
	 * Updates the call log list in the state with the downloaded file data.
	 * @param {string} messageId - The ID of the message.
	 * @param {CallLogSummary} summary - The call log summary containing attachment information.
	 * @returns {Promise<any>} A promise that resolves with the response from the download API or undefined if there is an error.
	 */
	const downloadAttachment = useCallback(async (messageId: string, summary: CallLogSummary) => {
		try {
			const response = await doGet(APIConfig.chatFileDownload + encodeURIComponent(summary.attachmentKey ?? ''), 'blob');
			const callLogList = [...state.log.callLogList];
			const callLogItem = callLogList.find(item => item.messageId === messageId);
			if (callLogItem) {
				const mimeType = Util.getMimeTypeFromPath(summary.attachmentKey ?? '');
				const blob = new Blob([response], { type: mimeType });
				callLogItem.summary.file = new File([blob], Util.getFilenameFromPath(summary.attachmentKey ?? ''),
					{
						type: mimeType
					});
			}
			dispatch(callLogListSuccess(callLogList));

			return response;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	return {
		fetchCallLogList,
		downloadAttachment,
		state
	};
}