import { Avatar, Box, Typography } from '@mui/material';

import MessageDateItem from '../MessageDateItem';
import React, { } from 'react';
import Util from '../../../../../utils/util';
import useStyles from './styles';

interface Props {
	name: string;
	date?: string;
}

/**
 * Renders a component displaying the sender's information (name, avatar, and message date).
 *
 * @param {Props} props - Component props containing the required `name` and optional `date` string.
 * 
 * @returns {JSX.Element} JSX element representing the MessageInfoBody component.
 */
const MessageInfoBody: React.FC<Props> = (props: Props) => {

	const styles = useStyles();

	return (
		<Box sx={styles.infoLayout}>
			<Avatar sx={styles.avatar}>
				<Typography variant='footer' sx={styles.username}>{Util.getInitialsFromName(props.name, 1)}</Typography>
			</Avatar>
			<Typography variant='h6' sx={styles.name}>{props.name}</Typography>
			<MessageDateItem date={props.date} />
		</Box>
	);
};

export default MessageInfoBody;