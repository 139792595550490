import { KEY_ORG_DOMAIN, KEY_SESSION_INFO, SIGN_UP_STORAGE_IV, SIGN_UP_STORAGE_SECRET } from './constants';

import BearerToken from '../types/bearer-token';
import CryptoUtil from './crypto-util';
import SignInResponse from '../types/sign-in-response';
import Token from '../types/token';

/**
 * SignUpUtil class
 *
 * Provides utility methods for handling sign-up related data storage and retrieval.
 * Uses encrypted storage for security.
 */
export default class SignUpUtil {

	private static storage: Storage;

	/**
	 * Retrieves the storage object (sessionStorage).
	 *
	 * @returns {Storage} - The storage object.
	 */
	private static getStorage(): Storage {
		if (!this.storage) {
			this.storage = sessionStorage;
		}

		return this.storage;
	}

	/**
   * Encrypts and saves a value to storage.
   *
   * @param {string} key - The key for the value.
   * @param {string} value - The value to store.
   */
	private static setItem(key: string, value: string): void {
		const encryptedKey = CryptoUtil.encryptAES(key, SIGN_UP_STORAGE_SECRET, SIGN_UP_STORAGE_IV);
		const encryptedValue = CryptoUtil.encryptAES(value, SIGN_UP_STORAGE_SECRET, SIGN_UP_STORAGE_IV);
		this.getStorage().setItem(encryptedKey, encryptedValue);
	}

	/**
   * Retrieves and decrypts a value from storage.
   *
   * @param {string} key - The key for the value.
   * @returns {string | null} - The decrypted value or null if not found.
   */
	private static getItem(key: string): string | null {
		const encryptedKey = CryptoUtil.encryptAES(key, SIGN_UP_STORAGE_SECRET, SIGN_UP_STORAGE_IV);
		const encryptedValue = this.getStorage().getItem(encryptedKey);
		const decryptedValue = encryptedValue ? CryptoUtil.decryptAES(encryptedValue, SIGN_UP_STORAGE_SECRET,
			SIGN_UP_STORAGE_IV) : null;

		return decryptedValue;
	}

	/**
   * Removes a value from storage.
   *
   * @param {string} key - The key of the value to remove.
   */
	private static removeItem(key: string): void {
		const encryptedKey = CryptoUtil.encryptAES(key, SIGN_UP_STORAGE_SECRET, SIGN_UP_STORAGE_IV);
		this.getStorage().removeItem(encryptedKey);
	}

	/**
	 * Clears all sign-up related data from session storage.
	 */
	public static clearAll(): void {
		this.removeItem(KEY_SESSION_INFO);
		this.removeItem(KEY_ORG_DOMAIN);
	}

	/**
	 * Saves sign-up information to local storage.
	 *
	 * @param {SignInResponse} signUpInfo - The sign-up response containing user information.
	 */
	public static saveSignUpInfo(signUpInfo: SignInResponse) {
		this.saveOrgDomain(signUpInfo.data.domain);
		signUpInfo.bearer_tokens && this.saveBearerToken(signUpInfo.bearer_tokens[0]);
	}

	/**
   * Saves the organization domain to local storage.
   *
   * @param {string} orgDomain - The organization domain to save.
   */
	private static saveOrgDomain(orgDomain: string) {
		this.setItem(KEY_ORG_DOMAIN, JSON.stringify(orgDomain));
	}

	/**
   * Saves the bearer token information to local storage.
   *
   * @param {BearerToken} bearerToken - The bearer token to save.
   */
	private static saveBearerToken(bearerToken: BearerToken) {
		this.setItem(KEY_SESSION_INFO, JSON.stringify(bearerToken));
	}

	/**
   * Retrieves the user email ID from the saved bearer token.
   *
   * @returns {string} - The user's email ID.
   */
	public static getUserEmailId(): string {
		const bearerToken: BearerToken | undefined = this.getBearerToken();
		const emailId: string | undefined = bearerToken ? bearerToken.sub : '';

		return emailId;
	}

	/**
   * Retrieves the organization domain from local storage.
   *
   * @returns {string} - The organization domain.
   */
	public static getOrgDomain(): string {
		const orgDomainStr: string | null = this.getItem(KEY_ORG_DOMAIN);
		const domain: string = orgDomainStr ? JSON.parse(orgDomainStr) : '';

		return domain;
	}

	/**
   * Retrieves the bearer token from local storage.
   *
   * @returns {BearerToken | undefined} - The bearer token object, or undefined if not found.
   */
	public static getBearerToken(): BearerToken | undefined {
		const bearerTokenStr: string | null = this.getItem(KEY_SESSION_INFO);
		const bearerToken: BearerToken | undefined = bearerTokenStr ? JSON.parse(bearerTokenStr) : undefined;

		return bearerToken;
	}

	/**
   * Retrieves the access token data from the saved bearer token.
   *
   * @returns {Token | undefined} - The access token data, or undefined if not found.
   */
	public static getAccessTokenData(): Token | undefined {
		const bearerToken: BearerToken | undefined = this.getBearerToken();
		const accessToken: Token | undefined = bearerToken ? bearerToken.access_token : undefined;

		return accessToken;
	}

}
