import {
	API_FAILURE,
	CREATE_TEAM,
	DELETE_TEAM,
	FETCH_TEAM_DETAIL,
	FETCH_TEAM_LIST,
	REMOVE_TEAM_MEMBER,
	RESET_ERROR,
	RESET_TEAM_STATE,
	TEAM_LIST_SUCCESS,
	TeamActionTypes,
	UPDATE_TEAM,
} from './action-types';

import TeamState from '../../types/states/team-state';

export const teamInitialState: TeamState = {
	apiStatus: undefined,
	teamList: []
};

/**
 * Team reducer
 *
 * Handles state updates for team-related data, including fetching, success, and failure states.
 */
export const teamReducer = (state: TeamState, action: TeamActionTypes): TeamState => {
	switch (action.type) {
		case FETCH_TEAM_LIST:
		case CREATE_TEAM:
		case UPDATE_TEAM:
		case FETCH_TEAM_DETAIL:
		case DELETE_TEAM:
		case REMOVE_TEAM_MEMBER:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
		case TEAM_LIST_SUCCESS:
			return {
				...state,
				teamList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true,
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: undefined,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_TEAM_STATE:
			return {
				...teamInitialState
			};
		case RESET_ERROR:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,					
					error: undefined,
				}
			};
		default:
			return state;
	}
};

export default teamReducer;
