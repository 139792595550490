import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook for CallLogScreen styling.
 *
 * @returns {object} - An object containing styled classes for the dialog.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { base, lg, section } = Dimens.spacing;

  const styles = {
    container: {
      bgcolor: theme.palette.background.paper,
      height: 'inherit',
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      ...commonStyles.noScrollBar,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      p: section,
      pt: base,
      [theme.breakpoints.down('sm')]: {
        p: lg,
        pt: base
      }
    },
    tableContainer: {
      flex: 1,
      backgroundImage: 'none',
      border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderRadius: Dimens.radius.md,
      boxShadow: 'none',
      '& th': {
        bgcolor: theme.palette.background.paper,
        borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        zIndex: 1,
        p: lg
      },
      '& td': {
        borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        p: lg,
        verticalAlign: 'top'
      },
      '& tbody': {
        '& tr': {
          '&:hover': {
            bgcolor: theme.palette.background.default
          }
        }
      }
    },
    userHeader: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg':{
        ...commonStyles.smIcon,
        ml: base
      }
    },
    noDataRow: { 
      display:'flex',
      flex:1,
      justifyContent: 'center'
     }
  };

  return styles;
};

export default useStyles;