import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CircleDownIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_2636_41130)'>
          <path d='M10.271 7.94922L8.00074 10.2195L5.73047 7.94922' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          <path d='M8.00027 1.89453C7.10587 1.89453 6.22021 2.0707 5.39389 2.41297C4.56756 2.75525 3.81674 3.25693 3.1843 3.88937C2.55185 4.52182 2.05017 5.27264 1.7079 6.09896C1.36562 6.92529 1.18945 7.81094 1.18945 8.70535C1.18945 9.59976 1.36562 10.4854 1.7079 11.3117C2.05017 12.1381 2.55185 12.8889 3.1843 13.5213C3.81674 14.1538 4.56756 14.6555 5.39389 14.9977C6.22021 15.34 7.10587 15.5162 8.00027 15.5162C9.80662 15.5162 11.539 14.7986 12.8163 13.5213C14.0935 12.2441 14.8111 10.5117 14.8111 8.70535C14.8111 6.89901 14.0935 5.16665 12.8163 3.88937C11.539 2.6121 9.80662 1.89453 8.00027 1.89453Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
          <clipPath id='clip0_2636_41130'>
            <rect width='16' height='16' fill='transparent' transform='translate(0 0.705078)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}

export default CircleDownIcon;