import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the TopBar component.
 * 
 * @param {boolean} hasDivider (optional) - Boolean flag to control whether to include a divider line.
 * Defaults to false.
 * 
 * @returns {object} - An object containing styles for the TopBar component.
 */
const useStyles = (hasDivider = false) => {

  const theme = useTheme();

  const styles = {
    container: {
      p: Dimens.spacing.section,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderBottom: hasDivider ? `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}` : 0,
      bgcolor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        p: Dimens.spacing.lg
      }
    },
    titleWrapper: {
      flexDirection: 'column'
    },
    subTitle: {
      mt: Dimens.spacing.mini
    }
  };

  return styles;
};

export default useStyles;
