import { InteractionType, MessagePriority } from '../../../../../utils/constants';

import Dimens from '../../../../../theme/dimens';
import { Props } from '.';
import ThemeUtil from '../../../../../theme/theme-util';
import useCommonStyles from '../../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles hook
 * 
 * This hook creates a styles object containing styling information for the 
 * `LeftChatItem` component.
 */
const useStyles = (props: Props) => {

  const { xxs, xs, base, lg, paper } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const isGroup = props.interactionType === InteractionType.Group;

  const styles = {
    fwdHeader: {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      pl: isGroup ? paper : 0,
      pb: xxs,
      '& svg': {
        ...commonStyles.smIcon,
        mr: xs
      }
    },
		wrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'start'
    },
    avatar: {
      width: Dimens.avatarSize.md,
      height: Dimens.avatarSize.md,
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.text.secondary,
      display: isGroup ? 'flex' : 'none',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      bgcolor: theme.palette.background.default,
      p: `${lg} ${lg} ${base} ${lg}`,
      borderRadius: `${xxs} ${lg} ${lg} ${lg}`,
      ml: isGroup ? base : 0,
      mb: xs,
      maxWidth: '70%',
      color: props.messageData.priority === MessagePriority.Urgent ? theme.palette.error.main : 'inherit',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
      }
    },
    contentText: {
      whiteSpace: 'pre-wrap',
      display: '-webkit-box',
      wordWrap: 'break-word',
      WebkitBoxOrient: 'vertical',
      overflowWrap: 'anywhere',
      lineHeight: ThemeUtil.pxToRem(20)
    },
    header: {
      mb: xs,
      fontWeight: Dimens.fontWeight.semiBold,
      display: isGroup ? 'unset' : 'none'
    },
    date: {
      color: theme.palette.text.secondary,
      display: 'flex',
      justifyContent: 'end',
      pt: xs
    },
    priorityLine: {
      borderColor: theme.palette.error.main,
      height: 'unset',
      borderWidth: Dimens.priorityLineWidth,
      borderRadius: Dimens.radius.md,
      mr: lg,
      display: props.messageData.priority === MessagePriority.Urgent ? 'unset' : 'none'
    }
  };

  return styles;
};

export default useStyles;