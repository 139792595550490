import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography
} from '@mui/material';

import ChevronDownIcon from '../../../../../../components/CustomIcons/ChevronDownIcon';
import ChevronUpIcon from '../../../../../../components/CustomIcons/ChevronUpIcon';
import ChildDrawerSubItem from '../ChildDrawerSubItem';
import DrawerItemData from '../../../../../../types/ui/drawer-item-data';
import ListItemIcon from '@mui/material/ListItemIcon';
import React from 'react';
import Util from '../../../../../../utils/util';
import { useLocation } from 'react-router-dom';
import useStyles from './styles';

/**
 * Interface defining props used by the ChildDrawerItem component.
 */
interface Props {
  label?: string;
  icon?: JSX.Element;
  isActive: boolean;
  menuList?: Array<DrawerItemData>;
  subMenuToggleMap: Map<string, boolean>;
  onSubmenuHeaderClick: (label: string) => void;
  onChildDrawerItemClick: () => void;
  onChildDrawerSubItemClick: (item: DrawerItemData) => void;
}

/**
 * ChildDrawerItem component
 * 
 * This component renders a single child drawer item within the child drawer.
 * It displays an icon, label, and optionally a chevron icon to indicate a sub-menu.
 * Clicking the item triggers a callback based on whether it has a sub-menu.
 * 
 * @param {Props} props - An object containing properties for the child drawer item.
 * @returns {JSX.Element} - The JSX element representing the child drawer item.
 */
const ChildDrawerItem: React.FC<Props> = (props: Props) => {

  const styles = useStyles(props.isActive);
  const location = useLocation();

  /**
   * This function handles clicks on the ChildDrawerItem.
   * It checks if the item has a sub-menu and triggers the appropriate callback.
   */
  const onItemClick = () => {
    if (Util.isArrayEmpty(props.menuList)) {
      props.onChildDrawerItemClick();
    } else {
      props.onSubmenuHeaderClick(props.label || '');
    }
  }

  /**
   * Function to check if the provided route matches the current pathname exactly.
   * 
   * @param {string} route - The route path to check.
   * @returns {boolean} - True if the route matches the current pathname exactly, false otherwise.
   */
  const isActiveRoute = (route: string): boolean => {
    
    return location.pathname === route;
  };

  /**
   * Determines whether the submenu associated with the current component should be shown.
   * Retrieves the open/closed state from `props.subMenuToggleMap` based on the `props.label`.
   * 
   * @returns {boolean} True if the submenu should be shown, false otherwise.
   */
  const showSubMenu = (): boolean => {

    return props.subMenuToggleMap.get(props.label || '') || false;
  }

  return (
    <>
      <ListItemButton onClick={() => onItemClick()} sx={styles.listBtn}>
        <ListItemIcon sx={styles.listIcon}>
          {props.icon}
        </ListItemIcon>
        <ListItemText sx={styles.listText} primary={<Typography variant='p1'>{props.label}</Typography>} />
        {props.menuList && (showSubMenu() ? <ChevronUpIcon /> : <ChevronDownIcon />)}
      </ListItemButton>
      {props.menuList && <Collapse in={showSubMenu()} timeout='auto' unmountOnExit>
        <List component='div' disablePadding sx={styles.list}>
          {props.menuList.map((subItem, index) =>
            <ChildDrawerSubItem
              key={`sub_item_${index}`}
              label={subItem.label}
              onChildDrawerSubItemClick={() => props.onChildDrawerSubItemClick(subItem)}
              isActive={isActiveRoute(subItem.route)}
            />
          )}
        </List>
      </Collapse>}
    </>
  );
};

export default ChildDrawerItem;
