import { AppFeature, InteractionType, MessageOptions, MessageSendStatus } from '../../../../../utils/constants';
import { Box, Stack, Typography } from '@mui/material';

import AlertCircleIcon from '../../../../../components/CustomIcons/AlertCircleIcon';
import ChatUtil from '../../../../../utils/chat-util';
import CheckCircleOutlineIcon from '../../../../../components/CustomIcons/CheckCircleOutlineIcon';
import CheckFilled2Icon from '../../../../../components/CustomIcons/CheckFilled2Icon';
import ClockIcon from '../../../../../components/CustomIcons/ClockIcon';
import DocumentView from '../DocumentView';
import LoginUtil from '../../../../../utils/login-util';
import MenuData from '../../../../../types/ui/menu-data';
import MenuSelectDialog from '../../../../../components/MenuSelectDialog';
import MessageData from '../../../../../types/message-data';
import React from 'react';
import { getMessageActionList } from '../../../../../utils/ui-constants';
import { useInboxApi } from '../../../../../data/inbox/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  messageData: MessageData;
  disableMessageClick?: boolean;
  interactionType: InteractionType;
  handleMsgActionClick: (action: MessageOptions) => void;
  onAttachmentClick: () => void;
}

/**
 * RightChatItem Component
 * 
 * This component renders a right-aligned chat message item, representing 
 * messages sent by the current user. It displays the message content 
 * and a timestamp using `ChatUtil.getChatFormatTime`.
 */
const RightChatItem: React.FC<Props> = (props: Props) => {

  const { messageData, disableMessageClick, interactionType } = props;
  const { t } = useTranslation();
  const styles = useStyles(ChatUtil.isUrgent(messageData.priority), disableMessageClick, messageData.status);
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const inboxApi = useInboxApi();

  /**
   * Handles the click event for an item in the action menu.
   *
   * @param {MenuData} item - The clicked menu item object.
   * 
   * Closes the action menu and delegates handling the action to the parent component through props.
   */
  const handleActionMenuClick = (item: MenuData) => {
    setActionMenuAnchorEl(null);
    props.handleMsgActionClick(item.id as MessageOptions);
  }

  /**
   * Handles the click event on a message.
   *
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e - The click event object.
   */
  const showMsgActionSubMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!disableMessageClick) {
      if (messageData.status !== MessageSendStatus.Failed && messageData.messageId) {
        setActionMenuAnchorEl(e.currentTarget);
      }
    }
  }

  /**
   * Handles the tap (click) event on a message.
   * If message clicks are not disabled, it checks if the message status is 'Failed' and resends the message if it is.
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e - The mouse event object.
   */
  const handleMessageTap = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!disableMessageClick) {
      if (messageData.status === MessageSendStatus.Failed) {
        inboxApi.sendChat(messageData);
      }
    }
  }

  /**
   * Determines if a message can be forwarded.
   * A message can be forwarded if it has no attachment OR if it has an attachment AND the user has the 'MultimediaForwarding' permission.
   * @returns {boolean} True if the message can be forwarded, false otherwise.
   */
  const canForward = () => {

    return Boolean((interactionType === InteractionType.Private || interactionType === InteractionType.Group)
      && !messageData.attachmentKey || (messageData.attachmentKey
        && LoginUtil.hasPermission(AppFeature.MultimediaForwarding)));
  }

  /**
   * Renders the appropriate message status icon based on the message data.
   *
   * Determines the icon to display based on the message's read status, delivered status, or send status.
   *
   * @returns {JSX.Element} The JSX element for the message status icon.
   */
  const renderMessageStatusIcon = () => {
    const isRead = messageData.groupRoomKey ? messageData.isReadAll : messageData.isRead;
    const isDelivered = messageData.groupRoomKey ? messageData.isDeliveredAll : Boolean(messageData.deliveredAt);
    if (isRead) {

      return <CheckFilled2Icon sx={styles.icon} />;
    } else if (isDelivered) {

      return <CheckCircleOutlineIcon sx={styles.deliveredIcon} />
    } else if (messageData.createdAt) {

      return <CheckCircleOutlineIcon sx={styles.sentIcon} />
    }
    switch (messageData.status) {
      case MessageSendStatus.InProgress:
      case MessageSendStatus.FileUpload:
        return <ClockIcon sx={styles.icon} />
      case MessageSendStatus.Failed:
        return <AlertCircleIcon sx={styles.icon} />
      case MessageSendStatus.Sent:
        return <CheckCircleOutlineIcon sx={styles.sentIcon} />
    }

    return <ClockIcon sx={styles.icon} />;
  }

  return (
    <Box sx={styles.wrapper} onContextMenu={(e) => e.preventDefault()}>
      <Stack sx={styles.contentWrapper}>
        <Box sx={styles.content} onClick={handleMessageTap} onContextMenu={showMsgActionSubMenu}>
          <Typography variant='p1' sx={styles.contentText}>{messageData.contentText}</Typography>
          <DocumentView messageData={messageData} onAttachmentClick={props.onAttachmentClick} />
        </Box>
        {messageData.status === MessageSendStatus.Failed ? (
          <Typography variant='caption' sx={styles.retry}>
            {t('failedToSendRetry')}
          </Typography>
        ) : (
          <Typography variant='caption' sx={styles.time}>{ChatUtil.getChatFormatTime(messageData.createdAt)}</Typography>
        )}
      </Stack>
      {renderMessageStatusIcon()}
      <MenuSelectDialog
        anchorEl={actionMenuAnchorEl}
        open={Boolean(actionMenuAnchorEl)}
        onClose={() => setActionMenuAnchorEl(null)}
        menuList={getMessageActionList(true, canForward())}
        onMenuItemSelected={handleActionMenuClick}
      />
    </Box>
  );
};

export default RightChatItem;
