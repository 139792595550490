import { Box, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import { Group } from '../../../../types/group';
import InlineAlert from '../../../../components/InlineAlert';
import { UPDATE_GROUP } from '../../../../data/directory/action-types';
import Util from '../../../../utils/util';
import { groupSchema } from '../../../../utils/validation-schema';
import { useDirectoryApi } from '../../../../data/directory/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  group: Group;
}

/**
 * DetailsTab component for managing group details (name and description).
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const DetailsTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [group, setGroup] = useState<Group>(props.group);
  const directoryApi = useDirectoryApi();
  const apiStatus = directoryApi.state.directory.apiStatus;

  /**
   * Handles form submission for updating group details.
   *
   * @param {Group} values - The updated group data from the form.
   */
  const onSubmit = (values: Group) => {
    directoryApi.updateGroup(values, props.group).then((data) => {
      if (data) {
        setGroup(values);
      }
    });
  }

  return (
    <Grid sx={styles.wrapper}>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={groupSchema}
        initialValues={group}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Box>
              <InlineAlert message={Util.getApiError([UPDATE_GROUP], apiStatus)} />
              <Field
                name='name'
                label={t('name')}
                placeholder={t('name')}
                component={CustomInput}
              />
              <Field
                name='description'
                label={t('description')}
                subLabel={t('optional')}
                placeholder={t('createGroupDesc')}
                component={CustomInput}
                multiline
              />
            </Box>
            <CustomButton
              type='submit'
              title={t('save')}
              color='primary'
              disabled={!dirty || !isValid}
              fullWidth
            />
          </Form>
        )}
      </Formik>
      <CircularProgressBar show={Util.isApiLoading([UPDATE_GROUP], apiStatus)} />
    </Grid>
  );
};

export default DetailsTab;