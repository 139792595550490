import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TeamIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<g id='Icon' clipPath='url(#clip0_195_1911)'>
					<path id='Vector' d="M9.73706 12.9992C9.73706 13.5994 9.97549 14.175 10.3999 14.5994C10.8243 15.0238 11.3999 15.2623 12.0002 15.2623C12.6004 15.2623 13.176 15.0238 13.6004 14.5994C14.0248 14.175 14.2633 13.5994 14.2633 12.9992C14.2633 12.399 14.0248 11.8233 13.6004 11.3989C13.176 10.9745 12.6004 10.7361 12.0002 10.7361C11.3999 10.7361 10.8243 10.9745 10.3999 11.3989C9.97549 11.8233 9.73706 12.399 9.73706 12.9992Z" stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
					<path id="Vector_2" d="M7.47363 23.1837V22.0522C7.47363 21.452 7.71207 20.8763 8.13648 20.4519C8.56089 20.0275 9.13652 19.7891 9.73673 19.7891H14.2629C14.8631 19.7891 15.4388 20.0275 15.8632 20.4519C16.2876 20.8763 16.526 21.452 16.526 22.0522V23.1837" stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
					<path id='Vector_3' d="M15.3945 3.94669C15.3945 4.5469 15.633 5.12253 16.0574 5.54695C16.4818 5.97136 17.0574 6.20979 17.6576 6.20979C18.2578 6.20979 18.8335 5.97136 19.2579 5.54695C19.6823 5.12253 19.9207 4.5469 19.9207 3.94669C19.9207 3.34648 19.6823 2.77085 19.2579 2.34644C18.8335 1.92203 18.2578 1.68359 17.6576 1.68359C17.0574 1.68359 16.4818 1.92203 16.0574 2.34644C15.633 2.77085 15.3945 3.34648 15.3945 3.94669Z" stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
					<path id="Vector_4" d="M17.6577 10.1702H19.9208C20.521 10.1702 21.0967 10.4086 21.5211 10.833C21.9455 11.2574 22.1839 11.8331 22.1839 12.4333V13.5648" stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
					<path id='Vector_5' d="M4.0791 3.94669C4.0791 4.5469 4.31753 5.12253 4.74195 5.54695C5.16636 5.97136 5.74199 6.20979 6.3422 6.20979C6.94241 6.20979 7.51804 5.97136 7.94245 5.54695C8.36687 5.12253 8.6053 4.5469 8.6053 3.94669C8.6053 3.34648 8.36687 2.77085 7.94245 2.34644C7.51804 1.92203 6.94241 1.68359 6.3422 1.68359C5.74199 1.68359 5.16636 1.92203 4.74195 2.34644C4.31753 2.77085 4.0791 3.34648 4.0791 3.94669Z" stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
					<path id='Vector_6' d="M1.81616 13.5648V12.4333C1.81616 11.8331 2.05459 11.2574 2.47901 10.833C2.90342 10.4086 3.47905 10.1702 4.07926 10.1702H6.34236" stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round'/>
				</g>
				<defs>
					<clipPath id='clip0_195_1911'>
					<rect width='24' height='24' fill='transparent' transform='translate(0 0.433594)'/>
					</clipPath>
				</defs>
				</svg>      
    </SvgIcon>
  );
}

export default TeamIcon;