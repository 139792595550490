import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';

/**
 * Creates styles for the DeviceListTableHeader component.
 *
 * @returns {object} An object containing styles for the user header and option menu button.
 */
const useStyles = () => {

  const commonStyles = useCommonStyles();

  const styles = {
    userHeader: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg':{
        ...commonStyles.smIcon,
        ml: Dimens.spacing.base
      }
    },
    optionMenuBtn: {
      '& svg':{
        ...commonStyles.smIcon
      }
    }
  };

  return styles;
};

export default useStyles;
