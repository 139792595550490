import { SxProps, Theme, useTheme } from '@mui/material';

import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';

/**
 * useStyles custom hook for CallLogDetailDialog styling.
 *
 * @returns {object} - An object containing styled classes for the dialog.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { base, lg, section } = Dimens.spacing;

  const styles: { [key: string]: SxProps<Theme> } = {
    dialog: {
      zIndex: 1,
      ml: Dimens.drawer.parentWidth,
      [theme.breakpoints.down('sm')]: {
        ml: 0
      },
      '& .MuiDialog-paper': {
        minWidth: '100%',
          height: '100%',
          minHeight: '100%',
      }
    },
    container: {
      bgcolor: theme.palette.background.paper,
      height: 'inherit',
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      ...commonStyles.noScrollBar,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      p: section,
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    },
    tableContainer: {
      flex: 1,
      backgroundImage: 'none',
      border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderRadius: Dimens.radius.md,
      boxShadow: 'none',
      '& th': {
        bgcolor: theme.palette.background.paper,
        borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        zIndex: 1,
        p: lg
      },
      '& td': {
        borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        p: lg,
        verticalAlign: 'top'
      },
      '& tbody': {
        '& tr': {
          '&:hover': {
            bgcolor: theme.palette.background.default
          }
        }
      }
    },
    userHeader: {
      display: 'inline-flex',
      flexDirection: 'row',
      alignItems: 'center',
      cursor: 'pointer',
      '& svg': {
        ...commonStyles.smIcon,
        ml: base
      }
    },
    basicInfoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      px: section,
      [theme.breakpoints.down('sm')]: {
        px: lg
      }
    },
    basicInfoLayout: {
      display: 'flex',
      flexDirection: 'row',
      pb: lg,
      '& >.MuiBox-root': {
        mr: lg,
        '&:last-of-type': {
          mr: 0
        }
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        '& >.MuiBox-root': {
          mr: 0
        }
      }
    },
    basicInfoInnerLayout: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      '& >.MuiBox-root': {
        flex: 1,
        mr: lg,
        '&:last-of-type': {
          mr: 0
        }
      },
      [theme.breakpoints.down('md')]: {
        mt: lg
      }
    },
    btnWrapper: {
      p: section,
      pt: 0,
      [theme.breakpoints.down('sm')]: {
        p: lg,
        pt: 0
      }
    }
  };

  return styles;
};

export default useStyles;