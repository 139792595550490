import * as TablerIcons from '@tabler/icons-react';

import Dimens from '../../theme/dimens';
import React, { } from 'react';
import { TablerIconType } from '../../types/tabler-icon-type';

/**
 * Interface defining the props for the CustomTablerIcon component.
 * @property {TablerIconType} name - The name of the Tabler icon to render. Defaults to 'IconBrain' if not provided.
 * @property {string} size - The size of the icon. Defaults to the large icon size from Dimens if not provided.
 */
interface Props {
  name?: TablerIconType;
  size?: string;
}

/**
 * CustomTablerIcon component renders a Tabler icon with a specified name and size.
 * It provides a default icon and size if not specified in the props.
 * @param {Props} props - The props passed to the component.
 * @returns {JSX.Element} The rendered Tabler icon component.
 */
const CustomTablerIcon: React.FC<Props> = (props: Props) => {

  const { name, size } = props;
  const IconComponent = TablerIcons[
    (name ?? TablerIcons.IconBrain) as keyof typeof TablerIcons
  ] as React.FC<TablerIcons.IconProps>;

  return <IconComponent size={size ?? Dimens.icon.lg} />;
};

export default CustomTablerIcon;
