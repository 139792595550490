import { Box, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TAB_TEAM_DETAIL, TAB_TEAM_MEMBER } from '../../../utils/constants';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import AlertCircleIcon from '../../../components/CustomIcons/AlertCircleIcon';
import AlertDialogSmall from '../../../components/AlertDialogSmall';
import DetailsTab from './DetailsTab';
import MembersTab from './MembersTab';
import RightDrawerLayout from '../../../layouts/RightDrawerLayout';
import { TEAM_TAB_LIST } from '../../../utils/ui-constants';
import Team from '../../../types/team';
import useStyles from './styles';
import { useTeamApi } from '../../../data/team/api';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	team: Team;
	onClose: () => void;
}

/**
 * TeamEditPanel component for managing team details.
 *
 * @param {Props} props - Component props.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const TeamEditPanel: React.FC<Props> = (props: Props) => {

	const { open, team, onClose } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [selectedTab, setSelectedTab] = useState(TAB_TEAM_DETAIL);
	const [detailsEdited, setDetailsEdited] = useState<boolean>(false);
	const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);
	const teamApi = useTeamApi();

	/**
	 * Fetches team details if the provided team object is incomplete.
	 */
	useEffect(() => {
		const teamDetail = getTeamDetail();
		if (open && team?.teamKey && (!teamDetail || !teamDetail?.isFetched)) {
			teamApi.fetchTeamDetail(team?.teamKey);
		}
	}, [open]);

	/**
	 * Fetches team details if not already available or incomplete.
	 *
	 * @returns {Team | null} The fetched team details or null if not found or loading.
	 */
	const getTeamDetail = () => {
		const detail = teamApi.state.team.teamList.find(item => item.teamKey === team?.teamKey);
		
		return detail;
	}

	const teamDetail = getTeamDetail();

	/**
   * Renders the appropriate TabPanel content based on the selected tab ID.
   *
   * @param {string} tabId - ID of the selected tab.
	 * 
   * @returns {JSX.Element} The content for the TabPanel.
   */
	const getTabPanel = (tabId: string) => {
		if(teamDetail) {
			switch (tabId) {
				case TAB_TEAM_DETAIL:
					return <DetailsTab team={teamDetail} onChange={setDetailsEdited} />;
				case TAB_TEAM_MEMBER:
					return <MembersTab team={teamDetail} />;
			}
		}
	}

	/**
	 * Handles the logic for closing the tab.
	 * If there are unsaved changes (`detailsEdited` is true), it prompts the user with a save dialog.
	 * Otherwise, it resets the state and closes the tab.
	 */
	const onTabClose = () => {
		if (detailsEdited) {
				setShowSaveDialog(true);
		} else {
				setDetailsEdited(false);
				setShowSaveDialog(false);
				teamApi.resetError();
				onClose();
		}
	};

	/**
	* Handles closing the tab without saving.
	* Resets the state and closes the tab immediately.
	*/
	const onLeaveWithoutSaving = () => {
		setDetailsEdited(false);
		setShowSaveDialog(false);
		onClose();
	};

	return (
		<RightDrawerLayout open={open} title={t('editTeam')} onClose={onTabClose}>
			{open && team &&
				<TabContext value={selectedTab}>
					<TabList variant='scrollable' onChange={(_, value) => setSelectedTab(value)} sx={styles.tabList}>
						{
							TEAM_TAB_LIST.map((item, index) => (
								<Tab key={`team_tab_${index}`} value={item.id} label={item.label} sx={styles.tab} />
							))
						}
					</TabList>
					<Box sx={styles.tabListWrapper}>
						{
							TEAM_TAB_LIST.map((item, index) => (
								<TabPanel key={`team${index}`} value={item.id} sx={styles.tabPanel}>
									{getTabPanel(item.id)}
								</TabPanel>
							))
						}
					</Box>
				</TabContext>
			}
			<AlertDialogSmall
				open={showSaveDialog}
				title={t('leaveWithouSave')}
				titleIcon={<AlertCircleIcon />}
				message={t('leaveWithouSaveMsg')}
				secondaryLabel={t('cancel')}
				onSecondaryAction={() => setShowSaveDialog(false)}
				primaryLabel={t('leave')}
				onPrimaryAction={onLeaveWithoutSaving}
				onClose={() => setShowSaveDialog(false)}
			/>
		</RightDrawerLayout>
	);
};

export default TeamEditPanel;