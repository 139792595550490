import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const DashboardIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M3.3125 2.5H10.5625C11.0432 2.5 11.5042 2.68437 11.8441 3.01256C12.184 3.34075 12.375 3.78587 12.375 4.25V14.75C12.375 15.2141 12.184 15.6593 11.8441 15.9874C11.5042 16.3156 11.0432 16.5 10.5625 16.5H3.3125C2.8318 16.5 2.37078 16.3156 2.03087 15.9874C1.69096 15.6593 1.5 15.2141 1.5 14.75V4.25C1.5 3.78587 1.69096 3.34075 2.03087 3.01256C2.37078 2.68437 2.8318 2.5 3.3125 2.5Z" stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
				<path d="M3.3125 23.5H10.5625C11.0432 23.5 11.5042 23.6844 11.8441 24.0126C12.184 24.3408 12.375 24.7859 12.375 25.25V28.75C12.375 29.2141 12.184 29.6593 11.8441 29.9874C11.5042 30.3156 11.0432 30.5 10.5625 30.5H3.3125C2.8318 30.5 2.37078 30.3156 2.03087 29.9874C1.69096 29.6593 1.5 29.2141 1.5 28.75V25.25C1.5 24.7859 1.69096 24.3408 2.03087 24.0126C2.37078 23.6844 2.8318 23.5 3.3125 23.5Z" stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
				<path d="M21.4375 16.5H28.6875C29.1682 16.5 29.6292 16.6844 29.9691 17.0126C30.309 17.3408 30.5 17.7859 30.5 18.25V28.75C30.5 29.2141 30.309 29.6593 29.9691 29.9874C29.6292 30.3156 29.1682 30.5 28.6875 30.5H21.4375C20.9568 30.5 20.4958 30.3156 20.1559 29.9874C19.816 29.6593 19.625 29.2141 19.625 28.75V18.25C19.625 17.7859 19.816 17.3408 20.1559 17.0126C20.4958 16.6844 20.9568 16.5 21.4375 16.5Z" stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
				<path d="M21.4375 2.5H28.6875C29.1682 2.5 29.6292 2.68437 29.9691 3.01256C30.309 3.34075 30.5 3.78587 30.5 4.25V7.75C30.5 8.21413 30.309 8.65925 29.9691 8.98744C29.6292 9.31563 29.1682 9.5 28.6875 9.5H21.4375C20.9568 9.5 20.4958 9.31563 20.1559 8.98744C19.816 8.65925 19.625 8.21413 19.625 7.75V4.25C19.625 3.78587 19.816 3.34075 20.1559 3.01256C20.4958 2.68437 20.9568 2.5 21.4375 2.5Z" stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
			</svg>
    </SvgIcon>
  );
}

export default DashboardIcon;