import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * This hook creates a set of styles using Material UI's theme for the ForgotPasswordScreen component.
 * It defines styles for an icon and a spacer element.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    icon: {
      color: theme.palette.primary.main,
      fontSize: Dimens.icon.sm
    },
    spacer: {
      height: Dimens.spacing.container
    }
  };

  return styles;
};

export default useStyles;