import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Styles for the VPermissionTableHeader component.
 * Defines the styles for the table row header, including background color, alignment for the last cell, and icon styling.
 *
 * @returns {Object} The styles object.
 */
const useStyles = (isCollapsed = false) => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    rowHeader: {
      bgcolor: theme.palette.background.default,
      borderBottom: isCollapsed?`${Dimens.menuBorderWidth} solid ${theme.palette.background.paper}`: 'none',
      '& td:last-of-type': {
        textAlign: 'right !important'
      },
      '& svg': {
        ...commonStyles.smIcon
      }      
    }, 
    titleRow: {
      borderBottom: isCollapsed 
      ? `${Dimens.smallBorderWidth} solid ${theme.palette.background.paper} !important` 
      : 'none !important'  
    },
    toggleRow : {
      cursor: 'pointer',
      borderBottom: isCollapsed?`${Dimens.smallBorderWidth} solid ${theme.palette.background.paper}`: 'none'      
    }
  };

  return styles;
};

export default useStyles;