import Dimens from '../../../theme/dimens';
import theme from '../../../theme/theme';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SettingMenu component.
 *
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

	const { lg, section, base } = Dimens.spacing;

	const styles = {
		container: {
			justifyContent: 'space-between',
			display: 'flex',
			flexDirection: 'column',
			flex: 1,
			p: `0 ${section} ${section} ${section}`,
			[ theme.breakpoints.down('sm') ]: {
				p: lg
			}
		},
		singleMenu: {
			pt: base
		}
	};

	return styles;
};

export default useStyles;
