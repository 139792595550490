import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const FilterIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M8.49219 9.5L9.49219 9.5L9.49219 7.5L8.49219 7.5L8.49219 9.5ZM1.34566 7.5C0.79338 7.5 0.345665 7.94772 0.345665 8.5C0.345665 9.05228 0.79338 9.5 1.34566 9.5L1.34566 7.5ZM8.49219 7.5L1.34566 7.5L1.34566 9.5L8.49219 9.5L8.49219 7.5Z' fill='currentColor' />
        <path d='M14.6543 9.5C15.2066 9.5 15.6543 9.05228 15.6543 8.5C15.6543 7.94772 15.2066 7.5 14.6543 7.5L14.6543 9.5ZM13.2898 7.5L12.2898 7.5L12.2898 9.5L13.2898 9.5L13.2898 7.5ZM14.6543 7.5L13.2898 7.5L13.2898 9.5L14.6543 9.5L14.6543 7.5Z' fill='currentColor' />
        <circle cx='10.8505' cy='8.49909' r='1.57135' transform='rotate(90 10.8505 8.49909)' stroke='currentColor' strokeWidth='2' />
        <path d='M7.50781 12.4277L6.50781 12.4277L6.50781 14.4277L7.50781 14.4277L7.50781 12.4277ZM14.6543 14.4277C15.2066 14.4277 15.6543 13.98 15.6543 13.4277C15.6543 12.8755 15.2066 12.4277 14.6543 12.4277L14.6543 14.4277ZM7.50781 14.4277L14.6543 14.4277L14.6543 12.4277L7.50781 12.4277L7.50781 14.4277Z' fill='currentColor' />
        <path d='M1.3457 12.4277C0.793418 12.4277 0.345703 12.8754 0.345703 13.4277C0.345703 13.98 0.793418 14.4277 1.3457 14.4277L1.3457 12.4277ZM2.71023 14.4277L3.71023 14.4277L3.71023 12.4277L2.71023 12.4277L2.71023 14.4277ZM1.3457 14.4277L2.71023 14.4277L2.71023 12.4277L1.3457 12.4277L1.3457 14.4277Z' fill='currentColor' />
        <circle cx='5.14948' cy='13.4286' r='1.57135' transform='rotate(-90 5.14948 13.4286)' stroke='currentColor' strokeWidth='2' />
        <path d='M7.50781 2.57227L6.50781 2.57227L6.50781 4.57227L7.50781 4.57227L7.50781 2.57227ZM14.6543 4.57227C15.2066 4.57227 15.6543 4.12455 15.6543 3.57227C15.6543 3.01998 15.2066 2.57227 14.6543 2.57227L14.6543 4.57227ZM7.50781 4.57227L14.6543 4.57227L14.6543 2.57227L7.50781 2.57227L7.50781 4.57227Z' fill='currentColor' />
        <path d='M1.3457 2.57227C0.793418 2.57227 0.345703 3.01998 0.345703 3.57227C0.345703 4.12455 0.793418 4.57227 1.3457 4.57227L1.3457 2.57227ZM2.71023 4.57227L3.71023 4.57227L3.71023 2.57227L2.71023 2.57227L2.71023 4.57227ZM1.3457 4.57227L2.71023 4.57227L2.71023 2.57227L1.3457 2.57227L1.3457 4.57227Z' fill='currentColor' />
        <circle cx='5.14948' cy='3.57123' r='1.57135' transform='rotate(-90 5.14948 3.57123)' stroke='currentColor' strokeWidth='2' />
      </svg>
    </SvgIcon>
  );
}

export default FilterIcon;