import AppRoutes from './routes';
import { CssBaseline } from '@mui/material';
import { HashRouter } from 'react-router-dom';
import React from 'react';
import SessionExpirationProvider from './store/session-expiration-provider';
import { StoreProvider } from './store/store';
import { ThemeProvider } from '@mui/material/styles';
import { colorModeManager } from './theme/color-mode-manager';
import theme from './theme/theme';

/**
 * App component is the root component of the application.
 *  - It wraps the entire application with necessary providers and routing configurations.
 *  - Leverages useMemo hook to optimize theme updates based on color mode changes.
 */
const App: React.FC = () => {
  // Update the theme only if the mode changes
  const appTheme = React.useMemo(() => theme, [colorModeManager.get()]);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <SessionExpirationProvider>
        <StoreProvider>
          <HashRouter>
            <AppRoutes />
          </HashRouter>
        </StoreProvider>
      </SessionExpirationProvider>
    </ThemeProvider>
  );
};

export default App;
