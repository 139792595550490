import Dimens from '../../../../../theme/dimens';
import useCommonStyles from '../../../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the TeamAddMemberPanel component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      px: Dimens.spacing.section,
      pt: Dimens.spacing.lg,
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        px: Dimens.spacing.lg
      }
    },
    contentWrapper: {
      ...commonStyles.noScrollBar,    
      overflowY: 'auto',
      pt: Dimens.spacing.lg
    }
  };

  return styles;
};

export default useStyles;
