import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

import Dimens from '../../../theme/dimens';
import React, {  } from 'react';
import UserType from '../../../types/user-type';

interface Props {
  userTypeList: Array<UserType>;
}

/**
 * Header component for the HPermissionTable.
 * This component renders the table headers, displaying the list of user types.
 *
 * @param {Props} props Component props containing the user type list.
 * 
 * @returns {JSX.Element} The JSX element for the table header.
 */
const HPermissionTableHeader: React.FC<Props> = (props: Props) => {

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {props.userTypeList.map((userType, index) => (
          <TableCell key={`permission-table-header-${index}`}>
            <Typography variant='p1' fontWeight={Dimens.fontWeight.medium}>{userType.userType}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default HPermissionTableHeader;
