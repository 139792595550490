import AppError from '../../exception/app-error';
import ChatHistoryDispatchPayload from '../../types/chat-history-dispatch-payload';
import ConversationData from '../../types/conversation-data';
import MarkChatAsDeliveredReadRequest from '../../types/mark-chat-as-delivered-read-request';
import MarkChatAsDeliveredReadResponse from '../../types/mark-chat-as-delivered-read-response';
import MessageData from '../../types/message-data';
import SessionStatusRequest from '../../types/session-status-request';

export const UPDATE_SESSION_STATUS = 'UPDATE_SESSION_STATUS';
export const FETCH_INTERACTION_HISTORY = 'FETCH_INTERACTION_HISTORY';
export const FETCH_CHAT_HISTORY = 'FETCH_CHAT_HISTORY';
export const CHAT_SEND_API = 'CHAT_SEND_API';
export const MARK_CHAT_AS_READ = 'MARK_CHAT_AS_READ';
export const UPDATE_SESSION_STATUS_SUCCESS = 'UPDATE_SESSION_STATUS_SUCCESS';
export const INTERACTION_HISTORY_SUCCESS = 'INTERACTION_HISTORY_SUCCESS';
export const CHAT_HISTORY_SUCCESS = 'CHAT_HISTORY_SUCCESS';
export const CHAT_SEND_SUCCESS = 'CHAT_SEND_SUCCESS';
export const CHAT_SEND_FAILED = 'CHAT_SEND_FAILED';
export const MARK_RECEIVED_CHAT_AS_READ_SUCCESS = 'MARK_RECEIVED_CHAT_AS_READ_SUCCESS';
export const MARK_SENT_CHAT_AS_READ_SUCCESS = 'MARK_SENT_CHAT_AS_READ_SUCCESS';
export const FETCH_MESSAGE_INFO = 'FETCH_MESSAGE_INFO';
export const MESSAGE_INFO_SUCCESS = 'MESSAGE_INFO_SUCCESS';
export const UPDATE_MESSAGE_ATTACHMENT = 'UPDATE_MESSAGE_ATTACHMENT';
export const API_FAILURE = 'INBOX_API_FAILURE';
export const RESET_INBOX_STATE = 'RESET_INBOX_STATE';
export const MARK_CHAT_AS_DELIVERED = 'MARK_CHAT_AS_DELIVERED';
export const MARK_RECEIVED_CHAT_AS_DELIVERED_SUCCESS = 'MARK_RECEIVED_CHAT_AS_DELIVERED_SUCCESS';
export const MARK_SENT_CHAT_AS_DELIVERED_SUCCESS = 'MARK_SENT_CHAT_AS_DELIVERED_SUCCESS';
export const FETCH_UNDELIVERED_INTERACTION_HISTORY = 'FETCH_UNDELIVERED_INTERACTION_HISTORY';
export const UNDELIVERED_INTERACTION_HISTORY_SUCCESS = 'FETCH_UNDELIVERED_INTERACTION_HISTORY';

export interface UpdateSessionStatusAction {
  type: typeof UPDATE_SESSION_STATUS;
}

export interface FetchInteractionHistoryAPIAction {
  type: typeof FETCH_INTERACTION_HISTORY;
}

export interface FetchChatHistoryAPIAction {
  type: typeof FETCH_CHAT_HISTORY;
}

export interface ChatSendAPIAction {
  type: typeof CHAT_SEND_API;
  payload: MessageData;
}

export interface MarkChatAsReadAction {
  type: typeof MARK_CHAT_AS_READ;
}

export interface FetchMessageInfoAction {
  type: typeof FETCH_MESSAGE_INFO;
}

export interface UpdateSessionStatusSuccessAction {
  type: typeof UPDATE_SESSION_STATUS_SUCCESS;
  payload: SessionStatusRequest;
}

export interface InteractionHistorySuccessAction {
  type: typeof INTERACTION_HISTORY_SUCCESS;
  payload: Array<ConversationData>;
}

export interface ChatHistorySuccessAction {
  type: typeof CHAT_HISTORY_SUCCESS;
  payload: ChatHistoryDispatchPayload;
}

export interface ChatSendSuccessAction {
  type: typeof CHAT_SEND_SUCCESS;
  payload: MessageData;
}

export interface ChatSendFailureAction {
  type: typeof CHAT_SEND_FAILED;
  payload: MessageData;
}

export interface MarkReceivedChatAsReadSuccessAction {
  type: typeof MARK_RECEIVED_CHAT_AS_READ_SUCCESS;
  payload: MarkChatAsDeliveredReadRequest;
}

export interface MarkSentChatAsReadSuccessAction {
  type: typeof MARK_SENT_CHAT_AS_READ_SUCCESS;
  payload: MarkChatAsDeliveredReadResponse;
}

export interface MessageInfoSuccessAction {
  type: typeof MESSAGE_INFO_SUCCESS;
  payload: MessageData;
}

export interface UpdateMessageAttachmentAction {
  type: typeof UPDATE_MESSAGE_ATTACHMENT;
  payload: MessageData;
}

export interface ApiFailureAction {
  type: typeof API_FAILURE;
  payload: AppError;
}

export interface ResetInboxStateAction {
  type: typeof RESET_INBOX_STATE;
}
export interface FetchUndeliveredInteractionHistoryAPIAction {
  type: typeof FETCH_UNDELIVERED_INTERACTION_HISTORY;
}

export interface MarkChatAsDeliveredAction {
  type: typeof MARK_CHAT_AS_DELIVERED;
}

export interface MarkReceivedChatAsDeliveredSuccessAction {
  type: typeof MARK_RECEIVED_CHAT_AS_DELIVERED_SUCCESS;
  payload: MarkChatAsDeliveredReadRequest;
}

export interface MarkSentChatAsDeliveredSuccessAction {
  type: typeof MARK_SENT_CHAT_AS_DELIVERED_SUCCESS;
  payload: MarkChatAsDeliveredReadResponse;
}

export interface UndeliveredInteractionHistorySuccessAction {
  type: typeof UNDELIVERED_INTERACTION_HISTORY_SUCCESS;
}

export type InboxActionTypes = UpdateSessionStatusAction | FetchInteractionHistoryAPIAction | FetchChatHistoryAPIAction
  | ChatSendAPIAction | MarkChatAsReadAction | UpdateSessionStatusSuccessAction | MarkReceivedChatAsReadSuccessAction
  | MarkSentChatAsReadSuccessAction | InteractionHistorySuccessAction | ChatHistorySuccessAction | ChatSendSuccessAction
  | ChatSendFailureAction | FetchMessageInfoAction | MessageInfoSuccessAction | UpdateMessageAttachmentAction
  | ApiFailureAction | ResetInboxStateAction | FetchUndeliveredInteractionHistoryAPIAction | MarkChatAsDeliveredAction
  | MarkReceivedChatAsDeliveredSuccessAction | MarkSentChatAsDeliveredSuccessAction
  | UndeliveredInteractionHistorySuccessAction;