import { SxProps, Theme, useMediaQuery, useTheme } from '@mui/material';

import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';

/**
 * useStyles custom hook for UserSearchAndFilterHeader styling.
 *
 * @returns {object} - An object containing styled classes for the dialog.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { base, xl, section, lg } = Dimens.spacing;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const styles: { [key: string]: SxProps<Theme> } = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto'
    },
    tabSearchContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      px: section,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        px: lg
      }
    },
    searchLayout: {
      display: 'flex',
      flexDirection: 'row',
      ml: section,
      [theme.breakpoints.down('sm')]: {
        ml: 0,
        mt: lg
      }
    },
    searchInput: {
      mr: base,
      flex: 1
    },
    filterBtn: {
      p: 0,
      'svg': {
        ...commonStyles.smIcon,
        color: theme.palette.text.secondary
      }
    },
    resetBtn: {
      right: 0,
      top: 0,
      p: xl,
      color: theme.palette.primary.main,
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.dark
      },
      [theme.breakpoints.up('sm')]: {
        position: 'absolute'
      },
      [theme.breakpoints.down('sm')]: {
        p: 0,
        position: 'relative',
        alignItems: 'center'
      }
    },
    form: {
      ...commonStyles.defaultBorder,
      overflowY: 'auto',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      position: 'relative',
      margin: isMobile ? `${lg} ${lg} 0 ${lg}` : `${section} ${section} 0 ${section}`
    },
    sortFilterContent: {
      ...commonStyles.noScrollBar,
      flex: 1,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'row',
      px: xl,
      pt: xl,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column'
      },
      [theme.breakpoints.down('sm')]: {
        px: lg,
        pt: lg,
        flexDirection: 'column'
      }
    },
    content: {
      flexDirection: 'row',
      display: 'flex',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
      '& >.MuiStack-root': {
        flex: 1
      }
    },
    spacer: {
      width: lg,
      [theme.breakpoints.down('sm')]: {
        width: 0
      }
    },
    applyBtn: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      m: xl,
      [theme.breakpoints.down('sm')]: {
        m: lg,
        justifyContent: 'space-between'
      }
    },
    searchButton: {
      minWidth: Dimens.squareButton
    }
  };

  return styles;
};

export default useStyles;