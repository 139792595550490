import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const PlayIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M2.98264 1.77797V14.222C2.9826 14.3604 3.01948 14.4963 3.08948 14.6157C3.15949 14.7351 3.26007 14.8336 3.38086 14.9011C3.50166 14.9686 3.63828 15.0027 3.77663 14.9998C3.91499 14.9969 4.05007 14.9572 4.16793 14.8847L14.2787 8.66265C14.392 8.59306 14.4855 8.49562 14.5504 8.37961C14.6152 8.26361 14.6493 8.13291 14.6493 8C14.6493 7.86709 14.6152 7.73639 14.5504 7.62039C14.4855 7.50438 14.392 7.40694 14.2787 7.33735L4.16793 1.11533C4.05007 1.04281 3.91499 1.00306 3.77663 1.00017C3.63828 0.99728 3.50166 1.03136 3.38086 1.09889C3.26007 1.16641 3.15949 1.26495 3.08948 1.38432C3.01948 1.5037 2.9826 1.63959 2.98264 1.77797Z' fill='currentColor' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
}

export default PlayIcon;