import Dimens from '../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the CarouselItem component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const { xs, lg, card } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    content: {
      height: Dimens.carousel.smImageWrapperHeight,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        height: 'auto'
      },
      'img': {
        pb: lg,
        height: Dimens.carousel.smImageHeight,
        [theme.breakpoints.up('sm')]: {
          height: 'auto'
        },
        [theme.breakpoints.up('md')]: {
          maxHeight: '100%',
          maxWidth: '100%',
          p: Dimens.welcomeImagePadding
        }
      }
    },
    image: {
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },
    contentTextWrapper: {
      zIndex: 1,
      width: Dimens.carousel.contentWidth,
      'h2': {
        pb: xs
      },
      [theme.breakpoints.down('md')]: {
        px: lg
      }
    },
    imageContainer: {
      [theme.breakpoints.up('md')]: {
        // height: '100%',
        width: '50%',
        position: 'absolute',
        top: '0',
        bottom: '0',
        right: '0',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden'
      }
    }
  };

  return styles;
};

export default useStyles;