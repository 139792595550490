import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the SettingScreen component.
 *
 * @returns An object containing the styled classes.
 */
const useStyles = () => {
	const theme = useTheme();

	const styles = {
		wrapper: {
			display: 'flex',
			flexDirection: 'row',
			flexGrow: 1,
			height: '100%'
		},
		leftPane: {
			p: 0,
			flex: 0.3,
			display: 'flex',
			height: 'inherit',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			bgcolor: theme.palette.background.paper,
			[ theme.breakpoints.down('md') ]: {
				flex: 0.7
			},
			[ theme.breakpoints.down('sm') ]: {
				flex: 1,
				bgcolor: theme.palette.background.paper
			}
		},
		rightPane: {
			flex: 0.7,
			display: 'flex',
			height: 'inherit',
			bgcolor: theme.palette.primary.light,
			[ theme.breakpoints.down('md') ]: {
				flex: 0.3
			},
			[ theme.breakpoints.down('sm') ]: {
				display: 'none'
			}
		},
		image: {
			flex: 1,
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			[ theme.breakpoints.down('md') ]: {
				display: 'none'
			}
		}
	};

	return styles;
};

export default useStyles;