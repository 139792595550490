import { AppFeature, DashboardItemType, TAB_DASHBOARD_NEW, dashboardInitialValue, emergencyCallInitialValue } from '../../utils/constants';
import { Box, Divider, Tab, Typography } from '@mui/material';
import { DELETE_DASHBOARD, FETCH_DASHBOARD_LIST } from '../../data/dashboard/action-types';
import React, { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import AddIcon from '../../components/CustomIcons/AddIcon';
import CircularProgressBar from '../../components/CircularProgressBar';
import CreateEditDashboardDialog from './CreateEditDashboardDialog';
import CreateEditEmergencyCallItemDialog from './CreateEditEmergencyCallItemDialog';
import CustomButton from '../../components/CustomButton';
import { Dashboard } from '../../types/dashboard';
import DashboardItemDialog from './DashboardItemDialog';
import DashboardTab from './DashboardTab';
import Dimens from '../../theme/dimens';
import EditIcon from '../../components/CustomIcons/EditIcon';
import LoginUtil from '../../utils/login-util';
import MenuData from '../../types/ui/menu-data';
import Util from '../../utils/util';
import { t } from 'i18next';
import { useDashboardApi } from '../../data/dashboard/api';
import useStyles from './styles';

/**
 * DashboardScreen component displays a screen with a list of dashboards and allows users to interact with them.
 */
const DashboardScreen: React.FC = () => {

  const styles = useStyles();
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>();
  const [selectedTab, setSelectedTab] = useState('');
  const [showDashboardDialog, setShowDashboardDialog] = useState<boolean>(false);
  const [showAddDashboardItemDialog, setShowAddDashboardItemDialog] = useState<boolean>(false);
  const [showAddEmergencyCallDialog, setShowAddEmergencyCallDialog] = useState<boolean>(false);
  const [isCreateDashboard, setIsCreateDashboard] = useState<boolean>(false);
  const dashboardApi = useDashboardApi();
  const apiStatus = dashboardApi.state.dashboard.apiStatus;
  const dashboardList = dashboardApi.state.dashboard.dashboardList;

  // Fetch the dashboard list on component mount.
  useEffect(() => {
    dashboardApi.fetchDashboardList().then(data => {
      if (data && data.length > 0) {
        setSelectedTab(data[0].id);
        setSelectedDashboard(data[0]);
      }
    });
  }, []);

  /**
   * Handles clicks on menu items within a dashboard.
   * @param {MenuData} menu - The menu item data.
   */
  const handleMenuItemClick = (menu: MenuData) => {
    switch (menu.id) {
      case DashboardItemType.Note:
        // TODO:
        break;
      case DashboardItemType.HyperLink:
        // TODO:
        break;
      case DashboardItemType.EmergencyCall:
        setShowAddEmergencyCallDialog(true);
        break;
    }
    setShowAddDashboardItemDialog(false);
  }

  /**
   * Gets the DashboardTab component for a given tab ID.
   * @param {string} tabId - The ID of the tab.
   * @returns {JSX.Element | undefined} The DashboardTab component or undefined if the tab is not found.
   */
  const getTabPanel = (tabId: string) => {
    if (tabId !== TAB_DASHBOARD_NEW && selectedDashboard) {

      return <DashboardTab dashboard={selectedDashboard} />;
    }
  }

  /**
   * Handles changes in the selected tab.
   * @param {string} value - The value of the selected tab (dashboard ID).
   */
  const onTabSelectionChange = (value: string) => {
    if (value === TAB_DASHBOARD_NEW) {
      setIsCreateDashboard(true);
      setShowDashboardDialog(true);
    } else {
      setSelectedTab(value);
      const dashboard = dashboardList.find(item => item.id === value);
      setSelectedDashboard(dashboard);
    }
  }

  /**
   * Handles the click event on the "Edit" button.
   * It sets the `isCreateDashboard` state to false and opens the "New Dashboard" dialog (`showDashboardDialog`).
   * This suggests that the "New Dashboard" dialog is reused for both creating and editing dashboards.
   */
  const handleEditClick = () => {
    setIsCreateDashboard(false);
    setShowDashboardDialog(true);
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant={'h4'} fontWeight={Dimens.fontWeight.bold}>
          {t('dashboard')}
        </Typography>
      </Box>
      {(LoginUtil.hasPermission(AppFeature.ManageDashboard) && Boolean(selectedDashboard?.canEdit)) &&
        <Box sx={styles.headerBtnWrapper}>
          <CustomButton
            color='primary'
            sx={styles.headerBtn}
            title={t('add')}
            startIcon={<AddIcon />}
            onClick={() => setShowAddEmergencyCallDialog(true)}
          />
          <CustomButton
            color='primary'
            title={t('edit')}
            startIcon={<EditIcon />}
            onClick={handleEditClick}
          />
        </Box>
      }
      <TabContext value={selectedTab}>
        <TabList variant='scrollable' onChange={(_, value) => onTabSelectionChange(value)} sx={styles.tabList}>
          {
            dashboardList.map((item, index) => (
              <Tab key={`dashboard_tab_${index}`} value={item.id} label={item.name} sx={styles.tab} />
            ))
          }
          {(LoginUtil.hasPermission(AppFeature.ManageDashboard) && !Util.isArrayEmpty(dashboardList)) &&
            <Divider sx={styles.divider} />
          }
          {LoginUtil.hasPermission(AppFeature.ManageDashboard) &&
            <Tab value={TAB_DASHBOARD_NEW} label={t('new')} icon={<AddIcon />} iconPosition='start' sx={styles.endTab} />
          }
        </TabList>
        <Box sx={styles.tabListWrapper}>
          {
            dashboardList.map((item, index) => (
              <TabPanel key={`dashboard_tab_panel_${index}`} value={item.id ?? ''} sx={styles.tabPanel}>
                {getTabPanel(item.id ?? '')}
              </TabPanel>
            ))
          }
        </Box>
      </TabContext>
      <CircularProgressBar show={Util.isApiLoading([FETCH_DASHBOARD_LIST, DELETE_DASHBOARD], apiStatus)} />
      <CreateEditDashboardDialog
        open={showDashboardDialog}
        dashboard={isCreateDashboard ? dashboardInitialValue : selectedDashboard ?? dashboardInitialValue}
        onClose={() => setShowDashboardDialog(false)}
      />
      <DashboardItemDialog
        open={showAddDashboardItemDialog}
        onClose={() => setShowAddDashboardItemDialog(false)}
        handleMenuItemClick={handleMenuItemClick}
      />
      {selectedDashboard &&
        <CreateEditEmergencyCallItemDialog
          dashboardItem={emergencyCallInitialValue}
          dashboard={selectedDashboard}
          onClose={() => setShowAddEmergencyCallDialog(false)}
          open={showAddEmergencyCallDialog}
        />
      }
    </Box>
  );
};

export default DashboardScreen