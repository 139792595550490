import Dimens from '../../theme/dimens'
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material'

/**
 * useStyles hook defines the styling for the DashboardScreen component.
 * It uses Material UI's `useTheme` hook to access the current theme and custom dimensions from `Dimens`.
 * It also utilizes a custom hook `useCommonStyles` for reusable styles.
 * 
 * @returns {object} An object containing the style definitions.
 */
const useStyles = () => {

  const theme = useTheme();
  const { xs, base, section, lg } = Dimens.spacing;
  const commonStyles = useCommonStyles();

  const styles = {
    container: {
      bgcolor: theme.palette.background.paper,
      height: 'inherit',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      position: 'relative'
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      p: section,
      pb: 0,
      justifyContent: 'space-between',
      alignItems: 'center',
      bgcolor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        p: lg,
        pb: 0
      }
    },
    headerBtnWrapper: {
      position: 'absolute',
      right: 0,
      p: section,
      zIndex: 1
    },
    headerBtn: {
      mr: base
    },
    tabList: {
      borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderColor: theme.palette.background.default,
      px: section,
      minHeight: 0,
      [theme.breakpoints.down('sm')]: {
        px: lg
      }
    },
    tab: {
      ...theme.typography.p1,
      fontWeight: Dimens.fontWeight.medium,
      textTransform: 'none',
      mr: section,
      p: `${base} 0`,
      minHeight: 0,
      minWidth: 0
    },
    endTab: {
      ...theme.typography.p1,
      fontWeight: Dimens.fontWeight.medium,
      textTransform: 'none',
      mr: section,
      p: `${base} 0`,
      minHeight: 0,
      minWidth: 0,
      'svg': {
        ...commonStyles.smIcon,
        '&.MuiTab-iconWrapper': {
          mr: xs
        }
      }
    },
    divider: {
      borderWidth: Dimens.smallBorderWidth,
      my: base,
      mr: section
    },
    tabListWrapper: {
      flex: 1,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column'
    },
    tabPanel: {
      p: 0,
      display: 'contents',
      overflowY: 'auto'
    }
  }

  return styles
}

export default useStyles
