import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const LandlineIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M25.75 3.40625H23.0625C22.3497 3.40625 21.6662 3.6894 21.1622 4.1934C20.6581 4.6974 20.375 5.38098 20.375 6.09375V24.9063C20.375 25.619 20.6581 26.3026 21.1622 26.8066C21.6662 27.3106 22.3497 27.5938 23.0625 27.5938H25.75C26.4628 27.5938 27.1463 27.3106 27.6504 26.8066C28.1544 26.3026 28.4375 25.619 28.4375 24.9063V6.09375C28.4375 5.38098 28.1544 4.6974 27.6504 4.1934C27.1463 3.6894 26.4628 3.40625 25.75 3.40625Z' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M20.375 4.75H5.59375C4.5246 4.75 3.49923 5.17472 2.74323 5.93073C1.98722 6.68673 1.5625 7.7121 1.5625 8.78125V22.2188C1.5625 23.2879 1.98722 24.3133 2.74323 25.0693C3.49923 25.8253 4.5246 26.25 5.59375 26.25H20.375' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M15 10.125H6.9375V14.1563H15V10.125Z' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M15 18.1875V18.2009' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.9688 18.1875V18.2009' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.9375 18.1875V18.2009' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M15 22.2188V22.2322' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10.9688 22.2188V22.2322' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6.9375 22.2188V22.2322' stroke='currentColor' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
      </svg>
    </SvgIcon>
  );
};

export default LandlineIcon;