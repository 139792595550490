import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const NoteIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_254_1337)">
        <path d="M5.05542 5.22222C5.05542 4.36764 5.3949 3.54805 5.99919 2.94377C6.60347 2.33948 7.42306 2 8.27764 2H24.3888C25.2433 2 26.0629 2.33948 26.6672 2.94377C27.2715 3.54805 27.611 4.36764 27.611 5.22222V27.7778C27.611 28.6324 27.2715 29.4519 26.6672 30.0562C26.0629 30.6605 25.2433 31 24.3888 31H8.27764C7.42306 31 6.60347 30.6605 5.99919 30.0562C5.3949 29.4519 5.05542 28.6324 5.05542 27.7778V5.22222Z" stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
        <path d="M11.5 8.44434H21.1667" stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
        <path d="M11.5 14.8889H21.1667" stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
        <path d="M11.5 21.3333H17.9444" stroke='currentColor' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'/>
      </g>
      <defs>
        <clipPath id="clip0_254_1337">
          <rect width="32" height="32" fill="white" transform="translate(0.333252 0.5)"/>
        </clipPath>
      </defs>
      </svg>      
    </SvgIcon>
  );
}

export default NoteIcon;