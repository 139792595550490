import { Box, IconButton, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import CaretDownIcon from '../../../components/CustomIcons/CaretDownIcon';
import CaretUpIcon from '../../../components/CustomIcons/CaretUpIcon';
import Dimens from '../../../theme/dimens';
import DotsVerticalIcon from '../../../components/CustomIcons/DotsVerticalIcon';
import MenuCheckboxDialog from '../../../components/MenuCheckboxDialog';
import MenuData from '../../../types/ui/menu-data';
import React, {  } from 'react';
import { SortOrder } from '../../../utils/constants';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  selectedTab: string;
  columns: Array<MenuData>;
  sortBy: SortOrder;
  onSortChange: () => void;
  onColumnVisibilityChange: (item: MenuData) => void;
}

/**
 * Renders the header row for the user list table.
 *
 * @param {Props} props - Component props containing sort order and sort change handler.
 * 
 * @returns JSX element representing the table header.
 */
const UserListTableHeader: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  /**
   * Handles the click event on a sub-menu item.
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The click event object.
   * 
   * Prevents event propagation and sets the anchor element for the sub-menu.
   */
  const onSubMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		setSubMenuAnchorEl(event.currentTarget);
	}

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Box sx={styles.userHeader} onClick={props.onSortChange}>
            <Typography variant='p1' fontWeight={Dimens.fontWeight.medium}>{t('user')}</Typography>
            {(props.sortBy === SortOrder.Asc) ? <CaretDownIcon /> : <CaretUpIcon />}
          </Box>
        </TableCell>
        {props.columns.filter(column => column.isChecked).map((header, index) => (
          <TableCell key={`user-table-header-${index}`}>
            <Typography variant='p1' fontWeight={Dimens.fontWeight.medium}>{header.value}</Typography>
          </TableCell>
        ))}
        <TableCell sx={styles.optionMenuBtn} align='right'>
          <IconButton onClick={onSubMenuClick}><DotsVerticalIcon /></IconButton>
        </TableCell>
      </TableRow>
      <MenuCheckboxDialog
				anchorEl={subMenuAnchorEl}
				open={Boolean(subMenuAnchorEl)}
				onClose={() => setSubMenuAnchorEl(null)}
				menuList={props.columns}
				onMenuItemSelected={props.onColumnVisibilityChange}
			/>
    </TableHead>
  );
};

export default UserListTableHeader;
