import AppError from '../../exception/app-error';
import Site from '../../types/site';

export const RESET_ERROR_STATE = 'RESET_SITE_ERROR_STATE';
export const FETCH_SITE_LIST = 'FETCH_SITE_LIST';
export const SITE_LIST_SUCCESS = 'SITE_LIST_SUCCESS';
export const FETCH_SITE_DETAIL = 'FETCH_SITE_DETAIL';
export const CREATE_SITE = 'CREATE_SITE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const DELETE_SITE = 'DELETE_SITE';
export const SITE_FAILURE = 'SITE_FAILURE';
export const RESET_SITE_STATE = 'RESET_SITE_STATE';

export interface ResetErrorStateAction {
  type: typeof RESET_ERROR_STATE;
}

export interface FetchSiteListAction {
  type: typeof FETCH_SITE_LIST;
}

export interface SiteListSuccessAction {
  type: typeof SITE_LIST_SUCCESS;
  payload: Array<Site>;
}

export interface FetchSiteDetailAction {
  type: typeof FETCH_SITE_DETAIL;
}

export interface CreateSiteAction {
  type: typeof CREATE_SITE;
}

export interface UpdateSiteAction {
  type: typeof UPDATE_SITE;
}

export interface DeleteSiteAction {
  type: typeof DELETE_SITE;
}

export interface SiteFailureAction {
  type: typeof SITE_FAILURE;
  payload: AppError;
}

export interface SiteStateResetAction {
  type: typeof RESET_SITE_STATE;
}


export type SiteActionTypes = ResetErrorStateAction | FetchSiteListAction | SiteListSuccessAction | CreateSiteAction |
  FetchSiteDetailAction | UpdateSiteAction | DeleteSiteAction | SiteFailureAction | SiteStateResetAction;