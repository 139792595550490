import Dimens from '../../../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * This hook creates styles for the ChildDrawerItem component based on theme and dimensions.
 * It also applies styles conditionally based on the `isActive` prop.
 * 
 * @param {boolean} isActive - Optional prop to indicate if the item is active. Defaults to false.
 * @returns {object} - An object containing styles for the child drawer item elements.
 */
const useStyles = (isActive = false) => {

  const theme = useTheme();

  const styles = {
    listBtn: {
      p: `${Dimens.spacing.md} ${Dimens.spacing.lg}`,
      borderRadius: Dimens.radius.md,
      'svg': {
        fontSize: Dimens.icon.sm
      }
    },
    listIcon: {
      color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
      minWidth: 0,
      'svg': {
        fontSize: Dimens.icon.sm
      }
    },
    listText: {
      color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
      margin: 0,
      marginLeft: Dimens.spacing.base
    },
    list: {
      ml: Dimens.spacing.section
    }
  };

  return styles;
};

export default useStyles;
