import { Box, Card, CardContent, IconButton, ListItemButton, Typography } from '@mui/material';

import CustomTablerIcon from '../../../../components/CustomTablerIcon';
import { DASHBOARD_TAB_SUB_MENU } from '../../../../utils/ui-constants';
import { DashboardItem } from '../../../../types/dashboard-item';
import DotsVerticalIcon from '../../../../components/CustomIcons/DotsVerticalIcon';
import MenuData from '../../../../types/ui/menu-data';
import MenuSelectDialog from '../../../../components/MenuSelectDialog';
import React from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
	data: DashboardItem;
	canEditDashboard: boolean;
	onItemSelected: (data: DashboardItem) => void;
	onSubmenuItemSelected: (menu: MenuData) => void;
}

/**
 * DashboardTabCard component displays a card representing a single item in a dashboard tab.
 * @param {Props} props - The props passed to the component.
 * @returns {JSX.Element} The rendered DashboardTabCard component.
 */
const DashboardTabCard: React.FC<Props> = (props: Props) => {

	const { data, onItemSelected, onSubmenuItemSelected, canEditDashboard, ...rest } = props;
	const styles = useStyles(canEditDashboard);
	const { t } = useTranslation();
	const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

	/**
	 * Handles the click event on the submenu icon.
	 * Stops event propagation to prevent the card's onClick handler from being triggered.
	 * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} e - The mouse event object.
	 */
	const onSubmenuClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		setSubMenuAnchorEl(e.currentTarget);
	}

	/**
	 * Handles the click event on an item within the submenu.
	 * Closes the submenu and calls the `onSubmenuItemSelected` callback with the selected item's data.
	 * @param {MenuData} item - The data of the selected menu item.
	 */
	const handleSubMenuItemClick = (item: MenuData) => {
		setSubMenuAnchorEl(null);
		onSubmenuItemSelected(item);
	}

	return (
		<Box>
			<Card {...rest} sx={styles.card} onClick={(e) => onItemSelected(data)}>
				<ListItemButton sx={styles.cardActionArea}>
					<CardContent sx={styles.cardContent}>
						<IconButton sx={styles.iconToggle} onClick={onSubmenuClick}>
							<DotsVerticalIcon />
						</IconButton>
						<CustomTablerIcon name={data.icon} />
						<Typography variant={'h6'} sx={styles.title}>{data.title}</Typography>
					</CardContent>
				</ListItemButton>
			</Card>
			<MenuSelectDialog
				anchorEl={subMenuAnchorEl}
				open={Boolean(subMenuAnchorEl)}
				onClose={() => setSubMenuAnchorEl(null)}
				menuList={DASHBOARD_TAB_SUB_MENU}
				onMenuItemSelected={handleSubMenuItemClick}
			/>
		</Box>
	);
};

export default DashboardTabCard;