import CloseIconDark from '../../assets/close_icon_dark.svg';
import CloseIconLight from '../../assets/close_icon_light.svg';
import Colors from '../../theme/colors';
import Dimens from '../../theme/dimens';
import ThemeUtil from '../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the TablerIconPicker component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = () => {

  const { xxs, xs, base, md, lg } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    container: {
      // mb: md
    },
    inputWrapper: {
      p: `${md} ${lg}`,      
      mb: xs,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.background.default,
      borderRadius: Dimens.radius.md,
      minWidth: Dimens.searchBarMinWidth,      
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto'
      },
      '& input[type="search"]::-webkit-search-cancel-button': {
        appearance: 'none',
        backgroundImage: `url(${ThemeUtil.isLightTheme(theme) ? CloseIconLight : CloseIconDark})`,
        backgroundSize: 'contain',
        width: lg,
        height: lg,
        cursor: 'pointer',
        opacity: 1,
      }
    },
    input: {
      ...theme.typography.p1,
      flex: 1,
      padding: 0
    },
    selectedIconBox: {
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center'
    },
    iconListCard: { 
      display: 'grid', 
      gridTemplateColumns: `repeat(auto-fit, minmax(${Dimens.avatarSize.xl}, 1fr))`, 
      gap: Dimens.spacing.sm, 
      overflowY: 'auto', 
      maxHeight: Dimens.dropDownMaxHeight,
      p: Dimens.spacing.base,
      borderRadius: Dimens.radius.md,
      bgcolor: theme.palette.background.default,
      boxShadow: `0px ${xxs} ${xs} 0px ${Colors.shadow}`,
    },
    iconBox: { 
      cursor: 'pointer', 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center' , 
      border: `${Dimens.menuBorderWidth} solid ${theme.palette.text.disabled}`, 
      paddingTop: Dimens.spacing.base, 
      paddingBottom: Dimens.spacing.base,
      maxHeight: Dimens.iconListUnitHeight,
      borderRadius: Dimens.radius.md,
      bgcolor: theme.palette.background.default
    },
    icon: {
      fontSize: Dimens.icon.sm,
      color: theme.palette.text.secondary,
      mr: Dimens.spacing.xs
    }   
  };

  return styles;
};

export default useStyles;
